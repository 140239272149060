import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-inline-translation',
  templateUrl: './inline-translation.component.html',
  styleUrls: ['./inline-translation.component.scss']
})
export class InlineTranslationComponent implements OnInit {

  name = 'Inline-Translation';
  url: string = "https://bnstage.bnlawmacau.com/";
  //url: string = "http://dev3.indusnettechnologies.com/lpms/filemaker/public_html/users/?action=dashboard";
  urlSafe: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
