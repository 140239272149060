import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-reports-quote',
  templateUrl: './reports-quote.component.html',
  styleUrls: ['./reports-quote.component.scss']
})
export class ReportsQuoteComponent implements OnInit {
  quoteList: any = [];
  case_type: any = [];
  geniusList: any = [];

  constructor(private data: DataService) { }
  quoteParam: any = {};
  year_arr : any = ['2019','2020','2021'];

  ngOnInit() {
    this.getAllQuotelist();
    this.getCaseTypeList();
    this.listGenius();
  }

  getCaseTypeList(){
    this.data.getTypeOfCase().subscribe(
      data => {
        this.case_type = data;
      }
    );
  }

  listGenius() {
    this.data.getTaskAssignee(3).subscribe(
      data => {
        this.geniusList = data;
      }
    );
  }


  yearChange(year){
    this.quoteParam.year = year;
    
    this.getAllQuotelist();
  }


  caseChange(case_id){

    if (this.quoteParam.year) {
      this.quoteParam.year = this.quoteParam.year;
    }
    
    if (this.quoteParam.assignee_id) {
      this.quoteParam.assignee_id = this.quoteParam.assignee_id;
    } 
    
    this.quoteParam.case_id = case_id;
    this.getAllQuotelist();
  }


  userchange(user){

    this.quoteParam.assignee_id = user;

    if (this.quoteParam.year) {
      this.quoteParam.year = this.quoteParam.year;
    } 
    if (this.quoteParam.case_id) {
      this.quoteParam.case_id = this.quoteParam.case_id;
    } 
    this.getAllQuotelist();
  }


  getAllQuotelist(){
    this.data.getAllQuotelist(this.quoteParam).subscribe(
      data => {
        if(data){
          this.quoteList = data;
         
        }
      }
    );
  }

}
