import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';

import { ReportsDashboardComponent } from './reports-dashboard/reports-dashboard.component';
import { IntellectualPropertyComponent } from './reports-dashboard/intellectual-property/intellectual-property.component';
import { IpMetricsComponent } from './reports-dashboard/ip-metrics/ip-metrics.component';
import { FinancialResultsComponent } from './reports-dashboard/financial-results/financial-results.component';
import { CommandCenterComponent } from './reports-dashboard/command-center/command-center.component';
import { ReportsMatterComponent } from './reports-matter/reports-matter.component';
import { GeneralListComponent } from './reports-matter/general-list/general-list.component';
import { MatterListComponent } from './reports-matter/matter-list/matter-list.component';
import { ReportsClientComponent } from './reports-client/reports-client.component';
import { LitigationComponent } from './reports-dashboard/litigation/litigation.component';
import { CorporateComponent } from './reports-dashboard/corporate/corporate.component';
import { ReportsTaskComponent } from './reports-task/reports-task.component';
import { ReportsQuoteComponent } from './reports-quote/reports-quote.component';
import { ReportsOperationsComponent } from './reports-operations/reports-operations.component';
import { YearlyComponent } from './reports-dashboard/yearly/yearly.component';



@NgModule({
  declarations: [
    ReportsDashboardComponent,
    IntellectualPropertyComponent,
    IpMetricsComponent,
    FinancialResultsComponent,
    CommandCenterComponent,
    ReportsMatterComponent,
    GeneralListComponent,
    MatterListComponent,
    ReportsClientComponent,
    LitigationComponent,
    CorporateComponent,
    ReportsTaskComponent,
    ReportsQuoteComponent,
    ReportsOperationsComponent,
    YearlyComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ChartsModule
  ]
})
export class ReportsModule { }
