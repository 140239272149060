import { Component, OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogServiceService } from "../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  myEmailCheck: any = true;
  selectedFiles: any;
  selectedUploadFiles: any;
  contactOrgForm: FormGroup;
  contactPersonForm: FormGroup;
  allList: any = [];
  fileArr: any = [];
  personList: any = [];
  orgList: any = [];
  orgnazitiontable: boolean = false;
  clientDetails: boolean = false;
  maritialStatus: boolean = false;
  formSubmit: boolean = false;
  moreEmail: boolean = false;
  activeOrg: boolean = false;
  activeP: boolean = false;
  activeAll: boolean = true;
  fileIndex = 1;
  errorClass;
  activeClass: string;
  url: any;
  countryList: any;
  searchType: String;
  peopleListing: boolean = false;
  orgListing: boolean = false;
  allListing: boolean = false;
  succ_msg: any;
  personDetails: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  allListCount;

  common_err_msg: any;
  contactParam: any = {};

  p: number = 0;
  itemsPerPage: number = 100;
  importFile: any;

  pstart: number = 0;
  pend: number = 100;

  @ViewChild("peopleImportInput", { static: false }) peopleImportInput: any;
  @ViewChild("orgImportInput", { static: false }) orgImportInput: any;

  personId: number = 0;
  orgId: number = 0;
  personDetailsId: number = 0;
  orgDetailsId: number = 0;
  orgBCheckId: number = 0;
  personBCheckId: number = 0;
  personView: boolean = false;
  orgView: boolean = false;
  sort_order: any;

  state$: Observable<any>;
  msgg: any;

  subs: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    if (this.subs["state"]) {
      this.subs["state"].unsubscribe();
    }
    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );

    this.subs["state"] = this.state$.subscribe((res) => (this.msgg = res));

    this.contactParam.pageNo = this.p;

    $(document).ready(function () {
      $(".contact-box h4").click(function () {
        $(this).toggleClass("active");
        $(this).parent().toggleClass("active");
        return false;
      });

      $(".hide").click(function () {
        $(this).toggleClass("active");
        $(this).parent().parent().parent().parent().toggleClass("active");
        return false;
      });
    });

    // Set for all contacts
    localStorage.setItem("contact_type", "");

    this.getAllList();
    this.getAllTotalListCount();
    this.searchType = "all";
    this.allListing = true;
  }

  peopleEdit(id) {
    if (id != 0) {
      this.personId = id;
    } else {
      this.personId = 0;
    }
    
      
  }

  orgEdit(id) {
    if (id != 0) {
      this.orgId = id;
    } else {
      this.orgId = 0;
    }
    
  }

  hideModal() {
    this.personId = 0;
    this.orgId = 0;
    this.personDetailsId = 0;
    this.orgDetailsId = 0;
    this.orgView = false;
    this.personView = false;
    this.getAllList();
    jQuery(
      "#personModal, #organizationsModal, #importModal",
      "#contactsViewModal"
    ).modal("hide");
  }

  paginationFeed(page, pageType) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    let end = start + this.itemsPerPage;
    this.contactParam.pageNo = page - 1;

    this.pstart = this.itemsPerPage * this.p + this.itemsPerPage;
    this.pend = this.itemsPerPage;

    this.contactParam.pstart = this.pstart;
    this.contactParam.pend = this.pend;
    if (pageType == "person") {
      this.getPersonList();
    } else if (pageType == "org") {
      this.getOrgListing();
    } else {
      this.getAllList();
    }
  }

  getAllList() {
    if (this.subs["allList"]) {
      this.subs["allList"].unsubscribe();
    }

    this.contactParam.contact_type = localStorage.getItem("contact_type")
      ? localStorage.getItem("contact_type")
      : "";
    this.contactParam.pstart = this.pstart;
    this.contactParam.pend = this.pend;
    this.subs["allList"] = this.data
      .getAllListing(this.contactParam)
      .subscribe((data) => {
        if (this.contactParam.contact_type == "ORG") {
          this.orgList = data;
        } else if (this.contactParam.contact_type == "PERS") {
          this.personList = data;
        } else {
          this.allList = data;
        }
        this.getAllTotalListCount();
      });
  }

  getAllTotalListCount() {
    if (this.subs["allListCount"]) {
      this.subs["allListCount"].unsubscribe();
    }
    this.contactParam.contact_type = "";
    this.subs["allListCount"] = this.data
      .getAllTotalCount(this.contactParam)
      .subscribe((datacnt) => {
        this.allListCount = datacnt;
      });
  }

  getOrgListing() {
    localStorage.setItem("contact_type", "ORG");
    this.getAllList();
  }

  getPersonList() {
    localStorage.setItem("contact_type", "PERS");
    this.getAllList();

    /*if (this.subs["perList"]) {
      this.subs["perList"].unsubscribe();
    }
    this.contactParam.contact_type = "PERS";
    this.subs["perList"] = this.data
      .getAllListing(this.contactParam)
      .subscribe((data) => {
        this.personList = data;
      });*/
  }

  viewDetails(val, dId) {
    if (val == "personView") {
      this.personDetailsId = dId;
      this.personView = true;
      this.orgView = false;
    } else if (val == "orgView") {
      this.orgDetailsId = dId;
      this.orgView = true;
      this.personView = false;
    }
  }

  filterClick(val) {
    if (val == "people") {
      this.getPersonList();
      this.activeP = true;
      this.activeOrg = false;
      this.activeAll = false;
      this.searchType = "people";
      this.peopleListing = true;
      this.orgListing = false;
      this.allListing = false;
    } else if (val == "org") {
      this.getOrgListing();
      this.activeOrg = true;
      this.activeP = false;
      this.activeAll = false;
      this.searchType = "org";
      this.peopleListing = false;
      this.orgListing = true;
      this.allListing = false;
    } else {
      localStorage.setItem("contact_type", "");
      this.getAllList();
      this.activeAll = true;
      this.activeOrg = false;
      this.activeP = false;
      this.searchType = "all";
      this.peopleListing = false;
      this.orgListing = false;
      this.allListing = true;
    }
  }

  searchAuditUnit(value, listType) {
    var s_key = jQuery("#searchKey").val();
    this.contactParam.selectedSearchKey = s_key;
    this.contactParam.searchString = value;
    if (listType == "people") {
      this.getPersonList();
    } else if (listType == "org") {
      this.getOrgListing();
    } else {
      this.getAllList();
    }
  }

  sort(val: any, sortType, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.contactParam.sortBy = i;
      if (this.contactParam.sortOrder == "desc") {
        this.contactParam.sortOrder = "asc";
      } else if (this.contactParam.sortOrder == "asc") {
        this.contactParam.sortOrder = "desc";
      } else {
        this.contactParam.sortOrder = "asc";
      }
    }
    if (sortType == "people") {
      this.getPersonList();
    } else if (sortType == "org") {
      this.getOrgListing();
    } else {
      this.getAllList();
    }
  }

  sortAll(field1: any, field2: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }

    for (var f1 in field1) {
      this.contactParam.sortBy = f1;
    }
    for (var f2 in field2) {
      this.contactParam.sortBy1 = f2;
    }
    if (this.contactParam.sortOrder == "desc") {
      this.contactParam.sortOrder = "asc";
    } else if (this.contactParam.sortOrder == "asc") {
      this.contactParam.sortOrder = "desc";
    } else {
      this.contactParam.sortOrder = "asc";
    }
    this.getAllList();
  }

  openConfirmationDialog(pid) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(pid, confirmed))
      .catch(() => {});
  }

  deleteListItem(pid, confirmed) {
    if (this.subs["delItem"]) {
      this.subs["delItem"].unsubscribe();
    }
    this.subs["delItem"] = this.data
      .getContactDetails(pid, "All")
      .subscribe((data) => {
        this.personDetails = data;
      });
    if (confirmed) {
      if (this.subs["delList"]) {
        this.subs["delList"].unsubscribe();
      }
      if (this.subs["delND"]) {
        this.subs["delND"].unsubscribe();
      }
      this.subs["delList"] = this.data
        .deleteAllContact(pid)
        .subscribe((response) => {
          if (this.personDetails.type == "Client") {
            var client_number = this.personDetails.client_data.client_number;
            this.subs["delND"] = this.data
              .deleteAllFromND(client_number, this.personDetails.team_code)
              .subscribe((response) => {});
          }

          // Refresh the contact list
          this.getAllList();
          this.getAllTotalListCount();

          this.succ_msg = "Deleted Successfully";
          setTimeout(() => {
            this.succ_msg = "";
            this.succ_msg = false;
          }, this.data.timeOut);
        });
    }
  }

  backgroundVerification(val, cId) {
    if (val == "organization") {
      this.orgBCheckId = cId;
    } else if (val == "people") {
      this.personBCheckId = cId;
    } else if (val == "all_people") {
      this.personBCheckId = cId;
    } else if (val == "all_org") {
      this.orgBCheckId = cId;
    }
  }

  refresh() {
    window.location.reload();
  }
}
