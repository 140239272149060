import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
declare var jQuery: any;

export interface TypeId {
  key: string;
  name: string;
}

export interface OrgTag {
  key: string;
  name: string;
}

@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent implements OnInit, OnChanges {
  myEmailCheck: any = true;
  selectedFiles: any;
  selectedUploadFiles: any;
  contactOrgForm: FormGroup;
  contactPersonForm: FormGroup;
  allList: any = [];
  fileArr: any = [];
  personList: any = [];
  orgList: any = [];
  orgnazitiontable: boolean = false;
  clientDetails: boolean = false;
  maritialStatus: boolean = false;
  formSubmit: boolean = false;
  moreEmail: boolean = false;
  activeAll: boolean = false;
  activeOrg: boolean = true;
  activeP: boolean = false;
  fileIndex = 1;
  errorClass;
  activeClass: string;
  url: any;
  countryList: any;
  searchType: String;
  peopleListing: boolean = false;
  orgListing: boolean = false;
  dynamicVar: any;
  primary_email_flag = false;
  orgFileName: any;
  last_client_id: any;
  clientNumbFlag: boolean = false;

  err_org_name: string = "";
  err_org_email: string = "";
  err_org_phone: string;
  err_org_fax: string;
  err_org_website: string;
  err_org_street: string;
  err_org_city: string;
  err_org_state: string;
  err_org_zip: string;
  err_org_country: string;
  err_org_type: string;
  err_org_reg_type: string;
  err_org_reg_certificate: string;
  err_org_reg_no: string;
  err_org_reg_type1: string;
  err_org_reg_tag: string;
  err_country_code: string;
  err_org_exist: String;
  err_email_exist_msg: String;
  err_client_number: String;
  err_team_code: String;
  succ_msg: any = "";
  common_err_msg: any;
  contactParam: any = {};
  importFile: any;
  orgDetails: any;

  myControl = new FormControl();
  fOrgList;
  filteredOrgList: Observable<string[]>;
  @Input() orgId: number;
  FormHeading: string;
  thumbnail: any;
  o_id: any = 0;
  base_url: any;
  labelList: any;

  case_type: any;

  subs: any = [];

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  options: TypeId[] = [
    { key: "Association", name: "Association" },
    { key: "Individual", name: "Individual" },
    { key: "Organization", name: "Organization" },
    { key: "Other", name: "Other" },
  ];

  tags: OrgTag[] = [
    { key: "Association", name: "Association" },
    { key: "Company", name: "Company" },
    { key: "Government Bureau", name: "Government Bureau" },
    { key: "International Organization", name: "International Organization" },
    { key: "Judiciary", name: "Judiciary" },
  ];
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    let chg = changes["orgId"];
    let cval = chg.currentValue;
    if (chg.currentValue > "0" && chg.currentValue == cval) {
      this.getOrgDetails();
      this.FormHeading = "Edit Organization";
      this.primary_email_flag = true;
    } else {
      this.FormHeading = "Add Organization";
      this.orgFormInit();
      this.primary_email_flag = false;
    }
  }

  ngOnInit() {
    this.dynamicVar = `${environment.lpmsUrl}`;
    this.orgFormInit();
    this.listNationality();
    this.FormHeading = "Add Organization";

    /*if(this.data.newOrgName){
      this.contactOrgForm.controls['org_name'].setValue(this.data.newOrgName);
    }*/
    this.getCaseTypeList();
    this.getAllContactLabel();
    this.base_url = `${environment.lpmsUrl}` + "documents/comp_cert/svg/";
  }

  getOrgListing() {
    if (this.subs["orgList"]) {
      this.subs["orgList"].unsubscribe();
    }
    this.contactParam.contact_type = "ORG";
    this.subs["orgList"] = this.data
      .getAllListing(this.contactParam)
      .subscribe((data) => {
        this.orgList = data;
      });
  }

  getAllContactLabel() {
    if (this.subs["allContact"]) {
      this.subs["allContact"].unsubscribe();
    }
    this.subs["allContact"] = this.data
      .getAllContactLabel()
      .subscribe((data) => {
        this.labelList = data;
      });
  }

  onChange(value) {
    if (value == "Client") {
      this.clientDetails = true;
    } else {
      this.clientDetails = false;
      this.last_client_id = "";
    }
  }

  checkMaxClientId(value) {
    let typeOfCaseId = value == 1 ? value : 2;
    if (this.FormHeading === "Add Organization") {
      this.getLastClientNo(typeOfCaseId);
    } else {
      this.contactOrgForm.controls["client_number"].setValue(
        this.orgDetails.client_data.client_number
      );
      // Change on 11May22 to allow edit client number
      this.clientNumbFlag = false;
    }
  }

  getOrgDetails() {
    if (this.subs["orgDtl"]) {
      this.subs["orgDtl"].unsubscribe();
    }
    this.subs["orgDtl"] = this.data
      .getContactDetails(this.orgId, "ORG")
      .subscribe((data) => {
        this.orgDetails = data;

        if (this.orgDetails.email != null) {
          this.primary_email_flag = true;
        } else {
          this.primary_email_flag = false;
        }
        this.o_id = this.orgDetails.contact_id;
        if (this.orgDetails.org_reg_certificate) {
          this.orgFileName = `${environment.lpmsUrl}` +"documents/comp_cert/orgRegCertificate/" +this.orgDetails.org_reg_certificate;
        } else {
          this.orgFileName = "";
        }

        if (this.orgDetails.client_data.client_number != "") {
          //this.clientNumbFlag = true;
        }

        //this.contactOrgForm.controls["org_type"].disable();

        this.contactOrgForm.patchValue({
          org_id: this.orgDetails.contact_id,
          org_name: this.orgDetails.org_name,
          org_email: this.orgDetails.email,
          org_phone: this.orgDetails.phone_number,
          //country_code : Number(this.orgDetails.country_code),
          org_fax: this.orgDetails.mobile_number,
          team_code: this.orgDetails.team_code,
          org_website: this.orgDetails.website,
          org_street: this.orgDetails.street,
          org_city: this.orgDetails.city,
          org_state: this.orgDetails.state,
          org_zip: this.orgDetails.zip,
          org_country: Number(this.orgDetails.country),

          org_work_phone_number: this.orgDetails.work_phone_number,
          org_other_phone_number: this.orgDetails.other_phone_number,
          org_work_email: this.orgDetails.work_email,
          org_other_email: this.orgDetails.other_email,
          org_street2: this.orgDetails.street2,
          org_city2: this.orgDetails.city2,
          org_state2: this.orgDetails.state2,
          org_zip2: this.orgDetails.zip2,
          org_country2: Number(this.orgDetails.country2),

          org_type: this.orgDetails.type,
          org_reg_type: this.orgDetails.org_reg_type,
          org_reg_no: this.orgDetails.reg_id_number,
          org_reg_type1: this.orgDetails.org_reg_type1,
          org_reg_certificate: this.orgDetails.org_reg_certificate,
          org_reg_tag: this.orgDetails.org_reg_tag,
        });
        if (this.orgDetails.type == "Client") {
          this.contactOrgForm.patchValue({
            client_number: this.orgDetails["client_data"].client_number,
            client_discount: this.orgDetails["client_data"].discount_in_percent,
            type_of_case: String(this.orgDetails["client_data"].type_of_case),
            folder_name: this.orgDetails["client_data"].folder_name,
            client_short_name: this.orgDetails["client_data"].short_name,
            client_billing_code: this.orgDetails["client_data"].billing_code,
            client_communicate_in_chinese:
              this.orgDetails["client_data"].communicate_in_chinese,
          });
          this.onChange(this.orgDetails.type);
        }
        // Custom File browse file name
        let fileName = document.getElementById("certificate");
        fileName.innerHTML = this.orgDetails.org_reg_certificate;
      });
  }

  listNationality() {
    if (this.subs["nationality"]) {
      this.subs["nationality"].unsubscribe();
    }
    this.subs["nationality"] = this.data
      .listNationality()
      .subscribe((response) => {
        this.countryList = response;
        for (let index = 0; index < this.countryList.length; index++) {
          this.countryList[index].name = this.countryList[index].nicename;
          var imgName = this.countryList[index].iso.toLowerCase();
          this.countryList[index].imgUrl = this.base_url + imgName + ".svg";
        }
      });
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    let fileName = document.getElementById("certificate");
    fileName.innerHTML = this.selectedFiles[0].name;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
      };
    }
  }

  addContactOrg(frmData) {
    let formdata = new FormData();
    formdata.append("name", frmData.org_name != null ? frmData.org_name : "");
    formdata.append(
      "email",
      frmData.org_email != null ? frmData.org_email : ""
    );
    formdata.append(
      "phone",
      frmData.org_phone != null ? frmData.org_phone : ""
    );
    //formdata.append('country_code', (frmData.country_code != null) ? frmData.country_code : '' );
    formdata.append("fax", frmData.org_fax != null ? frmData.org_fax : "");
    formdata.append(
      "team_code",
      frmData.team_code != null ? frmData.team_code : ""
    );
    formdata.append(
      "website",
      frmData.org_website != null ? frmData.org_website : ""
    );
    formdata.append(
      "street",
      frmData.org_street != null ? frmData.org_street : ""
    );
    formdata.append("city", frmData.org_city != null ? frmData.org_city : "");
    formdata.append(
      "state",
      frmData.org_state != null ? frmData.org_state : ""
    );
    formdata.append("zip", frmData.org_zip != null ? frmData.org_zip : "");
    formdata.append(
      "country",
      frmData.org_country != null ? frmData.org_country : ""
    );

    formdata.append(
      "other_phone_number",
      frmData.org_other_phone_number != null
        ? frmData.org_other_phone_number
        : ""
    );
    formdata.append(
      "work_phone_number",
      frmData.org_work_phone_number != null ? frmData.org_work_phone_number : ""
    );
    formdata.append(
      "work_email",
      frmData.org_work_email != null ? frmData.org_work_email : ""
    );
    formdata.append(
      "other_email",
      frmData.org_other_email != null ? frmData.org_other_email : ""
    );
    formdata.append(
      "street2",
      frmData.org_street2 != null ? frmData.org_street2 : ""
    );
    formdata.append(
      "city2",
      frmData.org_city2 != null ? frmData.org_city2 : ""
    );
    formdata.append(
      "state2",
      frmData.org_state2 != null ? frmData.org_state2 : ""
    );
    formdata.append("zip2", frmData.org_zip2 != null ? frmData.org_zip2 : "");
    formdata.append(
      "country2",
      frmData.org_country2 != null ? frmData.org_country2 : ""
    );

    formdata.append("type", frmData.org_type != null ? frmData.org_type : "");
    formdata.append(
      "registration_type",
      frmData.org_reg_type != null ? frmData.org_reg_type : ""
    );
    formdata.append(
      "registration_number",
      frmData.org_reg_no != null ? frmData.org_reg_no : ""
    );
    formdata.append(
      "other_type",
      frmData.org_reg_type1 != null ? frmData.org_reg_type1 : ""
    );
    formdata.append(
      "registration_tag",
      frmData.org_reg_tag != null ? frmData.org_reg_tag : ""
    );
    formdata.append("from_form", "1");
    formdata.append(
      "client_number",
      frmData.client_number != null ? frmData.client_number : ""
    );
    formdata.append(
      "discount",
      frmData.client_discount != null ? frmData.client_discount : ""
    );
    formdata.append(
      "type_of_case",
      frmData.type_of_case != null ? frmData.type_of_case : ""
    );
    formdata.append(
      "folder_name",
      frmData.folder_name != null ? frmData.folder_name : ""
    );
    formdata.append(
      "short_name",
      frmData.client_short_name != null ? frmData.client_short_name : ""
    );
    formdata.append(
      "billing_code",
      frmData.client_billing_code != null ? frmData.client_billing_code : ""
    );
    formdata.append(
      "communicate_in_chinese",
      frmData.client_communicate_in_chinese != null
        ? frmData.client_communicate_in_chinese
        : ""
    );

    formdata.append("contact_type", "ORG");
    formdata.append("contact_type_name", "Organization");

    if (frmData.org_id) {
      formdata.append("org_id", frmData.org_id);
    }

    if (this.selectedFiles == undefined) {
    } else {
      formdata.append("org_reg_certificate", this.selectedFiles[0]);
    }

    this.data.contactAddOrganisation(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrorMsgs();

          if (frmData.org_id) {
          } else {
            if (frmData.org_type == "Client") {
              this.data
                .insertClientToNDFromLPMS(formdata)
                .subscribe((res) => {});
            }
          }
          setTimeout(() => {
            this.succ_msg = false;
            this.closeModal.emit();
            jQuery("#organizationsModal").modal("hide");
            this.contactParam.contact_type = "ORG";
            this.getOrgListing();
          }, this.data.timeOut);

          if (frmData.org_id) {
          } else {
            this.orgFormInit();
          }
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.name) {
          this.err_org_name = error.error.name;
          this.err_org_exist = "";
        } else {
          this.err_org_name = "";
        }

        if (error.error.err_org_exist) {
          this.err_org_exist = error.error.err_org_exist_msg;
          this.err_org_name = "";
        } else {
          this.err_org_exist = "";
        }

        if (error.error.email) {
          this.err_org_email = error.error.email;
        } else {
          this.err_org_email = "";
        }

        if (error.error.phone) {
          this.err_org_phone = error.error.phone;
        } else {
          this.err_org_phone = "";
        }

        /*if (error.error.country_code) {
          this.err_country_code = error.error.country_code;
        } else {
          this.err_country_code = '';
        }*/

        if (error.error.fax) {
          this.err_org_fax = error.error.fax;
        } else {
          this.err_org_fax = "";
        }

        if (error.error.team_code) {
          this.err_team_code = error.error.team_code;
        } else {
          this.err_team_code = "";
        }

        if (error.error.website) {
          this.err_org_website = error.error.website;
        } else {
          this.err_org_website = "";
        }

        if (error.error.street2) {
          this.err_org_street = error.error.street2;
        } else {
          this.err_org_street = "";
        }

        if (error.error.city2) {
          this.err_org_city = error.error.city2;
        } else {
          this.err_org_city = "";
        }

        if (error.error.state) {
          this.err_org_state = error.error.state;
        } else {
          this.err_org_state = "";
        }

        if (error.error.zip) {
          this.err_org_zip = error.error.zip;
        } else {
          this.err_org_zip = "";
        }

        if (error.error.country2) {
          this.err_org_country = error.error.country2;
        } else {
          this.err_org_country = "";
        }

        if (error.error.type) {
          this.err_org_type = error.error.type;
        } else {
          this.err_org_type = "";
        }

        if (error.error.registration_type) {
          this.err_org_reg_type = error.error.registration_type;
        } else {
          this.err_org_reg_type = "";
        }

        if (error.error.registration_number) {
          this.err_org_reg_no = error.error.registration_number;
        } else {
          this.err_org_reg_no = "";
        }

        if (error.error.registration_tag) {
          this.err_org_reg_tag = error.error.registration_tag;
        } else {
          this.err_org_reg_tag = "";
        }

        if (error.error.err_email_exist_msg) {
          this.err_email_exist_msg = error.error.err_email_exist_msg;
        } else {
          this.err_email_exist_msg = "";
        }

        if (frmData.org_type == "Client") {
          if (error.error.client_number) {
            this.err_client_number = error.error.client_number;
          } else {
            this.err_client_number = "";
          }
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_org_name =
      this.err_org_email =
      this.err_org_phone =
      this.err_org_fax =
      this.err_org_website =
      this.err_org_street =
      this.err_org_city =
      this.err_org_state =
      this.err_org_zip =
      this.err_org_country =
      this.err_org_type =
      this.err_org_reg_type =
      this.err_org_reg_certificate =
      this.err_org_reg_no =
      this.err_org_reg_type1 =
      this.err_org_reg_tag =
      this.err_country_code =
        "";
    this.err_org_exist = this.err_email_exist_msg = this.err_client_number = "";
    this.err_team_code = "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    this.closeModal.emit();
    this.clientNumbFlag = false;
    document.getElementById("certificate").innerHTML = '';
    if (this.orgId == 0) {
      this.onChange("");
      this.orgFormInit();
    }
  }

  orgFormInit() {
    this.contactOrgForm = this.fb.group({
      org_id: new FormControl(""),
      org_name: new FormControl(""),
      org_email: new FormControl(""),
      org_phone: new FormControl(""),
      org_fax: new FormControl(""),
      team_code: new FormControl(),
      country_code: new FormControl([null]),
      org_website: new FormControl(""),
      org_street: new FormControl(""),
      org_city: new FormControl(""),
      org_state: new FormControl(""),
      org_zip: new FormControl(""),
      org_country: new FormControl([null]),

      org_work_phone_number: new FormControl(""),
      org_other_phone_number: new FormControl(""),
      org_work_email: new FormControl(""),
      org_other_email: new FormControl(""),
      org_street2: new FormControl(""),
      org_city2: new FormControl(""),
      org_state2: new FormControl(""),
      org_zip2: new FormControl(""),
      org_country2: new FormControl([null]),

      org_type: new FormControl(""),
      org_reg_type: new FormControl(""),
      org_reg_certificate: new FormControl(""),
      org_reg_no: new FormControl(""),
      org_reg_type1: new FormControl(""),
      org_reg_tag: new FormControl(""),
      person_type: new FormControl(""),
      client_number: new FormControl(""),
      client_discount: new FormControl(""),
      type_of_case: new FormControl(""),
      folder_name: new FormControl(""),
      client_short_name: new FormControl(""),
      client_billing_code: new FormControl(""),
      client_communicate_in_chinese: new FormControl(""),
    });

    // Reset Registration Certificate
    this.orgFileName = "";
    let fileName = document.getElementById("certificate");
    fileName.innerHTML = "";
  }
  

  getLastClientNo(typeOfCase) {
    if (this.subs["lastClient"]) {
      this.subs["lastClient"].unsubscribe();
    }
    this.contactParam.typeOfCase = typeOfCase;
    this.subs["lastClient"] = this.data
      .getLastClientNo(this.contactParam)
      .subscribe((data) => {
        this.last_client_id = data;
        this.last_client_id = this.last_client_id.data;
        this.contactOrgForm.controls["client_number"].setValue(
          this.last_client_id
        );
      });
  }


  
  getCaseTypeList() {
    if (this.subs["caseType"]) {
      this.subs["caseType"].unsubscribe();
    }
    this.subs["caseType"] = this.data.getTypeOfCase().subscribe((data) => {
      this.case_type = data;
    });
  }

  removeImage(){

    let contact_id=this.orgDetails.contact_id;

    this.data.removeRegisterImage(contact_id).subscribe((data) => {
      this.orgDetails.org_reg_certificate = "";
      this.getOrgDetails();
     
    });
  }
}
