import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './auth.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler
    ) : Observable<HttpEvent<any>> {
    let authService = this.injector.get(AuthenticationService)
    let tokenizedReq = request.clone(
      {
        headers: request.headers.set('Authorization', 'bearer ' + authService.getToken())
      }
    )

    return next.handle(tokenizedReq).pipe(
      catchError(error => {        // Checking if it is an Authentication Error (401)
        if (error.status === 401) {
          // <Log the user out of your application code>
          authService.remove();
          this.router.navigate(['/login']);
          return throwError(error);
        }       
         // If it is not an authentication error, just throw it
        return throwError(error);
      })
    );
  }
  

}