import { Component, OnInit, Input } from "@angular/core";
import { AuthenticationService } from "../../services/auth.service";
import { DataService } from "../../services/data.service";
import { environment } from "../../../environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-verify-company",
  templateUrl: "./verify-company.component.html",
  styleUrls: ["./verify-company.component.scss"],
})
export class VerifyCompanyComponent implements OnInit {
  terrorist: boolean = false;
  ofac: boolean = false;
  google: boolean = false;
  pep: boolean = false;
  @Input() personBCheckId: any;
  @Input() orgBCheckId: any;
  terroristBackgroundCheck: any;
  contact_id: any;
  field: string;
  name: string;
  terroristResponse: boolean = false;
  terroristLoader: boolean = true;
  searchPep: boolean = false;
  googleResult: any;
  ofacResult: any;
  pepResult: any;
  message: string = "";
  googleResultMessage: string = "";
  googleResultFlag: boolean = false;
  PepControl: boolean = false;
  base_url: any;
  lId: number;
  listDetails: Object;

  constructor(
    private auth: AuthenticationService,
    private data: DataService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.terrorist = true;
    this.ngOnChanges();
    this.base_url = `${environment.lpmsUrl}` + "";
    this.route.params.subscribe((params) => {
      if (params) {
        if (params["lId"]) {
          this.lId = +params["lId"];
          this.terroristListBackgroungChecking(this.lId);
        }
      }
    });
  }
  ngOnChanges() {
    this.terroristListBackgroungChecking(this.lId);
  }

  showContent(val) {
    if (val === "terrorist") {
      this.terrorist = true;
      this.ofac = false;
      this.google = false;
      this.pep = false;
      this.terroristListBackgroungChecking(this.lId);
    } else if (val === "ofac") {
      this.terrorist = false;
      this.ofac = true;
      this.google = false;
      this.pep = false;
      this.OfacList();
    } else if (val === "google") {
      this.terrorist = false;
      this.ofac = false;
      this.google = true;
      this.pep = false;
      this.googleSearch();
    } else if (val === "pep") {
      this.terrorist = false;
      this.ofac = false;
      this.google = false;
      this.pep = true;
      this.searchPEP();
    }
  }

  terroristListBackgroungChecking(id) {
    let person;
    this.data.getLeadDetails(id).subscribe((data) => {
      if (data) {
        person =
          data["given_name"].trim() +
          " " +
          data["family_name"].trim() +
          "|" +
          data["family_name"].trim() +
          " " +
          data["given_name"].trim() +
          "|" +
          data["given_name"].trim() +
          "|" +
          data["given_name"].trim() +
          " " +
          data["family_name"].trim() +
          "|" +
          data["given_name"].trim() +
          " " +
          "|" +
          data["given_name"].trim() +
          "|" +
          data["family_name"];
        this.name = data["given_name"] + " " + data["family_name"];
        let formdata = new FormData();
        formdata.append("name", person);
        formdata.append("lead_id", id);
        this.terroristResponse = false;
        this.terroristLoader = true;
        this.auth
          .terroristListBackgroundChecking(formdata)
          .subscribe((response) => {
            if (response) {
              setTimeout(() => {
                this.terroristBackgroundCheck = response;
                this.terroristResponse = true;
                this.terroristLoader = false;
              }, 3000);
            } else {
              this.terroristResponse = false;
            }
          });
      } else {
        this.terroristResponse = false;
      }
    });
  }

  googleSearch() {
    let formdata = new FormData();
    formdata.append("name", this.name);
    this.auth.googleSearch(formdata).subscribe((response) => {
      if (response["result"]) {
        this.googleResultMessage = "";
        this.googleResult = response["result"];
        this.googleResultFlag = true;
      } else if (response["message"]) {
        this.googleResultMessage = response["message"];
        this.googleResultFlag = false;
      }
    });
  }

  searchPEP() {
    let formdata = new FormData();
    formdata.append("name", this.name);
    this.auth.searchPEP(formdata).subscribe((response) => {
      if (response) {
        if (response["result"]) {
          this.pepResult = response["result"];
          this.message = "";
          this.PepControl = true;
        } else {
          if (response["message"]) {
            this.PepControl = false;
            this.message = "No record found";
            this.pepResult = "";
          } else {
            this.message = "";
          }
        }
        this.searchPep = true;
      } else {
        this.searchPep = false;
      }
    });
  }

  OfacList() {
    let formdata = new FormData();
    formdata.append("name", this.name);
    this.auth.ofacList(formdata).subscribe((response) => {
      if (response["result"]) {
        this.ofacResult = response["result"];
        this.message = "";
      } else {
        this.message = "No record found";
      }
    });
  }
}
