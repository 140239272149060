import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";

declare var jQuery: any;

export interface TypeId {
  name: string;
  key: string;
}
export interface Single {
  name: string;
  key: string;
}

@Component({
  selector: "app-person",
  templateUrl: "./person.component.html",
  styleUrls: ["./person.component.scss"],
})
export class PersonComponent implements OnInit, OnChanges {
  [x: string]: any;
  myEmailCheck: any = true;
  selectedFiles: any;
  selectedUploadFiles: any;
  contactOrgForm: FormGroup;
  contactPersonForm: FormGroup;
  allList: any = [];
  fileArr: any = [];
  personList: any = [];
  orgList: any = [];
  orgnazitiontable: boolean = false;
  clientDetails: boolean = false;
  maritialStatus: boolean = false;
  formSubmit: boolean = false;
  moreEmail: boolean = false;
  activeAll: boolean = false;
  activeOrg: boolean = false;
  activeP: boolean = true;
  fileIndex = 1;
  errorClass;
  activeClass: string;
  url: any;
  countryList: any;
  searchType: String;
  peopleListing: boolean = false;
  orgListing: boolean = false;

  clientNumbFlag: boolean = false;

  homeemail: string = "";
  workemail: string = "";
  otheremail: string = "";
  err_person_other_email: string = "";
  err_person_work_email: string = "";
  err_person_work_phone_number: string = "";
  err_person_other_phone_number: string = "";
  homephone: string = "";
  workphone: string = "";
  otherphone: string = "";
  emailList;
  phoneList;

  err_org_name: string = "";
  err_org_email: string = "";
  err_org_phone: string;
  err_org_mobile: string;
  err_org_website: string;
  err_org_street: string;
  err_org_city: string;
  err_org_state: string;
  err_org_zip: string;
  err_org_country: string;
  err_org_type: string;
  err_org_reg_type: string;
  err_org_reg_certificate: string;
  err_org_reg_no: string;
  err_org_reg_type1: string;
  err_org_reg_tag: string;
  err_email_exist: string;
  err_add_email_exist: string;
  err_person_salutation: string;
  err_person_organization: string;
  err_person_given_name: string;
  err_person_family_name: string;
  err_person_occupation: string;
  err_person_home_email: string;
  err_person_home_phone_number: string;
  err_country_code: string;
  err_person_mobile: string;
  err_person_website: string;
  err_person_street: string;
  err_person_city: string;
  err_team_code: String;
  err_person_state: string;
  err_person_zip: string;
  err_person_country: string;
  err_person_type: string;
  err_person_id_type: string;
  err_person_id_number: string;
  err_person_id_issue_date: string;
  err_person_issue_country: string;
  err_person_maritial_status: string;

  err_person_client_number: String;
  err_person_communicate_in_chinese: String;
  err_person_billing_code: String;
  err_person_short_name: String;
  err_person_folder_name: String;
  err_person_discount: String;
  err_org_exist: String;
  newOrgStatus: any;

  succ_msg: any = "";
  org_exist_msg: any = "";
  personDetails: any;
  p_id: any = 0;
  base_url: any;
  fileName: any = "";
  common_err_msg: any;
  contactParam: any = {};
  case_type: any;

  importFile: any;
  defaultSelect = 0;
  myControl = new FormControl();
  fOrgList;
  filteredOrgList: Observable<string[]>;

  @Input() personId: number;
  FormHeading: string;
  thumbnail: any;

  primary_email_flag = false;
  dynamicVar: any;
  last_client_id: any;

  hasEmail: number = 0;
  labelList: any;

  subs: any = [];

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  options: TypeId[] = [
    {
      name: "China Identification Document",
      key: "China Identification Document",
    },
    {
      name: "Hong Kong Non Permanent Resident Identity Card/Bilhete de Identidade de Residente Não Permanente de Hong Kong",
      key: "Hong Kong Non Permanent Resident Identity Card/Bilhete de Identidade de Residente Não Permanente de Hong Kong",
    },
    {
      name: "Hong Kong Permanent Resident Identity Card/Bilhete de Identidade de Residente Permanente de Hong Kong",
      key: "Hong Kong Permanent Resident Identity Card/Bilhete de Identidade de Residente Permanente de Hong Kong",
    },
    {
      name: "Macau Non Permanent Identity Card/Bilhete de Identidade de Residente Não Permanente de Macau",
      key: "Macau Non Permanent Identity Card",
    },
    {
      name: "Macau Permanent Identity Card/Bilhete de Identidade de Residente Permanente de Macau",
      key: "Macau Permanent Identity Card",
    },
    { name: "Passport/Passporte", key: "Passport" },
  ];

  married_status: Single[] = [
    {
      name: "Married in the matrimonial assets regime of joint acquired assets",
      key: "Married in the matrimonial assets regime of joint acquired assets",
    },
    {
      name: "Married in the matrimonial assets regime of joint assets",
      key: "Married in the matrimonial assets regime of joint assets",
    },
    {
      name: "Married in the matrimonial assets regime of separation",
      key: "Married in the matrimonial assets regime of separation",
    },
  ];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    let chg = changes["personId"];
    let cval = chg.currentValue;
    this.clientDetails = false;
    this.maritialStatus = false;
    if (chg.currentValue > "0" && chg.currentValue == cval) {
      this.getContactPerson();
      this.FormHeading = "Edit Person";
      this.primary_email_flag = true;
    } else {
      this.FormHeading = "Add Person";
      this.personFormInit();
      this.primary_email_flag = false;
    }
  }

  ngOnInit() {
    this.getOrgAutocomplete();
    this.dynamicVar = `${environment.lpmsUrl}`;
    this.personFormInit();
    this.FormHeading = "Add Person";
    this.listNationality();
    this.getCaseTypeList();
    this.checkEmailExists();
    this.checkPhoneExists();
    this.getAllContactLabel();
    this.base_url = `${environment.lpmsUrl}` + "documents/comp_cert/svg/";
  }

  getOrgAutocomplete() {
    if (this.subs["orgAuto"]) {
      this.subs["orgAuto"].unsubscribe();
    }
    this.subs["orgAuto"] = this.data.listFilteredOrg().subscribe((data) => {
      this.fOrgList = this.ToArray(data);
    });

    this.filteredOrgList = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private ToArray(array) {
    const temparry = [];
    array.map((item) => {
      temparry.push(item.org_name);
    });
    return temparry;
  }

  getAllContactLabel() {
    if (this.subs["contactGrp"]) {
      this.subs["contactGrp"].unsubscribe();
    }
    this.subs["contactGrp"] = this.data
      .getAllContactLabel()
      .subscribe((data) => {
        this.labelList = data;
      });
  }

  private _filter(value: string): string[] {
    if (value) {
      this.err_org_exist = "";
      this.err_person_organization = "";
      this.org_exist_msg = "";
      const filterValue = value.toLowerCase();
      return this.fOrgList.filter((value) =>
        value.toLowerCase().includes(filterValue)
      );
    } else {
      this.fOrgList;
    }
  }

  getCaseTypeList() {
    if (this.subs["caseType"]) {
      this.subs["caseType"].unsubscribe();
    }
    this.subs["caseType"] = this.data.getTypeOfCase().subscribe((data) => {
      this.case_type = data;
    });
  }

  getPersonList() {
    if (this.subs["perList"]) {
      this.subs["perList"].unsubscribe();
    }
    this.contactParam.contact_type = "PERS";
    this.subs["perList"] = this.data
      .getAllListing(this.contactParam)
      .subscribe((data) => {
        this.personList = data;
      });
  }

  getContactPerson() {
    if (this.subs["contactPer"]) {
      this.subs["contactPer"].unsubscribe();
    }
    this.subs["contactPer"] = this.data
      .getContactDetails(this.personId, "PERS")
      .subscribe((data) => {
        this.personDetails = data;

        if (this.personDetails.email != null) {
          this.primary_email_flag = true;
        } else {
          this.primary_email_flag = false;
        }
        if (this.personDetails.id_scan_copy != null) {
          this.fileName =
            `${environment.lpmsUrl}` +
            "documents/comp_cert/personScanDoc/" +
            this.personDetails.id_scan_copy;
        } else {
          this.fileName = "";
        }

        if (this.personDetails.client_data.client_number != "") {
          //this.clientNumbFlag = true;
        }

        //this.contactPersonForm.controls["person_type"].disable();

        this.p_id = this.personDetails.contact_id;
        this.contactPersonForm.patchValue({
          person_id: this.personDetails.contact_id,
          person_salutation: this.personDetails.salutation,
          person_organization: this.personDetails.org_name,
          person_given_name: this.personDetails.given_name,
          person_middle_name: this.personDetails.middle_name,
          person_occupation: this.personDetails.occupation,
          //person_address_type: this.personDetails.address_type,
          person_family_name: this.personDetails.family_name,
          //person_primary_email: this.personDetails.email,
          //person_phone_number: this.personDetails.phone_number,
          person_home_email: this.personDetails.home_email,
          person_work_email: this.personDetails.work_email,
          person_other_email: this.personDetails.other_email,
          person_home_phone_number: this.personDetails.home_phone_number,
          person_work_phone_number: this.personDetails.work_phone_number,
          person_other_phone_number: this.personDetails.other_phone_number,
          country_code: Number(this.personDetails.country_code),
          person_mobile: this.personDetails.mobile_number,
          person_website: this.personDetails.website,
          team_code: this.personDetails.team_code,
          person_street: this.personDetails.street,
          person_city: this.personDetails.city,
          person_state: this.personDetails.state,
          person_zip: this.personDetails.zip,
          person_country:
            this.personDetails.country != null
              ? Number(this.personDetails.country)
              : "",
          person_street2: this.personDetails.street2,
          person_city2: this.personDetails.city2,
          person_state2: this.personDetails.state2,
          person_zip2: this.personDetails.zip2,
          person_country2:
            this.personDetails.country2 != null
              ? Number(this.personDetails.country2)
              : "",
          person_website2: this.personDetails.website2,
          person_type: this.personDetails.type,
          person_id_type: this.personDetails.id_type,
          person_id_number: this.personDetails.reg_id_number,
          person_issue_date: this.personDetails.id_issue_date,
          person_issue_country:
            this.personDetails.id_issue_country != null
              ? Number(this.personDetails.id_issue_country)
              : "",
          person_maritial_status: this.personDetails.marital_status,
          person_maritial_regime: this.personDetails.maritial_regime,
          person_spouse_name: this.personDetails.spouse_name,
          person_id_scan: this.personDetails.id_scan_copy,
          person_gender: this.personDetails.person_gender,
        });

        this.onChangeMStatus(this.personDetails.marital_status);
        this.getOrgAutocomplete();

        let fileName = document.getElementById("scan_file");
        fileName.innerHTML = this.personDetails.id_scan_copy;

        if (this.personDetails.type == "Client") {
          this.contactPersonForm.patchValue({
            client_number: this.personDetails["client_data"].client_number,
            client_discount:
              this.personDetails["client_data"].discount_in_percent,
            type_of_case: String(
              this.personDetails["client_data"].type_of_case
            ),
            folder_name: this.personDetails["client_data"].folder_name,
            client_short_name: this.personDetails["client_data"].short_name,
            client_billing_code: this.personDetails["client_data"].billing_code,
            client_communicate_in_chinese:
              this.personDetails["client_data"].communicate_in_chinese,
          });

          this.onChange(this.personDetails.type);
        }

        /*if(this.personDetails.email_data){  
        this.hasEmail = 1;
        for (let i = 0; this.personDetails.email_data.length > i; i++){
          this.personEmailArr.push(this.fb.group({ person_email: this.personDetails.email_data[i].email }));
        }
      }*/
      });
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    let fileName = document.getElementById("scan_file");
    fileName.innerHTML = this.selectedFiles[0].name;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = reader.result;
      };
    }
  }

  onChange(value) {
    if (value == "Client") {
      this.clientDetails = true;
    } else {
      this.clientDetails = false;
    }
  }

  checkMaxClientId(value) {
    let typeOfCaseId = value == 1 ? value : 2;
    if (this.FormHeading === "Add Person") {
      this.getLastClientNo(typeOfCaseId);
    } else {
      this.contactPersonForm.controls["client_number"].setValue(
        this.personDetails.client_data.client_number
      );
      // Change on 11May22 to allow edit client number
      this.clientNumbFlag = false;
    }
  }

  onChangeMStatus(value) {
    if (value == "Married") {
      this.maritialStatus = true;
    } else {
      this.maritialStatus = false;
    }
  }

  listNationality() {
    if (this.subs["nationality"]) {
      this.subs["nationality"].unsubscribe();
    }
    this.subs["nationality"] = this.data
      .listNationality()
      .subscribe((response) => {
        this.countryList = response;
        for (let index = 0; index < this.countryList.length; index++) {
          this.countryList[index].name = this.countryList[index].nicename;
          var imgName = this.countryList[index].iso.toLowerCase();
          this.countryList[index].imgUrl = this.base_url + imgName + ".svg";
        }
      });
  }

  addContactPerson(postData) {
    if (
      postData.person_issue_date != "" &&
      postData.person_issue_date != null
    ) {
      var dateSendingToServer = new DatePipe("en-US").transform(
        postData.person_issue_date,
        "yyyy-MM-dd"
      );
    } else {
      var dateSendingToServer = "";
    }

    let formdata = new FormData();
    formdata.append(
      "salutation",
      postData.person_salutation != null ? postData.person_salutation : ""
    );
    formdata.append(
      "given_name",
      postData.person_given_name != null ? postData.person_given_name : ""
    );
    formdata.append(
      "middle_name",
      postData.person_middle_name != null ? postData.person_middle_name : ""
    );
    formdata.append(
      "family_name",
      postData.person_family_name != null ? postData.person_family_name : ""
    );
    formdata.append(
      "occupation",
      postData.person_occupation != null ? postData.person_occupation : ""
    );
    //formdata.append('address_type', (postData.person_address_type != null) ? postData.person_address_type : '' );
    //formdata.append('primary_email_flag', (postData.person_primary_email_flag != null) ? postData.person_primary_email_flag : '' );
    //formdata.append('email', (postData.person_primary_email != null) ? postData.person_primary_email : '' );
    //formdata.append('phone_number', (postData.person_phone_number != null) ? postData.person_phone_number : '' );
    formdata.append(
      "home_email",
      postData.person_home_email != null ? postData.person_home_email : ""
    );
    formdata.append(
      "work_email",
      postData.person_work_email != null ? postData.person_work_email : ""
    );
    formdata.append(
      "other_email",
      postData.person_other_email != null ? postData.person_other_email : ""
    );
    formdata.append(
      "home_phone_number",
      postData.person_home_phone_number != null
        ? postData.person_home_phone_number
        : ""
    );
    formdata.append(
      "work_phone_number",
      postData.person_work_phone_number != null
        ? postData.person_work_phone_number
        : ""
    );
    formdata.append(
      "other_phone_number",
      postData.person_other_phone_number != null
        ? postData.person_other_phone_number
        : ""
    );
    formdata.append(
      "country_code",
      postData.country_code != null ? postData.country_code : ""
    );
    formdata.append(
      "mobile_number",
      postData.person_mobile != null ? postData.person_mobile : ""
    );
    formdata.append(
      "website",
      postData.person_website != null ? postData.person_website : ""
    );
    formdata.append(
      "street",
      postData.person_street != null ? postData.person_street : ""
    );
    formdata.append(
      "city",
      postData.person_city != null ? postData.person_city : ""
    );
    formdata.append(
      "state",
      postData.person_state != null ? postData.person_state : ""
    );
    formdata.append(
      "zip",
      postData.person_zip != null ? postData.person_zip : ""
    );
    formdata.append(
      "country",
      postData.person_country != null ? postData.person_country : ""
    );

    formdata.append(
      "street2",
      postData.person_street2 != null ? postData.person_street2 : ""
    );
    formdata.append(
      "city2",
      postData.person_city2 != null ? postData.person_city2 : ""
    );
    formdata.append(
      "state2",
      postData.person_state2 != null ? postData.person_state2 : ""
    );
    formdata.append(
      "zip2",
      postData.person_zip2 != null ? postData.person_zip2 : ""
    );
    formdata.append(
      "country2",
      postData.person_country2 != null ? postData.person_country2 : ""
    );
    formdata.append(
      "website2",
      postData.person_website2 != null ? postData.person_website2 : ""
    );

    formdata.append(
      "type",
      postData.person_type != null ? postData.person_type : ""
    );
    formdata.append(
      "client_number",
      postData.client_number != null ? postData.client_number : ""
    );
    formdata.append(
      "discount",
      postData.client_discount != null ? postData.client_discount : ""
    );
    formdata.append(
      "type_of_case",
      postData.type_of_case != null ? postData.type_of_case : ""
    );
    formdata.append(
      "folder_name",
      postData.folder_name != null ? postData.folder_name : ""
    );
    formdata.append(
      "short_name",
      postData.client_short_name != null ? postData.client_short_name : ""
    );
    formdata.append(
      "billing_code",
      postData.client_billing_code != null ? postData.client_billing_code : ""
    );
    formdata.append(
      "communicate_in_chinese",
      postData.client_communicate_in_chinese != null
        ? postData.client_communicate_in_chinese
        : ""
    );
    formdata.append(
      "id_type",
      postData.person_id_type != null ? postData.person_id_type : ""
    );
    formdata.append(
      "id_number",
      postData.person_id_number != null ? postData.person_id_number : ""
    );
    formdata.append(
      "id_issue_date",
      dateSendingToServer ? dateSendingToServer : ""
    );
    formdata.append(
      "id_issue_country",
      postData.person_issue_country != null ? postData.person_issue_country : ""
    );
    formdata.append(
      "maritial_status",
      postData.person_maritial_status != null
        ? postData.person_maritial_status
        : ""
    );
    formdata.append(
      "maritial_regime",
      postData.person_maritial_regime != null
        ? postData.person_maritial_regime
        : ""
    );
    formdata.append(
      "spouse_name",
      postData.person_spouse_name != null ? postData.person_spouse_name : ""
    );
    formdata.append(
      "person_gender",
      postData.person_gender != null ? postData.person_gender : ""
    );
    //formdata.append('email_str', email_str);
    formdata.append("from_form", "1");
    formdata.append("contact_type", "PERS");
    formdata.append("contact_type_name", "Individual");
    formdata.append(
      "team_code",
      postData.team_code != null ? postData.team_code : ""
    );

    if (postData.person_id) {
      formdata.append("person_id", postData.person_id);
      if (this.myControl.value != "" && this.myControl.value != null) {
        formdata.append("organization", this.myControl.value);
      } else {
        formdata.append("organization", postData.person_organization);
      }
    } else {
      if (this.myControl.value != null) {
        formdata.append("organization", this.myControl.value);
      } else {
        formdata.append("organization", "");
      }
    }

    if (this.selectedFiles == undefined) {
    } else {
      formdata.append("person_id_scan", this.selectedFiles[0]);
    }

    this.data.contactAddPerson(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrorMsgs();

          if (postData.person_id) {
          } else {
            if (postData.person_type == "Client") {
              this.data
                .insertClientToNDFromLPMS(formdata)
                .subscribe((res) => {});
            }
          }

          setTimeout(() => {
            this.succ_msg = false;
            this.closeModal.emit();
            jQuery("#personModal").modal("hide");
            this.contactParam.contact_type = "ORG";
            this.getPersonList();
            this.onChangeMStatus("");
            this.onChange("");
          }, 100);

          if (postData.person_id) {
          } else {
            this.personFormInit();
          }
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.err_org_exist) {
          this.err_org_exist = error.error.err_org_exist_msg;
          this.org_exist_msg = "";
        } else {
          this.err_org_exist = "";
        }

        if (error.error.salutation) {
          this.err_person_salutation = error.error.salutation;
        } else {
          this.err_person_salutation = "";
        }

        if (error.error.organization) {
          this.err_person_organization = error.error.organization;
        } else {
          this.err_person_organization = "";
        }

        if (error.error.given_name) {
          this.err_person_given_name = error.error.given_name;
        } else {
          this.err_person_given_name = "";
        }

        if (error.error.family_name) {
          this.err_person_family_name = error.error.family_name;
        } else {
          this.err_person_family_name = "";
        }

        if (error.error.occupation) {
          this.err_person_occupation = error.error.occupation;
        } else {
          this.err_person_occupation = "";
        }

        /*if (error.error.home_email) {
          this.err_person_home_email = error.error.home_email;
        
        } else {
          this.err_person_home_email = '';
        }*/

        if (error.error.home_phone_number) {
          this.err_person_home_phone_number = error.error.home_phone_number;
        } else {
          this.err_person_home_phone_number = "";
        }

        if (error.error.country_code) {
          this.err_country_code = error.error.country_code;
        } else {
          this.err_country_code = "";
        }

        if (error.error.mobile_number) {
          this.err_person_mobile = error.error.mobile_number;
        } else {
          this.err_person_mobile = "";
        }

        if (error.error.website) {
          this.err_person_website = error.error.website;
        } else {
          this.err_person_website = "";
        }

        if (error.error.team_code) {
          this.err_team_code = error.error.team_code;
        } else {
          this.err_team_code = "";
        }

        if (error.error.street) {
          this.err_person_street = error.error.street;
        } else {
          this.err_person_street = "";
        }
        if (error.error.city) {
          this.err_person_city = error.error.city;
        } else {
          this.err_person_city = "";
        }

        if (error.error.state) {
          this.err_person_state = error.error.state;
        } else {
          this.err_person_state = "";
        }

        if (error.error.zip) {
          this.err_person_zip = error.error.zip;
        } else {
          this.err_person_zip = "";
        }
        if (error.error.country) {
          this.err_person_country = error.error.country;
        } else {
          this.err_person_country = "";
        }

        if (error.error.type) {
          this.err_person_type = error.error.type;
        } else {
          this.err_person_type = "";
        }

        if (error.error.id_type) {
          this.err_person_id_type = error.error.id_type;
        } else {
          this.err_person_id_type = "";
        }

        if (error.error.id_number) {
          this.err_person_id_number = error.error.id_number;
        } else {
          this.err_person_id_number = "";
        }

        if (error.error.id_issue_date) {
          this.err_person_id_issue_date = error.error.id_issue_date;
        } else {
          this.err_person_id_issue_date = "";
        }

        if (error.error.id_issue_country) {
          this.err_person_issue_country = error.error.id_issue_country;
        } else {
          this.err_person_issue_country = "";
        }
        if (error.error.maritial_status) {
          this.err_person_maritial_status = error.error.maritial_status;
        } else {
          this.err_person_maritial_status = "";
        }
        if (postData.person_type == "Client") {
          if (error.error.client_number) {
            this.err_person_client_number = error.error.client_number;
          } else {
            this.err_person_client_number = "";
          }

          if (error.error.discount) {
            this.err_person_discount = error.error.discount;
          } else {
            this.err_person_discount = "";
          }

          if (error.error.folder_name) {
            this.err_person_folder_name = error.error.folder_name;
          } else {
            this.err_person_folder_name = "";
          }

          if (error.error.short_name) {
            this.err_person_short_name = error.error.short_name;
          } else {
            this.err_person_short_name = "";
          }

          if (error.error.billing_code) {
            this.err_person_billing_code = error.error.billing_code;
          } else {
            this.err_person_billing_code = "";
          }

          if (error.error.communicate_in_chinese) {
            this.err_person_communicate_in_chinese =
              error.error.communicate_in_chinese;
          } else {
            this.err_person_communicate_in_chinese = "";
          }
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_org_name =
      this.err_org_email =
      this.err_org_phone =
      this.err_org_mobile =
      this.err_org_website =
      this.err_org_street =
      this.err_org_city =
      this.err_org_state =
      this.err_org_zip =
      this.err_org_country =
      this.err_org_type =
      this.err_org_reg_type =
      this.err_org_reg_certificate =
      this.err_org_reg_no =
      this.err_org_reg_type1 =
      this.err_org_reg_tag =
      this.err_email_exist =
      this.err_add_email_exist =
        "";

    this.err_person_salutation =
      this.err_person_organization =
      this.err_person_given_name =
      this.err_person_family_name =
      this.err_person_occupation =
      this.err_person_home_phone_number =
      this.err_person_mobile =
      this.err_country_code =
      this.err_person_website =
      this.err_person_street =
      this.err_person_city =
      this.err_person_state =
      this.err_person_zip =
      this.err_person_country =
      this.err_person_type =
      this.err_person_id_type =
      this.err_person_id_number =
      this.err_person_id_issue_date =
      this.err_person_issue_country =
      this.err_person_maritial_status =
        "";
    this.err_person_client_number =
      this.err_person_communicate_in_chinese =
      this.err_person_billing_code =
      this.err_person_short_name =
      this.err_person_discount =
      this.err_org_exist =
      this.err_person_folder_name =
        "";
    this.org_exist_msg = "";
    this.err_team_code = "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    this.closeModal.emit();
    this.clientNumbFlag = false;

    if (this.personId == 0) {
      this.onChangeMStatus("");
      this.onChange("");
      this.personFormInit();
    }
    /*if(this.hasEmail === 0) {		
      for(let i = 0; this.personEmailArr.length > i; i++){
        this.personEmailArr.removeAt(i);
      }
    }*/
    this.myEmailCheck = true;
    this.getOrgAutocomplete();
  }

  personFormInit() {
    this.contactPersonForm = this.fb.group({
      person_salutation: new FormControl(""),
      person_organization: new FormControl(""),
      person_given_name: new FormControl(""),
      person_middle_name: new FormControl(""),
      person_family_name: new FormControl(""),
      person_occupation: new FormControl(""),

      //person_address_type: new FormControl(''),
      //person_primary_email_flag: new FormControl(''),
      //person_primary_email: new FormControl(''),
      //person_email_arr: this.fb.array([this.fb.group({ person_email: '' })]),
      //person_phone_number: new FormControl(''),
      person_home_email: new FormControl(""),
      person_work_email: new FormControl(""),
      person_other_email: new FormControl(""),
      person_home_phone_number: new FormControl(""),
      person_work_phone_number: new FormControl(""),
      person_other_phone_number: new FormControl(""),
      country_code: new FormControl([null]),
      person_mobile: new FormControl(""),
      person_website: new FormControl(""),
      team_code: new FormControl(),
      person_street: new FormControl(""),
      person_city: new FormControl(""),
      person_state: new FormControl(""),
      person_zip: new FormControl(""),
      person_country: new FormControl([null]),
      person_street2: new FormControl(""),
      person_city2: new FormControl(""),
      person_state2: new FormControl(""),
      person_zip2: new FormControl(""),
      person_country2: new FormControl([null]),
      person_website2: new FormControl(""),
      person_type: new FormControl(""),
      client_number: new FormControl(""),
      client_discount: new FormControl(""),
      type_of_case: new FormControl(""),
      folder_name: new FormControl(""),
      client_short_name: new FormControl(""),
      client_billing_code: new FormControl(""),
      client_communicate_in_chinese: new FormControl(""),
      person_id_type: new FormControl(""),
      person_id_number: new FormControl(""),
      person_issue_date: new FormControl(""),
      person_issue_country: new FormControl([null]),
      person_id_scan: new FormControl(""),
      person_maritial_status: new FormControl(""),
      person_maritial_regime: new FormControl(""),
      person_spouse_name: new FormControl(""),
      person_id: new FormControl(""),
      person_new_org: new FormControl(""),
      person_gender: new FormControl(""),
    });
  }

  addOrg() {
    this.data.newOrgName = this.myControl.value;
    jQuery("#organizationsModal").modal("show");
    this.router.navigateByUrl("/contacts", { state: { addCreate: "popup" } });
    jQuery("#personModal").modal("hide");
    jQuery("#org_name").val(this.data.newOrgName);

    /*if (new_org_name !== '' && new_org_name !== null) {
      this.err_person_organization = "";
      this.data.addNewOrg(new_org_name).subscribe(response => {
        if (response) {
          this.org_exist_msg = response['org_exist_msg'];
          this.err_org_exist = '';
          this.err_person_organization = '';
        } else {
          this.org_exist_msg = '';
        }

      });
    } else {
      this.err_person_organization = "Please give an Organization name.";
    }*/
  }

  checkEmailExists() {
    if (this.subs["checkEmail"]) {
      this.subs["checkEmail"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["checkEmail"] = this.data.checkEmail().subscribe((data) => {
        this.emailList = data;
      });
      resolve(null);
    });
  }

  checkPhoneExists() {
    if (this.subs["checkPhone"]) {
      this.subs["checkPhone"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["checkPhone"] = this.data.checkPhone().subscribe((data) => {
        this.phoneList = data;
      });
      resolve(null);
    });
  }

  emailHome(event: any) {
    this.homeemail = event.target.value;
    this.err_person_home_email = "";

    var matches = this.emailList.indexOf(this.homeemail) > -1;
    if (matches == true) {
      this.err_person_home_email = "This email already exists";
    }

    if (
      this.homeemail.trim() != "" &&
      (this.homeemail == this.workemail || this.homeemail == this.otheremail)
    ) {
      this.err_person_home_email = "You have already entered this email";
    }
  }

  emailWork(event: any) {
    this.workemail = event.target.value;
    this.err_person_work_email = "";

    var matches = this.emailList.indexOf(this.workemail) > -1;
    if (matches == true) {
      this.err_person_work_email = "This email already exists";
    }

    if (
      this.workemail.trim() != "" &&
      (this.workemail == this.homeemail || this.workemail == this.otheremail)
    ) {
      this.err_person_work_email = "You have already entered this email";
    }
  }

  emailOther(event: any) {
    this.otheremail = event.target.value;
    this.err_person_other_email = "";

    var matches = this.emailList.indexOf(this.otheremail) > -1;
    if (matches == true) {
      this.err_person_other_email = "This email already exists";
    }

    if (
      this.otheremail.trim() != "" &&
      this.otheremail == this.homeemail &&
      this.otheremail == this.workemail
    ) {
      this.err_person_other_email = "You have already entered this email";
    }
  }

  phoneHome(event: any) {
    this.homephone = event.target.value;
    this.err_person_home_phone_number = "";

    var matches = this.phoneList.indexOf(this.homephone) > -1;
    if (matches == true) {
      this.err_person_home_phone_number = "This phone number already exists";
    }

    if (
      this.homephone.trim() != "" &&
      (this.homephone == this.workphone || this.homephone == this.otherphone)
    ) {
      this.err_person_home_phone_number =
        "You have already entered this phone number";
    }
  }

  phoneWork(event: any) {
    this.workphone = event.target.value;
    this.err_person_work_phone_number = "";

    var matches = this.phoneList.indexOf(this.workphone) > -1;
    if (matches == true) {
      this.err_person_work_phone_number = "This phone number already exists";
    }

    if (
      this.workphone.trim() != "" &&
      (this.workphone == this.homephone || this.workphone == this.otherphone)
    ) {
      this.err_person_work_phone_number =
        "You have already entered this phone number";
    }
  }

  phoneOther(event: any) {
    this.otherphone = event.target.value;
    this.err_person_other_phone_number = "";

    var matches = this.phoneList.indexOf(this.otherphone) > -1;
    if (matches == true) {
      this.err_person_other_phone_number = "This phone number already exists";
    }

    if (
      this.otherphone.trim() != "" &&
      (this.otherphone == this.homephone || this.otherphone == this.workphone)
    ) {
      this.err_person_other_phone_number =
        "You have already entered this phone number";
    }
  }

  getLastClientNo(typeOfCase) {
    if (this.subs["lastClient"]) {
      this.subs["lastClient"].unsubscribe();
    }
    this.contactParam.typeOfCase = typeOfCase;
    this.subs["lastClient"] = this.data
      .getLastClientNo(this.contactParam)
      .subscribe((data) => {
        this.last_client_id = data;
        this.last_client_id = this.last_client_id.data;
        this.contactPersonForm.controls["client_number"].setValue(
          this.last_client_id
        );
      });
  }
}
