import { Component, OnInit, Input, OnChanges } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
declare var jQuery: any;
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ConfirmationDialogServiceService } from "../../../../confirmation-dialog/confirmation-dialog-service.service";

@Component({
  selector: "app-matter-tasks",
  templateUrl: "./matter-tasks.component.html",
  styleUrls: ["./matter-tasks.component.scss"],
})
export class MatterTasksComponent implements OnInit {
  addTaskForm: FormGroup;
  checked: any = true;
  succ_msg: any;
  tid: number = 0;
  mId: any;
  mtId: any;
  mId2: any;
  mId3: any;
  assigneeList: any;
  clientList: any;
  taskListID: any;
  matterList: any;
  err_task_name: string;
  err_description: string;
  err_due_date: string;
  err_assignee_type: string;
  err_assignee_id: string;
  err_client_id: string;
  err_priority: string;
  err_task_status: string;
  err_due_date_msg: String;
  @Input() taskId: number;
  p: number = 0;
  FormHeading: string;
  listDetails: any;
  taskList: any = [];
  listParam: any = {};
  lastTaskId: any;
  matterDet: any;
  check_val: any;
  assigneeType: String = "";
  listMatterTaskDetails: any;
  mappedTaskArr: any = [];
  planDetails: any;
  tasklist: any;
  allDet: any;
  allDetails: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "200px",
    maxHeight: "200px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  matterDetails: any;
  mattername: string;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    this.getTaskList();
    this.taskFormInit();
    this.listClientList();
    //this.getTaskListID();
    //this.listMatterList();
    this.getLastTaskId();

    this.FormHeading = "Add Task";
    document.querySelector("body").classList.add("sidebar-toggle");

    // for add url
    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);

    // for edit url
    let url2 = window.location.href;
    this.mId2 = url2.substr(url2.lastIndexOf("/") + -2);
    this.mId3 = this.mId2.substr(-5, 2);

    if ((this.mId.indexOf("-") !== -1) == true) {
      var str_arr = this.mId.split("-");
      this.mId = str_arr[0];
      this.mtId = str_arr[1];
    } else {
      this.mId = this.mId;
      this.mtId = 0;
    }

    this.route.params.subscribe((params) => {
      if (params) {
        if (params["id"]) {
          //this.mtId = +params['id'];
        }
      }
    });
    if (this.mtId > 0) {
      this.editTaskList();
    }

    this.route.params.subscribe((params) => {
      if (params.id > 0) {
        this.editTaskList();
      } else {
        this.addTaskForm.controls["task_matter"].setValue(this.mId);
        this.data.getMatterDetails(this.mId).subscribe((data) => {
          this.matterDet = data;
          this.addTaskForm.controls["task_leads"].setValue(
            this.matterDet.client_id
          );
          this.addTaskForm.controls["tasklist_name"].setValue(
            this.matterDet.tasklist_name
          );
          this.addTaskForm.controls["tasklist_id"].setValue(
            this.matterDet.tasklist_id
          );
        });
      }
    });

    this.getAllTaskByClientMatter(this.mId);

    this.getAllMatterTaskList();
    this.listPlannedTaskFromMatter();
    this.getAllListFromMatter();
  }

  getLastTaskId() {
    this.data.getLastTaskId().subscribe((data) => {
      this.lastTaskId = data;
      this.getMatterDtlById(this.mId);
      if (this.addTaskForm.controls["task_id"].value == this.lastTaskId) {
        let numb = this.lastTaskId.replace("T", "");
        let digit = Number(numb) + 1;
        this.addTaskForm.controls["task_id"].setValue("T" + digit);
      } else {
        this.addTaskForm.controls["task_id"].setValue(this.lastTaskId);
      }
    });
  }

  getMatterDtlById(mId) {
    return new Promise((resolve, reject) => {
      this.data.getMatterDetails(mId).subscribe((data) => {
        this.matterDetails = data;
        this.mattername =
          this.matterDetails.matter_number +
          " - " +
          this.matterDetails.matter_name;
        jQuery("#edit_matter_id").val(this.mattername);
      });
      resolve(null);
    });
  }

  // Matter Planned List

  listPlannedTaskFromMatter() {
    this.data.listPlannedTaskFromMatter(this.mId).subscribe((data) => {
      this.planDetails = data;
      //this.defaultChoice = this.planDetails.done_status
    });
  }

  getAllMatterTaskList() {
    this.data.getAllMatterTaskList(this.mId).subscribe((data) => {
      this.tasklist = data;
    });
  }

  taskConfirmationDialog(tId, matterId) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteMatterTask(tId, matterId, confirmed))
      .catch(() => {});
  }

  deleteMatterTask(tId, matterId, confirmed) {
    if (confirmed) {
      this.data.deleteMatterTask(tId, matterId).subscribe((response) => {
        this.getAllMatterTaskList();
        this.succ_msg = "Deleted Successfully.";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getAllListFromMatter();
        }, this.data.timeOut);
      });
    }
  }

  taskEdit(t_id) {
    // if(id != 0) {
    // 	this.taskId = id;
    //   }else {
    // 	this.taskId = 0;
    // 	jQuery('#editTask').on('hide.bs.modal', ()=> {
    // 		this.listPlannedTaskFromMatter();
    // 		this.getAllMatterTaskList()
    // 	})
    // }
    this.router.navigate([
      "/matters/matter-home/matter-tasks/" + this.mId + "-" + t_id,
    ]);
    this.data.getlistDetails(t_id).subscribe((data) => {
      this.listDetails = data;

      if (this.listDetails.done_status == "true") {
        this.listDetails.done_status = true;
      } else {
        this.listDetails.done_status = false;
      }
      this.addTaskForm.patchValue({
        t_id: this.listDetails.t_id,
        task_id: this.listDetails.task_id,
        task_name: this.listDetails.task_name,
        task_description: this.listDetails.description,
        task_due_date: this.listDetails.due_date,
        task_assignee: this.listDetails.assignee_type,
        task_assignee_user: this.listDetails.assignee_id,
        task_leads: this.listDetails.client_id,
        task_matter: this.listDetails.matter_id,
        task_priority: this.listDetails.priority,
        task_status: this.listDetails.task_status,
        done_status: this.listDetails.done_status,
        tasklist_name: this.listDetails.tasklist_name,
        tasklist_id: this.listDetails.tasklist_id,
        google_task_id: this.listDetails.google_task_id,
      });
      this.onChange(this.listDetails.assignee_type);
    });
    document.querySelector("body").classList.add("sidebar-toggle");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  getAllListFromMatter() {
    var i = 0;
    var arr: any = [];
    this.data.getAllListFromMatter(this.mId).subscribe((data) => {
      this.allDet = data;

      for (let index = 0; index < this.allDet.length; index++) {
        if (this.allDet[index].data_value != "deleted") {
          arr[i] = this.allDet[index];

          i++;
        }
      }
      this.allDetails = arr;
    });
  }

  getAllTaskByClientMatter(matter_id) {
    this.data.getAllTaskByClientMatter(matter_id).subscribe((data) => {
      this.mappedTaskArr = data;
    });
  }

  getTaskList() {
    this.data.getAllTaskList(this.listParam).subscribe((data) => {
      this.taskList = data;
    });
  }

  editTaskList() {
    this.data.getlistDetails(this.mtId).subscribe((data) => {
      this.listDetails = data;
      if (this.listDetails.done_status == "true") {
        this.listDetails.done_status = true;
      } else {
        this.listDetails.done_status = false;
      }

      this.addTaskForm.controls["tasklist_id"].setValue(
        this.listDetails.task_id
      );
      this.addTaskForm.patchValue({
        t_id: this.listDetails.t_id,
        //task_id: this.listDetails.task_id,
        task_name: this.listDetails.task_name,
        task_description: this.listDetails.description,
        task_due_date: this.listDetails.due_date,
        task_assignee: this.listDetails.assignee_type,
        task_assignee_user: this.listDetails.assignee_id,
        task_leads: this.listDetails.client_id,
        task_matter: this.listDetails.matter_id,
        task_priority: this.listDetails.priority,
        task_status: this.listDetails.task_status,
        done_status: this.listDetails.done_status,
        tasklist_name: this.listDetails.tasklist_name,
        tasklist_id: this.listDetails.tasklist_id,
        google_task_id: this.listDetails.google_task_id,
      });
      this.onChange(this.listDetails.assignee_type);
    });
  }

  getTaskListID() {
    this.data.getTaskListID().subscribe((data) => {
      this.taskListID = data;
    });
  }

  onChange(value) {
    if (value == 3) {
      this.assigneeType = "Other";
      this.data.getTaskAssignee(value).subscribe((data) => {
        this.assigneeList = data;
      });
    } else if (value == 1) {
      this.assigneeType = "Other";
      this.data.getTaskAssignee(value).subscribe((data) => {
        this.assigneeList = data;
      });
    } else if (value == "-999") {
      this.assigneeType = "Client";
      this.data.listClientList().subscribe((data) => {
        this.assigneeList = data;
      });
    } else {
      this.assigneeList = [];
    }
  }

  listClientList() {
    this.data.listClientList().subscribe((data) => {
      this.clientList = data;
    });
  }

  checkCheckBoxvalue(event) {
    this.check_val = event.checked;
  }

  addTask(postData) {
    const dateSendingToServer = new DatePipe("en-US").transform(
      postData.task_due_date,
      "yyyy-MM-dd"
    );

    let formdata = new FormData();
    formdata.append("task_id", postData.task_id);
    formdata.append("task_name", postData.task_name);
    formdata.append("due_date", dateSendingToServer);
    formdata.append("description", postData.task_description);
    formdata.append("assignee_type", postData.task_assignee);
    formdata.append("assignee_id", postData.task_assignee_user);
    formdata.append("matter_id", postData.task_matter);
    formdata.append("client_id", postData.task_leads);
    formdata.append("priority", postData.task_priority);
    formdata.append("task_status", postData.task_status);
    formdata.append("add_from", "matter_dashboard");
    formdata.append("tasklist_name", postData.tasklist_name);
    formdata.append("tasklist_id", postData.tasklist_id);
    //formdata.append('matter_id', this.mId);
    formdata.append("done_status", postData.done_status);
    if (postData.t_id) {
      formdata.append("t_id", postData.t_id);
      formdata.append("google_task_id", postData.google_task_id);
    }

    this.data.addTaskpost(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          //this.editTaskList();
          this.blankErrorMsgs();
          setTimeout(() => {
            this.getTaskList();
            this.taskFormInit();
            this.succ_msg = false;
            if (postData.t_id) {
              this.router.navigate([
                "/matters/matter-home/matter-email/",
                this.mId,
              ]);
            } else {
              this.router.navigate([
                "/matters/matter-home/matter-email/",
                this.mId,
              ]);
            }
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.task_name) {
          this.err_task_name = error.error.task_name;
        } else {
          this.err_task_name = "";
        }

        if (error.error.description) {
          this.err_description = error.error.description;
        } else {
          this.err_description = "";
        }

        if (error.error.due_date) {
          this.err_due_date = error.error.due_date;
        } else {
          this.err_due_date = "";
        }

        if (error.error.err_due_date) {
          this.err_due_date_msg = error.error.err_due_date_msg;
          this.err_due_date = "";
        } else {
          this.err_due_date_msg = "";
        }

        if (error.error.assignee_type) {
          this.err_assignee_type = error.error.assignee_type;
        } else {
          this.err_assignee_type = "";
        }

        if (error.error.assignee_id) {
          this.err_assignee_id = error.error.assignee_id;
        } else {
          this.err_assignee_id = "";
        }

        if (error.error.client_id) {
          this.err_client_id = error.error.client_id;
        } else {
          this.err_client_id = "";
        }

        if (error.error.priority) {
          this.err_priority = error.error.priority;
        } else {
          this.err_priority = "";
        }

        if (error.error.task_status) {
          this.err_task_status = error.error.task_status;
        } else {
          this.err_task_status = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_task_name =
      this.err_description =
      this.err_due_date =
      this.err_assignee_type =
      this.err_assignee_id =
      this.err_client_id =
      this.err_priority =
      this.err_task_status =
      this.err_due_date_msg =
        "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    // this.tid =0;
    // this.addTaskForm.controls['task_id'].setValue('T100');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["matters/matter-home/matter-tasks/" + this.mId]);
    this.getTaskList();
    this.taskFormInit();
    this.listClientList();
    this.getTaskListID();
    //this.listMatterList();
    this.getLastTaskId();
  }

  taskFormInit() {
    this.addTaskForm = this.fb.group({
      t_id: new FormControl(""),
      task_id: new FormControl(""),
      task_name: new FormControl(""),
      task_due_date: new FormControl(""),
      task_description: new FormControl(""),
      task_assignee: new FormControl(""),
      task_assignee_user: new FormControl(""),
      task_priority: new FormControl(""),
      task_leads: new FormControl(""),
      task_matter: new FormControl(""),
      task_status: new FormControl(""),
      done_status: new FormControl(""),
      tasklist_id: new FormControl(""),
      tasklist_name: new FormControl(""),
      google_task_id: new FormControl(""),
    });
  }

  openConfirmationDialog(tid) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(tid, confirmed))
      .catch(() => {});
  }

  deleteListItem(tid, confirmed) {
    if (confirmed) {
      this.data
        .deleteListItemFromMatter(tid, this.mId)
        .subscribe((response) => {
          this.getTaskList();
          this.succ_msg = "Deleted Succesfully";
          setTimeout(() => {
            this.succ_msg = "";
            this.succ_msg = false;
          }, this.data.timeOut);
        });
    }
  }
}
