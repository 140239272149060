import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray, FormControlName } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
declare var jQuery: any;

@Component({
  selector: 'app-corporate-matter',
  templateUrl: './corporate-matter.component.html',
  styleUrls: ['./corporate-matter.component.scss']
})
export class CorporateMatterComponent implements OnInit {

  @Input() group: FormGroup;
  @Input() matter_type_id: number;
  matterTypeId: number;
  status : boolean  = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(private fb: FormBuilder, private data: DataService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.matterTypeId = this.matter_type_id;
    this.closeModal.emit();
  }

  onChangeValue(value) {
    if (value == 'Other') {
      this.status = true;
    } else {
      this.status = false;
    }
  }

}
