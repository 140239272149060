import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bn-law';
  ngOnInit()
  {
    $(document).ready(function(){
        $(".menu-toggle").click(function(){
          $('.leftmenu li a').removeClass('open');
          $('.leftmenu li a').find('.fa').removeClass('fa-angle-down');
          $('.leftmenu li a').next().hide();

          $('body').toggleClass('sidebar-toggle');
            return false;
        });

        // $('input[type="file"]').change(function(e){
        //   var fileName = e.target[0].files[0].name;
        //   $(this).next('.custom-file-label').html(fileName);
        // });
    });
  }
}
