import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { DataService } from "../../services/data.service";
import { ConfirmationDialogServiceService } from "../../confirmation-dialog/confirmation-dialog-service.service";

@Component({
  selector: "app-task-lists",
  templateUrl: "./task-lists.component.html",
  styleUrls: ["./task-lists.component.scss"],
})
export class TaskListsComponent implements OnInit {
  listParam: any = {};
  taskList: any = [];
  tasklistId: number = 0;
  sort_order: any;
  p: number = 0;
  itemsPerPage: number = 100;

  allTaskListrecords: any = [];
  succ_msg: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  totalTaskList: any;

  constructor(
    private data: DataService,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    this.getTaskList();
  }

  openConfirmationDialog(tid) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(tid, confirmed))
      .catch(() => {});
  }

  deleteListItem(tid, confirmed) {
    if (confirmed) {
      this.data.deleteTaskList(tid).subscribe((response) => {
        this.succ_msg = "Deleted Succesfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getTaskList();
        }, this.data.timeOut);
      });
    }
  }

  searchTaskList(value) {
    this.listParam.searchString = value;
    this.getTaskList();
  }

  getTaskList() {
    this.data.getAllTaskList(this.listParam).subscribe((data) => {
      //this.allTaskListrecords = data;
      this.taskList = data;
    });
  }

  // openConfirmationDialog(tid) {
  // 	this.confirmationDialogService.confirm('Please Confirm', 'Do you really want to Delete?')
  // 		.then((confirmed) => this.deleteListItem(tid, confirmed))
  // 		.catch(() => {});
  // }

  // deleteListItem(tid, confirmed) {
  // 	if (confirmed) {
  // 		this.data.deleteListItem(tid).subscribe(response => {
  // 			this.getTaskList();
  // 			this.succ_msg = "Deleted Succesfully";
  // 			setTimeout(() => {
  // 				this.succ_msg = '';
  // 				this.succ_msg = false;
  // 			}, this.data.timeOut);
  // 		}
  // 		);
  // 	}
  // }

  taskListEdit(id) {
    //this.tasklistId = id;
    if (id != 0) {
      this.tasklistId = id;
      jQuery("#taskListModal").on("hidden.bs.modal", (e) => {
        this.getTaskList();
      });
    } else {
      this.tasklistId = 0;
    }
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.listParam.pageNo = page - 1;
    this.getTaskList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getTaskList();
  }
}
