import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
declare var jQuery: any;

@Component({
	selector: 'app-create-lead',
	templateUrl: './create-lead.component.html',
	styleUrls: ['./create-lead.component.scss']
})
export class CreateLeadComponent implements OnInit {

	clientEmail: boolean = false;
	addLeadFrom: FormGroup;
	matterTypeList: any = [];
	succ_msg: any;
	err_find_from: string; err_family_name: string; err_given_name: string; err_is_client_email: string; err_mobile: string; err_lead_email: string; err_meeting_location: string; err_matter_type_id: string; err_meeting_date: string; err_meeting_time: string; err_case_details: string;
	err_less_meeting_date: String;
	err_email_exist_msg: String;

	@Input() leadId: number;
	FormHeading: string;
	listDetails: any;
	listParam: any = {};
	leadList: any;
	primary_email_flag = false;

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

	constructor(private fb: FormBuilder, private data: DataService, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

	public ngOnChanges(changes: SimpleChanges): void {
		let chg = changes['leadId'];
		let cval = chg.currentValue;
		if (chg.currentValue > '0' && chg.currentValue == cval) {
			this.editLeadList();
			this.FormHeading = "Edit Lead Engagement";
			this.primary_email_flag = true;
		} else {
			this.FormHeading = "Lead Engagement";
			this.leadFormInt();
			this.emailAvailable(0);
			this.primary_email_flag = false;
		}

	}

	ngOnInit() {
		this.getMatterType();
		this.leadFormInt();
	}

	editLeadList() {
		this.data.getLeadDetails(this.leadId).subscribe(
			data => {
				this.listDetails = data;
				if (this.listDetails.lead_email != null) {
					this.primary_email_flag = true;
				} else {
					this.primary_email_flag = false;
				}
				this.addLeadFrom.patchValue({
					lead_id: this.listDetails.lead_id,
					find_from: this.listDetails.find_from,
					family_name: this.listDetails.family_name,
					given_name: this.listDetails.given_name,
					is_client_email: this.listDetails.is_client_email.toString(),
					lead_email: this.listDetails.lead_email,
					mobile: this.listDetails.mobile,
					meeting_location: this.listDetails.meeting_location,
					matter_type_id: this.listDetails.matter_type_id,
					meeting_date: this.listDetails.meeting_date,
					meeting_time: this.listDetails.meeting_time,
					case_details: this.listDetails.case_details,
				});
				this.emailAvailable(this.listDetails.is_client_email);
			}
		);
	}

	emailAvailable(value) {
		if (value == 1) {
			this.clientEmail = true;
		} else {
			this.clientEmail = false;
		}
	}

	getMatterType() {
		this.data.getmatterTypeListForLeads().subscribe(
			data => {
				this.matterTypeList = data;
			}
		);
	}

	getLeadList() {
		this.data.getLeadList(this.listParam).subscribe(
			data => {
				this.leadList = data;
			}
		);
	}

	addLead(postData) {
			
		const meeting_date = new DatePipe('en-US').transform(postData.meeting_date, 'yyyy-MM-dd');

		let formdata = new FormData();
		formdata.append('find_from', postData.find_from);
		formdata.append('family_name', postData.family_name);
		formdata.append('given_name', postData.given_name);
		formdata.append('is_client_email', postData.is_client_email);
		formdata.append('mobile', postData.mobile);
		formdata.append('lead_email', postData.lead_email);
		formdata.append('meeting_location', postData.meeting_location);
		formdata.append('matter_type_id', postData.matter_type_id);
		formdata.append('meeting_date', meeting_date);
		formdata.append('meeting_time', postData.meeting_time);
		formdata.append('case_details', postData.case_details);
		
		if (postData.lead_id) {
			formdata.append('lead_id', postData.lead_id);
		}else{
			 if ((meeting_date != '' || postData.meeting_time != '00:00')) {
			 	let start_time = postData.meeting_time.replace('AM', '').replace('PM', '');
				let sdtime = new Date(meeting_date + ' ' + start_time + ':00').toISOString();
				formdata.append('start_date_time', sdtime);
				} else {
				let start_time = '00:00';
				let sdtime = new Date(meeting_date + ' ' + '00:00' + ':00').toISOString();
			 	formdata.append('start_date_time', sdtime);
			 }
		}

		 this.data.addLeadpost(formdata).subscribe(response => {
		 	if (response) {
		 		this.succ_msg = response['msg'];
		 		this.getLeadList();
		 		this.blankErrorMsgs();
		 		this.editLeadList();
		 		setTimeout(() => {
		 			this.succ_msg = false;
		 			jQuery("#leads").modal("hide");
		 			this.clickCancel();
		 			this.leadFormInt();
		 		}, this.data.timeOut);
		 	} else {
		 		this.succ_msg = '';
		 	}

		 },

		 	error => {
		 		if (error.error.find_from) {
		 			this.err_find_from = error.error.find_from;
		 		} else {
		 			this.err_find_from = '';
		 		}

		 		if (error.error.family_name) {
		 			this.err_family_name = error.error.family_name;
		 		} else {
		 			this.err_family_name = '';
		 		}

		 		if (error.error.given_name) {
		 			this.err_given_name = error.error.given_name;
		 		} else {
		 			this.err_given_name = '';
		 		}

		 		if (error.error.is_client_email) {
		 			this.err_is_client_email = error.error.is_client_email;
		 		} else {
		 			this.err_is_client_email = '';
		 		}

		 		if (error.error.mobile) {
		 			this.err_mobile = error.error.mobile;
		 		} else {
		 			this.err_mobile = '';
		 		}

		 		if (error.error.lead_email) {
		 			this.err_lead_email = error.error.lead_email;
		 		} else {
		 			this.err_lead_email = '';
		 		}

		 		if (error.error.meeting_location) {
		 			this.err_meeting_location = error.error.meeting_location;
		 		} else {
		 			this.err_meeting_location = '';
		 		}

		 		if (error.error.matter_type_id) {
					this.err_matter_type_id = error.error.matter_type_id;
		 		} else {
		 			this.err_matter_type_id = '';
		 		}

		 		if (error.error.meeting_date) {
		 			this.err_meeting_date = error.error.meeting_date;
		 		} else {
					this.err_meeting_date = '';
		 		}

		 		if (error.error.meeting_time) {
		 			this.err_meeting_time = error.error.meeting_time;
		 		} else {
		 			this.err_meeting_time = '';
		 		}

		 		if (error.error.case_details) {
		 			this.err_case_details = error.error.case_details;
		 		} else {
		 			this.err_case_details = '';
		 		}

		 		if (error.error.less_meeting_date) {
		 			this.err_less_meeting_date = error.error.less_meeting_date;
		 		} else {
		 			this.err_less_meeting_date = '';
		 		}

		 		if (error.error.err_email_exist_msg) {
		 			this.err_email_exist_msg = error.error.err_email_exist_msg;
		 		} else {
					this.err_email_exist_msg = '';
				}

		 	});
	}

	blankErrorMsgs() {
		this.err_find_from = this.err_family_name = this.err_given_name = this.err_is_client_email = this.err_mobile = this.err_lead_email = this.err_meeting_location = this.err_matter_type_id = this.err_meeting_date = this.err_meeting_time = this.err_case_details = this.err_less_meeting_date = this.err_email_exist_msg = '';
	}

	clickCancel() {
		this.closeModal.emit();
		this.blankErrorMsgs();
		if (this.leadId == 0) {
			this.leadFormInt();
		}
	}

	leadFormInt() {
		this.addLeadFrom = this.fb.group({
			lead_id: new FormControl(''),
			find_from: new FormControl(''),
			family_name: new FormControl(''),
			given_name: new FormControl(''),
			is_client_email: new FormControl(''),
			lead_email: new FormControl(''),
			mobile: new FormControl(''),
			case_details: new FormControl(''),
			matter_type_id: new FormControl(''),
			meeting_date: new FormControl(''),
			meeting_time: new FormControl(''),
			meeting_location: new FormControl(''),
		})
	}


}
