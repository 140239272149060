import { Component } from "@angular/core";
import { DataService } from "../../services/data.service";

@Component({
  selector: "piechart",
  templateUrl: "./piechart.html",
})
export class PieChartComponent {
  // Pie
  public pieChartLabels: string[] = ["Client", "Lead"];
  public pieChartData: any = [];
  //public pieChartData:number[] = [40, 20];
  public pieChartType: string = "pie";
  public pieChartColors: Array<any> = [
    {
      backgroundColor: ["#89bf00", "#e48b00", "rgba(148,159,177,0.2)"],
    },
  ];
  public options: any = {
    legend: {
      position: "left",
      labels: {
        fontSize: 16,
      },
    },
  };
  leadClientData: any;
  ngOnInit() {
    this.getLeadToclient();
  }
  constructor(private data: DataService) {}
  // events
  public chartClicked(e: any): void {}

  public chartHovered(e: any): void {}

  getLeadToclient() {
    this.data.getLeadToclient().subscribe((data) => {
      this.pieChartData = data;
    });
  }
}
