import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import Chart from "chart.js";

@Component({
  selector: "app-yearly",
  templateUrl: "./yearly.component.html",
  styleUrls: ["./yearly.component.scss"],
})
export class YearlyComponent implements OnInit {
  Param: any = {};
  yearChart: any = [];
  year_chart_corporate: any = [];
  year_chart_litigation: any = [];
  year_chart_litigationLabour: any = [];
  year_chart_legal: any = [];
  public barChartLabelsIntellectualProperty: Label[] = [
    "New applications",
    "Renewals/Annuities",
    "Change of address/name/agent",
    "Assignments",
    "Appeals/Oppositions",
    "Official searches",
  ];
  public barChartLabelsCorporate: Label[] = [
    "Incorporation",
    "Assignment of shares",
    "Alteration",
    "Dissolution",
    "Overseas",
    "Corporate Registrations",
    "Junket",
  ];
  public barChartLabelsLitigation: Label[] = [
    "Civil Lawsuit",
    "Criminal Lawsuit",
    "Administrative Lawsuit",
    "Appeal",
  ];
  public barChartLabelsLabourLitigation: Label[] = [
    "Work Permit Skilled",
    "Work Permit Non-Skilled",
    "Work Permit Renewal",
    "Dependant",
    "Resident Application",
    "Resident Application Renewal",
  ];
  public barChartLabelsLegal: Label[] = ["Corporate", "Finance", "Gaming"];

  year_chart_ip: any = [];
  public barChartLegend = true;

  trademarkObjective: any = [];
  parentObjective: any = [];
  parentExtentionObjective: any = [];
  corporateObjective: any = [];
  litigationLabourObjective: any = [];
  LitigationObjective: any = [];
  LigalObjective: any = [];
  constructor(private data: DataService) {}

  ngOnInit() {
    this.getYearlyReportCorporate();
    this.getYearlyReportLitigation();
    this.getYearlyReportIP();
    this.getObjectiveTrademark();
    this.getObjectiveParent();
    this.getObjectiveParentExtention();
    this.getObjectiveCorporate();
    this.getObjectiveLitigationlabour();
    this.getYearlyReportLabour();
    this.getObjectiveLitigation();
    this.getYearlyReportLegalopenion();
    this.getObjectiveLigal();
  }

  getYearlyReportCorporate() {
    this.Param.type_of_case = 2;
    this.data.getYearlyReport(this.Param).subscribe((data) => {
      this.yearChart = data;
      let ctxl1 = "canvas_year_corporate";
      this.year_chart_corporate = new Chart(ctxl1, {
        type: "bar",
        data: {
          labels: this.barChartLabelsCorporate,
          datasets: [
            {
              data: data["2019"]["type_of_work_chart"],
              backgroundColor: "#ffff00",
              label: "2019",
            },
            {
              data: data["2020"]["type_of_work_chart"],
              backgroundColor: "#e60000",
              label: "2020",
            },
            {
              data: data["2021"]["type_of_work_chart"],
              backgroundColor: "#99ff33",
              label: "2021",
            },
          ],
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
          },
        },
      });
    });
  }

  getYearlyReportIP() {
    this.Param.type_of_case = 1;
    this.data.getYearlyReport(this.Param).subscribe((data) => {
      this.yearChart = data;
      let ctxl1 = "canvas_year_ip";
      this.year_chart_ip = new Chart(ctxl1, {
        type: "bar",
        data: {
          labels: this.barChartLabelsIntellectualProperty,
          datasets: [
            {
              data: data["2019"]["type_of_work_chart"],
              backgroundColor: "#00ffff",
              label: "2019",
            },
            {
              data: data["2020"]["type_of_work_chart"],
              backgroundColor: "#ffff00",
              label: "2020",
            },
            {
              data: data["2021"]["type_of_work_chart"],
              backgroundColor: "#ff0000",
              label: "2021",
            },
          ],
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
          },
        },
      });
    });
  }

  getYearlyReportLitigation() {
    this.Param.type_of_case = 3;
    this.Param.matter_type_id = "";
    this.data.getYearlyReport(this.Param).subscribe((data) => {
      let ctxl1 = "canvas_year_litigation";
      this.year_chart_litigation = new Chart(ctxl1, {
        type: "bar",
        data: {
          labels: this.barChartLabelsLitigation,
          datasets: [
            {
              data: data["2019"]["type_of_work_chart"],
              backgroundColor: "#ffff00",
              label: "2019",
            },
            {
              data: data["2020"]["type_of_work_chart"],
              backgroundColor: "#e60000",
              label: "2020",
            },
            {
              data: data["2021"]["type_of_work_chart"],
              backgroundColor: "#99ff33",
              label: "2021",
            },
          ],
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
          },
        },
      });
    });
  }

  getYearlyReportLabour() {
    this.Param.type_of_case = 3;
    this.Param.matter_type_id = 16;
    this.data.getYearlyReport(this.Param).subscribe((data) => {
      let ctxl1 = "canvas_year_litigationLabour";
      this.year_chart_litigationLabour = new Chart(ctxl1, {
        type: "bar",
        data: {
          labels: this.barChartLabelsLabourLitigation,
          datasets: [
            {
              data: data["2019"]["type_of_work_chart"],
              backgroundColor: "#ffff00",
              label: "2019",
            },
            {
              data: data["2020"]["type_of_work_chart"],
              backgroundColor: "#e60000",
              label: "2020",
            },
            {
              data: data["2021"]["type_of_work_chart"],
              backgroundColor: "#99ff33",
              label: "2021",
            },
          ],
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
          },
        },
      });
    });
  }

  getYearlyReportLegalopenion() {
    this.Param.type_of_case = 2;
    this.Param.matter_type_id = 12;
    this.data.getYearlyReport(this.Param).subscribe((data) => {
      let ctxl1 = "canvas_year_legal";
      this.year_chart_legal = new Chart(ctxl1, {
        type: "bar",
        data: {
          labels: this.barChartLabelsLegal,
          datasets: [
            {
              data: data["2019"]["type_of_work_chart"],
              backgroundColor: "#ffff00",
              label: "2019",
            },
            {
              data: data["2020"]["type_of_work_chart"],
              backgroundColor: "#e60000",
              label: "2020",
            },
            {
              data: data["2021"]["type_of_work_chart"],
              backgroundColor: "#99ff33",
              label: "2021",
            },
          ],
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
          },
        },
      });
    });
  }

  getObjectiveTrademark() {
    this.Param.matter_type_id = 5;
    this.Param.type_of_case = 1;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.trademarkObjective = data;
    });
  }

  getObjectiveParent() {
    this.Param.matter_type_id = 3;
    this.Param.type_of_case = 1;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.parentObjective = data;
    });
  }

  getObjectiveParentExtention() {
    this.Param.matter_type_id = 2;
    this.Param.type_of_case = 1;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.parentExtentionObjective = data;
    });
  }

  getObjectiveCorporate() {
    this.Param.type_of_case = 2;
    this.Param.matter_type_id = "";
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.corporateObjective = data;
    });
  }

  getObjectiveLitigation() {
    this.Param.type_of_case = 3;
    this.Param.matter_type_id = "";
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.LitigationObjective = data;
    });
  }

  getObjectiveLitigationlabour() {
    this.Param.type_of_case = 3;
    this.Param.matter_type_id = 16;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.litigationLabourObjective = data;
    });
  }

  getObjectiveLigal() {
    this.Param.type_of_case = 2;
    this.Param.matter_type_id = 12;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.LigalObjective = data;
    });
  }
}
