import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
declare var jQuery: any;
import { environment } from "../../../environments/environment";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-create-matter",
  templateUrl: "./create-matter.component.html",
  styleUrls: ["./create-matter.component.scss"],
})
export class CreateMatterComponent implements OnInit {
  mId: any;
  clientList: any;
  clientParam: any = {};
  taskParam: any = {};
  matterTypeList: any;
  matterKindList: any;
  matterQsList: any;
  countryList: any;
  createMatterForm: FormGroup;
  matterFormControlArr: any = [];
  taskList: any;
  succ_msg: any;
  selectedFiles: any;
  oppoList: any;
  filtered: any;
  err_client_id: String = "";
  err_client_id2: String = "";
  err_matter_name: String = "";
  err_matter_number: String = "";
  err_engagement: String = "";
  err_fees_paid: String = "";
  err_court_fees_paid: String = "";
  err_fees_total: String = "";
  err_matter_status: String = "";
  err_responsible_genius: String = "";
  err_stage: String = "";
  err_source_of_work: String = "";
  err_source_of_work_content: String = "";
  err_matter_type_id: String = "";
  err_case_no: String = "";
  err_folder_name: String = "";
  err_notes: String = "";
  err_section: String = "";
  err_task_id: String = "";
  err_notify_all_assignee: String = "";
  err_type_of_case: String = "";
  err_stage_date: String = "";
  err_lawyer_exist: String;
  err_judge_exist: String;
  err_matter_id_exist: String;
  assigneeList: any;
  responsiableList: any;
  err_person_given_name: string;
  err_person_family_name: string;
  err_person_email: string;
  err_person_phone_number: string;
  err_country_code: string;
  err_email_exist: string;
  err_add_email_exist: string;
  err_person_middle_name: string;

  err_org_name: string = "";
  err_org_email: string = "";
  err_org_phone: string;
  err_org_fax: string;
  err_org_website: string;
  err_org_street: string;
  err_org_city: string;
  err_org_state: string;
  err_org_zip: string;
  err_org_country: string;
  err_org_type: string;
  err_org_reg_type: string;
  err_org_reg_certificate: string;
  err_org_reg_no: string;
  err_org_reg_type1: string;
  err_org_reg_tag: string;
  err_org_exist: String;
  err_email_exist_msg: String;

  customFieldList: any = [];
  getlastmatterParam: any = {};

  listParam: any = {};
  field_type: any = "";
  field_name: any = "";
  field_name_control: any = "";
  is_flag: any = "Add";
  disableclass: string = "";

  issueDate: string = "";

  @Input() matterId: number;
  @Input() otherFlag: boolean;
  FormHeading: string;
  matterDetails: any;
  feesStatus: any;
  stages: any;
  case_type: any;
  court_official_fees: any;
  type_of_case: any;

  chg: any;
  cval: any;
  matterList: any;
  case_flag: any;
  other_flag: boolean = true;
  template_flag: boolean = false;
  matter_type_id: number = 0;
  case_type_id: number;
  matterTypeValueList: any;
  sourceOfWork: any;
  selectedUserIds: any = [];
  matterNumbFlag: boolean = false;
  matterStageFlag: boolean = false;
  myEmailCheck: any = true;

  maritialStatus: boolean = false;
  maritialStatus1: boolean = false;
  clientOtherForm: boolean = false;

  primary_email_flag = false;
  countryListData: boolean = false;
  cName: any;
  dynamicVar: any;
  litigation_matter_type_value: Number = 0;
  litigation_court_type: String;
  hasEmail: number = 0;
  base_url: any;
  short_folder: string;
  first_name: string;
  last_name: string;
  check_client: any;
  last_client_id: any;
  err_person_exits_client_no: string;
  typeOfCaseName: string;

  subs: any = [];

  selected_matter_type_dtl: any;
  selected_matter_dtl: any;
  personTab: boolean = false;
  orgTab: boolean = false;
  contactType: any = "PERS";
  selected: any;
  main_issue_id: any;
  litigation_point_id: any;
  selected_subpoint_of_law: any;
  selected_point_of_law: any;
  checkList: any;
  last_matter_id: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "200px",
    maxHeight: "200px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.type_of_case = [
      { id: "1", text: "IP", type_flag: "IP" },
      { id: "2", text: "CORP", type_flag: "CORPORATE" },
      { id: "3", text: "LIT", type_flag: "LITIGATION" },
      { id: "4", text: "NOT", type_flag: "NOTARIZATION" },
      { id: "5", text: "LIC", type_flag: "LICENSING" },
    ];

    this.personTab = true;
    this.base_url = `${environment.lpmsUrl}` + "documents/comp_cert/svg/";
    this.disableclass = "";

    this.getCaseTypeList();
    this.getSourceOfWork();
    this.matterFormInit();
    this.getTaskList();
    this.getAllChecklist();
    this.getOppoCousil();

    if (this.data.selectedTypeOfCase) {
      this.createMatterForm.controls["type_of_case"].setValue(
        this.data.selectedTypeOfCase
      );
      this.getClientList(this.data.selectedTypeOfCase);
      this.createMatterForm.controls["client_id"].setValue(
        this.data.selectedClient
      );
      this.getLastMatterNo(
        this.data.selectedClient,
        this.data.selectedTypeOfCase
      );
      this.selected_matter_dtl = this.getByValue(
        this.type_of_case,
        this.data.selectedTypeOfCase
      );

      this.matter_type_id = 0;
      this.case_flag = this.selected_matter_dtl.type_flag;
      this.case_type_id = this.data.selectedTypeOfCase;
      this.selected_matter_dtl = this.getByValue(
        this.type_of_case,
        this.data.selectedTypeOfCase
      );

      if (
        this.selected_matter_dtl.type_flag == "IP" ||
        this.selected_matter_dtl.type_flag == "LITIGATION"
      ) {
        this.other_flag = false;
        this.template_flag = true;
      } else {
        this.other_flag = true;
        this.template_flag = false;
      }

      this.getMatterTypeList(this.data.selectedTypeOfCase);
      this.getAllMatterKind(this.data.selectedTypeOfCase);
      this.getTaskAssigneeList(this.data.selectedTypeOfCase);
      this.customFormInit();
    }

    //this.getcustomFieldList();
    this.dynamicVar = `${environment.lpmsUrl}`;
    this.listNationality();
    this.cName = "Select";

    this.feesStatus = [
      { value: "yes", text: "Paid", selected: "" },
      { value: "no", text: "Not paid", selected: "" },
    ];

    this.stages = [
      { value: "case_retained", text: "Case Retained", selected: "" },
      { value: "case_filed", text: "Case Filed", selected: "" },
      { value: "case_closed", text: "Case Closed", selected: "" },
    ];

    this.court_official_fees = [
      { value: "yes", text: "Yes", selected: "" },
      { value: "no", text: "No", selected: "" },
      { value: "not_applicable", text: "Not Applicable", selected: "" },
    ];
  }

  clientTab(value) {
    if (value === "person") {
      this.short_folder = "";
      //jQuery("#org_folder_name").val('');
      //jQuery("#org_short_name").val('');

      this.personTab = true;
      this.orgTab = false;
      this.contactType = "PERS";
    } else if (value === "org") {
      this.first_name = "";
      this.last_name = "";
      this.short_folder = "";
      //jQuery("#client_folder_name").val('');
      //jQuery("#client_short_name").val('');

      this.orgTab = true;
      this.personTab = false;
      this.contactType = "ORG";
    }
  }

  onChangeCLient(value) {
    this.data.selectedClient = value;
    this.getLastMatterNo(
      this.data.selectedClient,
      this.data.selectedTypeOfCase
    );

    if (value == "Other") {
      this.clientOtherForm = true;
      this.getLastClientNo();
    } else {
      this.clientOtherForm = false;
    }
  }

  getLastMatterNo(clientId, typeOfCase) {
    if (this.subs["lastMatter"]) {
      this.subs["lastMatter"].unsubscribe();
    }
    if (clientId && clientId > 0) {
      this.getlastmatterParam.clientId = isNaN(clientId)
        ? Number(clientId)
        : clientId;
      this.getlastmatterParam.typeOfCase = typeOfCase;
      this.subs["lastMatter"] = this.data
        .getLastMatterNo(this.getlastmatterParam)
        .subscribe((data) => {
          this.last_matter_id = data;
          this.last_matter_id = this.last_matter_id.data;
          this.createMatterForm.controls["matter_number"].setValue(
            this.last_matter_id
          );
        });
    } else {
      this.createMatterForm.controls["matter_number"].setValue("");
    }
  }

  onChangeMStatus(value) {
    if (value == "Married") {
      this.maritialStatus = true;
    } else {
      this.maritialStatus = false;
    }
  }

  onChangeMStatus1(value) {
    if (value == "Married") {
      this.maritialStatus1 = true;
    } else {
      this.maritialStatus1 = false;
    }
  }

  getLastClientNo() {
    if (this.subs["lastClient"]) {
      this.subs["lastClient"].unsubscribe();
    }
    this.getlastmatterParam.typeOfCase = this.data.selectedTypeOfCase;
    this.subs["lastClient"] = this.data
      .getLastClientNo(this.getlastmatterParam)
      .subscribe((data) => {
        this.last_client_id = data;
        this.last_client_id = this.last_client_id.data;
        this.createMatterForm.controls["client_number"].setValue(
          this.last_client_id
        );
      });
  }

  checkClient(event: any) {
    if (this.subs["clientCheck"]) {
      this.subs["clientCheck"].unsubscribe();
    }

    let client_number = event.target.value;

    this.subs["clientCheck"] = this.data
      .existingClientNoCheck(client_number)
      .subscribe((data) => {
        this.check_client = data;
        if (this.check_client.success == "true") {
          //this.createMatterForm.controls['client_number'].setValue('');

          setTimeout(() => {
            this.err_person_exits_client_no = this.check_client.message;
          }, 1000);
        } else {
          this.err_person_exits_client_no = "";
        }
      });
  }

  getGivenName(event) {
    this.first_name = event.target.value;

    if (this.last_name) {
      const myArray = this.last_name.split(" ");
      let lastElement = myArray[myArray.length - 1];

      this.last_name = lastElement.toUpperCase();
      this.short_folder = this.first_name + " " + this.last_name;
      jQuery("#client_folder_name").val(this.short_folder);
      jQuery("#client_short_name").val(this.short_folder);
    }
  }
  getFolderName(event) {
    
    let matter_name = event.target.value;
    let matter_number = this.createMatterForm.get('matter_number').value;

    this.createMatterForm.patchValue({folder_name: matter_number+ " " +matter_name});
  }
  getFamilyName(event) {
    if (this.first_name) {
      this.first_name = this.first_name;
    } else {
      this.first_name = jQuery("#person_given_name").val();
    }
    this.first_name = this.first_name.toUpperCase();

    let last_name = event.target.value;
    const myArray = last_name.split(" ");
    let lastElement = myArray[myArray.length - 1];

    this.last_name = lastElement.toUpperCase();
    this.short_folder = this.first_name + " " + this.last_name;
    jQuery("#client_folder_name").val(this.short_folder);
    jQuery("#client_short_name").val(this.short_folder);
  }

  getOrgyName(event) {
    let org_name = jQuery("#org_name").val();
    let orgname = org_name.replace(/[^a-zA-Z ]/g, "");
    const myArray = orgname.split(" ");
    let lastElement = myArray[0];
    this.short_folder = lastElement.toUpperCase();
    jQuery("#org_folder_name").val(this.short_folder);
    jQuery("#org_short_name").val(this.short_folder);
  }

  getSourceOfWork() {
    if (this.subs["sourceW"]) {
      this.subs["sourceW"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["sourceW"] = this.data.getSourceOfWork().subscribe((data) => {
        this.sourceOfWork = data;
      });
      resolve(null);
    });
  }
  addSWork = (term) => ({ source_of_work_id: term, source_of_work_name: term });

  getCaseTypeList() {
    if (this.subs["caseType"]) {
      this.subs["caseType"].unsubscribe();
    }
    this.subs["caseType"] = this.data.getTypeOfCase().subscribe((data) => {
      this.case_type = data;
    });
  }

  onChangeCase(caseType, type_flag, isChange = false) {
    this.matter_type_id = 0;
    this.createMatterForm.patchValue({client_id:0});

    this.case_flag = type_flag;
    this.case_type_id = caseType;
    this.data.selectedTypeOfCase = caseType;
    this.selected_matter_dtl = this.getByValue(
      this.type_of_case,
      this.data.selectedTypeOfCase
    );

    if (type_flag == "IP" || type_flag == "LITIGATION") {
      this.other_flag = false;
      this.template_flag = true;
    } else {
      this.other_flag = true;
      this.template_flag = false;
    }
    this.getMatterTypeList(caseType);
    this.getAllMatterKind(caseType);
    this.getTaskAssigneeList(caseType);
    this.getClientList(caseType);

  
    if (this.is_flag == "Edit") {
      // Reset Matter Number
      if (isChange == true && this.matterDetails.type_of_case != caseType) {
        //this.createMatterForm.controls["matter_number"].setValue("");
        this.getLastMatterNo(
          this.matterDetails.client_id,
          this.matterDetails.type_of_case
        );
      } else {
        this.createMatterForm.controls["matter_number"].setValue(
          this.matterDetails.matter_number
        );
      }
    }
   

    this.customFormInit();
  }

  getByValue(arr, value) {
    for (var i = 0, iLen = arr.length; i < iLen; i++) {
      if (arr[i].id == value) return arr[i];
    }
  }

  getTaskAssigneeList(caseType) {
    if (this.subs["taskAssgn"]) {
      this.subs["taskAssgn"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["taskAssgn"] = this.data
        .getTaskAssignee(caseType)
        .subscribe((data) => {
          this.assigneeList = data;
          for (let index = 0; index < this.assigneeList.length; index++) {
            this.assigneeList[index].name =
              this.assigneeList[index].first_name +
              " " +
              this.assigneeList[index].last_name;
          }
        });
      resolve(null);
    });
  }

  onChangeMTypeId(mTypeId) {
    if (mTypeId == 0) {
      this.matter_type_id = 0;
    } else {
      this.matter_type_id = mTypeId;
    }
    if (
      this.case_flag == "CORPORATE" &&
      mTypeId != 8 &&
      mTypeId != 9 &&
      mTypeId != 11 &&
      mTypeId != 12 &&
      mTypeId != -998
    ) {
      this.other_flag = false;
      this.template_flag = true;
    } else if (
      mTypeId == -996 ||
      mTypeId == -997 ||
      mTypeId == -998 ||
      mTypeId == -999 ||
      mTypeId == 8 ||
      mTypeId == 9 ||
      mTypeId == 11 ||
      mTypeId == 12
    ) {
      this.other_flag = true;
      this.template_flag = false;
    } else {
      this.other_flag = false;
      this.template_flag = true;
    }
    if (this.case_flag == "LITIGATION") {
      this.getLitigationMatterTypesValues(this.case_type_id, mTypeId);
      /*if (mTypeId == 14) {
        this.createMatterForm.controls["litigation_competent_court"].setValue(
          8
        );
      } else if (mTypeId == 13) {
        this.createMatterForm.controls["litigation_competent_court"].setValue(
          9
        );
      } else if (mTypeId == 16) {
        this.createMatterForm.controls["litigation_competent_court"].setValue(
          7
        );
      } else if (mTypeId == 15) {
        this.createMatterForm.controls["litigation_competent_court"].setValue(
          5
        );
      } else {
        this.createMatterForm.controls["litigation_competent_court"].setValue(
          4
        );
      }*/
    }
    this.customFormInit();

    this.getMatterTypeDtl(mTypeId);
  }

  getMatterTypeDtl(matter_type_id) {
    if (this.subs["matterDtl"]) {
      this.subs["matterDtl"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["matterDtl"] = this.data
        .getMatterTypeDtl(matter_type_id)
        .subscribe((data) => {
          this.selected_matter_type_dtl = data;

          if (this.selected_matter_type_dtl.short_matter_type_name) {
            this.selected_matter_type_dtl.matter_type_name =
              this.selected_matter_type_dtl.short_matter_type_name;
          } else {
            this.selected_matter_type_dtl.matter_type_name =
              this.selected_matter_type_dtl.matter_type_name;
          }
          /*this.createMatterForm.controls["matter_name"].setValue(
            this.selected_matter_type_dtl.matter_type_name
          );*/
        });
      resolve(null);
    });
  }

  getLitigationMatterTypesValues(caseTypeId, mTypeId) {
    if (this.subs["litMatterType"]) {
      this.subs["litMatterType"].unsubscribe();
    }
    if (mTypeId == 0) {
      mTypeId = 0;
    } else {
      mTypeId = mTypeId;
    }
    this.subs["litMatterType"] = this.data
      .getLitigationMatterTypesValues(caseTypeId, mTypeId)
      .subscribe((response) => {
        if (response) {
          this.matterTypeValueList = response;
        }
      });
  }

  onChangeMTypevalue(matterTypeValue) {
    this.litigation_matter_type_value = matterTypeValue;
  }

  onChangeCourtType(courtVal) {
    this.litigation_court_type = courtVal;
  }

  getMatterTypeList(caseId) {
    if (this.subs["matterType"]) {
      this.subs["matterType"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["matterType"] = this.data
        .getAllMatterTypes(caseId)
        .subscribe((response) => {
          if (response) {
            this.matterTypeList = response;
          }
        });
      resolve(null);
    });
  }

  getAllMatterKind(caseId) {
    if (this.subs["matterKind"]) {
      this.subs["matterKind"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["matterKind"] = this.data
        .getAllMatterKind(caseId)
        .subscribe((response) => {
          if (response) {
            this.matterKindList = response;
          }
        });
      resolve(null);
    });
  }

  getMatterList() {
    if (this.subs["matterList"]) {
      this.subs["matterList"].unsubscribe();
    }
    this.subs["matterList"] = this.data
      .getMatterList(this.listParam)
      .subscribe((data) => {
        this.matterList = data;
      });
  }

  private ToArray(array) {
    const temparry = [];
    array.map((item) => {
      var contact_name =
        item.given_name + " " + item.middle_name + " " + item.family_name;
      temparry.push(contact_name);
    });
    return temparry;
  }

  getTaskList() {
    if (this.subs["taskList"]) {
      this.subs["taskList"].unsubscribe();
    }
    this.subs["taskList"] = this.data
      .getAllTaskList(this.taskParam)
      .subscribe((response) => {
        if (response) {
          this.taskList = response;
          this.filtered = this.taskList.filter(function ({ tasklist_name }) {
            var key = `${tasklist_name}$`;
            return !this.has(key) && this.add(key);
          }, new Set());
        }
      });
  }

  getAllChecklist() {
    if (this.subs["chkList"]) {
      this.subs["chkList"].unsubscribe();
    }
    this.subs["chkList"] = this.data.getAllChecklist().subscribe((response) => {
      if (response) {
        this.checkList = response;
      }
    });
  }

  checkboxClick() {
    jQuery("#createMatterModal").modal("hide");
    this.router.navigate(["/process-st-lists"]);
  }

  getClientList(caseType) {
    if (this.subs["clientList"]) {
      this.subs["clientList"].unsubscribe();
    }
    this.clientParam.type_of_case = caseType;
    return new Promise((resolve, reject) => {
      this.subs["clientList"] = this.data
        .getClientList(this.clientParam)
        .subscribe((response) => {
          if (response) {
            this.clientList = response;
            this.clientList.sort((a, b) =>
              a.client_number > b.client_number
                ? 1
                : a.client_number === b.client_number
                ? a.client_number > b.client_number
                  ? 1
                  : -1
                : -1
            );
          }
        });
      resolve(null);
    });
  }

  get relatedContactArr() {
    return this.createMatterForm.get("related_contact_arr") as FormArray;
  }
  addContact() {
    this.relatedContactArr.push(this.initItemRowsForContact());
  }
  deleteContact(index) {
    this.relatedContactArr.removeAt(index);
  }

  get customFieldArr() {
    return this.createMatterForm.get("custom_field_arr") as FormArray;
  }

  addCustomField() {
    this.customFieldArr.push(this.initItemRowsForCustomField());
  }

  deleteCustomField(index) {
    this.customFieldArr.removeAt(index);
  }

  initItemRowsForContact() {
    return this.fb.group({
      related_contact_relation: "",
      related_contact_phone: "",
      related_contact_cc: "",
    });
  }

  initItemRowsForCustomField() {
    return this.fb.group({
      custom_field_qs: "",
      custom_field_ans: "",
    });
  }

  // Person Add
  listNationality() {
    if (this.subs["nList"]) {
      this.subs["nList"].unsubscribe();
    }
    this.subs["nList"] = this.data.listNationality().subscribe((response) => {
      this.countryList = response;
      for (let index = 0; index < this.countryList.length; index++) {
        this.countryList[index].name = this.countryList[index].nicename;
        var imgName = this.countryList[index].iso.toLowerCase();
        this.countryList[index].imgUrl = this.base_url + imgName + ".svg";
      }
    });
  }
  get personEmailArr() {
    return this.createMatterForm.get("person_email_arr") as FormArray;
  }

  addEmail() {
    this.personEmailArr.push(this.fb.group({ person_email: "" }));
  }

  deleteEmail(index) {
    this.personEmailArr.removeAt(index);
  }

  countryId(id) {
    const countryArray = this.countryList.filter((cn) => id === cn.id);
    return countryArray[0].name;
  }

  countryItem(cname, cimg, phonecode) {
    var imgSrc = cimg.toLowerCase();
    this.cName =
      `<span><img src="${environment.lpmsUrl}documents/comp_cert/svg/${imgSrc}.svg" class="sImage" alt=""></span>` +
      cname;
    this.countryListData = false;
    let v = document.getElementById("dataValue");
    v.setAttribute("data-type", phonecode);
  }

  countryListDrop() {
    this.countryListData = !this.countryListData;
  }

  matterFormInit() {
    if (this.is_flag == "Edit") {
      this.createMatterForm = this.fb.group({
        matter_id: new FormControl(""),
        client_id: new FormControl("0"),
        matter_number: new FormControl(""),
        matter_name: new FormControl(""),
        engagement: new FormControl(""),
        fees_total: new FormControl(""),
        matter_status: new FormControl(""),
        responsible_genius: new FormControl(""),
        stage: new FormControl(""),
        issue_date: new FormControl(""),
        source_of_work: new FormControl(""),
        source_of_work_content: new FormControl(""),
        type_of_case: new FormControl(""),
        matter_type_id: new FormControl(""),
        fees_paid: new FormControl(""),
        court_fees_paid: new FormControl(""),
        case_no: new FormControl(""),
        folder_name: new FormControl(""),
        notes: new FormControl(""),
        section: new FormControl(""),
        related_contact_arr: this.fb.array([
          this.fb.group({
            related_contact_relation: "",
            related_contact_phone: "",
            related_contact_cc: "",
          }),
        ]),
        task_id: new FormControl(""),
        checklist_id: new FormControl(""),
        generate_document: new FormControl(""),
        notify_all_assignee: new FormControl(""),
        custom_field_arr: this.fb.array([
          this.fb.group({
            custom_field_qs: "",
            custom_field_ans: "",
          }),
        ]),
        litigation_type_of_case: new FormControl(""),
        matter_type_value: new FormControl(""),
        matter_kind: new FormControl(""),
        practice_area: [null],
        responsible_attorney: [null],
        originating_attorney: [null],
        main_issue: [null],
        litigation_cllient_status: [null],
        litigation_opposing_party: [null],
        litigation_competent_court: [null],
        litigation_judges: [null],
        litigation_opposing_counsel: [null],
        overseas_country_incorpo: [null],
        overseas_director_nationality: [null],
        overseas_representative_nationality: [null],
        overseas_representative_passport_issue_country: [null],
        litigation_stage: new FormControl(""),
        person_given_name: new FormControl(""),
        person_middle_name: new FormControl(""),
        person_family_name: new FormControl(""),
        person_primary_email_flag: new FormControl(""),
        person_primary_email: new FormControl(""),
        person_email_arr: this.fb.array([this.fb.group({ person_email: "" })]),
        person_phone_number: new FormControl(""),
        country_code: new FormControl([null]),
      });
    } else {
      this.createMatterForm = this.fb.group({
        matter_id: new FormControl(""),
        client_id: new FormControl("0"),
        matter_number: new FormControl(""),
        matter_name: new FormControl(""),
        engagement: new FormControl(""),
        fees_total: new FormControl(""),
        matter_status: new FormControl(""),
        responsible_genius: new FormControl(""),
        stage: new FormControl(""),
        issue_date: new FormControl(""),
        source_of_work: new FormControl(""),
        source_of_work_content: new FormControl(""),
        type_of_case: new FormControl(""),
        matter_type_id: new FormControl(""),
        fees_paid: new FormControl(""),
        court_fees_paid: new FormControl(""),
        case_no: new FormControl(""),
        folder_name: new FormControl(""),
        notes: new FormControl(""),
        section: new FormControl(""),
        related_contact_arr: this.fb.array([this.initItemRowsForContact()]),
        task_id: new FormControl(""),
        generate_document: new FormControl(""),
        checklist_id: new FormControl(""),
        notify_all_assignee: new FormControl(""),
        custom_field_arr: this.fb.array([this.initItemRowsForCustomField()]),
        litigation_type_of_case: new FormControl(""),
        matter_type_value: new FormControl(""),
        matter_kind: new FormControl(""),
        practice_area: [null],
        responsible_attorney: [null],
        originating_attorney: [null],
        main_issue: [null],
        litigation_cllient_status: [null],
        litigation_opposing_party: [null],
        litigation_competent_court: [null],
        litigation_judges: [null],
        litigation_opposing_counsel: [null],
        overseas_country_incorpo: [null],
        overseas_director_nationality: [null],
        overseas_representative_nationality: [null],
        overseas_representative_passport_issue_country: [null],
        litigation_stage: new FormControl(""),
        person_given_name: new FormControl(""),
        person_middle_name: new FormControl(""),
        person_family_name: new FormControl(""),
        person_primary_email_flag: new FormControl(""),
        person_primary_email: new FormControl(""),
        person_email_arr: this.fb.array([this.fb.group({ person_email: "" })]),
        person_phone_number: new FormControl(""),
        country_code: new FormControl([null]),
        org_name: new FormControl(""),
        org_email: new FormControl(""),
        org_phone: new FormControl(""),
        org_street: new FormControl(""),
        org_city: new FormControl(""),
        org_country: new FormControl([null]),
        person_type: new FormControl(""),
        client_folder_name: new FormControl(),
        client_number: new FormControl(),
        client_discount: new FormControl(),
        client_short_name: new FormControl(),
        client_billing_code: new FormControl(),
        client_communicate_in_chinese: new FormControl(),
        org_type: new FormControl(""),
      });
    }
    this.customFormInit();
  }

  otherFormAddControl() {
    this.createMatterForm.addControl("custom_matter_id", new FormControl(""));
    this.createMatterForm.addControl(
      "custom_matter_type_name",
      new FormControl("")
    );
    this.createMatterForm.addControl("matter_description", new FormControl(""));
    this.createMatterForm.addControl("practice_area", new FormControl());
    this.createMatterForm.addControl(
      "other_matter_type_name",
      new FormControl("")
    );
    this.createMatterForm.addControl(
      "responsible_attorney",
      new FormControl("")
    );
    this.createMatterForm.addControl("open_date", new FormControl(""));
    this.createMatterForm.addControl("close_date", new FormControl(""));
    this.createMatterForm.addControl("pending_date", new FormControl(""));
    this.createMatterForm.addControl(
      "originating_attorney",
      new FormControl("")
    );
    this.createMatterForm.addControl("limitations_date", new FormControl(""));
    this.createMatterForm.addControl("cllient_ref_no", new FormControl(""));
    this.createMatterForm.addControl("matter_location", new FormControl(""));
  }

  customFormInit() {
    if (this.case_type_id == 1) {
      if (this.matter_type_id == -999) {
        this.otherFormAddControl();
      } else {
        this.createMatterForm.addControl("matter_ip_id", new FormControl(""));
        this.createMatterForm.addControl(
          "applicant_name_english",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "applicant_name_chinese",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "applicant_address_english",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "applicant_address_portuguese",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "ip_nationalities",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "applicant_address_chinese",
          new FormControl("")
        );
        this.otherFormAddControl();
      }
    } else if (this.case_type_id == 2) {
      if (this.matter_type_id == 10) {
        this.createMatterForm.addControl(
          "corporate_change_id",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "corporate_change_value",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "corporate_change_value_other",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "corporate_company_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "corporate_company_address",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "corporate_company_regno",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "corporate_taxpayer_no",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "company_cadastro_no",
          new FormControl("")
        );
        this.otherFormAddControl();
      } else if (this.matter_type_id == 6) {
        this.createMatterForm.addControl(
          "corporate_incorporation_id",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_comp_name_chinese",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_comp_name_portu",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_comp_name_english",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_full_name",
          new FormControl("")
        );
        this.createMatterForm.addControl("incorpo_gender", new FormControl(""));
        this.createMatterForm.addControl(
          "incorpo_nationality",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_marital_status",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_spouse_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_marital_regime",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_address",
          new FormControl("")
        );
        this.createMatterForm.addControl("incorpo_type", new FormControl(""));
        this.createMatterForm.addControl("incorpo_number", new FormControl(""));
        this.createMatterForm.addControl(
          "incorpo_id_issue_date",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_issue_country",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_issue_authority",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_date_of_birth",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_place_of_birth",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_source_of_fund",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_macau_pep",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_international_pep",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "incorpo_family_pep",
          new FormControl("")
        );
        this.otherFormAddControl();
      } else if (this.matter_type_id == 7) {
        this.createMatterForm.addControl("overseas_id", new FormControl(""));
        this.createMatterForm.addControl(
          "overseas_company_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_company_address",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_country_incorpo",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_company_regno",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_comp_director_fullname",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_director_gender",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_director_nationality",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_director_marital_status",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_spouse_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_marital_regime",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_director_address",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_director_id_type",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_director_id_no",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_doc_id_date",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_authority_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_gender",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_nationality",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_marital_status",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_spouse",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_address",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_id_type",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_id_no",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_id_issue_date",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_auth_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_representative_passport_issue_country",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_macau_comp_name",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_macau_comp_address",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_macau_comp_tax_regno",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_type_of_service",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_start_date",
          new FormControl("")
        );
        this.createMatterForm.addControl(
          "overseas_end_date",
          new FormControl("")
        );
        this.createMatterForm.addControl("overseas_price", new FormControl(""));
        this.otherFormAddControl();
      } else {
        this.otherFormAddControl();
      }
    } else if (this.case_type_id == 3) {
      this.createMatterForm.addControl("litigation_id", new FormControl(""));
      this.createMatterForm.addControl("main_issue", new FormControl(""));
      this.createMatterForm.addControl(
        "litigation_cllient_status",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_opposing_party",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_competent_court",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_case_filling_no",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_case_section",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_case_assigned",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_judges",
        new FormControl("")
      );
      this.createMatterForm.addControl("litigation_stage", new FormControl(""));
      this.createMatterForm.addControl("limitations_date", new FormControl(""));
      this.createMatterForm.addControl(
        "litigation_deadline",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_opposing_counsel",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_court_fees",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_points_of_law",
        new FormControl("")
      );
      this.createMatterForm.addControl(
        "litigation_subpoints_of_law",
        new FormControl("")
      );
    } else {
      this.otherFormAddControl();
    }
  }

  onUploadMatterFile(event) {
    this.selectedFiles = event.target.files;
    if (event.target.files) {
      var reader = new FileReader();
      reader.onload = (event) => {};
    }
  }

  otherMatterAppend(formdata, postData) {
    if (postData.open_date != "" && postData.open_date != null) {
      var open_date = new DatePipe("en-US").transform(
        postData.open_date,
        "yyyy-MM-dd"
      );
    } else {
      var open_date = "";
    }

    if (postData.close_date != "" && postData.close_date != null) {
      var close_date = new DatePipe("en-US").transform(
        postData.close_date,
        "yyyy-MM-dd"
      );
    } else {
      var close_date = "";
    }

    if (postData.pending_date != "" && postData.pending_date != null) {
      var pending_date = new DatePipe("en-US").transform(
        postData.pending_date,
        "yyyy-MM-dd"
      );
    } else {
      var pending_date = "";
    }
    if (postData.limitations_date != "" && postData.limitations_date != null) {
      var limitations_date = new DatePipe("en-US").transform(
        postData.limitations_date,
        "yyyy-MM-dd"
      );
    } else {
      var limitations_date = "";
    }
    formdata.append(
      "custom_matter_type_name",
      postData.custom_matter_type_name
    );
    formdata.append("other_matter_type_name", postData.other_matter_type_name);

    formdata.append("matter_description", postData.matter_description);
    formdata.append("practice_area", postData.practice_area);
    formdata.append("responsible_attorney", postData.responsible_attorney);
    formdata.append("open_date", open_date);
    formdata.append("close_date", close_date);
    formdata.append("pending_date", pending_date);
    formdata.append("originating_attorney", postData.originating_attorney);
    formdata.append("limitations_date", limitations_date);
    formdata.append("cllient_ref_no", postData.cllient_ref_no);
    formdata.append("matter_location", postData.matter_location);
    if (postData.custom_matter_id) {
      formdata.append("custom_matter_id", postData.custom_matter_id);
    }
  }

  getOppoCousil() {
    if (this.subs["ocList"]) {
      this.subs["ocList"].unsubscribe();
    }
    this.subs["ocList"] = this.data
      .getContactsByContactType("loc")
      .subscribe((data) => {
        this.oppoList = data;
      });
  }

  addMatter(postData) {
    var newvalue = jQuery("#locj").text();

    var contact_arr: any = [];
    var custom_field_arr: any = [];
    let formdata = new FormData();

    postData.custom_field_arr.map((item, ind) => {
      if (item.custom_field_qs != "" && item.custom_field_ans != "") {
        custom_field_arr[ind] =
          item.custom_field_qs + "," + item.custom_field_ans;
      }
    });
    const customFieldStr = JSON.stringify(custom_field_arr);

    postData.related_contact_arr.map((item, index) => {
      if (
        item.related_contact_relation != "" &&
        item.related_contact_phone != ""
      ) {
        contact_arr[index] =
          item.related_contact_relation +
          "," +
          item.related_contact_phone +
          "," +
          item.related_contact_cc;
      }
    });
    const contactStr = JSON.stringify(contact_arr);

    if (postData.issue_date !== "" && postData.issue_date != null) {
      var issue_date = new DatePipe("en-US").transform(
        postData.issue_date,
        "yyyy-MM-dd"
      );
    } else {
      var issue_date = "";
    }
    formdata.append("matter_number", postData.matter_number);
    if (postData.client_id == "Other") {
      if (this.contactType == "PERS") {
        let email_str: any = [];
        postData.person_email_arr.map((item) => {
          if (item.person_email != "") {
            email_str += item.person_email + ",";
          }
        });

        formdata.append(
          "given_name",
          postData.person_given_name != null ? postData.person_given_name : ""
        );
        formdata.append(
          "middle_name",
          postData.person_middle_name != null ? postData.person_middle_name : ""
        );
        formdata.append(
          "family_name",
          postData.person_family_name != null ? postData.person_family_name : ""
        );
        formdata.append(
          "primary_email_flag",
          postData.person_primary_email_flag != null
            ? postData.person_primary_email_flag
            : ""
        );
        formdata.append(
          "email",
          postData.person_primary_email != null
            ? postData.person_primary_email
            : ""
        );
        formdata.append(
          "phone_number",
          postData.person_phone_number != null
            ? postData.person_phone_number
            : ""
        );
        formdata.append(
          "country_code",
          postData.country_code != null ? postData.country_code : ""
        );
        formdata.append("email_str", email_str);
        formdata.append("type", "Client");
      }

      if (this.contactType == "ORG") {
        formdata.append(
          "name",
          postData.org_name != null ? postData.org_name : ""
        );
        formdata.append(
          "email",
          postData.org_email != null ? postData.org_email : ""
        );
        formdata.append(
          "phone",
          postData.org_phone != null ? postData.org_phone : ""
        );
        formdata.append(
          "country_code",
          postData.country_code != null ? postData.country_code : ""
        );
        formdata.append(
          "street",
          postData.org_street != null ? postData.org_street : ""
        );
        formdata.append(
          "city",
          postData.org_city != null ? postData.org_city : ""
        );
        formdata.append(
          "country",
          postData.org_country != null ? postData.org_country : ""
        );
        formdata.append("type", "Client");
      }

      formdata.append(
        "client_number",
        postData.client_number != null ? postData.client_number : ""
      );
      formdata.append(
        "discount",
        postData.client_discount != null ? postData.client_discount : ""
      );
      formdata.append(
        "client_folder_name",
        postData.client_folder_name != null ? postData.client_folder_name : ""
      );
      formdata.append(
        "short_name",
        postData.client_short_name != null ? postData.client_short_name : ""
      );
      formdata.append(
        "billing_code",
        postData.client_billing_code != null ? postData.client_billing_code : ""
      );
      formdata.append(
        "communicate_in_chinese",
        postData.client_communicate_in_chinese != null
          ? postData.client_communicate_in_chinese
          : ""
      );
      formdata.append("contact_type", this.contactType);
      this.data.insertClientToNDFromLPMS(formdata).subscribe((res) => {});
    }

    formdata.append(
      "client_id",
      postData.client_id != 0 ? postData.client_id : ""
    );
    formdata.append("matter_name", postData.matter_name);
    formdata.append("engagement", postData.engagement);
    formdata.append("fees_paid", postData.fees_paid);
    formdata.append("court_fees_paid", postData.court_fees_paid);
    formdata.append("fees_total", postData.fees_total);
    formdata.append("matter_status", postData.matter_status);
    formdata.append("responsible_genius", postData.responsible_genius);
    formdata.append("stage", postData.stage);
    formdata.append("stage_date", issue_date);
    formdata.append("source_of_work", postData.source_of_work);
    formdata.append("source_of_work_content", postData.source_of_work_content);
    formdata.append("type_of_case", postData.type_of_case);
    formdata.append("matter_type_id", postData.matter_type_id);
    formdata.append("folder_name", postData.folder_name);
    formdata.append("case_no", postData.case_no);
    formdata.append("notes", postData.notes);
    formdata.append("section", postData.section);
    formdata.append("task_id", postData.task_id);
    formdata.append("checklist_id", postData.checklist_id);
    formdata.append("generate_document", postData.generate_document);
    formdata.append("custom_fields", customFieldStr);
    formdata.append("notify_all_assignee", postData.notify_all_assignee);
    formdata.append("related_contact", contactStr);
    formdata.append("matter_add_from", "matter");
    formdata.append("matter_id", postData.matter_id);
    formdata.append("matter_kind", postData.matter_kind);

    if (postData.type_of_case == 1) {
      if (postData.matter_type_id == -999) {
        this.otherMatterAppend(formdata, postData);
      } else {
        formdata.append(
          "applicant_name_english",
          postData.applicant_name_english
        );
        formdata.append(
          "applicant_name_chinese",
          postData.applicant_name_chinese
        );
        formdata.append(
          "applicant_address_english",
          postData.applicant_address_english
        );
        formdata.append(
          "applicant_address_portuguese",
          postData.applicant_address_portuguese
        );
        formdata.append("ip_nationalities", postData.ip_nationalities);
        formdata.append(
          "applicant_address_chinese",
          postData.applicant_address_chinese
        );
        if (postData.matter_ip_id) {
          formdata.append("matter_ip_id", postData.matter_ip_id);
        }
        this.otherMatterAppend(formdata, postData);
      }
    } else if (postData.type_of_case == 2) {
      if (postData.matter_type_id == 10) {
        formdata.append(
          "corporate_change_value",
          postData.corporate_change_value
        );
        formdata.append(
          "corporate_company_name",
          postData.corporate_company_name
        );
        formdata.append(
          "corporate_company_address",
          postData.corporate_company_address
        );
        formdata.append(
          "corporate_company_regno",
          postData.corporate_company_regno
        );
        formdata.append(
          "corporate_taxpayer_no",
          postData.corporate_taxpayer_no
        );
        formdata.append("company_cadastro_no", postData.company_cadastro_no);
        if (postData.corporate_change_id) {
          formdata.append("corporate_change_id", postData.corporate_change_id);
        }
        this.otherMatterAppend(formdata, postData);
      } else if (postData.matter_type_id == 6) {
        if (
          postData.incorpo_id_issue_date !== "" &&
          postData.incorpo_id_issue_date != null
        ) {
          var incorpo_id_issue_date = new DatePipe("en-US").transform(
            postData.incorpo_id_issue_date,
            "yyyy-MM-dd"
          );
        } else {
          var incorpo_id_issue_date = "";
        }

        if (
          postData.incorpo_date_of_birth !== "" &&
          postData.incorpo_date_of_birth != null
        ) {
          var incorpo_date_of_birth = new DatePipe("en-US").transform(
            postData.incorpo_date_of_birth,
            "yyyy-MM-dd"
          );
        } else {
          var incorpo_date_of_birth = "";
        }

        formdata.append(
          "incorpo_comp_name_chinese",
          postData.incorpo_comp_name_chinese
        );
        formdata.append(
          "incorpo_comp_name_portu",
          postData.incorpo_comp_name_portu
        );
        formdata.append(
          "incorpo_comp_name_english",
          postData.incorpo_comp_name_english
        );
        formdata.append("incorpo_full_name", postData.incorpo_full_name);
        formdata.append("incorpo_gender", postData.incorpo_gender);
        formdata.append("incorpo_nationality", postData.incorpo_nationality);
        formdata.append(
          "incorpo_marital_status",
          postData.incorpo_marital_status
        );
        formdata.append("incorpo_spouse_name", postData.incorpo_spouse_name);
        formdata.append(
          "incorpo_marital_regime",
          postData.incorpo_marital_regime
        );
        formdata.append("incorpo_address", postData.incorpo_address);
        formdata.append("incorpo_id_type", postData.incorpo_type);
        formdata.append("incorpo_id_number", postData.incorpo_number);
        formdata.append("incorpo_id_issue_date", incorpo_id_issue_date);
        formdata.append(
          "incorpo_issue_country",
          postData.incorpo_issue_country
        );
        formdata.append(
          "incorpo_issue_authority",
          postData.incorpo_issue_authority
        );
        formdata.append("incorpo_date_of_birth", incorpo_date_of_birth);
        formdata.append(
          "incorpo_place_of_birth",
          postData.incorpo_place_of_birth
        );
        formdata.append(
          "incorpo_source_of_fund",
          postData.incorpo_source_of_fund
        );
        formdata.append("incorpo_macau_pep", postData.incorpo_macau_pep);
        formdata.append(
          "incorpo_international_pep",
          postData.incorpo_international_pep
        );
        formdata.append("incorpo_family_pep", postData.incorpo_family_pep);
        if (postData.corporate_incorporation_id) {
          formdata.append(
            "corporate_incorporation_id",
            postData.corporate_incorporation_id
          );
        }
        this.otherMatterAppend(formdata, postData);
      } else if (postData.matter_type_id == 7) {
        if (
          postData.overseas_doc_id_date !== "" &&
          postData.overseas_doc_id_date != null
        ) {
          var overseas_doc_id_date = new DatePipe("en-US").transform(
            postData.overseas_doc_id_date,
            "yyyy-MM-dd"
          );
        } else {
          var overseas_doc_id_date = "";
        }

        if (
          postData.overseas_representative_id_issue_date !== "" &&
          postData.overseas_representative_id_issue_date != null
        ) {
          var overseas_representative_id_issue_date = new DatePipe(
            "en-US"
          ).transform(
            postData.overseas_representative_id_issue_date,
            "yyyy-MM-dd"
          );
        } else {
          var overseas_representative_id_issue_date = "";
        }

        if (
          postData.overseas_start_date !== "" &&
          postData.overseas_start_date != null
        ) {
          var overseas_start_date = new DatePipe("en-US").transform(
            postData.overseas_start_date,
            "yyyy-MM-dd"
          );
        } else {
          var overseas_start_date = "";
        }

        if (
          postData.overseas_end_date !== "" &&
          postData.overseas_end_date != null
        ) {
          var overseas_end_date = new DatePipe("en-US").transform(
            postData.overseas_end_date,
            "yyyy-MM-dd"
          );
        } else {
          var overseas_end_date = "";
        }

        formdata.append(
          "overseas_company_name",
          postData.overseas_company_name
        );
        formdata.append(
          "overseas_company_address",
          postData.overseas_company_address
        );
        formdata.append(
          "overseas_country_incorpo",
          postData.overseas_country_incorpo
        );
        formdata.append(
          "overseas_company_regno",
          postData.overseas_company_regno
        );
        formdata.append(
          "overseas_comp_director_fullname",
          postData.overseas_comp_director_fullname
        );
        formdata.append(
          "overseas_director_gender",
          postData.overseas_director_gender
        );
        formdata.append(
          "overseas_director_nationality",
          postData.overseas_director_nationality
        );
        formdata.append(
          "overseas_director_marital_status",
          postData.overseas_director_marital_status
        );
        formdata.append("overseas_spouse_name", postData.overseas_spouse_name);
        formdata.append(
          "overseas_marital_regime",
          postData.overseas_marital_regime
        );
        formdata.append(
          "overseas_director_address",
          postData.overseas_director_address
        );
        formdata.append(
          "overseas_director_id_type",
          postData.overseas_director_id_type
        );
        formdata.append(
          "overseas_director_id_no",
          postData.overseas_director_id_no
        );
        formdata.append("overseas_doc_id_date", overseas_doc_id_date);
        formdata.append(
          "overseas_authority_name",
          postData.overseas_authority_name
        );
        formdata.append(
          "overseas_representative_name",
          postData.overseas_representative_name
        );
        formdata.append(
          "overseas_representative_gender",
          postData.overseas_representative_gender
        );
        formdata.append(
          "overseas_representative_nationality",
          postData.overseas_representative_nationality
        );
        formdata.append(
          "overseas_representative_marital_status",
          postData.overseas_representative_marital_status
        );
        formdata.append(
          "overseas_representative_spouse",
          postData.overseas_representative_spouse
        );
        formdata.append(
          "overseas_representative_address",
          postData.overseas_representative_address
        );

        formdata.append(
          "overseas_representative_id_type",
          postData.overseas_representative_id_type
        );
        formdata.append(
          "overseas_representative_id_no",
          postData.overseas_representative_id_no
        );
        formdata.append(
          "overseas_representative_id_issue_date",
          overseas_representative_id_issue_date
        );
        formdata.append(
          "overseas_representative_auth_name",
          postData.overseas_representative_auth_name
        );
        formdata.append(
          "overseas_representative_passport_issue_country",
          postData.overseas_representative_passport_issue_country
        );
        formdata.append(
          "overseas_macau_comp_name",
          postData.overseas_macau_comp_name
        );
        formdata.append(
          "overseas_macau_comp_address",
          postData.overseas_macau_comp_address
        );
        formdata.append(
          "overseas_macau_comp_tax_regno",
          postData.overseas_macau_comp_tax_regno
        );
        formdata.append(
          "overseas_type_of_service",
          postData.overseas_type_of_service
        );
        formdata.append("overseas_start_date", overseas_start_date);
        formdata.append("overseas_end_date", overseas_end_date);
        formdata.append("overseas_price", postData.overseas_price);
        if (postData.overseas_id) {
          formdata.append("overseas_id", postData.overseas_id);
        }
        this.otherMatterAppend(formdata, postData);
      } else {
        this.otherMatterAppend(formdata, postData);
      }
    } else if (postData.type_of_case == 3) {
      if (
        postData.litigation_deadline != "" &&
        postData.litigation_deadline != null
      ) {
        var litigation_deadline = new DatePipe("en-US").transform(
          postData.litigation_deadline,
          "yyyy-MM-dd"
        );
      } else {
        var litigation_deadline = "";
      }

      var foundValue = this.oppoList.filter(
        (obj) => obj.contact_id == postData.litigation_opposing_counsel
      );

      if (postData.litigation_opposing_counsel == null) {
        formdata.append("litigation_opposing_counsel", newvalue);
      } else {
        formdata.append(
          "litigation_opposing_counsel",
          postData.litigation_opposing_counsel
        );
      }

      formdata.append("main_issue", postData.main_issue);
      formdata.append(
        "litigation_type_of_case",
        postData.litigation_type_of_case
      );
      formdata.append("matter_type_value", postData.matter_type_value);
      formdata.append(
        "litigation_cllient_status",
        postData.litigation_cllient_status
      );
      console.log("POST", postData);
      formdata.append(
        "litigation_opposing_party",
        postData.litigation_opposing_party
      );
      formdata.append(
        "litigation_competent_court",
        postData.litigation_competent_court
      );
      formdata.append(
        "litigation_case_filling_no",
        postData.litigation_case_filling_no
      );
      formdata.append(
        "litigation_case_section",
        postData.litigation_case_section
      );
      formdata.append(
        "litigation_case_assigned",
        postData.litigation_case_assigned
      );
      formdata.append("litigation_judges", postData.litigation_judges);
      formdata.append("litigation_stage", postData.litigation_stage);
      formdata.append("litigation_deadline", litigation_deadline);

      formdata.append("litigation_court_fees", postData.litigation_court_fees);
      formdata.append(
        "litigation_points_of_law",
        postData.litigation_points_of_law
      );
      formdata.append(
        "litigation_subpoints_of_law",
        postData.litigation_subpoints_of_law
      );
      if (postData.litigation_id) {
        formdata.append("litigation_id", postData.litigation_id);
      }
    } else {
      this.otherMatterAppend(formdata, postData);
    }

    if (this.subs["addMatter"]) {
      this.subs["addMatter"].unsubscribe();
    }

    this.subs["addMatter"] = this.data.addMatter(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.clientList = [];
          this.blankErrorMsgs();
          formdata.append("client_number", response["client_number"]);

          setTimeout(() => {
            this.succ_msg = false;
            jQuery("#createMatterModal").modal("hide");
            this.closeModal.emit();
            this.data.selectedTypeOfCase = "";
            this.data.selectedClient = "";
            this.getlastmatterParam = "";
            //this.onChangeCase(-999,'OTHER');
            this.matterNumbFlag = false;
            this.matterStageFlag = false;
            this.clientOtherForm = false;
            this.matterFormInit();
            //this.getMatterList();

            if (postData.matter_id) {
            } else {
              this.matterFormInit();
            }
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        /* if (error.error.client_id) {
          this.err_client_id = error.error.client_id;
        } else {
          this.err_client_id = '';
        } */
        if (error.error.client) {
          this.err_client_id = error.error.client;
        } else {
          this.err_client_id = "";
        }

        if (error.error.err_client_id2_msg) {
          this.err_client_id2 = error.error.err_client_id2_msg;
        } else {
          this.err_client_id2 = "";
        }

        if (error.error.matter_number) {
          this.err_matter_number = error.error.matter_number;
          this.err_matter_id_exist = "";
        } else {
          this.err_matter_number = "";
        }
        if (error.error.matter_name) {
          this.err_matter_name = error.error.matter_name;
        } else {
          this.err_matter_name = "";
        }

        if (error.error.engagement) {
          this.err_engagement = error.error.engagement;
        } else {
          this.err_engagement = "";
        }

        if (error.error.fees_paid) {
          this.err_fees_paid = error.error.fees_paid;
        } else {
          this.err_fees_paid = "";
        }

        if (error.error.court_fees_paid) {
          this.err_court_fees_paid = error.error.court_fees_paid;
        } else {
          this.err_court_fees_paid = "";
        }

        if (error.error.fees_total) {
          this.err_fees_total = error.error.fees_total;
        } else {
          this.err_fees_total = "";
        }

        if (error.error.matter_status) {
          this.err_matter_status = error.error.matter_status;
        } else {
          this.err_matter_status = "";
        }

        if (error.error.responsible_genius) {
          this.err_responsible_genius = error.error.responsible_genius;
        } else {
          this.err_responsible_genius = "";
        }

        if (error.error.stage) {
          this.err_stage = error.error.stage;
        } else {
          this.err_stage = "";
        }

        if (error.error.stage_date) {
          this.err_stage_date = error.error.stage_date;
        } else {
          this.err_stage_date = "";
        }

        if (error.error.source_of_work) {
          this.err_source_of_work = error.error.source_of_work;
        } else {
          this.err_source_of_work = "";
        }

        if (error.error.source_of_work_content) {
          this.err_source_of_work_content = error.error.source_of_work_content;
        } else {
          this.err_source_of_work_content = "";
        }

        if (error.error.matter_type_id) {
          this.err_matter_type_id = error.error.matter_type_id;
        } else {
          this.err_matter_type_id = "";
        }

        if (error.error.folder_name) {
          this.err_folder_name = error.error.folder_name;
        } else {
          this.err_folder_name = "";
        }

        /*if (error.error.deadlines) {
          this.err_case_no = error.error.deadlines;
        } else {
          this.err_case_no = '';
        }

        if (error.error.notes) {
          this.err_notes = error.error.notes;
        } else {
          this.err_notes = '';
        }
        if (error.error.section) {
          this.err_section = error.error.section;
        } else {
          this.err_section = '';
        }*/

        /*if (error.error.err_materclient) {
          this.err_person_exits_client_no = error.error.err_materclient;
       
        } else {
          this.err_person_exits_client_no = '';
        }*/

        if (error.error.tasklist) {
          this.err_task_id = error.error.tasklist;
        } else {
          this.err_task_id = "";
        }
        if (error.error.notify_all_assignee) {
          this.err_notify_all_assignee = error.error.notify_all_assignee;
        } else {
          this.err_notify_all_assignee = "";
        }

        if (error.error.type_of_case) {
          this.err_type_of_case = error.error.type_of_case;
        } else {
          this.err_type_of_case = "";
        }

        if (error.error.matter_id_exist_msg) {
          this.err_matter_id_exist = error.error.matter_id_exist_msg;
        } else {
          this.err_matter_id_exist = "";
        }

        if (error.error.given_name) {
          this.err_person_given_name = error.error.given_name;
        } else {
          this.err_person_given_name = "";
        }

        if (error.error.family_name) {
          this.err_person_family_name = error.error.family_name;
        } else {
          this.err_person_family_name = "";
        }

        if (error.error.email) {
          this.err_person_email = error.error.email;
          this.err_email_exist = "";
        } else {
          this.err_person_email = "";
        }

        if (error.error.err_email_exist_msg) {
          this.err_email_exist = error.error.err_email_exist_msg;
          this.err_person_email = "";
        } else {
          this.err_email_exist = "";
        }

        if (error.error.err_add_email_exist_msg) {
          this.err_add_email_exist = error.error.err_add_email_exist_msg;
        } else {
          this.err_add_email_exist = "";
        }

        if (error.error.phone_number) {
          this.err_person_phone_number = error.error.phone_number;
        } else {
          this.err_person_phone_number = "";
        }

        if (error.error.country_code) {
          this.err_country_code = error.error.country_code;
        } else {
          this.err_country_code = "";
        }

        if (error.error.name) {
          this.err_org_name = error.error.name;
          this.err_org_exist = "";
        } else {
          this.err_org_name = "";
        }

        if (error.error.err_org_exist) {
          this.err_org_exist = error.error.err_org_exist_msg;
          this.err_org_name = "";
        } else {
          this.err_org_exist = "";
        }

        if (error.error.email) {
          this.err_org_email = error.error.email;
        } else {
          this.err_org_email = "";
        }

        if (error.error.phone) {
          this.err_org_phone = error.error.phone;
        } else {
          this.err_org_phone = "";
        }

        if (error.error.country_code) {
          this.err_country_code = error.error.country_code;
        } else {
          this.err_country_code = "";
        }

        if (error.error.street) {
          this.err_org_street = error.error.street;
        } else {
          this.err_org_street = "";
        }

        if (error.error.city) {
          this.err_org_city = error.error.city;
        } else {
          this.err_org_city = "";
        }

        if (error.error.country) {
          this.err_org_country = error.error.country;
        } else {
          this.err_org_country = "";
        }

        if (error.error.err_email_exist_msg) {
          this.err_email_exist_msg = error.error.err_email_exist_msg;
        } else {
          this.err_email_exist_msg = "";
        }
      }
    );
  }

  clickCancel() {
    this.clientList = [];
    this.closeModal.emit();
    this.blankErrorMsgs();
    this.data.selectedTypeOfCase = "";
    this.data.selectedClient = "";
    this.disableclass = "";
    this.selected_matter_type_dtl = "";
    this.selected_matter_dtl = "";
    this.getlastmatterParam = "";

    //this.onChangeCase(-999,'OTHER');

    this.matterNumbFlag = false;
    this.matterStageFlag = false;
    this.clientOtherForm = false;

    this.selected = "";
    this.data.typeOfCase = "";
    this.selected_subpoint_of_law = "";
    this.selected_point_of_law = "";
    if (this.hasEmail === 0) {
      for (let i = 0; this.personEmailArr.length > i; i++) {
        this.personEmailArr.removeAt(i);
      }
    }
    if (this.matterId == 0) {
      this.is_flag == "Add";
      this.case_flag = "";
      this.other_flag = true;
      this.template_flag = false;
      this.matterFormInit();
      this.onChangeMStatus("");
      this.onChangeMStatus1("");
      this.maritialStatus = false;
      this.maritialStatus1 = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chg = changes["matterId"];
    this.cval = this.chg.currentValue;
    if (this.chg.currentValue > "0" && this.chg.currentValue == this.cval) {
      this.matterDetailsById();
      this.FormHeading = "Edit Matter";
      this.is_flag = "Edit";
    } else {
      this.FormHeading = "Create Matter";
      this.matterFormInit();
      this.is_flag = "Add";
      this.cName = "Select";
    }
  }

  matterDetailsById() {
    if (this.subs["mDtlbyId"]) {
      this.subs["mDtlbyId"].unsubscribe();
    }

    this.is_flag = "Edit";

    this.subs["mDtlbyId"] = this.data
      .getMatterDetails(this.matterId)
      .subscribe((data) => {
        this.matterDetails = data;
        this.mId = this.matterDetails.matter_id;
        this.case_flag = this.matterDetails.type_flag;
        this.onChangeCase(
          this.matterDetails.type_of_case,
          this.case_flag,
          false
        );
        this.getMatterTypeList(this.matterDetails.type_of_case);
        this.onChangeMTypeId(this.matterDetails.matter_type_id);
        this.getClientList(this.matterDetails.type_of_case);
        this.createMatterForm.controls["client_id"].setValue(
          this.matterDetails.client_id
        );

        if (this.matterDetails.type_of_case) {
          this.disableclass = "disabled-radio";
        }

        if (
          this.matterDetails.type_of_case == 1 ||
          this.matterDetails.type_of_case == 3
        ) {
          this.other_flag = false;
          this.template_flag = true;
        } else {
          this.other_flag = true;
          this.template_flag = false;
        }

        if (this.matterDetails.fees_total == null) {
          this.createMatterForm.controls["fees_total"].setValue(0);
        } else {
          this.createMatterForm.controls["fees_total"].setValue(
            this.matterDetails.fees_total
          );
        }

        if (
          this.matterDetails.fees_paid == null ||
          this.matterDetails.fees_paid == "no"
        ) {
          this.createMatterForm.controls["fees_paid"].setValue("no");
        } else {
          this.createMatterForm.controls["fees_paid"].setValue("yes");
        }

        if (
          this.matterDetails.court_fees_paid == null ||
          this.matterDetails.court_fees_paid == "not_applicable"
        ) {
          this.createMatterForm.controls["court_fees_paid"].setValue(
            "not_applicable"
          );
        }

        if (this.matterDetails.court_fees_paid == "no") {
          this.createMatterForm.controls["court_fees_paid"].setValue("no");
        }

        if (this.matterDetails.court_fees_paid == "yes") {
          this.createMatterForm.controls["court_fees_paid"].setValue("yes");
        }

        if (this.matterDetails.case_no == null) {
          this.createMatterForm.controls["case_no"].setValue(0);
        } else {
          this.createMatterForm.controls["case_no"].setValue(
            this.matterDetails.case_no
          );
        }

        if (this.matterDetails.case_section == null) {
          this.createMatterForm.controls["section"].setValue(0);
        } else {
          this.createMatterForm.controls["section"].setValue(
            this.matterDetails.case_section
          );
        }

        //this.matterTypeDet(this.matterDetails.matter_type_id, 'edit');
        if (this.matterDetails.type_of_case) {
          this.getTaskAssigneeList(this.matterDetails.type_of_case);
        }
        if (this.matterDetails.type_of_case == "3") {
          if (this.matterDetails.liti_arr != null) {
            this.litigation_matter_type_value =
              this.matterDetails.liti_arr.matter_type_value;
            this.litigation_court_type =
              this.matterDetails.liti_arr.litigation_type_of_case;
            this.main_issue_id = this.matterDetails.liti_arr.main_issue;
            this.litigation_point_id = this.matterDetails.liti_arr.point_of_law;
          }
        }

        var customArray = [];
        if (this.matterDetails.custom_det) {
          for (let item1 of this.matterDetails.custom_det) {
            this.customFieldArr.push(this.initItemRowsForCustomField());
            customArray.push(item1);
          }
        }

        var contactArray = [];
        if (this.matterDetails.related_contact_det) {
          for (let item2 of this.matterDetails.related_contact_det) {
            this.relatedContactArr.push(this.initItemRowsForContact());
            contactArray.push(item2);
          }
        }

        this.getSourceOfWork();

        if (this.matterDetails.source_of_work) {
          var sarr = this.matterDetails.source_of_work.split(",");
          const temparry = [];
          sarr.map((item) => {
            temparry.push(Number(item));
          });
          this.selected = temparry;
        }

        if (this.matterDetails.matter_number != "") {
          this.matterNumbFlag = true;
        }

        if (this.matterDetails.stage == "case_closed") {
          this.matterStageFlag = true;
        }

        this.createMatterForm.patchValue({
          matter_id: this.matterDetails.matter_id,
          //client_id: this.matterDetails.client_id,
          matter_number: this.matterDetails.matter_number,
          //fees_total: this.matterDetails.fees_total,
          source_of_work_content: this.matterDetails.source_of_work_content,
          matter_name: this.matterDetails.matter_name,
          matter_type_id: this.matterDetails.matter_type_id,
          //case_no: this.matterDetails.case_no,
          folder_name: this.matterDetails.folder_name,
          notes: this.matterDetails.case_notes,
          //section: this.matterDetails.case_section,
          task_id: this.matterDetails.tasklist_id,
          checklist_id: this.matterDetails.checklist_id,
          generate_document: this.matterDetails.generate_document,
          //fees_paid: this.matterDetails.fees_paid,
          //court_fees_paid: this.matterDetails.court_fees_paid,
          stage: this.matterDetails.stage,
          type_of_case: this.matterDetails.type_of_case,
          responsible_genius: this.matterDetails.responsible_genius,
          issue_date: this.matterDetails.stage_date,
          source_of_work: this.selected,
          notify_all_assignee: this.matterDetails.notify_all_assignee,
          related_contact_arr: contactArray,
          custom_field_arr: customArray,
        });

        //this.onChangeCase(this.matterDetails.type_of_case, this.matterDetails.type_flag);
        this.onChangeMTypeId(this.matterDetails.matter_type_id);
        this.customFieldPatch(this.matterDetails);
      });
  }

  otherFieldPatch(matterDetails) {
    this.createMatterForm.patchValue({
      custom_matter_id: matterDetails.other_arr.custom_matter_id,
      custom_matter_type_name: matterDetails.other_arr.custom_matter_type_name,
      other_matter_type_name: matterDetails.other_arr.other_matter_type_name,

      matter_description: matterDetails.other_arr.matter_description,
      practice_area: Number(matterDetails.other_arr.practice_area),
      responsible_attorney: Number(
        matterDetails.other_arr.responsible_attorney
      ),
      open_date: matterDetails.other_arr.open_date,
      close_date: matterDetails.other_arr.close_date,
      pending_date: matterDetails.other_arr.pending_date,
      originating_attorney: Number(
        matterDetails.other_arr.originating_attorney
      ),
      limitations_date: matterDetails.other_arr.limitations_date,
      cllient_ref_no: matterDetails.other_arr.cllient_ref_no,
      matter_location: matterDetails.other_arr.matter_location,
      matter_kind: matterDetails.other_arr.matter_kind,
    });
  }

  customFieldPatch(matterDetails) {
    if (matterDetails.type_of_case == 1) {
      if (
        matterDetails.matter_type_id == -999 &&
        matterDetails.other_arr != null
      ) {
        this.otherFieldPatch(matterDetails);
      } else {
        if (matterDetails.ip_arr != null) {
          this.createMatterForm.patchValue({
            matter_ip_id: matterDetails.ip_arr.matter_ip_id,
            matter_kind: matterDetails.ip_arr.ip_matter_kind,
            applicant_name_english: matterDetails.ip_arr.applicant_name_english,
            applicant_name_chinese: matterDetails.ip_arr.applicant_name_chinese,
            applicant_address_english:
              matterDetails.ip_arr.applicant_address_english,
            applicant_address_portuguese:
              matterDetails.ip_arr.applicant_address_portuguese,
            ip_nationalities: Number(matterDetails.ip_arr.ip_nationalities),
            applicant_address_chinese:
              matterDetails.ip_arr.applicant_address_chinese,
          });
        }
        if (matterDetails.other_arr != null) {
          this.otherFieldPatch(matterDetails);
        }
      }
    } else if (matterDetails.type_of_case == 2) {
      if (
        matterDetails.matter_type_id == 10 &&
        matterDetails.corpChange_arr != null
      ) {
        this.createMatterForm.patchValue({
          matter_kind: matterDetails.corpChange_arr.corporate_matter_kind,
          corporate_change_id: matterDetails.corpChange_arr.corporate_change_id,
          corporate_change_value:
            matterDetails.corpChange_arr.corporate_change_value,
          corporate_company_name:
            matterDetails.corpChange_arr.corporate_company_name,
          corporate_company_address:
            matterDetails.corpChange_arr.corporate_company_address,
          corporate_company_regno:
            matterDetails.corpChange_arr.corporate_company_regno,
          corporate_taxpayer_no:
            matterDetails.corpChange_arr.corporate_taxpayer_no,
          company_cadastro_no: matterDetails.corpChange_arr.company_cadastro_no,
        });
        if (matterDetails.other_arr != null) {
          this.otherFieldPatch(matterDetails);
        }
      } else if (
        matterDetails.matter_type_id == 6 &&
        matterDetails.incorpo_arr != null
      ) {
        this.createMatterForm.patchValue({
          matter_kind: matterDetails.incorpo_arr.incorpo_matter_kind,
          corporate_incorporation_id:
            matterDetails.incorpo_arr.corporate_incorporation_id,
          incorpo_comp_name_chinese:
            matterDetails.incorpo_arr.incorpo_comp_name_chinese,
          incorpo_comp_name_portu:
            matterDetails.incorpo_arr.incorpo_comp_name_portu,
          incorpo_comp_name_english:
            matterDetails.incorpo_arr.incorpo_comp_name_english,
          incorpo_full_name: matterDetails.incorpo_arr.incorpo_full_name,
          incorpo_gender: matterDetails.incorpo_arr.incorpo_gender,
          incorpo_nationality: Number(
            matterDetails.incorpo_arr.incorpo_nationality
          ),
          incorpo_marital_status:
            matterDetails.incorpo_arr.incorpo_marital_status,
          incorpo_spouse_name: matterDetails.incorpo_arr.incorpo_spouse_name,
          incorpo_marital_regime:
            matterDetails.incorpo_arr.incorpo_marital_regime,
          incorpo_address: matterDetails.incorpo_arr.incorpo_address,
          incorpo_type: matterDetails.incorpo_arr.incorpo_id_type,
          incorpo_number: matterDetails.incorpo_arr.incorpo_id_number,
          incorpo_id_issue_date:
            matterDetails.incorpo_arr.incorpo_id_issue_date,
          incorpo_issue_country: Number(
            matterDetails.incorpo_arr.incorpo_issue_country
          ),
          incorpo_issue_authority:
            matterDetails.incorpo_arr.incorpo_issue_authority,
          incorpo_date_of_birth:
            matterDetails.incorpo_arr.incorpo_date_of_birth,
          incorpo_place_of_birth:
            matterDetails.incorpo_arr.incorpo_place_of_birth,
          incorpo_source_of_fund:
            matterDetails.incorpo_arr.incorpo_source_of_fund,
          incorpo_macau_pep: matterDetails.incorpo_arr.incorpo_macau_pep,
          incorpo_international_pep:
            matterDetails.incorpo_arr.incorpo_international_pep,
          incorpo_family_pep: matterDetails.incorpo_arr.incorpo_family_pep,
        });
        if (matterDetails.other_arr != null) {
          this.otherFieldPatch(matterDetails);
        }
      } else if (
        matterDetails.matter_type_id == 7 &&
        matterDetails.overseas_arr != null
      ) {
        this.maritialStatus = true;
        this.createMatterForm.patchValue({
          matter_kind: matterDetails.overseas_arr.overseas_matter_kind,
          overseas_id: matterDetails.overseas_arr.overseas_id,
          overseas_company_name:
            matterDetails.overseas_arr.overseas_company_name,
          overseas_company_address:
            matterDetails.overseas_arr.overseas_company_address,
          overseas_country_incorpo: Number(
            matterDetails.overseas_arr.overseas_country_incorpo
          ),
          overseas_company_regno:
            matterDetails.overseas_arr.overseas_company_regno,
          overseas_comp_director_fullname:
            matterDetails.overseas_arr.overseas_comp_director_fullname,
          overseas_director_gender:
            matterDetails.overseas_arr.overseas_director_gender,
          overseas_director_nationality: Number(
            matterDetails.overseas_arr.overseas_director_nationality
          ),
          overseas_director_marital_status:
            matterDetails.overseas_arr.overseas_director_marital_status,
          overseas_spouse_name: matterDetails.overseas_arr.overseas_spouse_name,
          overseas_marital_regime:
            matterDetails.overseas_arr.overseas_marital_regime,
          overseas_director_address:
            matterDetails.overseas_arr.overseas_director_address,
          overseas_director_id_type:
            matterDetails.overseas_arr.overseas_director_id_type,
          overseas_director_id_no:
            matterDetails.overseas_arr.overseas_director_id_no,
          overseas_doc_id_date: matterDetails.overseas_arr.overseas_doc_id_date,
          overseas_authority_name:
            matterDetails.overseas_arr.overseas_authority_name,
          overseas_representative_name:
            matterDetails.overseas_arr.overseas_representative_name,
          overseas_representative_gender:
            matterDetails.overseas_arr.overseas_representative_gender,
          overseas_representative_nationality: Number(
            matterDetails.overseas_arr.overseas_representative_nationality
          ),
          overseas_representative_marital_status:
            matterDetails.overseas_arr.overseas_representative_marital_status,
          overseas_representative_spouse:
            matterDetails.overseas_arr.overseas_representative_spouse,
          overseas_representative_address:
            matterDetails.overseas_arr.overseas_representative_address,
          overseas_representative_id_type:
            matterDetails.overseas_arr.overseas_representative_id_type,
          overseas_representative_id_no:
            matterDetails.overseas_arr.overseas_representative_id_no,
          overseas_representative_id_issue_date:
            matterDetails.overseas_arr.overseas_representative_id_issue_date,
          overseas_representative_auth_name:
            matterDetails.overseas_arr.overseas_representative_auth_name,
          overseas_representative_passport_issue_country: Number(
            matterDetails.overseas_arr
              .overseas_representative_passport_issue_country
          ),
          overseas_macau_comp_name:
            matterDetails.overseas_arr.overseas_macau_comp_name,
          overseas_macau_comp_address:
            matterDetails.overseas_arr.overseas_macau_comp_address,
          overseas_macau_comp_tax_regno:
            matterDetails.overseas_arr.overseas_macau_comp_tax_regno,
          overseas_type_of_service:
            matterDetails.overseas_arr.overseas_type_of_service,
          overseas_start_date: matterDetails.overseas_arr.overseas_start_date,
          overseas_end_date: matterDetails.overseas_arr.overseas_end_date,
          overseas_price: matterDetails.overseas_arr.overseas_price,
        });
        if (matterDetails.other_arr != null) {
          this.otherFieldPatch(matterDetails);
        }
        this.onChangeMStatus(
          matterDetails.overseas_arr.overseas_director_marital_status
        );
        this.onChangeMStatus1(
          matterDetails.overseas_arr.overseas_representative_marital_status
        );
      } else {
        if (matterDetails.other_arr != null) {
          this.otherFieldPatch(matterDetails);
        }
      }
    } else if (
      matterDetails.type_of_case == 3 &&
      matterDetails.liti_arr != null
    ) {
      if (matterDetails.liti_arr.subpoint_of_law != null) {
        var sarr = matterDetails.liti_arr.subpoint_of_law.split(",");
        const temparry = [];
        sarr.map((item) => {
          temparry.push(Number(item));
        });
        this.selected_subpoint_of_law = temparry;
      }

      if (matterDetails.liti_arr.point_of_law != null) {
        var parr = matterDetails.liti_arr.point_of_law.split(",");
        const tarry = [];
        parr.map((it) => {
          tarry.push(Number(it));
        });
        this.selected_point_of_law = tarry;
      }

      this.createMatterForm.patchValue({
        litigation_id: matterDetails.liti_arr.litigation_id,
        main_issue: Number(matterDetails.liti_arr.main_issue),
        litigation_type_of_case: matterDetails.liti_arr.litigation_type_of_case,
        matter_type_value: Number(matterDetails.liti_arr.matter_type_value),
        litigation_cllient_status: Number(
          matterDetails.liti_arr.litigation_cllient_status
        ),
        litigation_opposing_party: Number(
          matterDetails.liti_arr.litigation_opposing_party
        ),
        litigation_competent_court: Number(
          matterDetails.liti_arr.litigation_competent_court
        ),
        litigation_case_filling_no: Number(
          matterDetails.liti_arr.litigation_case_filling_no
        ),
        litigation_case_section: matterDetails.liti_arr.litigation_case_section,
        litigation_case_assigned:
          matterDetails.liti_arr.litigation_case_assigned,
        litigation_judges: Number(matterDetails.liti_arr.litigation_judges),
        litigation_stage: matterDetails.liti_arr.litigation_stage,
        litigation_deadline: matterDetails.liti_arr.litigation_deadline,
        litigation_opposing_counsel: Number(
          matterDetails.liti_arr.litigation_opposing_counsel
        ),
        litigation_court_fees: matterDetails.liti_arr.litigation_court_fees,
        litigation_points_of_law: this.selected_point_of_law,
        litigation_subpoints_of_law: this.selected_subpoint_of_law,
      });
    } else {
      if (matterDetails.other_arr != null) {
        this.otherFieldPatch(matterDetails);
      }
    }
  }

  refreshTaskList() {
    this.getTaskList();
  }

  blankErrorMsgs() {
    this.err_client_id = "";
    this.err_client_id2 = "";
    this.err_engagement = "";
    this.err_fees_paid = "";
    this.err_court_fees_paid = "";
    this.err_fees_total = "";
    this.err_matter_status = "";
    this.err_responsible_genius = "";
    this.err_stage = "";
    this.err_source_of_work = "";
    this.err_source_of_work_content = "";
    this.err_matter_type_id = "";
    this.err_case_no = "";
    this.err_folder_name = "";
    this.err_notes = "";
    this.err_section = "";
    this.err_task_id = "";
    this.err_notify_all_assignee = "";
    this.err_type_of_case = "";
    this.err_matter_name = "";
    this.err_matter_number = "";
    this.err_stage_date = "";
    this.err_matter_id_exist = "";
    this.err_email_exist = "";
    this.err_add_email_exist = "";

    this.err_org_name = "";
    this.err_org_email = "";
    this.err_org_phone = "";
    this.err_org_street = "";
    this.err_org_city = "";
    this.err_org_state = "";
    this.err_org_zip = "";
    this.err_org_country = "";
    this.err_org_type = "";
    this.err_org_reg_type = "";
    this.err_org_reg_certificate = "";
    this.err_org_reg_no = "";
    this.err_org_reg_type1 = "";
    this.err_org_reg_tag = "";
    this.err_country_code = "";
    this.err_org_exist = "";
    this.err_email_exist_msg = "";
  }

  clientBoxClose() {
    this.clientOtherForm = false;
  }

  onChangeStage(stageVal) {
    var DateObj = new Date();
    this.issueDate = stageVal == "case_closed" ? new DatePipe("en-US").transform(DateObj, "yyyy-MM-dd") : "";

    this.createMatterForm.patchValue({  
      issue_date: new DatePipe("en-US").transform(DateObj, "yyyy-MM-dd"),
      close_date: (stageVal == 'case_closed') ? new DatePipe("en-US").transform(DateObj, "yyyy-MM-dd") : "",
    });


    if (this.is_flag == "Add") {
      this.createMatterForm.patchValue({
        open_date: new DatePipe("en-US").transform(DateObj, "yyyy-MM-dd"),
      });
      
    }else{
      if(stageVal == 'case_retained'  && this.matterDetails.other_arr.open_date == '' ){
        this.createMatterForm.patchValue({
          open_date: new DatePipe("en-US").transform(DateObj, "yyyy-MM-dd")
        });
      }
     
    }
  }

  public onChangeStageDate(event): void {
    this.issueDate = event.value
      ? new DatePipe("en-US").transform(event.value, "yyyy-MM-dd")
      : "";
  }
}
