import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
declare var jQuery: any;

@Component({
  selector: 'app-add-edit-process-st',
  templateUrl: './add-edit-process-st.component.html',
  styleUrls: ['./add-edit-process-st.component.scss']
})
export class AddEditProcessStComponent implements OnInit {
  err_process_name: string = '';
  err_process_body: string = '';
  succ_msg: any;
  err_matter_type: string = '';
  processDetails: Object;
  FormHeading: string;

  constructor(private fb: FormBuilder, private data: DataService, private router: Router, private route: ActivatedRoute) { }

  @Input() processId: number;
  matterTypeList:any = [];
  addEditProcessListForm: FormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'adobe-caslon-pro',
    defaultFontSize: '',
    fonts: [
      { class: 'raleway', name: 'Raleway' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'caslon', name: 'adobe-caslon-pro' },
      { class: 'arial', name: 'arial-unicode-ms' },
    ],
    customClasses: [
      {
        name: 'Small Text',
        class: 'smallText',
        tag: 'p',
      },
      {
        name: 'Normal Text',
        class: 'normalText',
        tag: 'p',
      },
      {
        name: 'Large Text',
        class: 'largeText',
        tag: 'p',
      },
      {
        name: 'Huge Text',
        class: 'hugeText',
        tag: 'h2',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      ['strikeThrough', 'superscript', 'subscript'],
      ['fontSize', 'heading',],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine'],
      ['unlink', 'Insert Image', 'video']
    ]
  };

  ngOnInit() {
  
    this.getMatterTypeList();
    this.processListFormInit();
  }

  public ngOnChanges( changes: SimpleChanges ) : void {   
    let chg = changes['processId'];
    let cval = chg.currentValue;
    if (chg.currentValue > '0' && chg.currentValue == cval) {
      this.getProcessDetails();
      this.FormHeading = "Edit";
    }else {      
      this.FormHeading = "Add";
        this.processListFormInit();
        
	  }
	
  }

  clickCancel(){
    this.blankErrorMsgs();
  }

  getMatterTypeList() {
    let caseType = 0;
    this.data.getAllMatterTypes(caseType).subscribe(response => {
      if (response) {
        this.matterTypeList = response;
      }
    });
  }

  processListFormInit() {
		this.addEditProcessListForm = this.fb.group({
			process_id: new FormControl(''),
      process_name: new FormControl(''),
      process_body: new FormControl(''),
      matter_type_id: new FormControl('')
		})
  }
  addEditProcesslist(postData){
    let formdata = new FormData();
    if(postData.process_id != '' && postData.process_id != null){
      formdata.append('process_id', postData.process_id);
    }
    
    formdata.append('process_name', postData.process_name);
    formdata.append('process_body', postData.process_body);
    formdata.append('matter_type', postData.matter_type_id);
   
    
    this.data.addEditProcessSt(formdata).subscribe(response => {
      if (response) {
        this.succ_msg = response['msg'];
        this.blankErrorMsgs();
        setTimeout(() => {
          this.succ_msg = false;
          jQuery("#proceesAddEditModal").modal("hide");
        }, this.data.timeOut);

      } else {
        this.succ_msg = '';
      }

    },
    error => {

      if (error.error.process_name) {
        this.err_process_name = error.error.process_name;
      } else {
        this.err_process_name = '';
      }

      if (error.error.process_body) {
        this.err_process_body = error.error.process_body;
      } else {
        this.err_process_body = '';
      }

      if (error.error.matter_type) {
        this.err_matter_type = error.error.matter_type;
      } else {
        this.err_matter_type = '';
      }

    });

  }

  blankErrorMsgs(){
    this.err_process_name = this.err_process_body = this.err_matter_type = '' ;
  }

  getProcessDetails(){
    this.data.getProcessstDetails(this.processId).subscribe(
			data => {
				this.addEditProcessListForm.patchValue({
					process_id:data['process_id'],
          process_name: data['process_name'],
          process_body: data['process_body'],
          matter_type_id: data['matter_type_id'],
				});
				
			}
    );
  }


}
