import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reports-operations',
  templateUrl: './reports-operations.component.html',
  styleUrls: ['./reports-operations.component.scss']
})
export class ReportsOperationsComponent implements OnInit {
  assignedTask: any = [];
  TaskCompleted: any = [];
  total_matters: any = [];
  avgTaskCompleted: any = [];
  selectedYear : any = '2019'
  geniusList: any = [];
  taskParam: any = {};
  year_arr : any = ['2019','2020','2021'];
  case_type: any = [];

  constructor(private data: DataService) { }

  ngOnInit() {
    this.listGenius();
    this.getCaseTypeList();
    this.getAssignedTask();
    this.getMatterAssignedCount();
    this.getAssignedTaskCompleted();
   // this.getTotalMattersByYear();
    this.avgDurationTaskCompleted();
  }

  getCaseTypeList(){
    this.data.getTypeOfCase().subscribe(
      data => {
        this.case_type = data;
      }
    );
  }

  listGenius() {
    this.data.getTaskAssignee(3).subscribe(
      data => {
        this.geniusList = data;
      }
    );
  }


  yearChange(year){
    this.taskParam.year = year;
  
   if(this.taskParam.case_id){
    this.taskParam.case_id = this.taskParam.case_id;
   }

   if(this.taskParam.assignee_id){
    this.taskParam.assignee_id = this.taskParam.assignee_id;
   }
   
    this.getAssignedTask();
    this.getAssignedTaskCompleted();
    this.getMatterAssignedCount();
  }

  caseChange(case_id){
    if(this.taskParam.year){
      this.taskParam.year = this.taskParam.year;
    }else{
      this.taskParam.year = this.selectedYear;
    }

    if(this.taskParam.assignee_id){
      this.taskParam.assignee_id = this.taskParam.assignee_id;
    }
    this.taskParam.case_id = case_id;

    this.getAssignedTask();
    this.getAssignedTaskCompleted();
    this.getMatterAssignedCount();
  }

  userchange(user){
    if(this.taskParam.year){
      this.taskParam.year = this.taskParam.year;
    }else{
      this.taskParam.year = this.selectedYear;
    }

    if(this.taskParam.case_id){
      this.taskParam.case_id = this.taskParam.case_id;
    }

    this.taskParam.assignee_id = user;
    
    this.getAssignedTask();
    this.getAssignedTaskCompleted();
    this.getMatterAssignedCount();
  }

  getAssignedTask() {
    this.data.getAssignedTask(this.taskParam).subscribe(
      data => {
      this.assignedTask = data;  
      }
    );
  }

  getMatterAssignedCount() {
    this.data.getMatterAssignedCount(this.taskParam).subscribe(
      data => {
        this.total_matters = data;  
      }
    );
  }

  getAssignedTaskCompleted() {
    this.data.getAssignedTaskCompleted(this.taskParam).subscribe(
      data => {
      this.TaskCompleted = data;  
      }
    );
  }


  avgDurationTaskCompleted() {
    this.data.avgDurationTaskCompleted(this.taskParam).subscribe(
      data => {
      this.avgTaskCompleted = data; 
    
      }
    );
  }


}
