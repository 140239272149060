import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";
declare var jQuery: any;
@Component({
  selector: "app-category-topic",
  templateUrl: "./category-topic.component.html",
  styleUrls: ["./category-topic.component.scss"],
})
export class CategoryTopicComponent implements OnInit {
  tags: any = [];
  articleCategories: any = [];
  articleParam: any = {};
  selectedFiles: any;
  tagParam: any = {};
  pn: number = 0;
  tagitemsPerPage: number = 20;
  totalTag: any = [];
  subCatParam: any = {};
  articleSubCategories: any = [];
  constructor(private data: DataService, public router: Router) {}

  ngOnInit() {
    jQuery(function () {
      jQuery(document).on("click", ".category-list li a", function (e) {
        jQuery(this).next().toggle();
      });
    });
    this.getArticleTopics();
    this.getArticleCategories();
    this.getTagCount();
  }

  getArticleTopics() {
    this.data.getArticlePointsOfLaw(this.tagParam).subscribe((data) => {
      this.tags = data;
    });
  }

  getArticleCategories() {
    this.data.getArticleCategories(this.articleParam).subscribe((data) => {
      this.articleCategories = data;
    });
  }

  search(value, serchKey) {
    localStorage.setItem("Topic", value);
    // this.articleParam.searchString = value;
    // this.articleParam.selectedSearchKey = serchKey;

    var currentUrl = this.router.url;
    if (currentUrl == "/knowledge-base") {
      // location.reload();
    } else {
      this.router.navigateByUrl("/knowledge-base");
    }
  }

  searchCat(category_id) {
    localStorage.setItem("Category", category_id);
    var currentUrl = this.router.url;
    if (currentUrl == "/knowledge-base") {
      //location.reload();
    } else {
      this.router.navigateByUrl("/knowledge-base");
    }
  }

  paginationTag(p) {
    this.pn = p;
    let start = (p - 1) * this.tagitemsPerPage;
    let end = start + this.tagitemsPerPage;
    this.tagParam.p = p - 1;
    this.tagParam.start = start;
    this.tagParam.end = end;
    this.getArticleTopics();
  }

  getTagCount() {
    this.data.getTotalTags().subscribe((data) => {
      this.totalTag = data;
    });
  }

  getArticleSubCategories(parentId) {
    this.subCatParam.parent_id = parentId;
    this.data.getArticleCategories(this.subCatParam).subscribe((data) => {
      this.articleSubCategories = data;
    });
  }
}
