import { Component, OnInit, Input, Output } from "@angular/core";
declare var jQuery: any;
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../../../../services/data.service";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith, filter } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-company-dissolution",
  templateUrl: "./company-dissolution.component.html",
  styleUrls: ["./company-dissolution.component.scss"],
})
export class CompanyDissolutionComponent implements OnInit {
  overseasForm1: FormGroup;
  overseasForm2: FormGroup;
  overseasForm3: FormGroup;
  overseasForm4: FormGroup;
  overseasForm5: FormGroup;
  overseasForm6: FormGroup;
  overseasForm7: FormGroup;
  overseasForm8: FormGroup;
  overseasForm9: FormGroup;

  processData: any;
  processDisable: any = 0;
  checkBoxArr: any = [];
  Param: any = {};
  mId: any;
  @Input() matter_type_id: any;
  processList: any = [];
  buttonParam: any = {};

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.mId = params["mId"];
    });
    this.procedureCheckButtonDisable("DISSOLUTION_PROCESS1");
    var self = this;
    jQuery(function () {
      jQuery(".moreLinks").click(function () {
        jQuery(this).next().toggle();
      });
      /* jQuery('.steps-items li a').click(function (e) {
				var tab_html = jQuery(this).html();
				var process1 = tab_html.includes("Procedure 1");
				var process3 = tab_html.includes("Procedure 3");
				var process4 = tab_html.includes("Procedure 4");
				var process5 = tab_html.includes("Procedure 5");
				var process7 = tab_html.includes("Procedure 7");
				var process9 = tab_html.includes("Procedure 9");
				if(process1 == true){
					self.procedureCheckButtonDisable('DISSOLUTION_PROCESS1');
				}
				if(process3 == true){
					self.procedureCheckButtonDisable('DISSOLUTION_PROCESS3');
				}
				if(process4 == true){
					self.procedureCheckButtonDisable('DISSOLUTION_PROCESS4');
				}
				if(process5 == true){
					self.procedureCheckButtonDisable('DISSOLUTION_PROCESS5');
				}
				if(process7 == true){
					self.procedureCheckButtonDisable('DISSOLUTION_PROCESS7');
				}
				if(process9 == true){
					self.procedureCheckButtonDisable('DISSOLUTION_PROCESS9');
				}
				jQuery('.steps-items').hide()
			}) */
    });

    this.procedureFormInit();
    /* let url = window.location.href;		
		this.mId = url.substr(url.lastIndexOf('/') + 1); */
    this.processStListBymatterType();
  }

  procedureCheckButtonDisable(val) {
    this.processDisable = 0;
    this.buttonParam.matter_id = this.mId;
    this.buttonParam.fromProcess = val;
    this.data.checkDisabledButton(this.buttonParam).subscribe((data) => {
      this.processData = data;
      this.processDisable = this.processData.disabled_flag;
    });
    var formName: any = "";
    this.buttonParam.processCode = val;
    this.data.checkBoxListByProcessCode(this.buttonParam).subscribe((data) => {
      this.checkBoxArr = data;
      var arr = this.checkBoxArr;
      if (val == "DISSOLUTION_PROCESS1") {
        formName = this.overseasForm1;
      } else if (val == "DISSOLUTION_PROCESS2") {
        formName = this.overseasForm2;
      } else if (val == "DISSOLUTION_PROCESS3") {
        formName = this.overseasForm3;
      } else if (val == "DISSOLUTION_PROCESS4") {
        formName = this.overseasForm4;
      } else if (val == "DISSOLUTION_PROCESS5") {
        formName = this.overseasForm5;
      } else if (val == "DISSOLUTION_PROCESS6") {
        formName = this.overseasForm6;
      } else if (val == "DISSOLUTION_PROCESS7") {
        formName = this.overseasForm7;
      } else if (val == "DISSOLUTION_PROCESS8") {
        formName = this.overseasForm8;
      } else if (val == "DISSOLUTION_PROCESS9") {
        formName = this.overseasForm9;
      }
      for (let key in arr) {
        formName.controls[key].setValue(arr[key]);
      }
    });
  }

  procedureFormInit() {
    this.overseasForm1 = this.fb.group({
      overseas_lead_info: new FormControl(""),
      overseas_add_mailing_list: new FormControl(""),
      overseas_mark_lost: new FormControl(""),
      overseas_send_email: new FormControl(""),
      overseas_mark_won: new FormControl(""),
      overseas_prepare_invoice: new FormControl(""),
      overseas_email_client: new FormControl(""),
    });

    this.overseasForm2 = this.fb.group({
      overseas_client_payment: new FormControl(""),
      overseas_save_files: new FormControl(""),
    });

    this.overseasForm3 = this.fb.group({
      overseas_create_client: new FormControl(""),
      overseas_save_files: new FormControl(""),
    });

    this.overseasForm4 = this.fb.group({
      overseas_check_form: new FormControl(""),
      overseas_review_document: new FormControl(""),
      overseas_translate: new FormControl(""),
    });

    this.overseasForm5 = this.fb.group({
      overseas_recycled_paper: new FormControl(""),
      overseas_notary_review: new FormControl(""),
    });

    this.overseasForm6 = this.fb.group({
      overseas_client_review: new FormControl(""),
    });

    this.overseasForm7 = this.fb.group({
      overseas_print_paper: new FormControl(""),
      overseas_give_everything: new FormControl(""),
      overseas_day_before: new FormControl(""),
      overseas_confirm_pages: new FormControl(""),
      overseas_scan_documentation: new FormControl(""),
      overseas_deliver_dsf: new FormControl(""),
      overseas_file_bussiness: new FormControl(""),
      overseas_email_clientcopy: new FormControl(""),
    });

    this.overseasForm9 = this.fb.group({
      overseas_contact_tribuna: new FormControl(""),
      overseas_ads_need: new FormControl(""),
      overseas_prepare_registration: new FormControl(""),
      overseas_commercial_registry: new FormControl(""),
      overseas_respective_newspaper: new FormControl(""),
    });

    this.overseasForm8 = this.fb.group({
      overseas_registration_granted: new FormControl(""),
    });
  }

  overseasForm1Submit(postData) {
    let formdata = new FormData();
    formdata.append(
      "overseas_add_mailing_list",
      postData.overseas_add_mailing_list
    );
    formdata.append("overseas_email_client", postData.overseas_email_client);
    formdata.append("overseas_lead_info", postData.overseas_lead_info);
    formdata.append("overseas_mark_lost", postData.overseas_mark_lost);
    formdata.append("overseas_mark_won", postData.overseas_mark_won);
    formdata.append(
      "overseas_prepare_invoice",
      postData.overseas_prepare_invoice
    );
    formdata.append("overseas_send_email", postData.overseas_send_email);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS1");
    formdata.append("processName", "Procedure 1 Quote & Follow Up");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS1");
      this.processStListBymatterType();
    });
  }

  overseasForm2Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_client_payment", postData.overseas_create_client);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS2");
    formdata.append("processName", "Procedure 2 Client Payment Monitor");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS2");
      this.processStListBymatterType();
    });
  }

  overseasForm3Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_create_client", postData.overseas_create_client);
    formdata.append("overseas_save_files", postData.overseas_save_files);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS3");
    formdata.append("processName", "Procedure 3 Folder & Matter Creation");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS3");
      this.processStListBymatterType();
    });
  }

  overseasForm4Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_check_form", postData.overseas_check_form);
    formdata.append(
      "overseas_review_document",
      postData.overseas_review_document
    );
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS4");
    formdata.append("processName", "Procedure 4 Documents Prepared");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS4");
      this.processStListBymatterType();
    });
  }

  overseasForm5Submit(postData) {
    let formdata = new FormData();
    formdata.append(
      "overseas_recycled_paper",
      postData.overseas_recycled_paper
    );
    formdata.append("overseas_notary_review", postData.overseas_notary_review);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS5");
    formdata.append("processName", "Procedure 5 Notary Review");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS5");
      this.processStListBymatterType();
    });
  }
  overseasForm6Submit(postData) {
    let formdata = new FormData();

    formdata.append("overseas_client_review", postData.overseas_client_review);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS6");
    formdata.append("processName", "Procedure 6 Client Review");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS6");
      this.processStListBymatterType();
    });
  }

  overseasForm7Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_print_paper", postData.overseas_print_paper);
    formdata.append(
      "overseas_give_everything",
      postData.overseas_give_everything
    );
    formdata.append("overseas_day_before", postData.overseas_day_before);
    formdata.append("overseas_confirm_pages", postData.overseas_confirm_pages);
    formdata.append(
      "overseas_scan_documentation",
      postData.overseas_scan_documentation
    );
    formdata.append("overseas_deliver_dsf", postData.overseas_deliver_dsf);
    formdata.append("overseas_file_bussiness", postData.overseas_print_paper);
    formdata.append(
      "overseas_email_clientcopy",
      postData.overseas_email_clientcopy
    );
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS7");
    formdata.append("processName", "Procedure 7 Execute & File");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS7");
      this.processStListBymatterType();
    });
  }

  overseasForm8Submit(postData) {
    let formdata = new FormData();

    formdata.append(
      "overseas_registration_granted",
      postData.overseas_registration_granted
    );
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS8");
    formdata.append("processName", "Procedure 8 Registration Granted");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS8");
      this.processStListBymatterType();
    });
  }

  overseasForm9Submit(postData) {
    let formdata = new FormData();
    formdata.append(
      "overseas_contact_tribuna",
      postData.overseas_contact_tribuna
    );
    formdata.append("overseas_ads_need", postData.overseas_ads_need);
    formdata.append(
      "overseas_prepare_registration",
      postData.overseas_prepare_registration
    );
    formdata.append(
      "overseas_commercial_registry",
      postData.overseas_commercial_registry
    );
    formdata.append(
      "overseas_respective_newspaper",
      postData.overseas_respective_newspaper
    );
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "DISSOLUTION_PROCESS9");
    formdata.append("processName", "Procedure 9 Publish Ads");
    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      this.procedureCheckButtonDisable("DISSOLUTION_PROCESS9");
      this.processStListBymatterType();
    });
  }

  processStListBymatterType() {
    this.Param.matter_type_id = this.matter_type_id;
    this.Param.matter_id = this.mId;
    this.data.getAllProcesslistByMatterType(this.Param).subscribe((data) => {
      this.processList = data;
    });
  }

  procedureCheck(e) {
    var self = this;
    var tab_html = e.target.text;
    var process1 = tab_html.includes("Procedure 1");
    var process2 = tab_html.includes("Procedure 2");
    var process3 = tab_html.includes("Procedure 3");
    var process4 = tab_html.includes("Procedure 4");
    var process5 = tab_html.includes("Procedure 5");
    var process6 = tab_html.includes("Procedure 6");
    var process7 = tab_html.includes("Procedure 7");
    var process8 = tab_html.includes("Procedure 8");
    var process9 = tab_html.includes("Procedure 9");
    if (process1 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS1");
    }
    if (process2 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS2");
    }
    if (process3 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS3");
    }
    if (process4 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS4");
    }
    if (process5 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS5");
    }
    if (process6 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS6");
    }
    if (process7 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS7");
    }
    if (process8 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS8");
    }
    if (process9 == true) {
      self.procedureCheckButtonDisable("DISSOLUTION_PROCESS9");
    }
    jQuery(".steps-items").hide();
  }
}
