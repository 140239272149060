import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-litigation-matter",
  templateUrl: "./litigation-matter.component.html",
  styleUrls: ["./litigation-matter.component.scss"],
})
export class LitigationMatterComponent implements OnInit {
  pieChartPlugins = [];
  pieChartLegend: boolean;

  public pieChartOptions: any = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: "left",
    },
    plugins: {
      labels: {
        render: "percentage",
        overlap: true,
        precision: 1,
      },
    },
  };

  matterOppossingPartyList: any;
  matterCompetentList: any;
  matterClientStatusList: any;
  matterMainIssueList: any;
  pointList: any;
  subPointList: any;

  @Input() group: FormGroup;
  @Input() caseType: number;
  @Input() matter_type_id: number;
  @Input() litigation_matter_type_value: number;
  @Input() litigation_court_type: String;
  @Input() main_issue_id: any;
  @Input() litigation_point_id: any;
  @Input() selected_subpoint_of_law: any;
  contactParam: any = {};
  personList: any;
  litigationStage: any = "";
  oppoList: any;

  selected_point_of_law: any;
  showDiv: boolean = false;
  dataTab: boolean = false;
  chartTab: boolean = false;
  judgeData: any = [];
  subPointArr: any = [];

  subs: any = [];

  public barChartLabels: string[] = [
    "Criminal",
    "Civil",
    "Administrative Litigation",
    "Labour",
    "Administrative",
  ];
  public barChartType: string = "bar";
  public barChartLegend = false;

  //public options: any = {
  //legend: { position: 'left' }
  //}

  /* public barChartData: any[] = [
    { data: [10, 20, 0, 30, 56] },
  ]; */
  public barChartData: any = [];

  // Pie1
  public pieChartLabels: string[] = [
    "Criminal",
    "Civil",
    "Administrative Litigation",
    "Processo Labour",
    "Administrative",
  ];
  public pieChartData: any = [];
  //public pieChartData:number[] = [40, 20];
  public pieChartType: string = "pie";

  // Pie2
  public pieChartLabels2: string[] = ["Granted", "Denied"];
  public pieChartData2: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.litigation_court_type == "Court Cases") {
      this.litigation_court_type = "Court";
    } else if (this.litigation_court_type == "Pre Court") {
      this.litigation_court_type = "PreCourt";
    } else if (
      this.litigation_court_type &&
      this.litigation_court_type != "undefined"
    ) {
      this.litigation_court_type = this.litigation_court_type;
    } else {
      this.litigation_court_type = "none";
    }
    this.onChangeLitigation(this.caseType);
    //this.pieChartPlugins = [pluginLabels];
    this.pieChartLabels = [];
    this.pieChartLegend = true;
    this.getLitigationMatterCompetentCourt(this.litigation_court_type);
    //this.getLitigationMatterOpposingParty(this.caseType);

    this.getLitigationMatterOpposingParty();
    //this.getPersonList();
    //this.getLitigationStage();
    //this.getContactsByContactType();
    this.getLitigationMainIssueList(
      this.matter_type_id,
      this.litigation_matter_type_value,
      this.litigation_court_type
    );
    this.getPointsOfLaw(this.main_issue_id, this.litigation_matter_type_value);
    this.getSubPointsOfLaw(this.litigation_point_id);
    this.getOppoCousil();
    this.dataTab = true;
    this.chartTab = false;
  }

  public chartClicked(e: any): void {}

  public chartHovered(e: any): void {}

  ngOnChanges() {
    if (this.litigation_court_type == "Court Cases") {
      this.litigation_court_type = "Court";
    } else if (this.litigation_court_type == "Pre Court") {
      this.litigation_court_type = "PreCourt";
    } else if (
      this.litigation_court_type &&
      this.litigation_court_type != "undefined"
    ) {
      this.litigation_court_type = this.litigation_court_type;
    } else {
      this.litigation_court_type = "none";
    }
    //if(this.matter_type_id > 0 && this.litigation_matter_type_value > 0){
    this.getLitigationMainIssueList(
      this.matter_type_id,
      this.litigation_matter_type_value,
      this.litigation_court_type
    );
    this.getLitigationClientStatus(
      this.matter_type_id,
      this.litigation_matter_type_value
    );
    this.getPointsOfLaw(this.main_issue_id, this.litigation_matter_type_value);
    this.getLitigationMatterCompetentCourt(this.litigation_court_type);
    //this.getContactsByContactType();
    //this.onChangeLitigation(type_value);
    //}
  }

  getLitigationMainIssueList(
    matter_type_id,
    litigation_matter_type_value,
    litigation_court_type
  ) {
    if (this.subs["litList"]) {
      this.subs["litList"].unsubscribe();
    }
    this.subPointArr = [];
    this.subs["litList"] = this.data
      .getLitigationMainIssueList(
        matter_type_id,
        litigation_matter_type_value,
        litigation_court_type
      )
      .subscribe((response) => {
        if (response) {
          this.matterMainIssueList = response;
        }
      });
  }
  addMainIssue = (term) => ({ type_value_id: term, type_value_name: term });

  getLitigationClientStatus(matter_type_id, litigation_matter_type_value) {
    if (this.subs["litStatus"]) {
      this.subs["litStatus"].unsubscribe();
    }
    this.subs["litStatus"] = this.data
      .getLitigationClientStatus(matter_type_id, litigation_matter_type_value)
      .subscribe((response) => {
        if (response) {
          this.matterClientStatusList = response;
        }
      });
  }

  addClientStatus = (term) => ({ type_value_id: term, type_value_name: term });

  getLitigationMatterCompetentCourt(caseTypeId) {
    if (this.subs["litMatCourt"]) {
      this.subs["litMatCourt"].unsubscribe();
    }
    this.subs["litMatCourt"] = this.data
      .getLitigationMatterCompetentCourt(caseTypeId)
      .subscribe((res: any) => {
        this.matterCompetentList = res;
      });
  }

  getLitigationMatterOpposingParty() {
    if (this.subs["litMatParty"]) {
      this.subs["litMatParty"].unsubscribe();
    }
    this.subs["litMatParty"] = this.data
      .getContactsByContactType("op")
      .subscribe((response) => {
        if (response) {
          this.matterOppossingPartyList = response;

          for (const e of this.matterOppossingPartyList) {
            e.name =
              e.given_name != null
                ? e.given_name +
                  " " +
                  (e.middle_name != null ? e.middle_name : "") +
                  " " +
                  (e.family_name != null ? e.family_name : "")
                : e.org_name;
          }

          this.matterOppossingPartyList = this.matterOppossingPartyList.sort(
            (a: { name: string }, b: { name: string }) =>
              a.name.normalize().localeCompare(b.name.normalize())
          );
        }
      });
  }
  //addOpposingParty = (term) => ({ type_value_id: term, type_value_name: term });
  addOpposingParty(tag: string) {
    return tag;
  }

  getOppoCousil() {
    if (this.subs["oppoCou"]) {
      this.subs["oppoCou"].unsubscribe();
    }
    this.subs["oppoCou"] = this.data
      .getContactsByContactType("loc")
      .subscribe((data) => {
        this.oppoList = data;

        for (const e of this.oppoList) {
          e.name =
            e.given_name != ""
              ? e.given_name +
                " " +
                (e.middle_name != null ? e.middle_name : "") +
                " " +
                (e.family_name != null ? e.family_name : "")
              : e.org_name;
        }

        this.oppoList = this.oppoList.sort((a, b) =>
          a.name.normalize().localeCompare(b.name.normalize())
        );
      });
  }

  //addoppoCouncel = (term) => ({ person_id: term, name: term });
  addoppoCouncel(tag: string) {
    return tag;
  }

  addCompetentCourt(tag: string) {
    return tag;
  }

  onChangeLitigation(values) {
    if (this.subs["judge"]) {
      this.subs["judge"].unsubscribe();
    }
    this.subs["judge"] = this.data
      .getAllJudgesByCompetentCourt(values)
      .subscribe((data) => {
        this.personList = data;
        for (let index = 0; index < this.personList.length; index++) {
          this.personList[index].given_name =
            this.personList[index].given_name != null
              ? this.personList[index].given_name
              : "";
          this.personList[index].middle_name =
            this.personList[index].middle_name != null
              ? this.personList[index].middle_name
              : "";
          this.personList[index].family_name =
            this.personList[index].family_name != null
              ? this.personList[index].family_name
              : "";
          this.personList[index].name =
            this.personList[index].given_name +
            " " +
            this.personList[index].middle_name +
            " " +
            this.personList[index].family_name;
        }

        this.personList = this.personList.sort((a, b) =>
          a.name.normalize().localeCompare(b.name.normalize())
        );
      });
  }

  //addJudge = (term) => ({ contact_id: term, name: term });
  addJudge(tag: string) {
    return tag;
  }

  getPointsOfLaw(main_issue_id, litigation_matter_type_value) {
    if (this.subs["pointLaw"]) {
      this.subs["pointLaw"].unsubscribe();
    }
    this.subs["pointLaw"] = this.data
      .getPointsOfLaw(main_issue_id, "IP", litigation_matter_type_value)
      .subscribe((data) => {
        this.pointList = data;
      });
  }

  addPointsOfLaw = (term) => ({ point_id: term, point_of_law_name: term });

  getSubPointsOfLaw(pointId) {
    if (this.subs["subPointLaw"]) {
      this.subs["subPointLaw"].unsubscribe();
    }
    let formdata = new FormData();
    formdata.append("pointId", pointId);
    this.subs["subPointLaw"] = this.data
      .getSubPointsOfLaw(pointId)
      .subscribe((data) => {
        this.subPointList = data;
      });
  }

  addSubPointsOfLaw = (term) => ({
    subpoint_id: term,
    subpoint_of_law_name: term,
  });

  onChangeMainIssue(event) {
    this.main_issue_id = event.type_value_id;
    this.subPointArr = [];
    this.getPointsOfLaw(this.main_issue_id, this.litigation_matter_type_value);
  }

  onChangePointOfLaw(event) {
    this.subPointArr.push(event.point_id);
    this.getSubPointsOfLaw(this.subPointArr);
  }

  getJudgeSpecificCases(caseType, matter_type_id, judgeId) {
    if (this.subs["judgeCase"]) {
      this.subs["judgeCase"].unsubscribe();
    }
    this.subs["judgeCase"] = this.data
      .getJudgeSpecificCases(caseType, matter_type_id, judgeId)
      .subscribe((res) => {
        this.pieChartData = res;
      });
  }

  getJudgeGrantDenied(judgeId) {
    if (this.subs["judgeCaseDenied"]) {
      this.subs["judgeCaseDenied"].unsubscribe();
    }
    this.subs["judgeCaseDenied"] = this.data
      .getJudgeGrantDenied(judgeId)
      .subscribe((data) => {
        this.pieChartData2 = data;
      });
  }

  onChangeShowJudgeData(event) {
    if (this.subs["judgeCaseData"]) {
      this.subs["judgeCaseData"].unsubscribe();
    }
    var judgeId = event.contact_id;
    if (judgeId != "") {
      this.showDiv = true;
      this.subs["judgeCaseData"] = this.data
        .getJudgeData(judgeId)
        .subscribe((data) => {
          this.judgeData = data;
          for (let index = 0; index < this.judgeData.length; index++) {
            var linkArr = this.judgeData[index].link.split(",");
            this.judgeData[index].links = linkArr;
            this.data
              .courtCaseDateDiff(this.judgeData[index].contact_judge_id)
              .subscribe((data1) => {
                //this.pieChartData2 = data;
                var days = data1;
                this.judgeData[index].days = days;
              });
          }
        });

      this.getJudgeSpecificCases(this.caseType, this.matter_type_id, judgeId);
      this.getJudgeGrantDenied(judgeId);
    } else {
      this.showDiv = false;
    }
  }

  judgeBoxClose() {
    this.showDiv = false;
  }

  tab(value) {
    if (value === "data") {
      this.dataTab = true;
      this.chartTab = false;
    } else if (value === "chart") {
      this.chartTab = true;
      this.dataTab = false;
    }
  }

  deleteSingle(event) {
    const index: number = this.subPointArr.indexOf(event.value.point_id);
    this.subPointArr.splice(index, 1);
    this.getSubPointsOfLaw(this.subPointArr);
  }
}
