import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-matter-dashboard-right',
  templateUrl: './matter-dashboard-right.component.html',
  styleUrls: ['./matter-dashboard-right.component.scss']
})
export class MatterDashboardRightComponent implements OnInit {
  mId : any;
  mmID : any;
  isActive:boolean=false;
  matterDetails: any = [];
  

 
  constructor(private data: DataService) { }

  ngOnInit() {	

	  let url = window.location.href;		
    this.mId = url.substr(url.lastIndexOf('/') + 1);
    this.mmID = this.mId;
    /* if( (this.mId.indexOf('-') !== -1) == true){
			var str_arr = this.mId.split('-');
			this.mId = str_arr[0];
		}else{
			this.mId = this.mId;
    }
    this.mmID = this.mId; */
    this.matterDetailsData();
  
  }

  matterDetailsData(){
    this.data.getMatterDetByMatterId( this.mId ).subscribe(response => {
      if (response) {
        this.matterDetails = response;
        
      }
    });
    }

  

}
