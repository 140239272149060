import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { $ } from "protractor";
declare var jQuery: any;

@Component({
  selector: "app-view-task",
  templateUrl: "./view-task.component.html",
  styleUrls: ["./view-task.component.scss"],
})
export class ViewTaskComponent implements OnInit, OnChanges {
  addTaskForm: FormGroup;
  checked: any = true;
  succ_msg: any;
  tid: number = 0;
  assigneeList: any;
  clientList: any;
  taskListID: any;
  matterList: any;
  err_task_name: string;
  err_description: string;
  err_due_date: string;
  err_assignee_type: string;
  err_assignee_id: string;
  err_client_id: string;
  err_matter_id: string;
  err_priority: string;
  err_task_status: string;
  err_due_date_msg: String;
  @Input() taskId: number;
  p: number = 0;
  FormHeading: string;
  listDetails: any = [];
  taskList: any = [];
  listParam: any = {};
  lastTaskId: any;
  assigneeType: String = "";

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    let chg = changes["taskId"];
    let cval = chg.currentValue;
    if (chg.currentValue > "0" && chg.currentValue == cval) {
      this.editTaskList();
      this.FormHeading = "Edit Task";
    } else {
      this.FormHeading = "Add Task";
      this.taskFormInit();
      this.getLastTaskId();
    }
  }

  ngOnInit() {
    this.getTaskList();
    this.taskFormInit();
    this.listClientList();
    this.getTaskListID();
    this.listMatterList();
    this.getLastTaskId();
    this.FormHeading = "View Task";

    document.querySelectorAll("select.noOpen").forEach((selec) => {
      selec.addEventListener("mousedown", (e) => {
        e.preventDefault();
        window.focus();
      });
    });
  }

  // ngOnChanges(){
  // 	if (this.taskId > 0) {
  // 		this.editTaskList();
  // 		this.FormHeading ="Edit Task";
  // 	}
  // }

  getLastTaskId() {
    this.data.getLastTaskId().subscribe((data) => {
      this.lastTaskId = data;
      let numb = this.lastTaskId.toString().replace("T", "");
      let digit = Number(numb);
      if (digit > 0) {
        this.addTaskForm.controls["task_id"].setValue(this.lastTaskId);
      } else {
        this.addTaskForm.controls["task_id"].setValue("T1");
      }
    });
  }

  getTaskList() {
    this.data.getTaskList(this.listParam).subscribe((data) => {
      this.taskList = data;
    });
  }

  editTaskList() {
    this.data.getlistDetails(this.taskId).subscribe((data) => {
      this.listDetails = data;
      this.addTaskForm.patchValue({
        t_id: this.listDetails.t_id,
        task_id: this.listDetails.task_id,
        task_name: this.listDetails.task_name,
        // task_description: this.listDetails.description,
        task_due_date: this.listDetails.due_date,
        task_assignee: this.listDetails.assignee_type,
        task_assignee_user: this.listDetails.assignee_id,
        task_leads: this.listDetails.client_id,
        task_matter: this.listDetails.matter_id,
        task_priority: this.listDetails.priority,
        task_status: this.listDetails.task_status,
      });

      if (this.listDetails.process_id) {
        if (this.listDetails.cc == null) {
          this.listDetails.cc = "";
        }
        if (this.listDetails.bcc == null) {
          this.listDetails.bcc = "";
        }
        this.listDetails.description =
          "<br />CC : " +
          this.listDetails.cc +
          "<br />BCC : " +
          this.listDetails.bcc +
          "<br />Subject : " +
          this.listDetails.subject +
          "<br />Body : " +
          this.listDetails.mail_body +
          "<br />";
      } else {
        this.listDetails.description = this.listDetails.description;
      }

      this.onChange(this.listDetails.assignee_type);
    });
  }

  getTaskListID() {
    this.data.getTaskListID().subscribe((data) => {
      this.taskListID = data;
    });
  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        this.assigneeType = "Client";
        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
        });
      } else {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      }
    } else {
      this.assigneeList = [];
    }
  }

  listClientList() {
    this.data.listClientList().subscribe((data) => {
      this.clientList = data;
    });
  }

  listMatterList() {
    this.data.allMatters(this.listParam).subscribe((data) => {
      this.matterList = data;
    });
  }

  clickCancel() {
    this.closeModal.emit();
  }

  taskFormInit() {
    this.addTaskForm = this.fb.group({
      t_id: new FormControl(""),
      task_id: new FormControl(""),
      task_name: new FormControl(""),
      task_due_date: new FormControl(""),
      // task_description: new FormControl(''),
      task_assignee: new FormControl(""),
      task_assignee_user: new FormControl(""),
      task_priority: new FormControl(""),
      task_leads: new FormControl(""),
      task_matter: new FormControl(""),
      task_status: new FormControl(""),
    });
  }
}
