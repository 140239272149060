import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ip-metrics',
  templateUrl: './ip-metrics.component.html',
  styleUrls: ['./ip-metrics.component.scss']
})
export class IpMetricsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
