import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
declare var jQuery: any;
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarDateFormatter } from 'angular-calendar';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('viewmodalContent', { static: true }) viewmodalContent: TemplateRef<any>;
  paticipantList: any;
  contactParam: any = {};
  clientList: any;
  matterList: any;
  geniusList: any;
  succ_msg: any = "";
  time_flag: boolean = false;
  listParam: any = {};
  taskList: any = [];
  location: any = [];
  selectedParticipants: any;
  tashFormDisplay: boolean = false;
  eventFormDisplay: boolean = true;
  starttime: any = [];
  endtime: any = [];
  err_title: string;
  err_location: string;
  err_notes: string;
  err_participants: string;
  err_alert: string;
  err_start_date: string;
  err_end_date: string;
  err_start_time: string;
  err_end_time: string;
  err_task_title: string;
  err_task_note: string;
  err_alrt_val: string;
  eventData: any = [];
  eventList: any = [];
  calendarName: String;
  eventParam: any = {};
  currentUrl: string;
  currDate;
  curViewDate;
  currDateDB;
  calendarClass;
  modalRef;
  theCheckbox: boolean;
  submitted: boolean = false;
  participants = (term) => ({ email: term });
  modalOption: NgbModalOptions = {};
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  constructor(private data: DataService, private router: Router, private datePipe: DatePipe, private modal: NgbModal, activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.currDate = new Date();
    this.currentUrl = this.router.url;
   
    this.currDateDB = new DatePipe('en-US').transform(Date.now(), 'MM');
    let dashboard_month = localStorage.getItem('dashboard_month');
    if(dashboard_month!= null){
      localStorage.setItem('event_month', dashboard_month);
      let date = localStorage.getItem('dashboard_date')
      this.curViewDate = new DatePipe('en-US').transform(date, 'MMMM y');
    }else{
      localStorage.setItem('event_month', this.currDateDB);
      this.curViewDate = new DatePipe('en-US').transform(Date.now(), 'MMMM y');
    }
   
    this.getAllEvent();

  }

  getAllEvent() {
  
    this.eventParam.year = this.curViewDate;
    this.eventParam.title = localStorage.getItem('event_title');
    this.eventParam.location = localStorage.getItem('location');
    this.eventParam.word_no_present = localStorage.getItem('word_no_present');
    this.eventParam.start_date = localStorage.getItem('start_date');
    this.eventParam.end_date = localStorage.getItem('end_date');
    this.eventParam.calendar = localStorage.getItem('event_calendar');
    this.eventParam.participant = localStorage.getItem('event_participant');
    this.eventParam.month = localStorage.getItem('event_month');
    this.eventParam.genius = localStorage.getItem('genius');
    this.eventParam.staff = localStorage.getItem('staff');
    let calendar_type = localStorage.getItem('calendar_type');
    if(calendar_type == 'bn'){
      this.eventParam.calendar = 3
    }else if(calendar_type == 'ip'){
      this.eventParam.calendar = 4
    }
   
    this.data.agendaList(this.eventParam).subscribe(
      data => {
        this.eventList = data;
        localStorage.removeItem('event_title');
        localStorage.removeItem('location');
        localStorage.removeItem('word_no_present');
        localStorage.removeItem('start_date');
        localStorage.removeItem('end_date');
        localStorage.removeItem('event_calendar');
        localStorage.removeItem('event_participant');
        localStorage.removeItem('event_month');
        localStorage.removeItem('dashboard_month');
        localStorage.removeItem('calendar_type');
        localStorage.removeItem('genius');
        localStorage.removeItem('dashboard_date');
        localStorage.removeItem('staff');

      }
    );
  }

  nextPrevDate(dtObj, npDay) {
    let dte = this.currDate;
    if (dtObj) {
      dte = dtObj;
    }
    if (npDay === 'N') {
      dte.setMonth(dte.getMonth() + 1);
    } else if (npDay === 'P') {
      dte.setMonth(dte.getMonth() - 1);
    }

    this.currDate = dte;
    this.currDateDB = new DatePipe('en-US').transform(dte, 'MM');
    this.curViewDate = new DatePipe('en-US').transform(dte.toString(), 'MMMM y');
    localStorage.setItem('event_month', this.currDateDB);
    this.getAllEvent();
  }

  reloadAgenda(){
    location.reload();
  }
  loadTask(){
    this.eventParam.calendar_type = 'task';
    this.getAllEvent();
  }
  setDayView(){
    localStorage.setItem('cal_view','day');
  }
  setMonthView(){
    localStorage.setItem('cal_view','month');
  }
  setWeekView(){
    localStorage.setItem('cal_view','week');
  }

  editEventModal(action: string, event: CalendarEvent, eventDetails) {
    this.modalData = { event, action };
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    this.modalOption.windowClass = 'event-custom-class'
    this.modalRef = this.modal.open(this.modalContent, this.modalOption);
  }

}