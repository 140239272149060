import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, FormControl, Validators, Validator } from '@angular/forms';
import { AuthenticationService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  err_email: string;
  msg: string;
  constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router) { }

  ngOnInit() {
    $(document).ready(function () {
      $('body').addClass('login-page');
    });

    this.forgotpasswordForm = this.fb.group({
      email: ['']
    });

  }

  sendForgotpassmail(data) {
    let formdata = new FormData();
    formdata.append('email', data.email);
    formdata.append('base_url', location.origin);
    this.auth.forgotPassword(formdata).subscribe(response => {
      if (response) {
        if (response['msg']) {
          this.msg = response['msg'];
          this.err_email = '';  
          setTimeout(() => {
            this.msg = '';	
            this.forgotpasswordForm.reset();	 			   
          }, 2500);
        } else {
          this.msg = '';
        }
      }

    },
      error => {
        if (error.error.email) {
          this.err_email = error.error.email;
        } else if(error.error.invalid_account){
          this.err_email = error.error.invalid_account;
        }else{
          this.err_email = '';
        }
      });
  }
  ngOnDestroy() {
    $(document).ready(function () {
      $('body').removeClass('login-page');
    });
  }

}
