import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import Chart from "chart.js";

@Component({
  selector: "app-general-list",
  templateUrl: "./general-list.component.html",
  styleUrls: ["./general-list.component.scss"],
})
export class GeneralListComponent implements OnInit {
  year_arr: any = ["2019", "2020", "2021"];
  selectedYear: any = "2020";
  total_matters: any = [];
  duration_each_matter: any = [];
  duration_ip_matter: any = [];
  duration_corp_matter: any = [];
  duration_liti_matter: any = [];
  duration_chart: any = [];
  ip_duration_chart: any = [];
  corp_duration_chart: any = [];
  liti_duration_chart: any = [];
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };
  public barChartLabels: Label[] = ["Total", "IP", "Corporate", "Litigation"];
  public barChartLabels1: Label[] = [
    "Other",
    "Design",
    "Patent Extension",
    "Patent Invention",
    "Patent Utility",
    "Trademark",
  ];
  public barChartLabels2: Label[] = [
    "Other",
    "Incorporation",
    "Overseas Company Registration",
    "Assignment of Quota",
    "Dissolution",
    "Corporate Changes",
    "Junket",
    "Legal Opinion",
  ];
  public barChartLabels3: Label[] = [
    "Processo Penal/Criminal Procedure",
    "Processo Civil/Civil Procedure",
    "Processo Administrativo Contencioso/Administrative Litigation ",
    "Processo Laboral/Labour Procedure",
    "Procedimento Administrativo/Administrative Procedure",
  ];
  public barChartType: ChartType = "horizontalBar";
  public barChartLegend = true;

  /* public barChartData: ChartDataSets[] = [
    { data: [77, 4, 15, 5, 2, 0], label: 'Type of work' }
   
  ]; */
  public barChartData: any = [];
  constructor(private data: DataService) {}

  ngOnInit() {
    this.getTotalMattersByYear(this.selectedYear);
    this.getDurationOfEachMatter(this.selectedYear);
    this.IPSummaryGraph();
    this.corporateSummaryGraph();
    this.litigationSummaryGraph();
  }

  yearChange(selectedYear) {
    var chngYr = "";
    this.selectedYear = selectedYear;
    chngYr = this.selectedYear;
    if (chngYr != "") {
      this.getTotalMattersByYear(chngYr);
      this.getDurationOfEachMatter(chngYr);
      this.IPSummaryGraph();
      this.corporateSummaryGraph();
      this.litigationSummaryGraph();
    }
  }

  getTotalMattersByYear(selectedYear) {
    this.data.getTotalMattersByYear(selectedYear).subscribe((data) => {
      this.total_matters = data;
    });
  }

  IPSummaryGraph() {
    var givenYear = "";
    givenYear = this.selectedYear;
    this.data.getDurationOfEachMatterType("IP", givenYear).subscribe((res) => {
      this.duration_ip_matter = res;
      let c2 = "canvas2_" + givenYear;
      this.ip_duration_chart = "";
      this.ip_duration_chart = new Chart(c2, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels1,
          datasets: [
            {
              data: this.duration_ip_matter,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],
              label: "Count",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
  }

  corporateSummaryGraph() {
    var givenYear = "";
    givenYear = this.selectedYear;
    this.data
      .getDurationOfEachMatterType("CORPORATE", givenYear)
      .subscribe((res) => {
        this.duration_corp_matter = res;

        let c3 = "canvas3_" + givenYear;
        this.corp_duration_chart = "";
        this.corp_duration_chart = new Chart(c3, {
          type: "horizontalBar",
          data: {
            labels: this.barChartLabels2,
            datasets: [
              {
                data: this.duration_corp_matter,
                backgroundColor: [
                  "#ff00ff",
                  "#0000ff",
                  "#66ffff",
                  "#ffff00",
                  "#ff0000",
                  "#6600cc",
                  "#00e6e6",
                  "#00cc00",
                ],
                label: "Count",
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: "white",
                  },
                  display: true,
                  gridLines: {
                    display: true,
                    color: "white",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    fontColor: "white",
                  },
                  display: true,
                },
              ],
            },
          },
        });
      });
  }

  litigationSummaryGraph() {
    var givenYear = "";
    givenYear = this.selectedYear;
    this.data
      .getDurationOfEachMatterType("LITIGATION", givenYear)
      .subscribe((res) => {
        this.duration_liti_matter = res;
        let c4 = "canvas4_" + givenYear;
        this.liti_duration_chart = "";
        this.liti_duration_chart = new Chart(c4, {
          type: "horizontalBar",
          data: {
            labels: this.barChartLabels3,
            datasets: [
              {
                data: this.duration_liti_matter,
                backgroundColor: [
                  "#ff00ff",
                  "#0000ff",
                  "#00ffff",
                  "#ffff00",
                  "#ff0000",
                ],
                label: "Count",
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: "white",
                  },
                  display: true,
                  gridLines: {
                    display: true,
                    color: "white",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    fontColor: "white",
                  },
                  display: true,
                },
              ],
            },
          },
        });
      });
  }

  getDurationOfEachMatter(selectedYear) {
    var givenYear = "";
    givenYear = selectedYear;
    this.data.getDurationOfEachMatter(givenYear).subscribe((res) => {
      this.duration_each_matter = res;

      /* this.duration_each_matter = res; */
      let c1 = "canvas1_" + givenYear;
      this.duration_chart = "";
      this.duration_chart = new Chart(c1, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.duration_each_matter,
              backgroundColor: ["#0000ff", "#00ffff", "#ffff00", "#ff0000"],
              label: "Count",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
  }

  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public randomize(): void {
    this.barChartType = this.barChartType === "bar" ? "line" : "bar";
  }
}
