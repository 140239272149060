import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray, FormControlName } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
declare var jQuery: any;
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ip-matter',
  templateUrl: './ip-matter.component.html',
  styleUrls: ['./ip-matter.component.scss']
})
export class IpMatterComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() caseFlag: String;
  countryList: any;
  
  base_url: any;
  dynamicVar: any;

  constructor(private fb: FormBuilder, private data: DataService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.listNationality();
    this.base_url = `${environment.lpmsUrl}`+"documents/comp_cert/svg/";
    this.dynamicVar = `${environment.lpmsUrl}`;
  }

  listNationality() {
    this.data.listNationality().subscribe(response => {
      this.countryList = response;
      for (let index = 0; index < this.countryList.length; index++) {
        this.countryList[index].name = this.countryList[index].nicename;
        var imgName = this.countryList[index].iso.toLowerCase();
        this.countryList[index].imgUrl = this.base_url+imgName+'.svg';
      }
    });
  }

}
