import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-generate-quote',
  templateUrl: './generate-quote.component.html',
  styleUrls: ['./generate-quote.component.scss']
})
export class GenerateQuoteComponent implements OnInit {

  lId: any;
  price: any;
  quote_number: number;
  currency: string;
  currency_text: string = '¥';
  err_msg:string ;
  disply_quote_num:number;
  constructor(private route: ActivatedRoute, private data: DataService,private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lId = +params['lId'];
    });
    this.data.displayQuoteAmount(this.lId).subscribe(response => {
      if (response) {
        this.price = response['amount'];
        this.currency = response['currency'];
        this.disply_quote_num = response['quote_number'];
       // if (this.currency == 'YUAN') {
          this.currency_text = '$';
        //} else if (this.currency == 'USD') {
         // this.currency_text = '$';
          
        //}
      }
    });
  }

  getQuotePrice(quote_number) {
    let formdata = new FormData();
    formdata.append('quote_number', quote_number);
    formdata.append('lId', this.lId);
    this.data.getQuotePrice(formdata).subscribe(res => {
      if (res) {
        this.price = res['cost_price'];
        this.currency = res['currency'];
       // if (this.currency == 'YUAN') {
         // this.currency_text = '¥';
        //} else if (this.currency == 'USD') {
          this.currency_text = '$';
          
        //}
        this.err_msg = '';
      }else{
        this.price = 0 ;
      }
    },error =>{
      // if(error.error.quote_number){
      //   
      // }else if(this.err_msg){
      //   this.err_msg = error.error.msg;
      // }else{
      //   this.err_msg = '';
      // }
      this.err_msg = error.error.msg;
      this.price = 0 ;
    });
  }

  clickBack(){
	  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate(['/leads/leads-home/schedule-call/', this.lId]);
  }

  clickNext(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate(['/leads/leads-home/agreement-signed/', this.lId]);
  }

}
