import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
declare var jQuery: any;
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-add-task",
  templateUrl: "./add-task.component.html",
  styleUrls: ["./add-task.component.scss"],
})
export class AddTaskComponent implements OnInit, OnChanges {
  addTaskForm: FormGroup;
  checked: any = true;
  succ_msg: any;
  tid: number = 0;
  assigneeList: any;
  clientList: any;
  taskListID: any;
  matterList: any;
  err_task_name: string;
  err_description: string;
  err_due_date: string;
  err_assignee_type: string;
  err_assignee_id: string;
  err_client_id: string;
  err_matter_id: string;
  err_priority: string;
  err_task_status: string;
  err_due_date_msg: String;
  @Input() taskId: number;
  p: number = 0;
  FormHeading: string;
  listDetails: any;
  taskList: any = [];
  listParam: any = {};
  lastTaskId: any;
  assigneeType: String = "";
  readonly: boolean = false;

  matterTypeId: any;
  matterDtl: any;
  testbutton: boolean = true;
  selectedClient: any;
  selectedMatter: any;
  selectedUser: any;
  subscribes: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "400px",
    minHeight: "260px",
    maxHeight: "260px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    let chg = changes["taskId"];
    let cval = chg.currentValue;
    if (chg.currentValue > "0" && chg.currentValue == cval) {
      this.editTaskList();
      this.FormHeading = "Edit Task";
    } else {
      this.FormHeading = "Add Task";
      this.taskFormInit();
      this.getLastTaskId();
    }
  }

  ngOnInit() {
    //this.getTaskList();
    this.taskFormInit();
    this.getTaskListID();
    this.getLastTaskId();
    this.listClientList();
    //this.listMatterList('');
    this.FormHeading = "Add Task";
  }

  getLastTaskId() {
    if (this.subscribes["lastTask"]) {
      this.subscribes["lastTask"].unsubscribe();
    }
    this.subscribes["lastTask"] = this.data
      .getLastTaskId()
      .subscribe((data) => {
        this.lastTaskId = data;
        let numb = this.lastTaskId.toString().replace("T", "");
        let digit = Number(numb);
        if (digit > 0) {
          this.addTaskForm.controls["task_id"].setValue(this.lastTaskId);
        } else {
          this.addTaskForm.controls["task_id"].setValue("T1");
        }
      });
  }

  getTaskList() {
    if (this.subscribes["taskList"]) {
      this.subscribes["taskList"].unsubscribe();
    }
    this.subscribes["taskList"] = this.data
      .getTaskList(this.listParam)
      .subscribe((data) => {
        this.taskList = data;
      });
  }

  editTaskList() {
    if (this.subscribes["taskListId"]) {
      this.subscribes["taskListId"].unsubscribe();
    }

    this.subscribes["taskListId"] = this.data
      .getlistDetails(this.taskId)
      .subscribe((data) => {
        this.listDetails = data;
        let logInId = localStorage.getItem("UserId");

        if (this.listDetails.process_id) {
          this.testbutton = false;
        } else {
          this.testbutton = true;
        }

        this.listMatterList(this.listDetails);

        if (this.listDetails.process_id) {
          if (this.listDetails.cc == null) {
            this.listDetails.cc = "";
          }
          if (this.listDetails.bcc == null) {
            this.listDetails.bcc = "";
          }
          this.listDetails.description =
            "<br />CC : " +
            this.listDetails.cc +
            "<br />BCC : " +
            this.listDetails.bcc +
            "<br />Subject : " +
            this.listDetails.subject +
            "<br />Body : " +
            this.listDetails.mail_body +
            "<br />";
        } else {
          this.listDetails.description = this.listDetails.description;
        }

        this.addTaskForm.patchValue({
          t_id: this.listDetails.t_id,
          task_id: this.listDetails.task_id,
          task_name: this.listDetails.task_name,
          task_description: this.listDetails.description,
          task_due_date: this.listDetails.due_date,
          task_assignee: this.listDetails.assignee_type,
          task_assignee_user: this.listDetails.assignee_id,
          task_leads: Number(this.listDetails.client_id),
          task_matter: Number(this.listDetails.matter_id),
          task_priority: this.listDetails.priority,
          task_status: this.listDetails.task_status,
          google_task_id: this.listDetails.google_task_id,
        });

        if (this.clientList.length > 0) {
          this.selectedClient = this.clientList.filter(
            (c) => Number(c.clientID) === this.listDetails.client_id
          )[0];
        }

        this.getMatterTypeId(this.listDetails.matter_id);
        this.onChange(this.listDetails.assignee_type);
      });
  }

  getTaskListID() {
    if (this.subscribes["taskListId"]) {
      this.subscribes["taskListId"].unsubscribe();
    }
    this.subscribes["taskListId"] = this.data
      .getTaskListID()
      .subscribe((data) => {
        this.taskListID = data;
      });
  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        if (this.subscribes["clientList"]) {
          this.subscribes["clientList"].unsubscribe();
        }
        this.assigneeType = "Client";
        this.subscribes["clientList"] = this.data
          .listClientList()
          .subscribe((data) => {
            this.assigneeList = data;

            this.assigneeList = this.assigneeList.sort(
              (a, b) => b.client_id - a.client_id
            );

            if (this.assigneeList.length > 0 && this.listDetails) {
              this.selectedUser = this.assigneeList.filter(
                (a) => Number(a.clientID) === this.listDetails.client_id
              )[0];
            }
          });
      } else {
        if (this.subscribes["taskAssignee"]) {
          this.subscribes["taskAssignee"].unsubscribe();
        }
        this.assigneeType = "Other";
        this.subscribes["taskAssignee"] = this.data
          .getTaskAssignee(value)
          .subscribe((data) => {
            this.assigneeList = data;

            if (this.assigneeList.length > 0 && this.listDetails) {
              this.selectedUser = this.assigneeList.filter(
                (a) => a.member_id === this.listDetails.assignee_id
              )[0];
            }
          });
      }
    } else {
      this.assigneeList = [];
    }
  }

  listClientList() {
    if (this.subscribes["clientList"]) {
      this.subscribes["clientList"].unsubscribe();
    }
    this.subscribes["clientList"] = this.data
      .listClientList()
      .subscribe((data) => {
        this.clientList = data;
        this.clientList = this.clientList.sort(
          (a, b) => b.client_id - a.client_id
        );
        for (var i = 0; i < this.clientList.length; ++i) {
          this.clientList[i].client_name =
            this.clientList[i].client_number +
            " - " +
            this.clientList[i].client_name;
        }
      });
  }

  listMatterList(client_id) {
    return new Promise((resolve) => {
      if (this.subscribes["mlist"]) {
        this.subscribes["mlist"].unsubscribe();
      }
      this.listParam.client_id = client_id;
      this.subscribes["mlist"] = this.data
        .allMatters(this.listParam)
        .subscribe((data) => {
          this.matterList = data;

          this.matterList = this.matterList.sort(
            (a, b) => b.matter_id - a.matter_id
          );

          for (var i = 0; i < this.matterList.length; ++i) {
            this.matterList[i].matter_name =
              this.matterList[i].matter_number +
              " - " +
              this.matterList[i].matter_name;

            if (
              this.listDetails &&
              this.matterList[i].matter_id == this.listDetails.matter_id
            ) {
              this.selectedMatter = this.matterList[i];
            }
          }
        });
      resolve(null);
    });
  }

  addTask(postData) {
    if (postData.task_due_date != "" && postData.task_due_date != null) {
      var dateSendingToServer = new DatePipe("en-US").transform(
        postData.task_due_date,
        "yyyy-MM-dd"
      );
    } else {
      var dateSendingToServer = "";
    }

    let formdata = new FormData();
    formdata.append("task_id", postData.task_id);
    formdata.append("task_name", postData.task_name);
    formdata.append("due_date", dateSendingToServer);
    formdata.append("description", postData.task_description);
    formdata.append("assignee_type", postData.task_assignee);
    formdata.append("assignee_id", postData.task_assignee_user);
    formdata.append("matter_id", postData.task_matter);
    formdata.append("client_id", postData.task_leads);
    formdata.append("priority", postData.task_priority);
    formdata.append("task_status", postData.task_status);
    formdata.append("add_from", "task");

    if (postData.t_id) {
      formdata.append("t_id", postData.t_id);
      formdata.append("google_task_id", postData.google_task_id);
    }

    this.data.addTaskpost(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.getTaskList();
          this.blankErrorMsgs();
          this.editTaskList();
          setTimeout(() => {
            this.succ_msg = false;
            jQuery("#taskModal").modal("hide");
            this.clickCancel();
            this.getLastTaskId();
            this.taskFormInit();
          }, this.data.timeOut);
          //this.router.navigate(['/contacts']);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.task_name) {
          this.err_task_name = error.error.task_name;
        } else {
          this.err_task_name = "";
        }

        if (error.error.description) {
          this.err_description = error.error.description;
        } else {
          this.err_description = "";
        }

        if (error.error.err_due_date) {
          this.err_due_date_msg = error.error.err_due_date_msg;
          this.err_due_date = "";
        } else {
          this.err_due_date_msg = "";
        }

        if (error.error.due_date) {
          this.err_due_date = error.error.due_date;
          this.err_due_date_msg = "";
        } else {
          this.err_due_date = "";
        }

        if (error.error.assignee_type) {
          this.err_assignee_type = error.error.assignee_type;
        } else {
          this.err_assignee_type = "";
        }

        if (error.error.assignee_id) {
          this.err_assignee_id = error.error.assignee_id;
        } else {
          this.err_assignee_id = "";
        }

        if (error.error.client_id) {
          this.err_client_id = error.error.client_id;
        } else {
          this.err_client_id = "";
        }

        if (error.error.matter_id) {
          this.err_matter_id = error.error.matter_id;
        } else {
          this.err_matter_id = "";
        }

        if (error.error.priority) {
          this.err_priority = error.error.priority;
        } else {
          this.err_priority = "";
        }

        if (error.error.task_status) {
          this.err_task_status = error.error.task_status;
        } else {
          this.err_task_status = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_task_name =
      this.err_description =
      this.err_due_date =
      this.err_assignee_type =
      this.err_assignee_id =
      this.err_client_id =
      this.err_matter_id =
      this.err_priority =
      this.err_task_status =
        "";
    this.err_due_date_msg = "";
  }

  clickCancel() {
    this.closeModal.emit();
    this.blankErrorMsgs();
    if (this.taskId == 0) {
      this.taskFormInit();
      this.getLastTaskId();
    }
  }

  testSend() {
    jQuery("#taskModal").modal("hide");
    return new Promise((resolve, reject) => {
      /*this.data.getTemplateDtl(this.matterDtl.matter_type_id,this.listDetails.process_id).subscribe(
				data => {
					this.matterTypeId=data;
					this.router.navigateByUrl('/emails/'+this.matterTypeId.process_id);
			});*/
      this.router.navigateByUrl(
        "/emails/compose/?task=" + this.listDetails.process_id
      );
      resolve(null);
    });
  }

  getMatterTypeId(matterId) {
    return new Promise((resolve, reject) => {
      this.data.getMatterDetails(matterId).subscribe((data) => {
        this.matterDtl = data;
      });
      resolve(null);
    });
  }

  taskFormInit() {
    this.addTaskForm = this.fb.group({
      t_id: new FormControl(""),
      google_task_id: new FormControl(""),
      task_id: new FormControl(""),
      task_name: new FormControl(""),
      task_due_date: new FormControl(""),
      task_description: new FormControl(""),
      task_assignee: new FormControl(""),
      task_assignee_user: new FormControl(""),
      task_priority: new FormControl(""),
      task_leads: new FormControl(""),
      task_matter: new FormControl(""),
      task_status: new FormControl(""),
    });
  }
}
