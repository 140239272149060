import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-add-task-lists',
  templateUrl: './add-task-lists.component.html',
  styleUrls: ['./add-task-lists.component.scss']
})
export class AddTaskListsComponent implements OnInit, OnChanges {

  taskList: any = [];
  listParam: any;
  addTaskListForm: FormGroup;
  taskIdArr: any = [];
  succ_msg: any;
  err_tasklist_name: String;
  err_tasklist_desc: String;

  listDetails: any;
  finalArr: Array<any> = [];
  @Input() tasklistId: number;
  FormHeading:any;

  allTaskrecords: any =[];

  constructor(private fb: FormBuilder, private data: DataService, private router: Router, private route: ActivatedRoute) { }

  public ngOnChanges( changes: SimpleChanges ) : void {   
    let chg = changes['tasklistId'];
    let cval = chg.currentValue;
    if (chg.currentValue > '0' && chg.currentValue == cval) {
      this.taskListEdit();
      this.FormHeading = "Edit List";
    }else {      
      this.FormHeading = "Add List";
        this.taskListFormInit();
        this.getTaskList();
	  }
	
  }

  ngOnInit() {
    this.taskListFormInit();
  }

  // ngOnChanges(){	
	// 	if (this.tasklistId > 0) {
	// 		this.taskListEdit();	
	// 	} 	
	// }

  getTaskList() {
		this.data.getTaskList(this.listParam).subscribe(
		  data => {
      this.taskIdArr = [];
      
      this.allTaskrecords = data;
      this.taskList = this.allTaskrecords.items;
      this.taskList.map((item) => {
        this.addTaskListForm.addControl(item.t_id, new FormControl(''));
        this.taskIdArr.push(item.t_id);
      });
     
		  }
		);
  }
  
  taskListFormInit() {
		this.addTaskListForm = this.fb.group({
			tasklist_id: new FormControl(''),
			tasklist_name: new FormControl(''),
      tasklist_desc: new FormControl('')
		})
  }

  removeDynamicControl(){
    for (let ind = 0; ind < this.taskIdArr.length; ind++) {
      var cntrl = this.taskIdArr[ind];
      this.addTaskListForm.removeControl(cntrl);
    }
  }
  
  taskListEdit() {
		this.data.getTaskListDetails(this.tasklistId).subscribe(
			data => {
        this.listDetails = data;
        let toArray = this.listDetails.related_task_arr;
       
        for (let ind = 0; ind < this.taskList.length; ind++) {  
          for (let index = 0; index < toArray.length; index++) {
            if(toArray.indexOf(parseInt(this.taskList[ind].t_id)) > -1){
              this.taskList[ind].isChecked = true;
            }else{
              this.taskList[ind].isChecked = false;
            }
            
          }
        }

				this.addTaskListForm.patchValue({
					tasklist_id:this.listDetails.tasklist_id,
					tasklist_name: this.listDetails.tasklist_name,
          tasklist_desc: this.listDetails.tasklist_desc,
				});
				
			}
    );
    
	}
  
  addTaskList(postData){
    
    var related_taskids_arr: any = [];
    let formdata = new FormData();
    if(postData.tasklist_id != '' && postData.tasklist_id != null){
      formdata.append('tasklist_id', postData.tasklist_id);
    }
    
    formdata.append('tasklist_name', postData.tasklist_name);
    formdata.append('tasklist_desc', postData.tasklist_desc);
    formdata.append('add_from', 'tasklist');

    for (let index = 0; index < this.taskIdArr.length; index++) {
      var cntrl = this.taskIdArr[index];
      if(postData[cntrl] == true){
        related_taskids_arr.push(cntrl);
      }
    }
    const relatedTaskidsStr = JSON.stringify(related_taskids_arr);
    formdata.append('related_task_ids', relatedTaskidsStr);
    this.data.addTaskList(formdata).subscribe(response => {
      if (response) {
        this.succ_msg = response['msg'];
        this.blankErrorMsgs();
        setTimeout(() => {
          this.succ_msg = false;
          jQuery("#taskListModal").modal("hide");
        }, this.data.timeOut);

      } else {
        this.succ_msg = '';
      }

    },
    error => {

      if (error.error.tasklist_name) {
        this.err_tasklist_name = error.error.tasklist_name;
      } else {
        this.err_tasklist_name = '';
      }

      if (error.error.tasklist_desc) {
        this.err_tasklist_desc = error.error.tasklist_desc;
      } else {
        this.err_tasklist_desc = '';
      }

    });

  }

  clickCancel(){
    this.blankErrorMsgs();
  }

  blankErrorMsgs(){
    this.err_tasklist_name = this.err_tasklist_desc = '';
  }

}
