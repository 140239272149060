import { Component, OnInit, Input, Output } from "@angular/core";
declare var jQuery: any;
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../../../../services/data.service";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith, filter } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-overseas-company-registration",
  templateUrl: "./overseas-company-registration.component.html",
  styleUrls: ["./overseas-company-registration.component.scss"],
})
export class OverseasCompanyRegistrationComponent implements OnInit {
  overseasForm1: FormGroup;
  overseasForm2: FormGroup;
  overseasForm3: FormGroup;
  overseasForm4: FormGroup;
  overseasForm5: FormGroup;
  overseasForm6: FormGroup;
  overseasForm7: FormGroup;

  processData: any;
  processDisable: any = 0;
  checkBoxArr: any = [];

  Param: any = {};
  buttonParam: any = {};

  mId: any;
  @Input() matter_type_id: any;
  processList: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.mId = params["mId"];
    });
    //this.procedureCheckButtonDisable('OVERSEAS_PROCESS1');
    var self = this;
    jQuery(function () {
      jQuery(".moreLinks").click(function () {
        jQuery(this).next().toggle();
      });
    });
    this.procedureFormInit();

    /* let url = window.location.href;		
		this.mId = url.substr(url.lastIndexOf('/') + 1); */
    this.processStListBymatterType();
  }

  procedureCheckButtonDisable(index, currentval) {
    index = index + 1;
    index = index % this.processList.length;

    for (const obj of this.processList) {
      if (obj.process_id === currentval) {
        obj.isShown = true;
        break;
      }
    }

    for (const obj of this.processList) {
      if (obj.process_id === this.processList[index].process_id) {
        obj.isShown = false;
        break;
      }
    }

    var last_element = this.processList[this.processList.length - 1];

    if (last_element.process_id == this.processList[index].process_id) {
      this.processDisable = 1;
    } else {
      this.processDisable = 0;
    }
  }

  procedureFormInit() {
    this.overseasForm1 = this.fb.group({
      overseas_lead_info: new FormControl(""),
      overseas_add_mailing_list: new FormControl(""),
      overseas_mark_lost: new FormControl(""),
      overseas_send_email: new FormControl(""),
      overseas_mark_won: new FormControl(""),
      overseas_prepare_invoice: new FormControl(""),
      overseas_email_client: new FormControl(""),
    });

    this.overseasForm2 = this.fb.group({
      overseas_client_payment: new FormControl(""),
    });

    this.overseasForm3 = this.fb.group({
      overseas_create_client: new FormControl(""),
      overseas_save_files: new FormControl(""),
    });

    this.overseasForm4 = this.fb.group({
      overseas_check_form: new FormControl(""),
      overseas_review_document: new FormControl(""),
    });

    this.overseasForm5 = this.fb.group({
      overseas_recycled_paper: new FormControl(""),
      overseas_notary_review: new FormControl(""),
    });

    this.overseasForm6 = this.fb.group({
      overseas_client_review: new FormControl(""),
    });

    this.overseasForm7 = this.fb.group({
      overseas_print_paper: new FormControl(""),
      overseas_hand_notary: new FormControl(""),
      overseas_reconfirm_appo: new FormControl(""),
      overseas_page_signed: new FormControl(""),
      overseas_make_certified: new FormControl(""),
      overseas_scan_save: new FormControl(""),
      overseas_file_dsf: new FormControl(""),
      overseas_scan_filed: new FormControl(""),
      overseas_email_client_m1: new FormControl(""),
      overseas_prepare_reglog: new FormControl(""),
    });
  }

  checkValue(val) {}

  overseasForm1Submit(postData) {
    let formdata = new FormData();
    formdata.append(
      "overseas_add_mailing_list",
      postData.overseas_add_mailing_list
    );
    formdata.append("overseas_email_client", postData.overseas_email_client);
    formdata.append("overseas_lead_info", postData.overseas_lead_info);
    formdata.append("overseas_mark_lost", postData.overseas_mark_lost);
    formdata.append("overseas_mark_won", postData.overseas_mark_won);
    formdata.append(
      "overseas_prepare_invoice",
      postData.overseas_prepare_invoice
    );
    formdata.append("overseas_send_email", postData.overseas_send_email);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS1");
    formdata.append("processName", "Procedure 1 Quote & Follow up");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS1');
      this.processStListBymatterType();
    });
  }

  overseasForm2Submit(postData) {
    let formdata = new FormData();
    formdata.append(
      "overseas_client_payment",
      postData.overseas_client_payment
    );
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS2");
    formdata.append("processName", "Procedure 2 Client Payment Monitor");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS2');
      this.processStListBymatterType();
    });
  }

  overseasForm3Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_create_client", postData.overseas_create_client);
    formdata.append("overseas_save_files", postData.overseas_save_files);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS3");
    formdata.append("processName", "Procedure 3 Folder & Matter Creation");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS3');
      this.processStListBymatterType();
    });
  }

  overseasForm4Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_check_form", postData.overseas_check_form);
    formdata.append(
      "overseas_review_document",
      postData.overseas_review_document
    );
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS4");
    formdata.append("processName", "Procedure 4 Documents Prepared");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS4');
      this.processStListBymatterType();
    });
  }

  overseasForm5Submit(postData) {
    let formdata = new FormData();
    formdata.append(
      "overseas_recycled_paper",
      postData.overseas_recycled_paper
    );
    formdata.append("overseas_notary_review", postData.overseas_notary_review);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS5");
    formdata.append("processName", "Procedure 5 Notary Review");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS5');
      this.processStListBymatterType();
    });
  }

  overseasForm6Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_client_review", postData.overseas_client_review);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS6");
    formdata.append("processName", "Procedure 6 Client Review");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS6');
      this.processStListBymatterType();
    });
  }

  overseasForm7Submit(postData) {
    let formdata = new FormData();
    formdata.append("overseas_print_paper", postData.overseas_print_paper);
    formdata.append(
      "overseas_email_client_m1",
      postData.overseas_email_client_m1
    );
    formdata.append("overseas_file_dsf", postData.overseas_file_dsf);
    formdata.append("overseas_hand_notary", postData.overseas_hand_notary);
    formdata.append(
      "overseas_make_certified",
      postData.overseas_make_certified
    );
    formdata.append("overseas_page_signed", postData.overseas_page_signed);
    formdata.append(
      "overseas_prepare_reglog",
      postData.overseas_prepare_reglog
    );
    formdata.append(
      "overseas_reconfirm_appo",
      postData.overseas_reconfirm_appo
    );
    formdata.append("overseas_scan_filed", postData.overseas_scan_filed);
    formdata.append("overseas_scan_save", postData.overseas_scan_save);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_type_id", this.matter_type_id);
    formdata.append("processCode", "OVERSEAS_PROCESS7");
    formdata.append("processName", "Procedure 7 Execute & File");

    this.data.matterWorkflowCompleteTask(formdata).subscribe((data) => {
      //this.procedureCheckButtonDisable('OVERSEAS_PROCESS7');
      this.processStListBymatterType();
    });
  }

  testSend(process_id) {
    this.router.navigateByUrl("/emails/compose/?task=" + process_id);
  }

  processStListBymatterType() {
    this.Param.matter_type_id = this.matter_type_id;
    this.Param.matter_id = this.mId;
    return new Promise((resolve) => {
      this.data.getAllProcesslistByMatterType(this.Param).subscribe((data) => {
        this.processList = data;

        this.data
          .getAllProcesslistByMatterType(this.Param)
          .subscribe((data) => {
            this.processList = data;

            this.processList.forEach((proObj, i) => {
              if (i == 0) {
                proObj["isShown"] = false;
              } else {
                proObj["isShown"] = true;
              }
            });
          });
      });
      resolve(null);
    });
  }

  procedureCheck(e) {
    var self = this;
    var tab_html = e.target.text;
    var process1 = tab_html.includes("Procedure 1");
    var process2 = tab_html.includes("Procedure 2");
    var process3 = tab_html.includes("Procedure 3");
    var process4 = tab_html.includes("Procedure 4");
    var process5 = tab_html.includes("Procedure 5");
    var process6 = tab_html.includes("Procedure 6");
    var process7 = tab_html.includes("Procedure 7");
    if (process1 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS1');
    }
    if (process2 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS2');
    }
    if (process3 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS3');
    }
    if (process4 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS4');
    }
    if (process5 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS5');
    }
    if (process6 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS6');
    }
    if (process7 == true) {
      //self.procedureCheckButtonDisable('OVERSEAS_PROCESS7');
    }
    jQuery(".steps-items").hide();
  }
}
