import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
declare var jQuery: any;

@Component({
  selector: "app-add-custom-fields",
  templateUrl: "./add-custom-fields.component.html",
  styleUrls: ["./add-custom-fields.component.scss"],
})
export class AddCustomFieldsComponent implements OnInit, OnChanges {
  customFieldList: any = [];
  listParam: any;
  addCustomFieldForm: FormGroup;
  customIdArr: any = [];
  succ_msg: any;
  err_customField_name: String;
  err_customField_type: String;

  listDetails: any;
  finalArr: Array<any> = [];
  @Input() customfieldId: number;
  FormHeading: any;
  fieldList: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    let chg = changes["customfieldId"];
    let cval = chg.currentValue;
    if (chg.currentValue > "0" && chg.currentValue == cval) {
      //this.taskListEdit();
      this.FormHeading = "Edit Custom Field";
    } else {
      this.FormHeading = "Add Custom Field";
      this.customFieldFormInit();
      //this.getcustomFieldList();
    }
  }

  ngOnInit() {
    this.customFieldFormInit();
    this.getFieldList();
  }

  getFieldList() {
    this.data.getFieldList(this.listParam).subscribe((data) => {
      this.fieldList = data;
    });
  }

  customFieldFormInit() {
    this.addCustomFieldForm = this.fb.group({
      customField_id: new FormControl(""),
      customField_name: new FormControl(""),
      customField_type: new FormControl(""),
    });
  }

  /* addCustomField(postData){
    
    let formdata = new FormData();
    if(postData.customField_id != '' && postData.customField_id != null){
      formdata.append('customField_id', postData.customField_id);
    }
    
    formdata.append('customField_name', postData.customField_name);
    formdata.append('customField_type', postData.customField_type);
    formdata.append('add_from', 'createMatter');

    this.data.addCustomField(formdata).subscribe(response => {
      if (response) {
        this.succ_msg = response['msg'];
        this.blankErrorMsgs();
        setTimeout(() => {
          this.succ_msg = false;
          jQuery("#customFieldsModal").modal("hide");
        }, this.data.timeOut);
        this.getcustomFieldList();
      } else {
        this.succ_msg = '';
      }

    },
    error => {

      if (error.error.customField_name) {
        this.err_customField_name = error.error.customField_name;
      } else {
        this.err_customField_name = '';
      }

      if (error.error.customField_type) {
        this.err_customField_type = error.error.customField_type;
      } else {
        this.err_customField_type = '';
      }

    });

  } */
  clickCancel() {
    this.blankErrorMsgs();
  }

  blankErrorMsgs() {
    this.err_customField_name = this.err_customField_type = "";
  }
}
