import { Component, OnInit, Input, OnChanges } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-schedule-call",
  templateUrl: "./schedule-call.component.html",
  styleUrls: ["./schedule-call.component.scss"],
})
export class ScheduleCallComponent implements OnInit {
  addUpdateSchedule: FormGroup;
  assigneeList: any;
  lId: any;
  leadData: any;
  lead_id: any;
  succ_msg: any;
  err_schedule_subject: string;
  err_schedule_start_date: string;
  err_schedule_start_time: string;
  err_schedule_end_date: string;
  err_schedule_end_time: string;
  err_schedule_note: string;
  err_schedule_attend_by: string;
  err_schedule_attend_id: string;
  err_schedule_priority: string;
  listDetails: any = "";

  err_less_start_date: String;
  err_less_end_date: String;
  err_end_date: String;
  err_start_date: String;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params) {
        if (params["lId"]) {
          this.lId = +params["lId"];
          this.data.getLeadData(this.lId).subscribe((res) => {
            if (res) {
              this.leadData = res;
              this.lead_id = res["lead_id"];
              this.addUpdateSchedule.controls["schedule_lead"].setValue(
                this.lead_id
              );
              this.addUpdateSchedule.controls["schedule_start_date"].setValue(
                this.leadData.meeting_date
              );
              this.addUpdateSchedule.controls["schedule_start_time"].setValue(
                this.leadData.meeting_time
              );
            }
          });
        }
      }
    });

    this.scheduleFormInit();
    this.editLeadSchedule();
  }

  editLeadSchedule() {
    this.data.getScheduleDetByLeadId(this.lId).subscribe((data) => {
      if (data) {
        this.listDetails = data;
        this.addUpdateSchedule.patchValue({
          s_call_id: this.listDetails.s_call_id,
          schedule_subject: this.listDetails.schedule_subject,
          schedule_note: this.listDetails.schedule_note,
          schedule_start_date: this.listDetails.schedule_start_date,
          schedule_start_time: this.listDetails.schedule_start_time,
          schedule_end_date: this.listDetails.schedule_end_date,
          schedule_end_time: this.listDetails.schedule_end_time,
          schedule_attend_by: this.listDetails.schedule_attend_by,
          schedule_attend_id: this.listDetails.schedule_attend_id,
          schedule_priority: this.listDetails.schedule_priority,
        });
        this.onChange(this.listDetails.schedule_attend_by);
      } else {
        this.scheduleFormInit();
      }
    });
  }

  onChange(value) {
    if (value !== "") {
      this.data.getTaskAssignee(value).subscribe((data) => {
        this.assigneeList = data;
      });
    } else {
      this.assigneeList = [];
    }
  }

  addSchedule(postData) {
    let formdata = new FormData();

    if (postData.schedule_start_date !== "") {
      const startDate = new DatePipe("en-US").transform(
        postData.schedule_start_date,
        "yyyy-MM-dd"
      );
      formdata.append("schedule_start_date", startDate);
    } else {
      formdata.append("schedule_start_date", postData.schedule_start_date);
    }

    if (postData.schedule_end_date !== "") {
      const endDate = new DatePipe("en-US").transform(
        postData.schedule_end_date,
        "yyyy-MM-dd"
      );
      formdata.append("schedule_end_date", endDate);
    } else {
      formdata.append("schedule_end_date", postData.schedule_end_date);
    }

    formdata.append("schedule_subject", postData.schedule_subject);
    formdata.append("schedule_start_time", postData.schedule_start_time);
    formdata.append("schedule_end_time", postData.schedule_end_time);
    formdata.append("schedule_note", postData.schedule_note);
    formdata.append("schedule_attend_by", postData.schedule_attend_by);
    formdata.append("schedule_attend_id", postData.schedule_attend_id);
    formdata.append("schedule_priority", postData.schedule_priority);
    formdata.append("schedule_lead", postData.schedule_lead);
    formdata.append("lead_id", this.lead_id);
    formdata.append("s_call_id", postData.s_call_id);

    this.data.addSchedule(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrorMsgs();
          this.scheduleFormInit();
          setTimeout(() => {
            this.succ_msg = false;
            this.router.navigate([
              "/leads/leads-home/agreement-signed/",
              this.lead_id,
            ]);
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.schedule_subject) {
          this.err_schedule_subject = error.error.schedule_subject;
        } else {
          this.err_schedule_subject = "";
        }

        if (error.error.schedule_start_date) {
          this.err_schedule_start_date = error.error.schedule_start_date;
        } else {
          this.err_schedule_start_date = "";
        }

        if (error.error.schedule_start_time) {
          this.err_schedule_start_time = error.error.schedule_start_time;
        } else {
          this.err_schedule_start_time = "";
        }

        if (error.error.schedule_end_date) {
          this.err_schedule_end_date = error.error.schedule_end_date;
        } else {
          this.err_schedule_end_date = "";
        }

        if (error.error.schedule_end_time) {
          this.err_schedule_end_time = error.error.schedule_end_time;
        } else {
          this.err_schedule_end_time = "";
        }

        if (error.error.schedule_note) {
          this.err_schedule_note = error.error.schedule_note;
        } else {
          this.err_schedule_note = "";
        }

        if (error.error.schedule_attend_by) {
          this.err_schedule_attend_by = error.error.schedule_attend_by;
        } else {
          this.err_schedule_attend_by = "";
        }

        if (error.error.schedule_attend_id) {
          this.err_schedule_attend_id = error.error.schedule_attend_id;
        } else {
          this.err_schedule_attend_id = "";
        }

        if (error.error.schedule_priority) {
          this.err_schedule_priority = error.error.schedule_priority;
        } else {
          this.err_schedule_priority = "";
        }

        if (error.error.err_start_date) {
          this.err_start_date = error.error.err_start_date;
        } else {
          this.err_start_date = "";
        }

        if (error.error.err_end_date) {
          this.err_end_date = error.error.err_end_date;
        } else {
          this.err_end_date = "";
        }

        if (error.error.err_less_end_date) {
          this.err_less_end_date = error.error.err_less_end_date;
        } else {
          this.err_less_end_date = "";
        }

        if (error.error.err_less_start_date) {
          this.err_less_start_date = error.error.err_less_start_date;
        } else {
          this.err_less_start_date = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_schedule_subject =
      this.err_schedule_start_date =
      this.err_schedule_start_time =
      this.err_schedule_end_date =
      this.err_schedule_end_time =
      this.err_schedule_note =
      this.err_schedule_attend_by =
      this.err_schedule_attend_id =
      this.err_schedule_priority =
      this.err_less_start_date =
      this.err_less_end_date =
      this.err_end_date =
      this.err_start_date =
        "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    this.scheduleFormInit();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/leads/leads-home/assign-task/", this.lead_id]);
  }

  scheduleFormInit() {
    this.addUpdateSchedule = this.fb.group({
      schedule_subject: new FormControl(""),
      schedule_start_date: new FormControl(""),
      schedule_start_time: new FormControl(""),
      schedule_end_date: new FormControl(""),
      schedule_end_time: new FormControl(""),
      schedule_note: new FormControl(""),
      schedule_attend_by: new FormControl(""),
      schedule_attend_id: new FormControl(""),
      schedule_priority: new FormControl(""),
      schedule_lead: new FormControl(""),
      s_call_id: new FormControl(""),
    });
  }
}
