import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
declare var jQuery: any;

@Component({
  selector: "app-view-contact",
  templateUrl: "./view-contact.component.html",
  styleUrls: ["./view-contact.component.scss"],
})
export class ViewContactComponent implements OnInit, OnChanges {
  @Input() personDetailsId: number;
  @Input() orgDetailsId: number;
  zero: number = 0;
  personDetails: any = [];
  orgDetails: any = [];
  personViewtemp: boolean = false;
  orgViewtemp: boolean = false;
  countryId: string;
  countryName: any;
  clientNumber: any;
  officeCountryId: string;
  officeCountryName: any;
  details: any = [];
  /* p: number = 0; itemsPerPage: number = 5; */
  listParam: any = {};
  matterList: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  contactParam: any = {};
  allList: any = [];
  constructor(
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}
  ngOnChanges() {
    if (this.orgDetailsId > 0) {
      this.orgViewtemp = true;
      this.getOrgDetails();
      this.getClientDetails("org");
    } else if (this.personDetailsId > 0) {
      this.personViewtemp = true;
      this.getPersonDetails();
      this.getClientDetails("person");
    }
  }

  //paginationFeed(page) {
  /* this.p = page;
		let start = (page - 1) * this.itemsPerPage;
	  	this.ngOnChanges(); */
  // }

  getClientDetails(type) {
    if (type == "org") {
      var id = this.orgDetailsId;
    } else {
      var id = this.personDetailsId;
    }
    this.data.getClientDetails(id, type).subscribe((data) => {
      this.details = data;
    });
  }

  closeWindow() {
    jQuery("#contactsViewModal").modal("hide");
  }

  getPersonDetails() {
    this.data
      .getContactDetails(this.personDetailsId, "PERS")
      .subscribe(async (data) => {
        this.personDetails = data;
        this.countryId = this.personDetails.country;
        this.officeCountryId = this.personDetails.country2;

        if (this.countryId) {
          await this.data.getCountryName(this.countryId).subscribe((data) => {
            this.countryName = data;
          });
        } else {
          this.countryName = "";
        }

        if (this.officeCountryId) {
          await this.data.getCountryName(this.officeCountryId).subscribe((data2) => {
            this.officeCountryName = data2;
          });
        } else {
          this.officeCountryName = "";
        }
      });
  }

  getOrgDetails() {
    this.data.getContactDetails(this.orgDetailsId, "ORG").subscribe(async (data) => {
      this.orgDetails = data;
      this.clientNumber = this.orgDetails.client_data.client_number;
      this.orgDetails.org_name = this.orgDetails.org_name.replace(
        /&amp;/g,
        "&"
      );
      if (this.orgDetails.country) {
        await this.data.getCountryName(this.orgDetails.country).subscribe((data) => {
          this.countryName = data;
          
        });
      } else {
        this.countryName = "";
      }
     
      if (this.orgDetails.country2) {
        await this.data
          .getCountryName(this.orgDetails.country2)
          .subscribe((data2) => {
            this.officeCountryName = data2;
          });
      } else {
        this.officeCountryName = "";
      }
    });
  }

  getMatterList() {
    this.data.getMatterList(this.listParam).subscribe((data) => {
      this.matterList = data;
    });
  }

  createMatters(id) {
    $("document").ready(function () {
      jQuery('#createMatterModal').modal('show')
    });
    this.router.navigateByUrl('/matters', { state: { addCreate: 'popup' } });
    jQuery("#contactsViewModal").modal("hide");
  }

  clickMatters(id) {
    this.router.navigateByUrl("/matters", { state: { clientMatters: "yes", clientNumber: id } });
  }

  viewAllMatters(id) {
    this.router.navigateByUrl("/matters", { state: { clientID: id } });
    //jQuery("#contactsViewModal").modal("hide");
  }

  dashboardRedirect(id) {
    //jQuery("#contactsViewModal").modal("hide");
    //this.router.navigate(["/matters/matter-home/matter-notes/" + id]);
    this.router.navigate([]).then(result => {  window.open("/#/matters/matter-home/matter-notes/" + id, '_blank'); });
  }

  clickCancel() {
    this.closeModal.emit();
    this.getAllList();
  }

  getAllList() {
    this.contactParam.contact_type = "";
    this.data.getAllListing(this.contactParam).subscribe((data) => {
      this.allList = data;
    });
  }
}
