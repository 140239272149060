import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LeadComponent } from './lead/lead.component';
import { CreateLeadComponent } from './lead/create-lead/create-lead.component';
import { ApplicationComponent } from './application/application.component';
import { UnpublishedtmComponent } from './unpublishedtm/unpublishedtm.component';
import {MatTooltipModule} from '@angular/material/tooltip';



@NgModule({
  declarations: [
    LeadComponent,
    CreateLeadComponent,
    ApplicationComponent,
    UnpublishedtmComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTooltipModule
  ]
})
export class ListsModule { }
