import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator } from '@angular/forms';
import { AuthenticationService } from '../../services/auth.service';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
declare var jQuery:any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  token: any;
  Username: string;
  changePasswordForm: FormGroup;
  msg: string;
  err_current_password: string;
  err_password: string;
  err_confirm_password: string;
  err_password_mismatch:string;

  constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router, public activatedRoute: ActivatedRoute, private googleAuthService: AuthService) {
    auth.getLoggedInName.subscribe((name: string) => this.changeName(name));
  }

  ngOnInit() {
	this.Username = localStorage.getItem('Username');

    this.changePasswordForm = this.fb.group({
      current_password: [''],
      password: [''],
      confirm_password: ['']
    });
  }

  changePassword(data) {
    let formdata = new FormData();

    formdata.append('old_password', data.current_password);
    formdata.append('new_password', data.password);
    formdata.append('confirm_password', data.confirm_password);
    formdata.append('user_id',  localStorage.getItem('UserId'));
    this.auth.changePassword(formdata).subscribe(
      response => {
        if (response) {
          this.msg = response['msg'];
          this.err_password_mismatch = ''
          this.err_current_password = '';
          this.err_confirm_password = '';
          this.err_password = '';
          this.changePasswordForm.reset();
          this.formInit();
        }else{
          this.msg = '';
        }
        window.setTimeout(() => {
          this.msg = '';
        }, 1500);
      },
      error => {
        if (error.error.old_password) {
          this.err_current_password = error.error.old_password;
        }else{
          this.err_current_password = '';
        }
        if (error.error.new_password) {
          this.err_password = error.error.new_password;
        }else{
          this.err_password = '';
        }
        if (error.error.confirm_password) {
          this.err_confirm_password = error.error.confirm_password;
        }else{
          this.err_confirm_password = '';
        }
        if(error.error.password_mismatch){
          this.err_password_mismatch = error.error.password_mismatch;
        }else{
          this.err_password_mismatch = '';
        }
      }

    );

  }

  private changeName(name: string): void {
    this.Username = name;
  }

  clickCancel() {
    this.err_current_password = '';
    this.err_password = '';
    this.err_confirm_password = '';
    this.err_password_mismatch = '';
    this. formInit();
  }

  // signOut(): void {
  //   this.googleAuthService.signOut();
  // }

  formInit() {
    this.changePasswordForm = new FormGroup({
      current_password: new FormControl(''),
      password: new FormControl(''),
      confirm_password: new FormControl('')
    })
   }

}
