import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-leads-home',
    templateUrl: './leads-home.component.html',
    styleUrls: ['./leads-home.component.scss']
  })
  export class LeadsHomeComponent implements OnInit {	
	lId:any;
	exampleParent:any;

	constructor() { }
	
  ngOnInit() {
	  let url = window.location.href;		
    this.lId = url.substr(url.lastIndexOf('/') + 1);  
  }

	
	 
	 

}
