import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-other-matter",
  templateUrl: "./other-matter.component.html",
  styleUrls: ["./other-matter.component.scss"],
})
export class OtherMatterComponent implements OnInit {
  @Input() issueDate: String;
  @Input() group: FormGroup;
  @Input() other_flag: boolean;
  @Input() assigneeList: any;
  @Input() mId: any;
  @Input() is_flag: boolean;
  other: boolean = true;
  subs: any = [];
  practice = [
    { id: 1, name: "Administrative" },
    { id: 2, name: "Bankruptcy" },
    { id: 3, name: "Builder's Liens" },
    { id: 4, name: "Business" },
    { id: 5, name: "Civil Litigation" },
    { id: 6, name: "Commercial" },
    { id: 7, name: "Conveyance (Purchase)" },
    { id: 8, name: "Conveyance (Sale)" },
    { id: 9, name: "Corporate" },
    { id: 10, name: "Criminal" },
    { id: 11, name: "Employment" },
    { id: 12, name: "Estates" },
    { id: 13, name: "Family" },
    { id: 14, name: "Immigration" },
    { id: 15, name: "Insurance" },
    { id: 16, name: "Personal Injury" },
    { id: 17, name: "Tax" },
    { id: 18, name: "Wills" },
  ];
  age: any;

  
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(){
    
  }

  getTaskAssigneeList(caseType) {
    if (this.subs["assignee"]) {
      this.subs["assignee"].unsubscribe();
    }
    this.subs["assignee"] = this.data
      .getTaskAssignee(caseType)
      .subscribe((data) => {
        this.assigneeList = data;
        for (let index = 0; index < this.assigneeList.length; index++) {
          this.assigneeList[index].name =
            this.assigneeList[index].first_name +
            " " +
            this.assigneeList[index].last_name;
        }
      });
  }

  ngOnChanges() {
    
    if(this.mId){
      this.data.dateDiff(this.mId).subscribe((response) => {
        this.age = response;
      });
    }
   

    if( this.data.typeOfCase && this.data.typeOfCase > 0 ) {
      this.getTaskAssigneeList(this.data.typeOfCase);
    }
    if (this.other_flag == true) {
      this.other = true;
    } else {
      this.other = false;
    }
  }
}
