import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DataService } from "../services/data.service";
import { ViewQuotesComponent } from "./view-quotes/view-quotes.component";
declare var jQuery: any;
@Component({
  selector: "app-quotes",
  templateUrl: "./quotes.component.html",
  styleUrls: ["./quotes.component.scss"],
})
export class QuotesComponent implements OnInit {
  quoteList: any = [];
  p: number = 0;
  itemsPerPage: number = 5;
  quoteParam: any = {};
  quoteId: number = 0;
  modalRef: BsModalRef;
  sortDir = 1; //1= 'ASE' -1= DSC
  constructor(
    private data: DataService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getAllQuotelist();
  }

  openModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({
        ignoreBackdropClick: true,
        keyboard: false,
        class: "gray modal-sm",
      })
    );
    this.quoteId = id;
  }

  hideModal() {
    this.modalRef.hide();
    this.getAllQuotelist();
  }

  getAllQuotelist() {
    this.data.getAllQuotelist(this.quoteParam).subscribe((data) => {
      if (data) {
        this.quoteList = data;
      }
    });
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.quoteList.pageNo = page - 1;
    this.getAllQuotelist();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.quoteParam.sortBy = i;
      if (this.quoteParam.sortOrder == "desc") {
        this.quoteParam.sortOrder = "asc";
      } else if (this.quoteParam.sortOrder == "asc") {
        this.quoteParam.sortOrder = "desc";
      } else {
        this.quoteParam.sortOrder = "asc";
      }
    }
    this.getAllQuotelist();
  }

  search(value) {
    var s_key = jQuery("#searchKey").val();

    this.quoteParam.searchString = value;
    this.getAllQuotelist();
  }

  //   viewQuote(id) {
  //     if(id != 0) {
  //     this.quoteId = id;

  //       jQuery('#viewQuote').on('hidden.bs.modal', (e)=> {
  //           this.getAllQuotelist();
  //       })
  //     }else {
  // 	  this.quoteId = 0;
  //     }
  //   }
}
