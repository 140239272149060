import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-dashboard',
  templateUrl: './reports-dashboard.component.html',
  styleUrls: ['./reports-dashboard.component.scss']
})
export class ReportsDashboardComponent implements OnInit {

  constructor() { }
  flag:number = 1;
  year:string ;
  ngOnInit() {
    this.year = '2020';
  }

  setFilter(year,param){
    this.flag = param;
    this.year = year;
    
  }

}
