import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-leads-engaged",
  templateUrl: "./leads-engaged.component.html",
  styleUrls: ["./leads-engaged.component.scss"],
})
export class LeadsEngagedComponent implements OnInit {
  matterQsList: any;
  lId: any;
  matterFormControlArr: any = [];
  createLeadForm: FormGroup;
  countryList: any;
  leadData: any;
  client_token_id: any;
  matter_type_id: any;
  matter_type_name: any;
  lead_id: any;
  selectedFiles: any;
  succ_msg: any;
  matterAns: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.leadFormInit();
    this.route.params.subscribe((params) => {
      if (params) {
        if (params["lId"]) {
          this.lId = +params["lId"];
          this.data.getLeadData(this.lId).subscribe((res) => {
            if (res) {
              this.leadData = res;

              this.matter_type_name = this.leadData.matter_type_name;
              this.matter_type_id = this.leadData.matter_type_id;
              this.client_token_id = this.leadData.client_token_id;
              this.lead_id = this.leadData.lead_id;
              this.createLeadForm.patchValue({
                lead_id: this.leadData.lead_id,
                client_token_id: this.leadData.client_token_id,
                matter_type_id: this.leadData.matter_type_id,
                matter_type_name: this.leadData.matter_type_name,
              });
            }
          });

          this.data
            .getMatterQsSetByMatterTypeId(this.lId)
            .subscribe((response) => {
              if (response) {
                this.matterQsList = response;
                var cList: any = [];
                var d = this.data;
                this.matterQsList.map((item) => {
                  this.matterFormControlArr.push(item.qs_field_control);
                  if (
                    item.qs_field_type == "Dropdown" ||
                    item.qs_field_type == "Radio"
                  ) {
                    d.getMatterListValues(
                      item.matter_type_id,
                      item.qs_field_type,
                      item.qs_field_control
                    ).subscribe((response) => {
                      if (response == "tbl_country") {
                        d.listNationality().subscribe((res) => {
                          this.countryList = res;
                          this.countryList.forEach(function (value) {
                            cList.push(value.nicename);
                          });
                          item["listValues"] = cList;
                        });
                      } else {
                        item["listValues"] = response;
                      }
                    });
                  }
                  if (item.matter_ans != "") {
                    this.createLeadForm.addControl(
                      item.qs_field_control,
                      new FormControl(item.matter_ans)
                    );
                  } else {
                    this.createLeadForm.addControl(
                      item.qs_field_control,
                      new FormControl("")
                    );
                  }
                });
              }
            });
        }
      }
    });
  }

  leadFormInit() {
    this.createLeadForm = this.fb.group({
      client_token_id: new FormControl(),
      matter_type_id: new FormControl(""),
      matter_type_name: new FormControl(""),
      lead_id: new FormControl(""),
    });
  }

  addLeadmatterAns(postData) {
    let formdata = new FormData();
    if (postData.matter_type_id != "") {
      for (let index = 0; index < this.matterFormControlArr.length; index++) {
        var cntrl = this.matterFormControlArr[index];
        var flag = cntrl.includes("Date");
        if (flag == true) {
          if (postData[cntrl] != "" && postData[cntrl] != null) {
            var cntrl_date = new DatePipe("en-US").transform(
              postData[cntrl],
              "yyyy-MM-dd"
            );
            formdata.append(cntrl, cntrl_date);
          } else {
            formdata.append(cntrl, "0000-00-00");
          }
        } else if (cntrl == "scanSupportDoc") {
          if (this.selectedFiles == undefined) {
          } else {
            formdata.append(cntrl, this.selectedFiles[0]);
          }
        } else {
          formdata.append(cntrl, postData[cntrl]);
        }
      }
    }
    const matterFormControlStr = JSON.stringify(this.matterFormControlArr);
    formdata.append("matterFormControlStr", matterFormControlStr);
    formdata.append("matter_add_from", "lead");
    formdata.append("client_token_id", postData.client_token_id);
    formdata.append("matter_type_id", postData.matter_type_id);
    formdata.append("matter_type_name", postData.matter_type_name);
    formdata.append("lead_id", postData.lead_id);

    this.data.addMatter(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.leadFormInit();
          this.removeDynamicControl();
          this.router.navigate(["leads/leads-home/assign-task/" + this.lId]);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        /* for (let index = 0; index < this.matterFormControlArr.length; index++) {
        var cntrl = this.matterFormControlArr[index];
        if (error.error.cntrl) {
          this.err_cntrl = error.error.cntrl;
        } else {
          this.err_cntrl = '';
        }
      } */
      }
    );
  }

  removeDynamicControl() {
    for (let ind = 0; ind < this.matterFormControlArr.length; ind++) {
      var cntrl = this.matterFormControlArr[ind];
      this.createLeadForm.removeControl(cntrl);
    }
  }

  onUploadMatterFile(event) {
    this.selectedFiles = event.target.files;
    let fileName = document.querySelector(".custom-file-label");
    fileName.innerHTML = this.selectedFiles[0].name;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
      };
    }
  }
}
