import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, FormControl, Validators, Validator } from '@angular/forms';
import { AuthenticationService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetpasswordForm: FormGroup;
  err_email: string;
  err_password: string;
  err_confirm_password: string;
  err_invalid_token: string;
  reset_pass_token;
  msg: string;
  constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.reset_pass_token = params['token'];
    });
  }

  ngOnInit() {
    $(document).ready(function () {
      $('body').addClass('login-page');
    });

    this.resetpasswordForm = this.fb.group({
      password: [''],
      confirm_password: ['']

    });

  }
  resetPassword(data) {

    let formdata = new FormData();
    formdata.append('old_password', data.current_password);
    formdata.append('new_password', data.password);
    formdata.append('confirm_password', data.confirm_password);
    formdata.append('user_id', localStorage.getItem('UserId'));
    formdata.append('token',this.reset_pass_token);
    this.auth.resetPassword(formdata).subscribe(
      response => {
        if (response) {
          this.msg = response['msg'];
          this.err_invalid_token = '';
          this.err_password = '';
          this.err_confirm_password = '';
        } else {
          this.msg = '';
        }

      },
      error => {

        if (error.error.new_password) {
          this.err_password = error.error.new_password;
        } else {
          this.err_password = '';
        }
        if (error.error.confirm_password) {
          this.err_confirm_password = error.error.confirm_password;
        } else {
          this.err_confirm_password = '';
        }
        if (error.error.invalid_token) {
          this.err_invalid_token = error.error.invalid_token;
        } else {
          this.err_invalid_token = '';
        }

      }

    );
  }

  ngOnDestroy() {
    $(document).ready(function () {
      $('body').removeClass('login-page');
    });
  }

}
