import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as $ from "jquery";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
} from "@angular/forms";
import { AuthenticationService } from "../services/auth.service";
import { Router } from "@angular/router";
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { CookieService } from "ngx-cookie-service";
import DeviceDetector from "device-detector-js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  err_email: string;
  err_password: string;
  msg: string;
  email: string;
  cookieUserEmail = "";
  cookieUserPassword = "";
  cookieRememberMe: string;
  theCheckbox: boolean;
  usertype;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    public router: Router,
    private googleAuthService: AuthService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.theCheckbox = false;
    $(document).ready(function () {
      $("body").addClass("login-page");
    });

    this.loginForm = this.fb.group({
      email: [""],
      password: [""],
      rememberMe: [""],
    });

    this.cookieRememberMe = this.cookieService.get("rememberMe");
    if (this.cookieRememberMe != "") {
      this.cookieUserEmail = this.cookieService.get("user_email");
      this.cookieUserPassword = this.cookieService.get("user_password");
      jQuery("#email").val(this.cookieUserEmail);
      jQuery("#password").val(this.cookieUserPassword);
      this.theCheckbox = true;
    }

    /*if (this.cookieUserEmail != '' && this.cookieUserPassword != '') {
      this.loginForm.patchValue({
        email: this.cookieUserEmail,
        password: this.cookieUserPassword
      })
      this.theCheckbox = true;
    } else {
      this.theCheckbox = false;
    }*/
  }

  login(data) {
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    if (data.rememberMe == true) {
      this.cookieService.set("user_email", data.email);
      this.cookieService.set("user_password", data.password);
      this.cookieService.set("rememberMe", data.rememberMe);
    } else {
      this.cookieService.deleteAll();
    }

    var browser = this.get_browser();
    let formdata = new FormData();
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    formdata.append("access_from", device.client.type); // browser or other
    formdata.append("browser", browser.name);
    formdata.append("browser_version", browser.version);
    formdata.append("device_type", device.device.type);
    formdata.append("os_name", device.os.name);
    formdata.append("os_platform", device.os.platform);
    this.auth.login(formdata).subscribe(
      (response) => {
        this.auth.setToken(response["token"]);
        if (response["token"]) {
          this.auth.getUser().subscribe((response) => {
            if (response) {
              response["name"] =
                response["first_name"] + " " + response["last_name"];
              this.auth.getLoggedInName.emit(response["name"]);
              localStorage.setItem("Username", response["name"]);
              localStorage.setItem("UserId", response["user_id"]);
              localStorage.setItem("UserType", response["user_type_id"]);
              this.usertype = localStorage.getItem("UserType");

              let frmdata = new FormData();
              frmdata.append("email", data.email);
              frmdata.append("password", data.password);
              let token = localStorage.getItem("token");
              frmdata.append("jwt_token", token);

              this.loginFilemaker(frmdata).then((value) => {});

              if (this.usertype == "1") {
                this.router.navigate(["/bnoperations"]);
              } else if (this.usertype == "3") {
                this.router.navigate(["/bnlawyers"]);
              } else {
                this.router.navigate(["/bnip"]);
              }

              /*this.auth.filemakerLogin(frmdata).subscribe(response2 => {

            });*/
            }
          });
        } else {
          if (response["msg"]) {
            this.msg = response["msg"];
            this.err_email = "";
            this.err_password = "";
          } else {
            this.msg = "";
          }
        }
      },
      (error) => {
        this.msg = "";
        if (error.error.email) {
          this.err_email = error.error.email;
        } else {
          this.err_email = "";
        }
        if (error.error.password) {
          this.err_password = error.error.password;
        } else {
          this.err_password = "";
        }
      }
    );
  }

  loginFilemaker(frmdata) {
    return new Promise((resolve) => {
      this.auth.filemakerLogin(frmdata).subscribe((response2) => {});
    });
  }

  signinWithGoogle() {
    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.googleAuthService
      .signIn(socialPlatformProvider)
      .then((userData) => {
        let formdata = new FormData();
        const deviceDetector = new DeviceDetector();
        const userAgent = window.navigator.userAgent;
        const device = deviceDetector.parse(userAgent);
        var browser = this.get_browser();
        formdata.append("browser", browser.name);
        formdata.append("browser_version", browser.version);
        formdata.append("access_from", device.client.type); // browser or other
        formdata.append("device_type", device.device.type);
        formdata.append("os_name", device.os.name);
        formdata.append("os_platform", device.os.platform);
        //  formdata.append('token', userData.authToken);
        formdata.append("token", userData.idToken);
        formdata.append("email", userData.email);
        formdata.append("name", userData.name);
        this.auth.login(formdata).subscribe((response) => {
          if (response["token"]) {
            this.auth.setToken(response["token"]);
            this.auth.getUser().subscribe((data) => {
              if (data) {
                data["name"] = data["first_name"] + " " + data["last_name"];
                this.auth.getLoggedInName.emit(data["name"]);
                localStorage.setItem("Username", data["name"]);
                localStorage.setItem("UserId", data["user_id"]);
                localStorage.setItem("UserType", response["user_type_id"]);
                this.usertype = localStorage.getItem("UserType");

                let frmdata = new FormData();
                frmdata.append("email", userData.email);
                frmdata.append("password", "lpms_dummy_google");

                this.loginFilemaker(frmdata).then((value) => {});

                if (this.usertype == "1") {
                  this.router.navigate(["/bnoperations"]);
                } else if (this.usertype == "3") {
                  this.router.navigate(["/bnlawyers"]);
                } else {
                  this.router.navigate(["/bnip"]);
                }

                /*this.auth.filemakerLogin(frmdata).subscribe(response => {

              });*/
              }
            });
            //this.router.navigate(['/contacts']);
          }
        });
      })
      .catch((err) => console.error(err));
  }

  get_browser() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  /*rememberUser(event: any) {

    if (this.theCheckbox == true) {
      this.cookieService.set('user_email', this.userEmail.value);
      this.cookieService.set('user_password', this.userPassword.value);
    } else {
      this.cookieService.deleteAll();
    }
    
  }*/

  get userEmail(): any {
    return this.loginForm.get("email");
  }

  get userPassword(): any {
    return this.loginForm.get("password");
  }
  ngOnDestroy() {
    $(document).ready(function () {
      $("body").removeClass("login-page");
    });
  }
}
