import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-custom-fields",
  templateUrl: "./custom-fields.component.html",
  styleUrls: ["./custom-fields.component.scss"],
})
export class CustomFieldsComponent implements OnInit {
  customFieldList: any = [];
  listParam: any = {};
  sort_order: any;
  p: number = 0;
  itemsPerPage: number = 10;
  customfieldId: number = 0;

  constructor(private data: DataService) {}

  ngOnInit() {
    //this.getcustomFieldList();
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.listParam.pageNo = page - 1;
    //this.getcustomFieldList();
  }
}
