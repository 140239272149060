import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { $ } from "protractor";
declare var jQuery: any;

@Component({
  selector: "app-edit-task",
  templateUrl: "./edit-task.component.html",
  styleUrls: ["./edit-task.component.scss"],
})
export class EditTaskComponent implements OnInit, OnChanges {
  addTaskForm: FormGroup;
  checked: any = true;
  succ_msg: any;
  tid: number = 0;
  assigneeList: any;
  clientList: any;
  taskListID: any;
  tasklist: any;
  matterList: any;
  err_task_name: string;
  err_description: string;
  err_due_date: string;
  err_assignee_type: string;
  err_assignee_id: string;
  err_client_id: string;
  err_matter_id: string;
  err_priority: string;
  err_task_status: string;
  err_due_date_msg: String;
  @Input() taskId: number;
  p: number = 0;
  FormHeading: string;
  listDetails: any;
  taskList: any = [];
  listParam: any = {};
  lastTaskId: any;
  planDetails: any;
  mId: any;
  check_val: any;

  @Output() valueChange = new EventEmitter();
  counter = 0;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    let chg = changes["taskId"];
    let cval = chg.currentValue;
    if (chg.currentValue > "0" && chg.currentValue == cval) {
      this.editTaskList();
      this.FormHeading = "Edit Task";
    } else {
      this.FormHeading = "Add Task";
      this.taskFormInit();
      this.getLastTaskId();
    }
  }

  ngOnInit() {
    this.getTaskList();
    this.taskFormInit();
    this.listClientList();
    this.getTaskListID();
    this.listMatterList("");
    this.getLastTaskId();
    this.FormHeading = "Add Task";

    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);
  }

  valueChanged() {
    // You can give any function name
    this.counter = this.counter + 1;
    this.valueChange.emit(this.counter);
  }

  // ngOnChanges(){
  // 	if (this.taskId > 0) {
  // 		this.editTaskList();
  // 		this.FormHeading ="Edit Task";
  // 	}
  // }

  getLastTaskId() {
    this.data.getLastTaskId().subscribe((data) => {
      this.lastTaskId = data;
      let numb = this.lastTaskId.toString().replace("T", "");
      let digit = Number(numb);
      if (digit > 0) {
        this.addTaskForm.controls["task_id"].setValue(this.lastTaskId);
      } else {
        this.addTaskForm.controls["task_id"].setValue("T1");
      }
    });
  }

  getTaskList() {
    this.data.getTaskList(this.listParam).subscribe((data) => {
      this.taskList = data;
    });
  }

  editTaskList() {
    this.data.getlistDetails(this.taskId).subscribe((data) => {
      this.listDetails = data;

      if (this.listDetails.done_status == "true") {
        this.listDetails.done_status = true;
      } else {
        this.listDetails.done_status = false;
      }

      this.addTaskForm.patchValue({
        t_id: this.listDetails.t_id,
        task_id: this.listDetails.task_id,
        task_name: this.listDetails.task_name,
        task_description: this.listDetails.description,
        task_due_date: this.listDetails.due_date,
        task_assignee: this.listDetails.assignee_type,
        task_assignee_user: this.listDetails.assignee_id,
        task_leads: this.listDetails.client_id,
        task_matter: this.listDetails.matter_id,
        task_priority: this.listDetails.priority,
        task_status: this.listDetails.task_status,
        done_status: this.listDetails.done_status,
      });
      this.onChange(this.listDetails.assignee_type);
    });
  }

  getTaskListID() {
    this.data.getTaskListID().subscribe((data) => {
      this.taskListID = data;
    });
  }

  onChange(value) {
    if (value !== "") {
      this.data.getTaskAssignee(value).subscribe((data) => {
        this.assigneeList = data;
      });
    } else {
      this.assigneeList = [];
    }
  }

  listClientList() {
    this.data.listClientList().subscribe((data) => {
      this.clientList = data;
    });
  }

  listMatterList(client_id) {
    this.data.allMatters(this.listParam).subscribe((data) => {
      this.matterList = data;
    });
  }
  checkCheckBoxvalue(event) {
    this.check_val = event.checked;
  }

  addTask(postData) {
    const dateSendingToServer = new DatePipe("en-US").transform(
      postData.task_due_date,
      "yyyy-MM-dd"
    );

    let formdata = new FormData();
    formdata.append("task_id", postData.task_id);
    formdata.append("task_name", postData.task_name);
    formdata.append("due_date", dateSendingToServer);
    formdata.append("description", postData.task_description);
    formdata.append("assignee_type", postData.task_assignee);
    formdata.append("assignee_id", postData.task_assignee_user);
    formdata.append("matter_id", postData.task_matter);
    formdata.append("client_id", postData.task_leads);
    formdata.append("priority", postData.task_priority);
    formdata.append("task_status", postData.task_status);
    formdata.append("add_from", "matter_dashboard");
    formdata.append("done_status", postData.done_status);

    if (postData.t_id) {
      formdata.append("t_id", postData.t_id);
    }

    this.data.addTaskpost(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.getTaskList();
          this.blankErrorMsgs();
          this.editTaskList();
          this.valueChanged();
          setTimeout(() => {
            this.succ_msg = false;
            jQuery("#editTask").modal("hide");
            this.getLastTaskId();
            //this.taskFormInit();
          }, this.data.timeOut);
          //this.router.navigate(['/contacts']);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.task_name) {
          this.err_task_name = error.error.task_name;
        } else {
          this.err_task_name = "";
        }

        if (error.error.description) {
          this.err_description = error.error.description;
        } else {
          this.err_description = "";
        }

        if (error.error.err_due_date) {
          this.err_due_date_msg = error.error.err_due_date_msg;
          this.err_due_date = "";
        } else {
          this.err_due_date_msg = "";
        }

        if (error.error.due_date) {
          this.err_due_date = error.error.due_date;
          this.err_due_date_msg = "";
        } else {
          this.err_due_date = "";
        }

        if (error.error.assignee_type) {
          this.err_assignee_type = error.error.assignee_type;
        } else {
          this.err_assignee_type = "";
        }

        if (error.error.assignee_id) {
          this.err_assignee_id = error.error.assignee_id;
        } else {
          this.err_assignee_id = "";
        }

        if (error.error.client_id) {
          this.err_client_id = error.error.client_id;
        } else {
          this.err_client_id = "";
        }

        if (error.error.matter_id) {
          this.err_matter_id = error.error.matter_id;
        } else {
          this.err_matter_id = "";
        }

        if (error.error.priority) {
          this.err_priority = error.error.priority;
        } else {
          this.err_priority = "";
        }

        if (error.error.task_status) {
          this.err_task_status = error.error.task_status;
        } else {
          this.err_task_status = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_task_name =
      this.err_description =
      this.err_due_date =
      this.err_assignee_type =
      this.err_assignee_id =
      this.err_client_id =
      this.err_matter_id =
      this.err_priority =
      this.err_task_status =
        "";
    this.err_due_date_msg = "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    if (this.taskId == 0) {
      this.taskFormInit();
      this.getLastTaskId();
    }
  }

  taskFormInit() {
    this.addTaskForm = this.fb.group({
      t_id: new FormControl(""),
      task_id: new FormControl(""),
      task_name: new FormControl(""),
      task_due_date: new FormControl(""),
      task_description: new FormControl(""),
      task_assignee: new FormControl(""),
      task_assignee_user: new FormControl(""),
      task_priority: new FormControl(""),
      task_leads: new FormControl(""),
      task_matter: new FormControl(""),
      task_status: new FormControl(""),
      done_status: new FormControl(""),
    });
  }
}
