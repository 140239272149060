import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Renderer2,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AbstractControl } from "@angular/forms";
import { ConfirmationDialogServiceService } from "../confirmation-dialog/confirmation-dialog-service.service";
import "rxjs/add/operator/filter";
declare var jQuery: any;
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { AuthenticationService } from "../services/auth.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-emails",
  templateUrl: "./emails.component.html",
  styleUrls: ["./emails.component.scss"],
  providers: [
    /* { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS } , */ DatePipe,
  ],
})
export class EmailsComponent implements OnInit, OnDestroy {
  emailParam: any = {};
  p: number = 0;
  itemsPerPage: number = 100;
  pstart: number = 0;
  pend: number = 100;

  emailList: any = [];
  allEmailrecords: any = [];
  templateList: any = [];
  mailDet: any = [];

  clientList: any = [];
  matterList: any;
  assigneeList: any;
  listParam: any = {};
  taskList: any;
  emailAssociateForm: FormGroup;
  emailSearchForm: FormGroup;
  composeForm: FormGroup;
  succ_msg: any;
  taskHtmlFlag: boolean = false;

  err_task_name: String = "";
  err_description: String = "";
  err_due_date: String = "";
  err_assignee_id: String = "";
  err_client_id: String = "";
  err_matter_id: String = "";
  err_priority: String = "";
  err_due_date_msg: String = "";
  err_genius: String = "";
  err_email_to: String;
  err_email_cc: String = "";
  err_email_bcc: String = "";
  err_template_name: String;
  err_visible_for: String;
  lastTaskId: any;
  listDetails: any;
  mId: any;
  allActive: boolean = false;
  myEmailActive: boolean = false;
  matterEmailActive: boolean = false;
  unAssignedActive: boolean = false;
  matterDraftEmailActive: boolean = false;
  matterSentEmailActive: boolean = false;
  parDiv: any;
  selectedFiles: any;
  templateData: any;
  attachmentList: any = "";
  basenameList: any = "";
  assigneeType: String = "";
  // For calendar-event
  totalHours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  totalMins = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  newArr: any = [];
  hourNow = 0;
  minuteNow = 0;
  eventDataArr: any = [];
  meet_time = 0;
  res: any;
  err_translate: String = "";
  auditList: any = [];

  //ifCompose: boolean = true;
  ifReply: boolean = false;
  ifForward: boolean = false;
  ifBcc: boolean = false;
  ifCc: boolean = false;
  replyDropdown: boolean = false;
  isTranslated: boolean = false;
  isTranslateDrop: boolean = false;
  ifEmailTracking: boolean = false;
  checkFlag: number = 0;
  //ifDisable: boolean = true;
  mailSendTime;
  calenderParam: any = {};
  curViewDate: any;
  curDate: any;
  eventData: any;
  currDate: any;
  curTime: any;
  eventType: any;
  timeInterval: any;
  eventLocation: any;
  files: string[] = [];
  names: string[] = [];
  templateId: any;
  attachfiles: any = [];
  htmlContent = "";
  autoDraft: boolean = false;
  hid_m_id: any = "";
  interval: any = "";
  translateSrcLang: any = "";
  translateDestiLang: any = "";
  eventDate;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "200px",
    maxHeight: "200px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };
  modalRef;
  modalOption: NgbModalOptions = {};
  eventForm: FormGroup;
  taskEventForm: FormGroup;
  paticipantList: any;
  geniusList: any;
  emailCount: any;
  testmaildtl: any;
  time_flag: boolean = false;
  participants = (term) => ({ email: term });
  submitted: boolean = false;
  contactParam: any = {};
  starttime: any = [];
  endtime: any = [];
  location: any = [];
  loginuserdtl: any = [];
  searchDropdown: boolean = false;
  err_title: string;
  err_location: string;
  err_notes: string;
  err_participants: string;
  err_alert: string;
  err_start_date: string;
  err_end_date: string;
  err_start_time: string;
  err_end_time: string;
  err_task_title: string;
  err_task_note: string;
  err_alrt_val: string;
  err_calendar: string;
  tashFormDisplay: boolean = false;
  eventFormDisplay: boolean = true;
  viewDate: Date = new Date();
  minDate: Date;
  searchText: string;
  checkReply: boolean = false;
  checkReplyAll: boolean = false;
  checkDraft: boolean = false;
  replyCurrentView: any;
  userEmailPath: any;
  getCheckTxt: any;
  customAlert: boolean = false;
  translatedContent: any = "";
  translatedSubject: any = "";

  isMultipleToAdd: boolean = false;

  //Subscription
  emailListSubs: any;

  editorApiKey: any;

  subs: any = [];
  matter_mail_id: any;

  @ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;
  @ViewChild("clientsModal", { static: true }) clientsModal: TemplateRef<any>;
  calendarList: any = [];
  public removeEventListener: () => void;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private data: DataService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogServiceService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    let url = this.router.url.split("/");
    this.matter_mail_id = url[2];
    if (this.matter_mail_id > 0) {
      this.getContent(this.matter_mail_id, "yes");
    }

    //this.outletID = url[url.length - 1];

    if (this.subs["emails"]) {
      this.subs["emails"].unsubscribe();
      this.subs["tasks"].unsubscribe();
    }

    this.editorApiKey = this.data.getEditorApiKey();

    jQuery(function () {
      jQuery(document).on("click", ".dropdown-menu", function (e) {
        e.stopPropagation(); // This replace if conditional.
      });
    });
    this.getCheckTxt = "Track Email Opening";

    this.userEmailPath =
      `${environment.lpmsUrl}` + "documents/comp_cert/userEmail/";

    this.subs["emails"] = this.router.events
      .filter((event) => event instanceof NavigationStart)
      .subscribe((event: NavigationStart) => {
        let curl = event.url;
        if (curl != "/emails") {
          this.draft();
        }
      });
    this.minDate = new Date();

    /*let url = window.location.href;
    this.templateId = url.substr(url.lastIndexOf("/") + 1);

    if (this.templateId != "" && !isNaN(this.templateId)) {
      //this.ifCompose = !this.ifCompose;
      this.testmaildtl = this.onChangeTemplate(this.templateId);
    }

    this.subs["tasks"] =  this.activatedRoute.queryParams.subscribe((params) => {
      this.templateId = params["task"];
      if (this.templateId != "" && !isNaN(this.templateId)) {
        //this.ifCompose = !this.ifCompose;
        this.testmaildtl = this.processTemplate(this.templateId);
      }
    });*/

    this.currDate = new Date();
    let currDateDB = new DatePipe("en-US").transform(
      Date.now().toString(),
      "yyyy-MM-dd"
    );

    this.loadEventData(currDateDB);

    this.listEmail();
    this.listClientList();
    //this.listMatterList();
    //this.listGenius();
    this.getTaskList();
    this.emailFormInit();
    this.emailSearchFormInit();
    this.composeFormInit();
    this.getLastTaskId();
    this.getAllTemplate();
    this.getAllGoogleCalender();
    this.allActive = true;

    this.curViewDate = new DatePipe("en-US").transform(
      Date.now(),
      "EE, dd/MM/yyyy"
    );
    this.replyCurrentView = new DatePipe("en-US").transform(
      Date.now(),
      "EE, dd/MM/yyyy h:mm a"
    );
    this.curDate = new DatePipe("en-US").transform(Date.now(), "yyyy-MM-dd");
    this.currDate = new Date();

    this.getLocation();
    this.eventFormInIt();
    this.taskEventFormInit();
    this.listParticipent();
    this.listClientList();
    //this.listMatterList();
    //this.listGenius();
    this.listTask();
    this.populateStartTime();
    this.populateEndTime();
    this.getCurrentUserDtl();

    // To enable the link/unlink pop-up in TinyMCE
    jQuery(document).on("focusin", function (e) {
      if (jQuery(e.target).closest(".tox-dialog").length) {
        e.stopImmediatePropagation();
      }
    });
  }

  ngOnDestroy() {
    // Cleanup by removing the event listener on destroy
    //this.removeEventListener();
    if (this.subs["emailList"]) {
      this.subs["emailList"].unsubscribe();
    }
  }

  composeFormInit() {
    this.composeForm = this.fb.group({
      email_from: new FormControl(""),
      email_to: new FormControl(""),
      email_cc: new FormControl(""),
      email_bcc: new FormControl(""),
      email_subject: new FormControl(""),
      email_body: new FormControl(""),
      hid_mail_id: new FormControl(""),
      email_trans_body: new FormControl(""),
      email_trans_subject: new FormControl(""),
      tracking_flag: new FormControl(""),
      template_name: new FormControl(""),
      visible_for: new FormControl(""),
    });
  }
  getAssociateGenius(event) {
    return new Promise((resolve, reject) => {
      this.data.getAssociateGenius(event.matter_id).subscribe((data) => {
        this.assigneeList = data;
      });
      resolve(null);
    });
  }

  nextPrevDate(dtObj, npDay) {
    let dte = this.currDate;
    if (dtObj) {
      dte = dtObj;
    }
    if (npDay === "N") {
      dte.setDate(dte.getDate() + 1);
    } else if (npDay === "P") {
      dte.setDate(dte.getDate() - 1);
    }
    //this.curViewDate = new DatePipe('en-US').transform(dte.toString(), 'EE d, y');
    this.curViewDate = new DatePipe("en-US").transform(
      dte.toString(),
      "EE, dd/MM/yyyy"
    );
    this.currDate = dte;
    let currDateDB = new DatePipe("en-US").transform(
      dte.toString(),
      "yyyy-MM-dd"
    );
    this.loadEventData(currDateDB);
  }

  loadEventData(currDateDB) {
    if (this.subs["eventDtl"]) {
      this.subs["eventDtl"].unsubscribe();
    }

    this.subs["eventDtl"] = this.data
      .geteventByDate(currDateDB)
      .subscribe((data) => {
        this.eventDataArr = data;
        let todayDate = new Date();
        for (let index = 0; index < this.eventDataArr.length; index++) {
          let stDate = new Date(
            this.eventDataArr[index].start_date +
              " " +
              this.eventDataArr[index].start_time
          );
          if (stDate > todayDate) {
            this.eventType = this.eventDataArr[index].event_type;
            // new DatePipe('en-US').transform(this.eventDataArr[index].start_time, 'h:mm a');
            let start_time = new DatePipe("en-US").transform(
              this.eventDataArr[index].start_date_time,
              "h:mm a"
            );
            let end_time = new DatePipe("en-US").transform(
              this.eventDataArr[index].end_date_time,
              "h:mm a"
            );
            this.timeInterval = start_time + "- " + end_time;
            this.eventLocation = this.eventDataArr[index].event_location;
          }
        }

        for (let hour in this.totalHours) {
          this.newArr[hour] = [
            { id: 0, duration: 0, attend: 0 },
            { id: 1, duration: 0, attend: 0 },
            { id: 2, duration: 0, attend: 0 },
            { id: 3, duration: 0, attend: 0 },
            { id: 4, duration: 0, attend: 0 },
            { id: 5, duration: 0, attend: 0 },
            { id: 6, duration: 0, attend: 0 },
            { id: 7, duration: 0, attend: 0 },
            { id: 8, duration: 0, attend: 0 },
            { id: 9, duration: 0, attend: 0 },
            { id: 10, duration: 0, attend: 0 },
            { id: 11, duration: 0, attend: 0 },
          ];
        }
        this.eventDataArr.forEach((event) => {
          let fromTime = new Date(event.start_date + " " + event.start_time);
          let toTime = new Date(event.end_date + " " + event.end_time);
          let currTime = new Date();
          let differenceTravel = toTime.getTime() - fromTime.getTime();
          this.meet_time = Math.floor(differenceTravel / (1000 * 60));
          let attendedTime;

          if (currTime.getTime() > toTime.getTime()) {
            attendedTime = 100;
          } else if (currTime.getTime() < fromTime.getTime()) {
            attendedTime = 0;
          } else {
            let attendedTimeDiff = currTime.getTime() - fromTime.getTime();
            attendedTime =
              (Math.floor(attendedTimeDiff / (1000 * 60)) / this.meet_time) *
              100;
          }

          let st = event.start_time;
          let st_exp = st.split(":");
          this.hourNow = Number(st_exp[0]);
          this.minuteNow = Number(st_exp[1]);

          for (let hour in this.totalHours) {
            if (this.totalHours[hour] == this.hourNow) {
              for (let min in this.totalMins) {
                let mins = Number(this.totalMins[min]) * 5;
                if (mins === this.minuteNow) {
                  this.newArr[hour][min]["duration"] = Math.floor(
                    (this.meet_time / 5) * 6.5
                  );
                  this.newArr[hour][min]["attend"] = attendedTime;
                }
              }
            }
          }
        });
      });
  }

  getCurrentUserDtl() {
    if (this.subs["user"]) {
      this.subs["user"].unsubscribe();
    }

    this.subs["user"] = this.auth.getUser().subscribe((response) => {
      if (response) {
        this.loginuserdtl = response;
      }
    });
  }

  rplyCancel() {
    if (
      (this.checkDraft =
        true && this.composeForm.controls.hid_mail_id.value != "")
    ) {
      this.saveDraft(this.composeForm.value, "draft");
    }
    //this.ifReply = !this.ifReply;
    //this.isTranslated = !this.ifReply;

    this.ifReply = this.ifReply === true ? false : false;
    this.ifForward = this.ifForward === true ? false : false;

    this.checkReply = false;
    this.checkReplyAll = false;
    this.checkDraft = false;
    this.composeFormInit();
  }

  isReply(mail_id, is_replyall) {
    this.modalRef.close();
    this.files = [];
    this.names = [];
    this.checkReply = is_replyall == true ? false : true;
    this.checkReplyAll = is_replyall;
    this.ifReply = !this.ifReply;
    this.ifEmailTracking = false;
    this.ifForward = false;
    this.checkDraft = false;
    this.getContent(mail_id, "no");
    this.isTranslateDrop = false;
    this.composeForm.patchValue({
      email_to: this.mailDet.mail_from_email,
    });
    if (this.ifReply) {
      this.isTranslated = false;
    } else {
      this.isTranslated = true;
    }
    let cc_list = "";

    // Add multiple TO emails to cc list
    if (this.isMultipleToAdd != false) {
      this.mailDet.cc_list =
        this.mailDet.cc_list != ""
          ? this.mailDet.cc_list + "," + this.mailDet.mail_to_email
          : this.mailDet.mail_to_email;
    }

    if (this.data.userTypeId == 3) {
      this.ifCc = !this.ifCc;
      cc_list =
        (this.mailDet.cc_list != "" && is_replyall == true
          ? this.mailDet.cc_list + ","
          : "") +
        (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
        environment.opsCCEmail;
      cc_list = cc_list
        .split(",")
        .filter((x, i, a) => a.indexOf(x) == i)
        .join(",");
    } else if (this.data.userTypeId == 4) {
      this.ifCc = !this.ifCc;
      cc_list =
        (this.mailDet.cc_list != "" && is_replyall == true
          ? this.mailDet.cc_list + ","
          : "") +
        (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
        environment.ipCCEmail;
      cc_list = cc_list
        .split(",")
        .filter((x, i, a) => a.indexOf(x) == i)
        .join(",");
    } else {
      this.ifCc = !this.ifCc;
      cc_list =
        (this.mailDet.cc_list != "" && is_replyall == true
          ? this.mailDet.cc_list + ","
          : "") + "bnunes@bnlawmacau.com";
      cc_list = cc_list
        .split(",")
        .filter((x, i, a) => a.indexOf(x) == i)
        .join(",");
    }
    this.composeForm.patchValue({
      email_cc: cc_list,
    });
  }

  forward(mail_id) {
    if (this.subs["emailDtl"]) {
      this.subs["emailDtl"].unsubscribe();
    }
    this.ifCc = true;
    this.attachfiles = [];
    this.ifForward = !this.ifForward;
    this.ifReply = false;
    this.isTranslated = !this.isTranslated;
    this.replyDropdown = false;

    if (this.ifForward) {
      this.isTranslated = false;
    } else {
      this.isTranslated = true;
    }

    this.subs["emailDtl"] = this.data
      .emailDescById(mail_id, "no")
      .subscribe((data) => {
        this.mailDet = data;
        this.attachmentList = this.mailDet.attachment_name_list;
        if (this.mailDet.attachment_name_list != "") {
          var flag = this.attachmentList.includes(",");
          if (flag == true) {
            this.basenameList = this.mailDet.disp_file_name.split(",");
            let attachfiles = this.attachmentList.split(",");

            this.attachfiles = attachfiles.map((id, index) => {
              return {
                filesurl: attachfiles[index],
                basename: this.basenameList[index],
                filesurlenc: encodeURIComponent(attachfiles[index]),
                basenameenc: encodeURIComponent(this.basenameList[index]),
              };
            });
          } else {
            this.attachfiles.push(this.attachmentList);
          }
        }
      });

    if (this.data.userTypeId == 3) {
      this.composeForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.opsCCEmail,
      });
    } else if (this.data.userTypeId == 4) {
      this.composeForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.ipCCEmail,
      });
    } else {
      this.composeForm.patchValue({
        email_cc: "bnunes@bnlawmacau.com",
      });
    }

    this.composeForm.patchValue({
      email_subject: this.mailDet.subject,
      email_body: this.mailDet.body,
      email_attachment: this.attachfiles,
    });
  }

  isDraftEmail(mail_id) {
    this.ifReply = false;
    this.ifForward = false;
    this.ifEmailTracking = false;
    this.checkDraft = true;
    this.getContent(mail_id, "no");
    this.isTranslateDrop = false;
    if (this.ifReply) {
      this.isTranslated = false;
    } else {
      this.isTranslated = true;
    }

    if (this.data.userTypeId == 3) {
      this.ifCc = !this.ifCc;
      this.composeForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.opsCCEmail,
      });
    } else if (this.data.userTypeId == 4) {
      this.ifCc = !this.ifCc;
      this.composeForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.ipCCEmail,
      });
    } else {
      this.ifCc = !this.ifCc;
      this.composeForm.patchValue({
        email_cc: "bnunes@bnlawmacau.com",
      });
    }
  }

  isTracking(mail_id) {
    if (this.subs["emailAudit"]) {
      this.subs["emailAudit"].unsubscribe();
    }

    this.ifEmailTracking = !this.ifEmailTracking;
    this.ifReply = false;
    this.checkReply = true;
    //this.getContent(mail_id, 'no');
    this.subs["emailAudit"] = this.data
      .getEmailAudit(mail_id)
      .subscribe((res) => {
        this.auditList = res;
        for (let ind = 0; ind < this.auditList.length; ind++) {
          if (
            this.auditList[ind].post_date != "" &&
            this.auditList[ind].post_date != null
          ) {
            var dateSendingToServer1 = new DatePipe("en-US").transform(
              this.auditList[ind].post_date,
              "dd/MM/yyyy hh:MM a"
            );
          } else {
            var dateSendingToServer1 = "";
          }
          this.auditList[ind].audit_date = dateSendingToServer1;
        }
      });
    this.isTranslateDrop = false;
    if (this.ifEmailTracking) {
      this.isTranslated = false;
    } else {
      this.isTranslated = true;
    }
  }

  replyAll(mail_id) {
    this.checkReply = false;
    this.checkReplyAll = true;
    this.checkDraft = false;
    this.getContent(mail_id, "no");
    this.isTranslateDrop = false;
  }

  togglecc() {
    this.ifCc = !this.ifCc;
  }

  togglebcc() {
    this.ifBcc = !this.ifBcc;
  }

  onClickedOutside(e: Event) {
    const mn = <HTMLCanvasElement>document.getElementById("menu");
    const tb = <HTMLCanvasElement>document.getElementById("toggleButton");
    if (e.target !== tb && e.target !== mn) {
      this.replyDropdown = false;
    }

    const tb2 = <HTMLCanvasElement>document.getElementById("dropButton");
    const mn2 = <HTMLCanvasElement>document.getElementById("dropdownBox");
    if (e.target !== tb2 && e.target !== mn2) {
      this.searchDropdown = false;
    }
  }
  replyDrop() {
    this.isTranslated = false;
    this.replyDropdown = !this.replyDropdown;
  }
  translateDrop() {
    this.isTranslateDrop = !this.isTranslateDrop;
  }
  translateDrop2() {
    this.isTranslateDrop = !this.isTranslateDrop;
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    for (var i = 0; i < event.target.files.length; i++) {
      this.names.push(event.target.files[i].name);
      this.files.push(event.target.files[i]);
    }
    /* 
    let fileName = document.getElementById('attachment')
    fileName.innerHTML = this.selectedFiles[0].name;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
      }
    } */
  }

  closeDrop() {
    //jQuery('#dropdown-menu').find('a.dropdown-toggle').dropdown('toggle')
    this.searchDropdown = !this.searchDropdown;
  }

  taskOpen() {
    this.taskHtmlFlag = true;
  }
  hide() {
    this.taskHtmlFlag = false;
  }

  getLastTaskId() {
    if (this.subs["taskDtl"]) {
      this.subs["taskDtl"].unsubscribe();
    }

    this.subs["taskDtl"] = this.data.getLastTaskId().subscribe((data) => {
      this.lastTaskId = data;
      let numb = this.lastTaskId.toString().replace("T", "");
      let digit = Number(numb);
      if (digit > 0) {
        this.emailAssociateForm.controls["task_id"].setValue(this.lastTaskId);
      } else {
        this.emailAssociateForm.controls["task_id"].setValue("T1");
      }
    });
  }

  filterList(value) {
    this.err_translate = "";
    if (value == "all") {
      this.allActive = true;
      this.myEmailActive = false;
      this.matterEmailActive = false;
      this.unAssignedActive = false;
      this.matterDraftEmailActive = false;
      this.matterSentEmailActive = false;
      this.emailParam.searchStringFilter = "";
      this.listEmail();
    } else if (value == "myEmail") {
      this.allActive = false;
      this.myEmailActive = true;
      this.matterEmailActive = false;
      this.unAssignedActive = false;
      this.matterDraftEmailActive = false;
      this.matterSentEmailActive = false;
      this.emailParam.searchStringFilter = value;

      this.listEmail();
    } else if (value == "matter_email") {
      this.allActive = false;
      this.myEmailActive = false;
      this.matterEmailActive = true;
      this.unAssignedActive = false;
      this.matterDraftEmailActive = false;
      this.matterSentEmailActive = false;
      this.emailParam.searchStringFilter = value;
      this.listEmail();
    } else if (value == "unassigned") {
      this.allActive = false;
      this.myEmailActive = false;
      this.matterEmailActive = false;
      this.unAssignedActive = true;
      this.matterDraftEmailActive = false;
      this.matterSentEmailActive = false;
      this.emailParam.searchStringFilter = value;
      this.listEmail();
    } else if (value == "sentEmail") {
      this.allActive = false;
      this.myEmailActive = false;
      this.matterEmailActive = false;
      this.unAssignedActive = false;
      this.matterDraftEmailActive = false;
      this.matterSentEmailActive = true;
      this.emailParam.searchStringFilter = value;
      this.listEmail();
    } else if (value == "draftEmail") {
      this.allActive = false;
      this.myEmailActive = false;
      this.matterEmailActive = false;
      this.unAssignedActive = false;
      this.matterDraftEmailActive = true;
      this.matterSentEmailActive = false;
      this.emailParam.searchStringFilter = value;
      this.listEmail();
    } else {
      this.emailParam.searchStringFilter = "";
      this.listEmail();
    }
  }

  listEmail() {
    if (this.subs["emailList"]) {
      this.subs["emailList"].unsubscribe();
    }
    //this.emailParam.user_id = localStorage.getItem("UserId");
    this.emailParam.user_type_id = localStorage.getItem("UserType");
    this.emailList = [];
    this.emailCount = 0;
    //this.emailParam.pend=this.pend;
    this.subs["emailList"] = this.emailListSubs = this.data
      .listEmail(this.emailParam)
      .subscribe((data) => {
        //this.emailList = data;
        this.allEmailrecords = data;
        this.emailList = this.allEmailrecords.items;

        this.emailCount = this.allEmailrecords.totalRecords;
        this.p = this.allEmailrecords.pageNo;
        for (let index = 0; index < this.emailList.length; index++) {
          var dateSendingToServer = "";
          if (
            this.emailList[index].post_date != "" &&
            this.emailList[index].post_date != null
          ) {
            dateSendingToServer = moment(
              this.emailList[index].post_date
            ).format("YYYY-MM-DD h:mm A");
          }
          this.emailList[index].email_date = dateSendingToServer;
          this.searchDropdown = false;
        }
      });
    this.searchDropdown = false;
  }

  getEmailCount() {
    return new Promise((resolve, reject) => {
      this.data.countEmails().subscribe((data) => {
        this.emailCount = data;
      });
      resolve(null);
    });
  }

  paginationFeed(page) {
    this.p = page;
    this.emailParam.pageNo = this.p;
    this.listEmail();
  }

  listClientList() {
    if (this.subs["clientList"]) {
      this.subs["clientList"].unsubscribe();
    }
    this.subs["clientList"] = this.data.listClientList().subscribe((data) => {
      this.clientList = data;
      this.clientList = this.clientList.sort(
        (a: { client_number: any }, b: { client_number: any }) =>
          Number(b.client_number) - Number(a.client_number)
      );
      for (const element of this.clientList) {
        element.clientname = element.client_number + "-" + element.client_name;
      }
    });
  }

  /*listMatterList() {
    
    this.data.allMatters(this.listParam).subscribe(data => {
      this.matterList = data;
      for (let index = 0; index < this.matterList.length; index++) {
        this.matterList[index].mattername = this.matterList[index].matter_number+' '+this.matterList[index].matter_name;
      }
    });
  }*/

  MatterList(client_id) {
    if (this.subs["matterList"]) {
      this.subs["matterList"].unsubscribe();
    }
    this.listParam.client_id = client_id;
    this.subs["matterList"] = this.data
      .allMatters(this.listParam)
      .subscribe((data) => {
        this.matterList = data;
        this.matterList = this.matterList.sort(
          (a: { matter_id: number }, b: { matter_id: number }) =>
            b.matter_id - a.matter_id
        );
        for (const element of this.matterList) {
          element.mattername =
            element.matter_number + " - " + element.matter_name;
        }
      });
  }

  changeMatterList(client_id) {
    this.MatterList(client_id);
    if (this.emailAssociateForm) {
      this.emailAssociateForm.patchValue({
        matter: "",
      });
    }
  }

  /*listGenius() {
    this.data.getTaskAssignee(3).subscribe((data) => {
      this.assigneeList = data;
    });
  }*/

  getTaskList() {
    if (this.subs["taskList"]) {
      this.subs["taskList"].unsubscribe();
    }

    this.subs["taskList"] = this.data
      .getTaskList(this.listParam)
      .subscribe((data) => {
        this.taskList = data;
        this.taskList = Array.of(this.taskList.items);
      });
  }

  blankErrorMsgs() {
    this.err_task_name =
      this.err_description =
      this.err_due_date =
      this.err_assignee_id =
      this.err_client_id =
      this.err_matter_id =
      this.err_priority =
        "";
    this.err_due_date_msg = this.err_genius = "";
    this.err_title = "";
    this.err_location = "";
    this.err_participants = "";
    this.err_notes = "";
    this.err_alert = "";
    this.err_start_time = "";
    this.err_end_date = "";
    this.err_task_title = "";
    this.err_task_note = "";
    this.err_translate = "";
  }

  emailFormInit() {
    this.emailAssociateForm = this.fb.group({
      client: new FormControl(""),
      matter: new FormControl(""),
      genius: new FormControl(""),
      task: new FormControl(""),
      mail_id: new FormControl(""),
      task_id: new FormControl(""),
      task_name: new FormControl(""),
      task_due_date: new FormControl(""),
      task_description: new FormControl(""),
      task_priority: new FormControl(""),
    });
  }

  editAssociate(mail_id) {
    if (this.subs["emailDtl"]) {
      this.subs["emailDtl"].unsubscribe();
    }

    this.err_translate = "";
    this.blankErrorMsgs();
    this.taskHtmlFlag = false;
    this.mId = mail_id;
    this.subs["emailDtl"] = this.data
      .emailDescById(mail_id, "no")
      .subscribe(async (data) => {
        this.listDetails = data;
        if (this.listDetails.associate_client) {
          this.MatterList(this.listDetails.associate_client);
        }
        this.emailAssociateForm.patchValue({
          mail_id: this.listDetails.mail_id,
          task: this.listDetails.associate_task,
          genius: this.listDetails.associate_genius,
          client: this.listDetails.associate_client,
          matter: this.listDetails.associate_matter,
        });
      });
  }
  public parseDate(str) {
    var m = str.match(/\d{2}([\/.-])\d{2}\1\d{4}/g);
    return m;
  }

  showTrasnslate() {
    this.isTranslated = !this.isTranslated;
  }

  getContent(mail_id, flag) {
    this.attachfiles = [];
    this.ifCc = false;
    this.ifBcc = false;
    this.isTranslated = false;
    this.data.emailDescById(mail_id, flag).subscribe((data) => {
      this.mailDet = data;

      let tos = this.mailDet.mail_to_email.split(",");
      this.isMultipleToAdd = tos.length > 1 ? true : false;

      this.assigneeList = this.mailDet.genius_list;
      this.mailSendTime = "";
      if (this.mailDet.post_date != "" && this.mailDet.post_date != null) {
        this.mailSendTime = moment(this.mailDet.post_date).format(
          "YYYY-MM-DD h:mm A"
        );
      }

      this.attachmentList = this.mailDet.attachment_name_list;
      if (this.mailDet.attachment_name_list != "") {
        var flag = this.attachmentList.includes(",");
        if (flag == true) {
          this.basenameList = this.mailDet.disp_file_name.split(",");
          let attachfiles = this.attachmentList.split(",");

          this.attachfiles = attachfiles.map((id, index) => {
            return {
              filesurl: attachfiles[index],
              basename: this.basenameList[index],
              filesurlenc: encodeURIComponent(attachfiles[index]),
              basenameenc: encodeURIComponent(this.basenameList[index]),
            };
          });
        } else {
          //this.attachfiles.push(this.attachmentList);
          this.attachfiles.push({
            filesurl: this.attachmentList,
            basename: this.mailDet.disp_file_name,
            filesurlenc: encodeURIComponent(this.attachmentList),
            basenameenc: encodeURIComponent(this.mailDet.disp_file_name),
          });
        }
      }

      if (this.mailDet.tracking_flag == 1) {
        this.composeForm.patchValue({
          tracking_flag: true,
        });
      }
      if (this.mailDet.trans_body != null) {
        this.translatedContent = this.mailDet.trans_body;
        this.composeForm.patchValue({
          email_trans_body: this.mailDet.trans_body,
        });
      }

      if (this.mailDet.trans_subject != null) {
        this.translatedSubject = this.mailDet.trans_subject;
        this.composeForm.patchValue({
          email_trans_subject: this.mailDet.trans_subject,
        });
      }

      var word = this.parseDate(this.mailDet.body);
      if (word != null) {
        word.forEach((element) => {
          this.mailDet.body = this.mailDet.body.replace(
            new RegExp("(" + element + ")"),
            '<a class="mDet" href="">$1</a>'
          );
        });
        this.removeEventListener = this.renderer.listen(
          this.elementRef.nativeElement,
          "click",
          (event) => {
            if (event.target instanceof HTMLAnchorElement) {
              // Your custom anchor click event handler
              const anchor = event.target as HTMLAnchorElement;
              /* alert(`You are trying to navigate to ${anchor.className}`); */
              if (anchor.className == "mDet") {
                event.preventDefault();
                this.addEventModal();
              }
            }
          }
        );
      }
    });
    if (
      this.checkReply == true ||
      this.checkReplyAll == true ||
      this.checkDraft == true
    ) {
      this.composeForm.patchValue({
        email_to: this.mailDet.mail_to_email,
        email_cc: this.mailDet.cc_list,
        email_bcc: this.mailDet.bcc_list,
        email_subject:
          (this.checkReply == true || this.checkReplyAll == true
            ? "Re: "
            : this.ifForward == true
            ? "Fwd: "
            : "") + this.mailDet.subject,
        //email_body: this.mailDet.body,
      });
      if (this.checkDraft == true) {
        this.composeForm.patchValue({
          email_body: this.mailDet.body,
          hid_mail_id: this.mailDet.mail_id,
        });
      }

      if (this.checkReply == true || this.checkReplyAll == true) {
        this.composeForm.patchValue({
          email_body:
            "<br />" +
            this.htmlDecode(this.loginuserdtl.email_signature) +
            "<br />----<br/>" +
            this.mailDet.body,
        });
      }
    }
    this.markEmailAsRead(mail_id);
    this.modalRef = this.modal.open(this.clientsModal, { size: "xl" });
  }

  htmlDecode(str) {
    var div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText;
  }

  emailAssociate(postData) {
    if (this.subs["emailAssoc"]) {
      this.subs["emailAssoc"].unsubscribe();
    }

    this.err_translate = "";
    if (postData.task_due_date != "" && postData.task_due_date != null) {
      var dateSendingToServer = new DatePipe("en-US").transform(
        postData.task_due_date,
        "yyyy-MM-dd"
      );
    } else {
      var dateSendingToServer = "";
    }

    let formdata = new FormData();
    formdata.append("client", postData.client != null ? postData.client : "");
    formdata.append("matter", postData.matter != null ? postData.matter : "");
    formdata.append("genius", postData.genius != null ? postData.genius : "");

    if (this.taskHtmlFlag == false) {
      formdata.append("email_new_task", "0");
      formdata.append("task", postData.task != null ? postData.task : "");
    } else {
      formdata.append(
        "task_id",
        postData.task_id != null ? postData.task_id : ""
      );
      formdata.append(
        "task_name",
        postData.task_name != null ? postData.task_name : ""
      );
      formdata.append("due_date", dateSendingToServer);
      formdata.append(
        "description",
        postData.task_description != null ? postData.task_description : ""
      );
      formdata.append(
        "priority",
        postData.task_priority != null ? postData.task_priority : ""
      );
      formdata.append("email_new_task", "1");
    }

    formdata.append("mail_id", this.mId);

    this.subs["emailAssoc"] = this.data.associatEmail(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];

          setTimeout(() => {
            if (localStorage.getItem("UserId") != null) {
              this.succ_msg = false;
              jQuery("#clientsModal1").modal("hide");

              this.getLastTaskId();
              this.emailFormInit();
              this.listEmail();
              this.listClientList();
              //this.listMatterList();
              //this.listGenius();
              this.getTaskList();
              this.blankErrorMsgs();
              this.taskHtmlFlag = false;
            }
          }, this.data.timeOut);

          if (postData.mail_id) {
          } else {
            this.emailFormInit();
          }
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (this.taskHtmlFlag == true) {
          if (error.error.genius) {
            this.err_genius = error.error.genius;
          } else {
            this.err_genius = "";
          }

          if (error.error.task_name) {
            this.err_task_name = error.error.task_name;
          } else {
            this.err_task_name = "";
          }

          if (error.error.description) {
            this.err_description = error.error.description;
          } else {
            this.err_description = "";
          }

          if (error.error.err_due_date) {
            this.err_due_date_msg = error.error.err_due_date_msg;
            this.err_due_date = "";
          } else {
            this.err_due_date_msg = "";
          }

          if (error.error.due_date) {
            this.err_due_date = error.error.due_date;
            this.err_due_date_msg = "";
          } else {
            this.err_due_date = "";
          }

          if (error.error.assignee_id) {
            this.err_assignee_id = error.error.assignee_id;
          } else {
            this.err_assignee_id = "";
          }

          if (error.error.priority) {
            this.err_priority = error.error.priority;
          } else {
            this.err_priority = "";
          }
        }
      }
    );
  }

  changeSrc(src) {
    this.err_translate = "";
    if (src != "") {
      this.translateSrcLang = src;
    } else {
      this.translateSrcLang = "en";
    }
  }

  changeDesti(desti) {
    this.err_translate = "";
    if (desti != "") {
      this.translateDestiLang = desti;
    } else {
      this.translateDestiLang = "ch";
    }
  }

  translateContent(postData, mail_id) {
    if (this.subs["transContent"]) {
      this.subs["transContent"].unsubscribe();
    }

    this.replyDropdown = !this.replyDropdown;
    jQuery(".dropdown-toggle").dropdown("hide");
    this.err_translate = "";
    let formdata = new FormData();
    if (postData != "") {
      formdata.append("email_subject", postData.email_subject);
      formdata.append("email_body", postData.email_body);

      if (postData.hid_mail_id != "") {
        formdata.append("hid_mail_id", postData.hid_mail_id);
      }

      if (postData.email_trans_subject) {
        formdata.append("email_trans_subject", postData.email_trans_subject);
      }
      if (postData.email_trans_body) {
        formdata.append("email_trans_body", postData.email_trans_body);
      }
    }

    if (this.translateSrcLang != "") {
      this.translateSrcLang = this.translateSrcLang;
    } else {
      this.translateSrcLang = "en";
    }
    if (this.translateDestiLang != "") {
      this.translateDestiLang = this.translateDestiLang;
    } else {
      this.translateDestiLang = "ch";
    }

    formdata.append("mail_id", mail_id);
    formdata.append("src", this.translateSrcLang);
    formdata.append("desti", this.translateDestiLang);

    this.subs["transContent"] = this.data
      .translateContent(formdata)
      .subscribe((data) => {
        this.res = data;
        if (this.res.status == 1) {
          this.listEmail();
          this.getContent(mail_id, "no");
        } else {
          //this.err_translate = this.res.msg;
        }
      });
    this.translateDestiLang = this.translateSrcLang = "";
  }

  emailSearchFormInit() {
    this.err_translate = "";
    this.emailSearchForm = this.fb.group({
      from: new FormControl(""),
      to: new FormControl(""),
      subject: new FormControl(""),
      has_word_present: new FormControl(""),
      word_no_present: new FormControl(""),
    });
  }

  reset() {
    this.listEmail();
    this.err_translate = "";
    this.emailSearchFormInit();
    this.blankEmailSearchField();
    this.closeDrop();
    this.searchDropdown = false;
    this.searchText = "";
  }

  blankEmailSearchField() {
    this.err_translate = "";
    this.emailParam.from = "";
    this.emailParam.to = "";
    this.emailParam.subject = "";
    this.emailParam.has_word_present = "";
    this.emailParam.word_no_present = "";
  }

  emailSearch(postData) {
    this.err_translate = "";
    if (postData.from) {
      this.searchText = postData.from;
    }
    if (postData.to) {
      this.searchText = postData.to;
    }
    if (postData.subject) {
      this.searchText = postData.subject;
    }
    if (postData.has_word_present) {
      this.searchText = postData.has_word_present;
    }
    if (postData.word_no_present) {
      this.searchText = postData.word_no_present;
    }

    this.emailParam.from = postData.from;
    this.emailParam.to = postData.to;
    this.emailParam.subject = postData.subject;
    this.emailParam.has_word_present = postData.has_word_present;
    this.emailParam.word_no_present = postData.word_no_present;
    this.listEmail();

    jQuery("#searchForm").trigger("reset");
  }

  closeModal() {
    jQuery(".template-modal").modal("hide");
    this.succ_msg = "";
    this.err_email_to = "";
    this.err_email_cc = this.err_email_bcc = "";
    this.err_template_name = "";
    this.err_visible_for = "";
    this.err_translate = "";
    this.ifReply = false;
  }

  addUserEmails(postData, send_flag) {
    if (this.subs["emailUser"]) {
      this.subs["emailUser"].unsubscribe();
    }

    this.err_translate = "";
    this.err_email_to = "";
    this.err_email_cc = this.err_email_bcc = "";
    let formdata = new FormData();

    formdata.append("email_to", postData.email_to);
    if (
      this.ifCc == true &&
      postData.email_cc != null &&
      postData.email_cc != ""
    ) {
      formdata.append("cc", postData.email_cc);
    }
    if (
      this.ifBcc == true &&
      postData.email_bcc != null &&
      postData.email_bcc != ""
    ) {
      formdata.append("bcc", postData.email_bcc);
    }

    formdata.append("email_subject", postData.email_subject);
    formdata.append("email_body", postData.email_body);
    formdata.append("from", "lpms_email");
    formdata.append("send_flag", send_flag);

    if (this.selectedFiles == undefined) {
    } else {
      for (var i = 0; i < this.files.length; i++) {
        formdata.append("email_attach_file[]", this.files[i]);
      }
    }
    if (this.attachmentList != "") {
      formdata.append("dbattachfiles[]", this.attachfiles);
    }

    if (this.attachfiles != "") {
      formdata.append("dbattachfiles", JSON.stringify(this.attachfiles));
    }

    if (postData.hid_mail_id != "") {
      formdata.append("hid_mail_id", postData.hid_mail_id);
    }

    if (postData.tracking_flag == true) {
      formdata.append("tracking_flag", "1");
    } else {
      formdata.append("tracking_flag", "0");
    }

    if (send_flag == "template") {
      formdata.append("template_name", postData.template_name);
      formdata.append("visible_for", postData.visible_for);
    }

    /* if (this.ifCompose == false) {
      formdata.append("temporary_flag_email", "1");
    } else {
      formdata.append("temporary_flag_email", "0");
    }*/

    if (postData.email_trans_body != "") {
      formdata.append("email_trans_body", postData.email_trans_body);
    }

    if (postData.email_trans_subject != "") {
      formdata.append("email_trans_subject", postData.email_trans_subject);
    }

    /*formdata.forEach(function (value) {
      console.log(value);
    });*/

    this.subs["emailUser"] = this.data.addUserEmails(formdata).subscribe(
      (response) => {
        if (response) {
          jQuery(".template-modal").modal("hide");
          if (send_flag == "draft") {
            this.succ_msg = "";
          } else {
            this.succ_msg = response["msg"];
            //this.ifCompose = !this.ifCompose;
            this.err_email_to = "";
            this.err_email_cc = this.err_email_bcc = "";
            //this.ifDisable = true;

            setTimeout(() => {
              this.succ_msg = false;
              this.asmClose();
            }, 3000);
          }

          /*this.err_email_to = '';
        this.err_email_cc = '';
        this.err_email_bcc = '';
        this.err_template_name = '';
        this.err_visible_for = '';
       
        setTimeout(() => {
          
          this.succ_msg = false;
          this.getLastTaskId();
          this.listClientList();
          this.listMatterList();
          this.listGenius();
          this.getTaskList();
          this.blankErrorMsgs();
          this.ifBcc = false;
          this.ifCc = false;
          this.getAllTemplate();
          this.checkDraft == false;
          if (send_flag == 'draft') {
            var mailId = response['e_id'];
            this.hid_m_id = mailId;
            this.data.emailDescById(mailId, 'no').subscribe(
              data => {
                if (data) {
                  this.templateData = data;
                  if (this.templateData.cc_list != '' && this.templateData.cc_list != null) {
                    this.ifCc = true;
                  }
                  if (this.templateData.bcc_list != '' && this.templateData.bcc_list != null) {
                    this.ifBcc = true;
                  }
                  this.composeForm.patchValue({
                    email_to: this.templateData.mail_to_email,
                    email_cc: this.templateData.cc_list,
                    email_bcc: this.templateData.bcc_list,
                    email_subject: this.templateData.subject,
                    email_body: this.templateData.body,
                    hid_mail_id: mailId
                  });

                  this.attachmentList = this.templateData.attachment_name_list;
                  if (this.templateData.attachment_name_list != '') {
                    var flag = this.templateData.attachment_name_list.includes(',');
                    if (flag == true) {
                      this.attachfiles = this.mailDet.attachment_name_list.split(',');
                    } else {
                      this.attachfiles = this.mailDet.attachment_name_list;
                    }
                  }
                  //this.autoDraft == false;
                }
              }
            );

          } else {
            
            this.asmClose();
            this.getContent(response['e_id'], 'no');
            this.listEmail();
            //this.composeEmail();
            this.ifDisable = true;
            this.composeFormInit();
            //this.ifCompose = false;
          }
        }, this.data.timeOut);*/
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (send_flag == "sent") {
          if (error.error.email_to != "") {
            this.err_email_to = error.error.email_to;
          } else {
            this.err_email_to = "";
          }

          if (error.error.cc != "") {
            this.err_email_cc = error.error.cc;
          } else {
            this.err_email_cc = "";
          }

          if (error.error.bcc != "") {
            this.err_email_bcc = error.error.bcc;
          } else {
            this.err_email_bcc = "";
          }
        }

        if (send_flag == "template") {
          if (error.error.template_name != "") {
            this.err_template_name = error.error.template_name;
          } else {
            this.err_template_name = "";
          }

          if (error.error.visible_for != "") {
            this.err_visible_for = error.error.visible_for;
          } else {
            this.err_visible_for = "";
          }
        }
      }
    );
  }

  saveDraft(draftData, draft_flag) {
    this.err_translate = "";
    this.addUserEmails(draftData, draft_flag);
  }

  saveTemplate(templateData, template_flag) {
    this.err_translate = "";
    this.addUserEmails(templateData, template_flag);
  }

  getAllTemplate() {
    if (this.subs["template"]) {
      this.subs["template"].unsubscribe();
    }

    this.subs["template"] = this.data.getAllTemplate().subscribe((response) => {
      if (response) {
        this.templateList = response;
      }
    });
  }

  addEventModal() {
    const anchor = event.target as HTMLAnchorElement;
    if (anchor.text != undefined) {
      this.eventForm.patchValue({
        event_start_date: new Date(anchor.text),
        event_end_date: new Date(anchor.text),
      });
    } else {
      this.eventForm.patchValue({
        event_start_date: this.viewDate,
        event_end_date: this.viewDate,
      });
    }

    this.err_translate = "";
    this.getLastTaskId();
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.modalOption.size = "lg";
    this.modalOption.windowClass = "event-custom-class";
    this.modalRef = this.modal.open(this.modalContent, this.modalOption);
  }

  eventFormInIt() {
    this.err_translate = "";
    this.eventForm = this.fb.group({
      event_title: ["", [Validators.required, removeSpaces]],
      event_location: ["", [Validators.required, removeSpaces]],
      event_type: new FormControl(""),
      event_notes: ["", [Validators.required, removeSpaces]],
      participant: ["", Validators.required],
      associate_cilent: new FormControl(""),
      associate_matter: new FormControl(""),
      associate_genius: new FormControl(""),
      event_start_date: new FormControl(this.viewDate),
      event_end_date: new FormControl(this.viewDate),
      event_alert: ["", Validators.required],
      event_start_time: new FormControl("00:00"),
      event_end_time: new FormControl("00:00"),
      event_custom_alrt_val: new FormControl(0),
      event_custom_alrt_type: new FormControl(""),
      calendar: ["", Validators.required],
    });
  }

  addEvent(post) {
    if (this.subs["event"]) {
      this.subs["event"].unsubscribe();
    }
    this.err_translate = "";
    this.submitted = true;
    if (this.eventForm.valid) {
      var start_date = new DatePipe("en-US").transform(
        post.event_start_date,
        "yyyy-MM-dd"
      );
      var end_date = new DatePipe("en-US").transform(
        post.event_end_date,
        "yyyy-MM-dd"
      );

      let formdata = new FormData();
      formdata.append("event_title", post.event_title);
      formdata.append("event_location", post.event_location);
      formdata.append("event_type", post.event_type);
      formdata.append("calendar", post.calendar);
      formdata.append("event_notes", post.event_notes);
      formdata.append("participants", post.participant);
      formdata.append("matter", post.associate_matter);
      formdata.append("client", post.associate_cilent);
      formdata.append("genius", post.associate_genius);
      formdata.append("event_alert", post.event_alert);
      formdata.append("start_date", start_date);
      formdata.append(
        "start_time",
        post.event_start_time != "" ? post.event_start_time : "00:00"
      );
      formdata.append("end_date", end_date);
      formdata.append(
        "end_time",
        post.event_end_time != "" ? post.event_end_time : "00:00"
      );
      formdata.append("event_custom_alert_type", post.event_custom_alrt_type);
      formdata.append("event_custom_alert_val", post.event_custom_alrt_val);
      if (post.event_start_time != "") {
        let start_time = post.event_start_time
          .replace("AM", "")
          .replace("PM", "");
        let sdtime = new Date(
          start_date + " " + start_time + ":00"
        ).toISOString();
        formdata.append("start_date_time", sdtime);
      } else {
        let start_time = "00:00";
        let sdtime = new Date(
          start_date + " " + start_time + ":00"
        ).toISOString();
        formdata.append("start_date_time", sdtime);
      }

      if (post.event_end_time != "") {
        let end_time = post.event_end_time.replace("AM", "").replace("PM", "");
        let edtime = new Date(end_date + " " + end_time + ":00").toISOString();
        formdata.append("end_date_time", edtime);
      } else {
        let end_time = "00:00";
        let edtime = new Date(end_date + " " + end_time + ":00").toISOString();
        formdata.append("end_date_time", edtime);
      }
      this.subs["event"] = this.data.addCalendarEvent(formdata).subscribe(
        (response) => {
          if (response) {
            this.succ_msg = response["msg"];
            this.blankErrorMsgs();
            setTimeout(() => {
              this.succ_msg = false;
              this.time_flag = false;
              this.cancelEvent();
            }, 2000);
          } else {
            this.succ_msg = "";
          }
        },
        (error) => {
          if (error.error.event_title) {
            this.err_title = error.error.event_title;
          } else {
            this.err_title = "";
          }

          if (error.error.event_location) {
            this.err_location = error.error.event_location;
          } else {
            this.err_location = "";
          }

          if (error.error.paticipants) {
            this.err_participants = error.error.paticipants;
          } else {
            this.err_participants = "";
          }

          if (error.error.calendar) {
            this.err_calendar = error.error.calendar;
          } else {
            this.err_calendar = "";
          }

          if (error.error.event_notes) {
            this.err_notes = error.error.event_notes;
          } else {
            this.err_notes = "";
          }
          /*if (error.error.event_alert) {
          this.err_alert = error.error.event_alert;
        } else {
          this.err_alert = '';
        }*/
          if (error.error.start_date) {
            this.err_start_date = error.error.start_date;
          } else {
            this.err_start_date = "";
          }
          if (error.error.end_date) {
            this.err_end_date = error.error.end_date;
          } else {
            this.err_end_date = "";
          }
          if (error.error.start_time) {
            this.err_start_time = error.error.start_time;
          } else {
            this.err_start_time = "";
          }
          if (error.error.end_time) {
            this.err_end_time = error.error.end_time;
          } else {
            this.err_end_time = "";
          }
          if (error.error.event_custom_alert_val) {
            this.err_alrt_val = error.error.event_custom_alert_val;
          } else {
            this.err_alrt_val = "";
          }
        }
      );
    }
  }

  addTime(event) {
    this.time_flag = !this.time_flag;
    if (this.time_flag == false) {
      this.eventForm.patchValue({
        event_start_time: "00:00",
        event_end_time: "00:00",
      });
    }
  }

  get eventFormControl() {
    return this.eventForm.controls;
  }

  displayTask() {
    this.tashFormDisplay = true;
    this.eventFormDisplay = false;
  }

  displayEvent() {
    this.tashFormDisplay = false;
    this.eventFormDisplay = true;
  }

  listParticipent() {
    if (this.subs["listPar"]) {
      this.subs["listPar"].unsubscribe();
    }
    //this.contactParam.contact_type = '';
    this.subs["listPar"] = this.data
      .getAllListing(this.contactParam)
      .subscribe((data) => {
        this.paticipantList = data;
      });
  }

  listTask() {
    if (this.subs["taskList"]) {
      this.subs["taskList"].unsubscribe();
    }
    this.subs["taskList"] = this.data
      .getTaskList(this.listParam)
      .subscribe((data) => {
        this.taskList = data;
        this.taskList = this.taskList.items;
      });
  }

  taskEventFormInit() {
    this.taskEventForm = this.fb.group({
      title: ["", [Validators.required, removeSpaces]],
      task_assignee: ["", Validators.required],
      task_assignee_user: ["", Validators.required],
      task_date: new FormControl(this.viewDate),
      task_id: new FormControl(),
      note: ["", [Validators.required, removeSpaces]],
    });
  }
  get taskEventFormControl() {
    return this.taskEventForm.controls;
  }
  saveTaskEvent(post) {
    if (this.subs["saveTask"]) {
      this.subs["saveTask"].unsubscribe();
    }

    this.submitted = true;
    if (this.taskEventForm.valid) {
      var start_date = new DatePipe("en-US").transform(
        post.task_date,
        "yyyy-MM-dd"
      );
      var date_time = new Date(start_date + " " + "00" + ":00").toISOString();
      let formdata = new FormData();
      formdata.append("title", post.title);
      formdata.append("task_due_date", start_date);
      formdata.append("start_date", start_date);
      formdata.append("task_date_time", date_time);
      formdata.append("end_date", start_date);
      formdata.append("assignee_id", post.task_assignee_user);
      formdata.append("assignee_type", post.task_assignee);
      formdata.append("task_id", post.task_id);
      formdata.append("note", post.note);
      this.subs["saveTask"] = this.data
        .addCalendarTaskEvent(formdata)
        .subscribe(
          (response) => {
            if (response) {
              this.succ_msg = response["msg"];

              setTimeout(() => {
                this.succ_msg = false;
                this.time_flag = false;
                this.cancelEvent();
              }, 2000);
            } else {
              this.succ_msg = "";
            }
          },
          (error) => {
            if (error.error.title) {
              this.err_task_title = error.error.title;
            } else {
              this.err_task_title = "";
            }
            if (error.error.note) {
              this.err_task_note = error.error.note;
            } else {
              this.err_task_note = "";
            }
          }
        );
    }
  }
  cancel() {
    this.err_translate = "";
    //this.globalCalendar.getAllEvents();
    this.blankErrorMsgs();
    this.eventFormInIt();
    this.taskEventFormInit();
    this.time_flag = false;
    jQuery("body").addClass("mm");
    /* jQuery('#clientsModal').modal("hide"); */
    jQuery("#clientsModal1").modal("hide");
    this.submitted = false;
    this.eventFormDisplay = true;
    this.tashFormDisplay = false;
  }
  cancelEvent() {
    this.modalRef.close();
    this.blankErrorMsgs();
    this.eventFormInIt();
    this.time_flag = false;
    this.submitted = false;
    this.eventFormDisplay = true;
    this.tashFormDisplay = false;
  }
  asmClose() {
    jQuery("body").removeClass("mm");
    this.modalRef.close();

    this.isTranslateDrop = false;
    this.checkReply = false;
    this.checkReplyAll = false;
    this.checkDraft = false;
    this.isTranslated = true;
    this.ifReply = false;
    this.ifForward = false;
    this.composeFormInit();
    this.blankErrorMsgs();
    this.attachmentList = "";
    this.ifEmailTracking = false;
  }

  populateStartTime() {
    var hours, minutes, ampm, hoursValue;
    for (var i = 420; i <= 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hoursValue = hours;
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;

      if (hours === 0) {
        hours = 12;
      }
      let newstartTimeArr = {
        value: hoursValue + ":" + minutes,
        text: hours + ":" + minutes + " " + ampm,
      };
      this.starttime.push(newstartTimeArr);
    }
  }

  populateEndTime() {
    var hours, minutes, ampm, hoursValue;
    for (var i = 420; i <= 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hoursValue = hours;
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;

      if (hours === 0) {
        hours = 12;
      }
      let newendTimeArr = {
        value: hoursValue + ":" + minutes,
        text: hours + ":" + minutes + " " + ampm,
      };
      this.endtime.push(newendTimeArr);
    }
  }

  getLocation() {
    if (this.subs["location"]) {
      this.subs["location"].unsubscribe();
    }

    var i = 0;
    var arr: any = [];
    this.subs["location"] = this.data.getEventLocation().subscribe((data) => {
      this.location = data;
      for (let index = 0; index < this.location.length; index++) {
        if (this.location[index].location != "None") {
          arr[i] = this.location[index];
          i++;
        }
      }
      this.location = arr;
    });
  }

  /*onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        this.assigneeType = "Client";
        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
         
        });
      } else {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      }
    } else {
      this.assigneeList = [];
    }
  }*/

  removeDbAttachment(index) {
    this.attachfiles.splice(index, 1);
  }

  removeAttachment(index) {
    this.names.splice(index, 1);
    this.files.splice(index, 1);
  }

  openConfirmationDialog(mailId) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(mailId, confirmed))
      .catch(() => {});
  }

  deleteListItem(mailId, confirmed) {
    if (confirmed) {
      this.data.deleteEmail(mailId).subscribe((response) => {
        this.succ_msg = "Deleted Successfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.listEmail();
        }, this.data.timeOut);
      });
    }
  }

  getClientName(clientId) {
    if (clientId > 0) {
      let client_name =
        this.clientList.find((data) => data.client_id === clientId)
          .client_number +
        " " +
        this.clientList.find((data) => data.client_id === clientId).client_name;
      return client_name;
    }
  }

  getMatterName(matterId) {
    if (matterId > 0) {
      let matter_name =
        this.matterList.find((data) => data.matter_id === matterId)
          .matter_number +
        " " +
        this.matterList.find((data) => data.matter_id === matterId).matter_name;
      return matter_name;
    }
  }

  draft() {
    if (
      this.composeForm.controls.email_to.value.length > 0 ||
      this.composeForm.controls.email_subject.value.length > 0 ||
      this.composeForm.controls.email_body.value.length > 0
    ) {
      this.saveDraft(this.composeForm.value, "draft");
    }
    this.listEmail();
  }

  getCheckboxVal() {
    if (this.composeForm.controls.tracking_flag.value == true) {
      this.checkFlag = 1;
      this.getCheckTxt = "Do not Track Email Opening";
    } else {
      this.checkFlag = 0;
      this.getCheckTxt = "Track Email Opening";
    }
  }
  openCustomAlert(event) {
    this.customAlert = !this.customAlert;
    if (this.customAlert == false) {
      this.eventForm.patchValue({
        event_custom_alrt_type: "",
        event_custom_alrt_val: 0,
      });
      this.err_alrt_val = "";
    }
  }

  closeCustomAlert(event) {
    this.customAlert = false;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    if (
      item.givenName != null ||
      item.familyName != null ||
      item.middleName != null
    ) {
      return (
        item.givenName.toLocaleLowerCase().indexOf(term) > -1 ||
        item.location.toLocaleLowerCase().indexOf(term) > -1 ||
        item.familyName.toLocaleLowerCase().indexOf(term) > -1 ||
        item.middleName.toLocaleLowerCase().indexOf(term) > -1
      );
    }
  }

  markEmailAsRead(mail_id) {
    if (this.subs["emailRead"]) {
      this.subs["emailRead"].unsubscribe();
    }

    let formdata = new FormData();
    formdata.append("mail_id", mail_id);
    this.subs["emailRead"] = this.data
      .markEmailAsRead(formdata)
      .subscribe((data) => {});
  }

  getAllGoogleCalender() {
    if (this.subs["calender"]) {
      this.subs["calender"].unsubscribe();
    }

    this.subs["calender"] = this.calenderParam.calender_type = "global";
    this.data.getAllGoogleCalender(this.calenderParam).subscribe((data) => {
      this.calendarList = data;
    });
  }

  downloadFile(file_url, basename) {
    console.log(file_url + ", " + basename);
    fetch(file_url)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        let objectURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectURL;
        a.download = basename;
        a.target = "_self";
        a.click();
      });
  }
}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, "").length) {
    control.setValue("");
  }
  return null;
}
