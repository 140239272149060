import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ɵConsole,
} from "@angular/core";
import { AuthenticationService } from "../../services/auth.service";
import { DataService } from "../../services/data.service";
import { environment } from "../../../environments/environment";
import { INFERRED_TYPE } from "@angular/compiler/src/output/output_ast";
@Component({
  selector: "app-background-verification",
  templateUrl: "./background-verification.component.html",
  styleUrls: ["./background-verification.component.scss"],
})
export class BackgroundVerificationComponent implements OnInit {
  terrorist: boolean = false;
  ofac: boolean = false;
  google: boolean = false;
  pep: boolean = false;
  @Input() personBCheckId: any;
  @Input() orgBCheckId: any;
  terroristBackgroundCheck: any;
  contact_id: any;
  field: string;
  name: string;
  terroristResponse: boolean = false;
  terroristLoader: boolean = true;
  searchPep: boolean = false;
  googleResult: any;
  ofacResult: any;
  pepResult: any;
  message: string = "";
  googleResultMessage: string = "";
  googleResultFlag: boolean = false;
  PepControl: boolean = false;
  base_url: any;
  constructor(private auth: AuthenticationService, private data: DataService) {}

  ngOnInit() {
    this.terrorist = true;
    this.ngOnChanges();
    this.base_url = `${environment.lpmsUrl}` + "";
  }
  ngOnChanges() {
    if (this.personBCheckId > 0) {
      this.contact_id = this.personBCheckId;
      this.field = "contact_person_id";
      this.getPeopleDetails();
      this.terroristListBackgroungChecking(this.personBCheckId);
    }
    if (this.orgBCheckId > 0) {
      this.contact_id = this.orgBCheckId;
      this.field = "contact_org_id";
      this.getOrgDetails();
      this.terroristListBackgroungChecking(this.personBCheckId);
    }
  }

  showContent(val) {
    if (val === "terrorist") {
      this.terrorist = true;
      this.ofac = false;
      this.google = false;
      this.pep = false;
      this.terroristListBackgroungChecking(this.personBCheckId);
    } else if (val === "ofac") {
      this.terrorist = false;
      this.ofac = true;
      this.google = false;
      this.pep = false;
      this.OfacList();
    } else if (val === "google") {
      this.terrorist = false;
      this.ofac = false;
      this.google = true;
      this.pep = false;
      this.googleSearch();
    } else if (val === "pep") {
      this.terrorist = false;
      this.ofac = false;
      this.google = false;
      this.pep = true;
      this.searchPEP();
    }
  }

  terroristListBackgroungChecking(id) {
    let person;
    this.data.getContactDetails(id, "PERS").subscribe((data) => {
      if (data) {
        if (data["middle_name"] != null) {
          person =
            data["given_name"].trim() +
            " " +
            data["middle_name"].trim() +
            " " +
            data["family_name"].trim() +
            "|" +
            data["family_name"].trim() +
            " " +
            data["middle_name"].trim() +
            " " +
            data["given_name"].trim() +
            "|" +
            data["middle_name"].trim() +
            " " +
            data["given_name"].trim() +
            "|" +
            data["given_name"].trim() +
            " " +
            data["family_name"].trim() +
            "|" +
            data["given_name"].trim() +
            " " +
            data["middle_name"].trim() +
            "|" +
            data["given_name"].trim() +
            "|" +
            data["family_name"];
        } else {
          person =
            data["given_name"].trim() +
            " " +
            data["family_name"].trim() +
            "|" +
            data["family_name"].trim() +
            " " +
            data["given_name"].trim() +
            "|" +
            data["given_name"].trim() +
            "|" +
            data["given_name"].trim() +
            " " +
            data["family_name"].trim() +
            "|" +
            data["given_name"].trim() +
            "|" +
            data["given_name"].trim() +
            "|" +
            data["family_name"];
        }

        this.name =
          data["given_name"] + " " + data["middle_name"]
            ? data["middle_name"]
            : "" + " " + data["family_name"];
        if (data["middle_name"] == null) {
          this.name = data["given_name"] + " " + data["family_name"];
        } else {
          this.name =
            data["given_name"] +
            " " +
            data["middle_name"] +
            " " +
            data["family_name"];
        }

        let formdata = new FormData();
        formdata.append("name", person);
        formdata.append("field", this.field);
        formdata.append("contact_id", id);
        this.terroristResponse = false;
        this.terroristLoader = true;
        this.auth
          .terroristListBackgroundChecking(formdata)
          .subscribe((response) => {
            if (response) {
              setTimeout(() => {
                this.terroristBackgroundCheck = response;
                this.terroristResponse = true;
                this.terroristLoader = false;
              }, 3000);
            } else {
              this.terroristResponse = false;
            }
          });
      } else {
        this.terroristResponse = false;
      }
    });
  }

  getOrgDetails() {
    this.data.getContactDetails(this.orgBCheckId, "ORG").subscribe((data) => {
      if (data) {
        this.name = data["org_name"];
      }
    });
  }

  getPeopleDetails() {
    this.data
      .getContactDetails(this.personBCheckId, "PERS")
      .subscribe((data) => {
        if (data) {
          this.name =
            data["given_name"] +
            " " +
            data["middle_name"] +
            " " +
            data["family_name"];
        }
      });
  }

  googleSearch() {
    let formdata = new FormData();
    formdata.append("name", this.name);
    this.auth.googleSearch(formdata).subscribe((response) => {
      if (response["result"]) {
        this.googleResultMessage = "";
        this.googleResult = response["result"];
        this.googleResultFlag = true;
      } else if (response["message"]) {
        this.googleResultMessage = response["message"];
        this.googleResultFlag = false;
      }
    });
  }

  close() {
    this.terrorist = false;
    this.terroristResponse = false;
    this.terroristLoader = true;
    this.searchPep = false;
    this.terroristBackgroundCheck = "";
    this.name = "";
    this.googleResultFlag = false;
    //this.googleSearch();
    //this.getPeopleDetails();
    //this.terroristListBackgroungChecking(this.personBCheckId);
    this.showContent("terrorist");
    location.reload();
  }

  searchPEP() {
    let formdata = new FormData();
    formdata.append("name", this.name);
    this.auth.searchPEP(formdata).subscribe((response) => {
      if (response) {
        if (response["result"]) {
          this.pepResult = response["result"];
          this.message = "";
          this.PepControl = true;
        } else {
          if (response["message"]) {
            this.PepControl = false;
            this.message = "No record found";
            this.pepResult = "";
          } else {
            this.message = "";
          }
        }
        this.searchPep = true;
      } else {
        this.searchPep = false;
      }
    });
  }

  OfacList() {
    let formdata = new FormData();
    formdata.append("name", this.name);
    this.auth.ofacList(formdata).subscribe((response) => {
      if (response["result"]) {
        this.ofacResult = response["result"];
        this.message = "";
      } else {
        this.message = "No record found";
      }
    });
  }
}
