import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import * as $ from "jquery";
import { GlobalCalendarComponent } from "../global-calendar/global-calendar.component";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { CalendarEvent } from "angular-calendar";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { DataService } from "../../services/data.service";
import { ThrowStmt } from "@angular/compiler";
import { AgendaComponent } from "../agenda/agenda.component";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { startWith } from "rxjs/internal/operators/startWith";
import { map } from "rxjs/internal/operators/map";
@Component({
  selector: "calendar-header",
  templateUrl: "./calendar-header.component.html",
  styleUrls: ["./calendar-header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarHeaderComponent implements OnInit {
  @ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;
  searchform: FormGroup;
  eventForm: FormGroup;
  taskEventForm: FormGroup;
  paticipantList: any;
  clientList: any;
  matterList: any;
  geniusList: any;
  succ_msg: any = "";
  time_flag: boolean = false;
  participants = (term) => ({ email: term });
  submitted: boolean = false;
  contactParam: any = {};
  listParam: any = {};
  taskList: any = [];
  lastTaskId: any;
  starttime: any = [];
  endtime: any = [];
  location: any = [];
  searchDropdown: boolean = false;
  err_title: string;
  err_location: string;
  err_notes: string;
  err_participants: string;
  err_alert: string;
  err_start_date: string;
  err_end_date: string;
  err_start_time: string;
  err_end_time: string;
  err_task_title: string;
  err_task_note: string;
  err_alrt_val: string;
  err_calendar: string;
  tashFormDisplay: boolean = false;
  eventFormDisplay: boolean = true;
  modalRef;
  modalOption: NgbModalOptions = {};
  assigneeList: any;
  assigneeType: String = "";
  minDate: Date;
  searchText: any;

  matterDetails: any;
  matter_type_id: any;

  customAlert: boolean = false;
  calendarList: any = [];
  calenderParam: any = {};
  pstart: number = 0;
  pend: number = 100;
  theCheckbox: boolean;
  userTypeId: any;

  constructor(
    public globalCalendar: GlobalCalendarComponent,
    private fb: FormBuilder,
    private modal: NgbModal,
    private datePipe: DatePipe,
    private data: DataService,
    public agenda: AgendaComponent,
    public router: Router
  ) {}

  ngOnInit() {
    //this.userTypeId = localStorage.getItem('UserType');

    this.theCheckbox = false;
    this.searchFormInit();
    let title = localStorage.getItem("event_title");
    let location = localStorage.getItem("location");
    let word_no_present = localStorage.getItem("word_no_present");
    let start_date = localStorage.getItem("start_date");
    let end_date = localStorage.getItem("end_date");
    let calendar = localStorage.getItem("event_calendar");
    let participant = localStorage.getItem("event_participant");
    //let calendar_type = localStorage.getItem('calendar_type');

    if (title) {
      this.searchText = title;
    }
    if (location) {
      this.searchText = location;
    }
    if (word_no_present) {
      this.searchText = word_no_present;
    }
    if (end_date) {
      this.searchText = start_date;
    }
    if (participant) {
      this.searchText = participant;
    }
    if (
      title != null ||
      location != null ||
      end_date != null ||
      participant != null ||
      calendar != null
    ) {
      //this.searchDropdown = true;
      this.searchform.patchValue({
        event_title: title != null ? title : "",
        event_location: location != null ? location : "",
        word_no_present: word_no_present != null ? word_no_present : "",
        event_start_date: start_date,
        event_end_date: end_date,
        event_participant: participant != null ? participant : "",
      });
    }

    this.minDate = new Date();
    this.getLocation();
    this.eventFormInIt();
    this.taskEventFormInit();
    this.listParticipent();
    this.listClientList();
    //this.listMatterList('');
    //this.listGenius();
    this.listTask();
    this.populateStartTime();
    this.populateEndTime();
  }

  onClickedOutside(e: Event) {
    const mn = <HTMLCanvasElement>document.getElementById("dropdownMenu");
    const tb = <HTMLCanvasElement>document.getElementById("toggleButton");
    if (e.target !== tb && e.target !== mn) {
      this.searchDropdown = false;
    }
  }
  searchOpen() {
    this.searchDropdown = !this.searchDropdown;
  }
  searchFormInit() {
    this.searchform = this.fb.group({
      event_title: new FormControl(""),
      event_location: new FormControl(""),
      word_no_present: new FormControl(""),
      event_start_date: new FormControl(""),
      event_end_date: new FormControl(""),
      event_calendar: new FormControl(""),
      event_participant: new FormControl(""),
    });
  }
  search(data) {
    localStorage.setItem(
      "event_title",
      data.event_title != "null" ? data.event_title : ""
    );
    localStorage.setItem(
      "word_no_present",
      data.word_no_present != "null" ? data.word_no_present : ""
    );
    localStorage.setItem(
      "location",
      data.event_location != "null" ? data.event_location : ""
    );
    var start_date = new DatePipe("en-US").transform(
      data.event_start_date,
      "yyyy-MM-dd"
    );
    localStorage.setItem("start_date", start_date != "null" ? start_date : "");
    var end_date = new DatePipe("en-US").transform(
      data.event_end_date,
      "yyyy-MM-dd"
    );
    localStorage.setItem("end_date", end_date != "null" ? end_date : "");
    localStorage.setItem(
      "event_calendar",
      data.event_calendar != "null" ? data.event_calendar : ""
    );
    localStorage.setItem(
      "event_participant",
      data.event_participant != "null" ? data.event_participant : ""
    );
    var currentUrl = this.router.url;
    if (currentUrl == "/agenda") {
      this.agenda.getAllEvent();
    } else {
      this.router.navigateByUrl("/agenda");
    }
  }

  reset() {
    this.searchFormInit();
    this.searchDropdown = false;
    location.reload();
  }

  addEventModal() {
    this.getAllGoogleCalender();
    this.getLastTaskId();
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.modalOption.size = "xl";
    this.modalOption.windowClass = "event-custom-class";
    this.modalRef = this.modal.open(this.modalContent, this.modalOption);
  }
  eventFormInIt() {
    this.eventForm = this.fb.group({
      event_title: ["", [Validators.required, removeSpaces]],
      //event_location: ['', [Validators.required, removeSpaces]],
      event_location: new FormControl(""),
      event_type: new FormControl(""),
      event_notes: ["", [Validators.required, removeSpaces]],
      participant: ["", Validators.required],
      event_allday: new FormControl(""),
      associate_cilent: new FormControl(null),
      associate_matter: new FormControl(null),
      associate_genius: new FormControl(""),
      event_start_date: new FormControl(this.globalCalendar.viewDate),
      event_end_date: new FormControl(this.globalCalendar.viewDate),
      //event_alert: ['', Validators.required],
      event_alert: new FormControl(""),
      event_start_time: new FormControl("00:00"),
      event_end_time: new FormControl("00:00"),
      event_custom_alrt_val: new FormControl(0),
      event_custom_alrt_type: new FormControl(""),
      calendar: ["", Validators.required],
    });
  }

  addEvent(post) {
    this.submitted = true;
    if (this.eventForm.valid) {
      var start_date = new DatePipe("en-US").transform(
        post.event_start_date,
        "yyyy-MM-dd"
      );
      var end_date = new DatePipe("en-US").transform(
        post.event_end_date,
        "yyyy-MM-dd"
      );

      let formdata = new FormData();
      formdata.append("event_title", post.event_title);
      formdata.append("event_location", post.event_location);
      formdata.append("event_allday", post.event_allday);
      formdata.append("event_type", post.event_type);
      formdata.append("event_notes", post.event_notes);
      formdata.append("participants", post.participant);
      formdata.append("matter", post.associate_matter);
      formdata.append("client", post.associate_cilent);
      formdata.append("genius", post.associate_genius);
      formdata.append("event_alert", post.event_alert);
      formdata.append("start_date", start_date);
      formdata.append(
        "start_time",
        post.event_start_time != "" ? post.event_start_time : "00:00"
      );
      formdata.append("end_date", end_date);
      formdata.append(
        "end_time",
        post.event_end_time != "" ? post.event_end_time : "00:00"
      );
      formdata.append("event_custom_alert_type", post.event_custom_alrt_type);
      formdata.append("event_custom_alert_val", post.event_custom_alrt_val);
      formdata.append("calendar", post.calendar);

      /*if (post.event_start_time != '') {
        let start_time = post.event_start_time.replace('AM', '').replace('PM', '');
        let sdtime = new Date(start_date + ' ' + start_time + ':00').toISOString();
        formdata.append('start_date_time', sdtime);

      } else {
        let start_time = '00:00';
        let sdtime = new Date(start_date + ' ' + start_time + ':00').toISOString();
        formdata.append('start_date_time', sdtime);

      }

      if (post.event_end_time != '') {
        let end_time = post.event_end_time.replace('AM', '').replace('PM', '');
        let edtime = new Date(end_date + ' ' + end_time + ':00').toISOString();
        formdata.append('end_date_time', edtime);
      } else {
        let end_time = '00:00';
        let edtime = new Date(end_date + ' ' + end_time + ':00').toISOString();
        formdata.append('end_date_time', edtime);

      }*/

      //formdata.append('start_date_time', start_date);
      //formdata.append('start_date_time', end_date);

      this.data.addCalendarEvent(formdata).subscribe(
        (response) => {
          if (response) {
            this.theCheckbox = false;
            this.succ_msg = response["msg"];
            this.blankErrorMsgs();
            setTimeout(() => {
              this.succ_msg = false;
              this.time_flag = false;
              this.cancel();
            }, 2000);
          } else {
            this.succ_msg = "";
          }
        },
        (error) => {
          if (error.error.event_title) {
            this.err_title = error.error.event_title;
          } else {
            this.err_title = "";
          }
          if (error.error.event_notes) {
            this.err_notes = error.error.event_notes;
          } else {
            this.err_notes = "";
          }

          if (error.error.start_date) {
            this.err_start_date = error.error.start_date;
          } else {
            this.err_start_date = "";
          }
          if (error.error.end_date) {
            this.err_end_date = error.error.end_date;
          } else {
            this.err_end_date = "";
          }
          if (error.error.start_time) {
            this.err_start_time = error.error.start_time;
          } else {
            this.err_start_time = "";
          }
          if (error.error.end_time) {
            this.err_end_time = error.error.end_time;
          } else {
            this.err_end_time = "";
          }
          if (error.error.event_custom_alert_val) {
            this.err_alrt_val = error.error.event_custom_alert_val;
          } else {
            this.err_alrt_val = "";
          }

          if (error.error.paticipants) {
            this.err_participants = error.error.paticipants;
          } else {
            this.err_participants = "";
          }

          if (error.error.calendar) {
            this.err_calendar = error.error.calendar;
          } else {
            this.err_calendar = "";
          }
        }
      );
    }
  }

  addTime(event) {
    this.time_flag = !this.time_flag;
    this.theCheckbox = false;
    if (this.time_flag == false) {
      this.eventForm.patchValue({
        event_start_time: "00:00",
        event_end_time: "00:00",
      });
    }
  }

  allDay($event) {
    this.time_flag = false;
    this.theCheckbox = true;
    if (this.time_flag == false) {
      this.eventForm.patchValue({
        event_start_time: "",
        event_end_time: "",
      });
    }
  }

  get eventFormControl() {
    return this.eventForm.controls;
  }
  blankErrorMsgs() {
    this.err_title = "";
    //this.err_location = '';
    this.err_participants = "";
    this.err_notes = "";
    this.err_alert = "";
    this.err_start_time = "";
    this.err_end_date = "";
    this.err_task_title = "";
    this.err_task_note = "";
  }

  displayTask() {
    this.tashFormDisplay = true;
    this.eventFormDisplay = false;
  }

  displayEvent() {
    this.tashFormDisplay = false;
    this.eventFormDisplay = true;
  }

  listClientList() {
    this.data.listClientList().subscribe((data) => {
      this.clientList = data;

      this.clientList = this.clientList.sort(
        (a, b) => b.client_id - a.client_id
      );
      for (var i = 0; i < this.clientList.length; ++i) {
        this.clientList[i].client_name =
          this.clientList[i].client_number +
          " - " +
          this.clientList[i].client_name;
      }
    });
  }

  listMatterList(event) {
    this.listParam.client_id = event.client_id;
    this.data.allMatters(this.listParam).subscribe((data) => {
      this.matterList = data;
      this.matterList = this.matterList.sort(
        (a, b) => b.matter_id - a.matter_id
      );

      for (var i = 0; i < this.matterList.length; ++i) {
        this.matterList[i].matter_name =
          this.matterList[i].matter_number +
          " - " +
          this.matterList[i].matter_name;
      }
    });
  }

  listParticipent() {
    this.contactParam.contact_type = "";
    this.contactParam.pstart = this.pstart;
    this.contactParam.pend = this.pend;
    this.data.getAllListing(this.contactParam).subscribe((data) => {
      this.paticipantList = data;
    });
  }

  listTask() {
    this.data.getTaskList(this.listParam).subscribe((data) => {
      this.taskList = data;
      this.taskList = this.taskList.items;
    });
  }

  taskEventFormInit() {
    this.taskEventForm = this.fb.group({
      title: ["", [Validators.required, removeSpaces]],
      // associate_genius: ['', Validators.required],
      task_date: new FormControl(this.globalCalendar.viewDate),
      task_id: new FormControl(),
      note: ["", [Validators.required, removeSpaces]],
      task_assignee: ["", Validators.required],
      task_assignee_user: ["", Validators.required],
      matter_id: new FormControl(null),
      client_id: new FormControl(null),
    });
  }
  get taskEventFormControl() {
    return this.taskEventForm.controls;
  }
  saveTaskEvent(post) {
    this.submitted = true;
    if (this.taskEventForm.valid) {
      var start_date = new DatePipe("en-US").transform(
        post.task_date,
        "yyyy-MM-dd"
      );
      var date_time = new Date(start_date + " " + "00" + ":00").toISOString();
      let formdata = new FormData();
      formdata.append("title", post.title);
      formdata.append("task_due_date", start_date);
      formdata.append("start_date", start_date);
      formdata.append("task_date_time", date_time);
      formdata.append("end_date", start_date);
      formdata.append("assignee_id", post.task_assignee_user);
      formdata.append("assignee_type", post.task_assignee);
      formdata.append("task_id", post.task_id);
      formdata.append("note", post.note);
      formdata.append("matter_id", post.matter_id);
      formdata.append("client_id", post.client_id);
      this.data.addCalendarTaskEvent(formdata).subscribe(
        (response) => {
          if (response) {
            this.succ_msg = response["msg"];

            setTimeout(() => {
              this.succ_msg = false;
              this.time_flag = false;
              this.cancel();
            }, 2000);
          } else {
            this.succ_msg = "";
          }
        },
        (error) => {
          if (error.error.title) {
            this.err_task_title = error.error.title;
          } else {
            this.err_task_title = "";
          }
          if (error.error.note) {
            this.err_task_note = error.error.note;
          } else {
            this.err_task_note = "";
          }
        }
      );
    }
  }
  cancel() {
    this.globalCalendar.getAllEvents();
    this.blankErrorMsgs();
    this.eventFormInIt();
    this.taskEventFormInit();
    this.time_flag = false;
    this.modalRef.close();
    this.submitted = false;
    this.eventFormDisplay = true;
    this.tashFormDisplay = false;
    this.theCheckbox = false;
    //location.reload();
  }

  getLastTaskId() {
    this.data.getLastTaskId().subscribe((data) => {
      this.lastTaskId = data;
      let numb = this.lastTaskId.toString().replace("T", "");
      let digit = Number(numb);
      if (digit > 0) {
        this.taskEventForm.controls["task_id"].setValue(this.lastTaskId);
      } else {
        this.taskEventForm.controls["task_id"].setValue("T1");
      }
    });
  }

  populateStartTime() {
    var hours, minutes, ampm, hoursValue;
    for (var i = 420; i <= 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hoursValue = hours;
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;

      if (hours === 0) {
        hours = 12;
      }
      let newstartTimeArr = {
        value: hoursValue + ":" + minutes,
        text: hours + ":" + minutes + " " + ampm,
      };
      this.starttime.push(newstartTimeArr);
    }
  }

  populateEndTime() {
    var hours, minutes, ampm, hoursValue;
    for (var i = 420; i <= 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hoursValue = hours;
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;

      if (hours === 0) {
        hours = 12;
      }
      let newendTimeArr = {
        value: hoursValue + ":" + minutes,
        text: hours + ":" + minutes + " " + ampm,
      };
      this.endtime.push(newendTimeArr);
    }
  }

  getLocation() {
    var i = 0;
    var arr: any = [];
    this.data.getEventLocation().subscribe((data) => {
      this.location = data;

      /*for (let index = 0; index < this.location.length; index++) {
          if (this.location[index].location != 'None') {
            arr[i] = this.location[index];
            i++;
          }
        }
        this.location = arr;*/
    });
  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        this.assigneeType = "Client";

        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
          this.assigneeList = this.assigneeList.sort(
            (a, b) => b.client_id - a.client_id
          );
        });
      } else {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      }
    } else {
      this.assigneeList = [];
    }
  }

  getAssociateGenius(event) {
    //event.matter_id;
    return new Promise((resolve, reject) => {
      this.data.getAssociateGenius(event.matter_id).subscribe((data) => {
        this.geniusList = data;
      });
      resolve(null);
    });
  }

  /*listGenius() {
    return new Promise((resolve, reject) => {
        this.data.getTaskAssignee(3).subscribe(
          data => {
            this.geniusList = data;

          });
          resolve(null);
    });	      
  }*/

  getClientName(clientId) {
    if (clientId > 0) {
      let client_name =
        this.clientList.find((data) => data.client_id === clientId)
          .client_number +
        " " +
        this.clientList.find((data) => data.client_id === clientId).client_name;
      return client_name;
    }
  }

  getMatterName(matterId) {
    if (matterId > 0) {
      let matter_name =
        this.matterList.find((data) => data.matter_id === matterId)
          .matter_number +
        " " +
        this.matterList.find((data) => data.matter_id === matterId).matter_name;
      return matter_name;
    }
  }

  openCustomAlert(event) {
    this.customAlert = !this.customAlert;
    if (this.customAlert == false) {
      this.eventForm.patchValue({
        event_custom_alrt_type: "",
        event_custom_alrt_val: 0,
      });
      this.err_alrt_val = "";
    }
  }

  closeCustomAlert(event) {
    this.customAlert = false;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    if (
      item.givenName != null ||
      item.familyName != null ||
      item.middleName != null
    ) {
      return (
        item.givenName.toLocaleLowerCase().indexOf(term) > -1 ||
        item.location.toLocaleLowerCase().indexOf(term) > -1 ||
        item.familyName.toLocaleLowerCase().indexOf(term) > -1 ||
        item.middleName.toLocaleLowerCase().indexOf(term) > -1
      );
    }
  }

  getAllGoogleCalender() {
    this.data.calenderType = localStorage.getItem("calendar_type");
    this.calenderParam.calender_type = this.data.calenderType;
    this.data.getAllGoogleCalender(this.calenderParam).subscribe((data) => {
      this.calendarList = data;
      this.calendarList = this.calendarList.sort((a, b) =>
        a.google_calendar_name
          .normalize()
          .localeCompare(b.google_calendar_name.normalize())
      );
    });
  }
}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, "").length) {
    control.setValue("");
  }
  return null;
}
