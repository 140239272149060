import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DataService } from "../../services/data.service";

@Component({
  selector: "app-matter-lists",
  templateUrl: "./matter-lists.component.html",
  styleUrls: ["./matter-lists.component.scss"],
})
export class MatterListsComponent implements OnInit {
  viewType: any;
  ongoing_matters: boolean = false;
  filed_matters: boolean = false;
  matterTitle: any;
  matterList: any = [];
  sort_order: any;
  sortDir = 1; //1= 'ASE' -1= DSC
  matterCount: any;

  listParam: any = {};
  p: number = 0;
  itemsPerPage: number = 100;
  pstart: number = 0;
  pend: number = 100;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private data: DataService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params) {
        if (params["viewType"]) {
          this.viewType = params["viewType"];
          if (this.viewType == "ongoing_matters") {
            this.ongoing_matters = true;
            this.filed_matters = false;
            this.matterTitle = "Ongoing Matters";
          } else if (this.viewType == "filed_matters") {
            this.ongoing_matters = false;
            this.filed_matters = true;
            this.matterTitle = "Filed Matters";
          }
          this.listParam.filterType = this.viewType;

          this.getMatterList();
          this.getMatterCount();
        }
      }
    });
  }

  getMatterList() {
    this.listParam.pstart = this.pstart;
    this.listParam.pend = this.pend;
    this.data.getMatterList(this.listParam).subscribe((data) => {
      this.matterList = data;

      this.getMatterCount();
    });
  }

  getMatterCount() {
    //this.listParam.filterType = this.viewType;
    this.data.countMatter(this.listParam).subscribe((data) => {
      //if(Object.keys(this.listParam).length === 0){
      this.matterCount = data;
      //}else{
      //this.matterCount = this.matterList.length;
      //}
    });
  }

  searchTaskList(value) {
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.getMatterList();
    this.getMatterCount();
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    let end = start + this.itemsPerPage;
    this.listParam.pageNo = page - 1;

    this.pstart = this.itemsPerPage * this.p + this.itemsPerPage;
    this.pend = this.itemsPerPage;

    this.listParam.start = this.pstart;
    this.listParam.end = this.pend;
    this.getMatterCount();
    this.getMatterList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getMatterList();
  }
}
