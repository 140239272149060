import { Component, OnInit, Input, OnChanges } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmationDialogServiceService } from "../../confirmation-dialog/confirmation-dialog-service.service";

@Component({
  selector: "app-assign-task",
  templateUrl: "./assign-task.component.html",
  styleUrls: ["./assign-task.component.scss"],
})
export class AssignTaskComponent implements OnInit {
  addTaskForm: FormGroup;
  checked: any = true;
  succ_msg: any;
  tid: number = 0;
  assigneeList: any;
  clientList: any;
  taskListID: any;
  matterList: any;
  err_task_name: string;
  err_description: string;
  err_due_date: string;
  err_assignee_type: string;
  err_assignee_id: string;
  err_task_lead: string;
  err_priority: string;
  err_task_status: string;
  p: number = 0;
  listDetails: any;
  taskList: any = [];
  listParam: any = {};
  lastTaskId: any;

  lId: any;
  leadData: any;
  lead_id: any;
  editId: number = 0;
  assigneeType: String = "";

  assigneeListID: any = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    this.taskFormInit();
    //this.getTaskListID();
    this.getLastTaskId();
    this.route.params.subscribe((params) => {
      if (params) {
        if (params["lId"]) {
          this.lId = +params["lId"];

          this.data.getLeadData(this.lId).subscribe((res) => {
            if (res) {
              this.leadData = res;
              this.lead_id = res["lead_id"];
            }
          });
        }
      }
    });
    this.assigneeListByLeadId();
  }

  getLastTaskId() {
    this.data.getLastTaskId().subscribe((data) => {
      this.lastTaskId = data;
      if (this.addTaskForm.controls["task_id"].value == this.lastTaskId) {
        let numb = this.lastTaskId.replace("T", "");
        let digit = Number(numb) + 1;
        this.addTaskForm.controls["task_id"].setValue("T" + digit);
      } else {
        this.addTaskForm.controls["task_id"].setValue(this.lastTaskId);
      }
    });
  }

  getTaskListID() {
    this.data.getTaskListID().subscribe((data) => {
      this.taskListID = data;
    });
  }

  assigneeListByLeadId() {
    this.data.assigneeListByLeadId(this.lId).subscribe((data) => {
      this.assigneeListID = data;
    });
  }

  openConfirmationDialog(t_id) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(t_id, confirmed))
      .catch(() => {});
  }

  deleteListItem(t_id, confirmed) {
    if (confirmed) {
      this.data.deleteassigneeList(t_id, this.lId).subscribe((response) => {
        this.succ_msg = "Deleted Succesfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          //this.getTaskListID();
          this.getLastTaskId();
          this.assigneeListByLeadId();
        }, this.data.timeOut);
      });
    }
  }

  onChange(value) {
    if (value !== "") {
      if (value == 3) {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      } else if (value == 1) {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      } else if (value == "-999") {
        this.assigneeType = "Client";
        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
        });
      } else {
        this.assigneeList = [];
      }
    } else {
      this.assigneeList = [];
    }
  }

  editLeadTask(tId) {
    this.editId = tId;
    this.data.getlistDetails(tId).subscribe((data) => {
      this.listDetails = data;
      this.addTaskForm.patchValue({
        t_id: this.listDetails.t_id,
        task_id: this.listDetails.task_id,
        task_name: this.listDetails.task_name,
        task_description: this.listDetails.description,
        task_due_date: this.listDetails.due_date,
        task_assignee: this.listDetails.assignee_type,
        task_assignee_user: this.listDetails.assignee_id,
        task_priority: this.listDetails.priority,
        task_status: this.listDetails.task_status,
      });
      this.onChange(this.listDetails.assignee_type);
    });
  }

  addTask(postData) {
    const dateSendingToServer = new DatePipe("en-US").transform(
      postData.task_due_date,
      "yyyy-MM-dd"
    );

    let formdata = new FormData();
    formdata.append("task_id", postData.task_id);
    formdata.append("task_name", postData.task_name);
    formdata.append("due_date", dateSendingToServer);
    formdata.append("description", postData.task_description);
    formdata.append("assignee_type", postData.task_assignee);
    formdata.append("assignee_id", postData.task_assignee_user);
    formdata.append("priority", postData.task_priority);
    formdata.append("task_status", postData.task_status);
    formdata.append("task_lead", postData.task_lead);
    formdata.append("add_from", "lead");
    formdata.append("lead_id", this.lead_id);

    if (postData.t_id) {
      formdata.append("t_id", postData.t_id);
    }

    this.data.addTaskpost(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrorMsgs();
          this.taskFormInit();
          setTimeout(() => {
            this.succ_msg = false;
            this.router.navigate([
              "/leads/leads-home/schedule-call/",
              this.lead_id,
            ]);
          }, this.data.timeOut);
          //this.router.navigate(['/contacts']);
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.task_name) {
          this.err_task_name = error.error.task_name;
        } else {
          this.err_task_name = "";
        }

        if (error.error.description) {
          this.err_description = error.error.description;
        } else {
          this.err_description = "";
        }

        if (error.error.due_date) {
          this.err_due_date = error.error.due_date;
        } else {
          this.err_due_date = "";
        }

        if (error.error.assignee_type) {
          this.err_assignee_type = error.error.assignee_type;
        } else {
          this.err_assignee_type = "";
        }

        if (error.error.assignee_id) {
          this.err_assignee_id = error.error.assignee_id;
        } else {
          this.err_assignee_id = "";
        }

        if (error.error.task_lead) {
          this.err_task_lead = error.error.task_lead;
        } else {
          this.err_task_lead = "";
        }

        if (error.error.priority) {
          this.err_priority = error.error.priority;
        } else {
          this.err_priority = "";
        }

        if (error.error.task_status) {
          this.err_task_status = error.error.task_status;
        } else {
          this.err_task_status = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_task_name =
      this.err_description =
      this.err_due_date =
      this.err_assignee_type =
      this.err_assignee_id =
      this.err_task_lead =
      this.err_priority =
      this.err_task_status =
        "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    this.taskFormInit();
    // this.tid =0;
    // this.addTaskForm.controls['task_id'].setValue('T100');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/leads/leads-home/leads-engaged/", this.lead_id]);
  }

  taskFormInit() {
    this.addTaskForm = this.fb.group({
      t_id: new FormControl(""),
      task_id: new FormControl(""),
      task_name: new FormControl(""),
      task_due_date: new FormControl(""),
      task_description: new FormControl(""),
      task_assignee: new FormControl(""),
      task_assignee_user: new FormControl(""),
      task_priority: new FormControl(""),
      task_status: new FormControl(""),
      task_lead: new FormControl(""),
    });
  }
}
