import { Component, OnInit } from "@angular/core";
import { HttpUrlEncodingCodec } from "@angular/common/http";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ConfirmationDialogServiceService } from "../../../../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;
import { environment } from "../../../../../environments/environment";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: "app-done-listings",
  templateUrl: "./done-listings.component.html",
  styleUrls: ["./done-listings.component.scss"],
})
export class DoneListingsComponent implements OnInit {
  codec = new HttpUrlEncodingCodec();
  matterNotesForm: FormGroup;
  succ_msg: any = "";
  err_matter_notes: any = "";
  mId: any;
  listDetails: any;
  list: any;
  tasklist: any;
  filelists: any;
  emailists: any;
  doneProcessLists: any;
  logDetails: any;
  partiesDetails: any = [];
  plannedDetails: any;
  planDetails: any;
  allDet: any;
  allDetails: any;
  noteEdit: boolean = false;
  defaultChoice: boolean = false;
  articleList: any;
  articleFilePath: any;
  sameTagMatterList: any;
  rawPageContent: any;
  polDataArr: any = [];
  POLName: any;

  taskId: number = 0;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "100px",
    minHeight: "100px",
    maxHeight: "100px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "Open Sans",
    defaultFontSize: "",
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["heading", "fontName"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  currentfilter: string = "all";
  allList: boolean = false;
  partiesList: boolean = false;
  notesList: boolean = false;
  workflowList: boolean = false;
  tasksList: boolean = false;
  emailList: boolean = false;
  fileList: boolean = false;
  wikiList: boolean = false;
  changelogList: boolean = false;
  stageList: boolean = false;
  matterDetails: any;
  matterDocs: Object;
  matterFilePath: string;
  netDocViewPath: any;
  matterQuesAns: any = [];
  postdate: any;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogServiceService,
    private sanitizer: DomSanitizer
  ) {
    this.router.events
      .filter((event) => event instanceof NavigationStart)
      .subscribe((event: NavigationStart) => {
        let url = event.url;
        if (
          url == "/matters/matter-home/matter-workflow/" + this.mId ||
          url == "/matters/matter-home/matter-notes/" + this.mId ||
          url == "/matters/matter-home/matter-tasks/" + this.mId ||
          url == "/matters/matter-home/matter-email/" + this.mId ||
          url == "/matters/matter-home/matter-files/" + this.mId
        ) {
          this.getAllMatterNotesList();
          this.getAllMatterTaskList();
          this.getAllMatterFileList();
          this.getAllMatterEmailList();
          this.getAllMatterChangeLog();
          this.getAllPartiesByMatterId();
          this.listPlannedTaskFromMatter();
          this.getAllListFromMatter();
          this.matterDetailsData();
          this.getAllCompleteWorkflowProcessList();
        }
      });
  }

  ngOnInit() {
    this.articleFilePath =
      `${environment.lpmsUrl}` + "documents/comp_cert/articleFiles/";
    this.matterFilePath =
      `${environment.filemakerUrl}` + "table/?action=download_poa";
    this.netDocViewPath =
      `${environment.filemakerUrl}` + "table/?action=preview_doc";
    this.allList = true;
    document.querySelector("body").classList.add("sidebar-toggle");

    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);
    this.matterFormInit();
    this.getAllMatterNotesList();
    this.getAllMatterTaskList();
    this.getAllMatterFileList();
    this.getAllMatterEmailList();
    this.getAllMatterChangeLog();
    this.getAllPartiesByMatterId();
    this.listPlannedTaskFromMatter();
    this.getAllListFromMatter();
    this.matterDetailsData();
    this.getAllCompleteWorkflowProcessList();
    this.getMatterDocuments();

    this.filterLinks(this.currentfilter);
  }

  //   ngOnDestroy() {
  //     document.querySelector('body').classList.remove('sidebar-toggle');
  //   }

  encodeUrlStr(documentid) {
    let urlfilter =
      this.netDocViewPath + "&dID" + documentid + "&d_flg=1&view=1";
    window.open(urlfilter, "_blank");
  }

  displayCounter(count) {
    this.getAllMatterTaskList();
    this.getAllListFromMatter();
  }

  addMatterNotes(postData) {
    let formdata = new FormData();
    formdata.append("matter_notes_id", postData.matter_notes_id);
    formdata.append("matter_notes", postData.matter_notes);
    formdata.append("matter_id", this.mId);

    if (postData.matter_notes_id) {
      formdata.append("matter_notes_id", postData.matter_notes_id);
    }
    this.data.addMatterNotes(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.err_matter_notes = "";
          //this.router.navigate(['/matters/matter-home/matter-workflow/', this.mId]);
          setTimeout(() => {
            this.succ_msg = false;
            this.editCancel(this.list);
            this.allDetails.noteEdit = false;
            this.matterFormInit();
            this.getAllMatterNotesList();
            this.getAllListFromMatter();
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.matter_notes != "") {
          this.err_matter_notes = error.error.matter_notes;
        } else {
          this.err_matter_notes = "";
        }
      }
    );
  }

  editNotes(nID, data) {
    data.noteEdit = true;
    this.editMatterNotesById(nID);
  }
  editCancel(data) {
    data.noteEdit = false;
  }

  editMatterNotesById(nID) {
    this.data.editMatterNotesById(nID).subscribe((data) => {
      this.listDetails = data;
      this.matterNotesForm.patchValue({
        matter_notes_id: this.listDetails.matter_notes_id,
        matter_notes: this.listDetails.matter_notes,
      });
    });
  }

  filterLinks(value) {
    if (value == "notes") {
      this.notesList = true;
      this.tasksList = false;
      this.fileList = false;
      this.emailList = false;
      this.changelogList = false;
      this.partiesList = false;
      this.allList = false;
      this.workflowList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllMatterNotesList();
    }
    if (value == "tasks") {
      this.tasksList = true;
      this.notesList = false;
      this.fileList = false;
      this.emailList = false;
      this.changelogList = false;
      this.partiesList = false;
      this.allList = false;
      this.workflowList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllMatterTaskList();
    }
    if (value == "files") {
      this.fileList = true;
      this.tasksList = false;
      this.notesList = false;
      this.emailList = false;
      this.changelogList = false;
      this.partiesList = false;
      this.allList = false;
      this.workflowList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllMatterFileList();
    }
    if (value == "email") {
      this.emailList = true;
      this.fileList = false;
      this.tasksList = false;
      this.notesList = false;
      this.changelogList = false;
      this.partiesList = false;
      this.allList = false;
      this.workflowList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllMatterEmailList();
    }
    if (value == "changelog") {
      this.changelogList = true;
      this.fileList = false;
      this.tasksList = false;
      this.notesList = false;
      this.emailList = false;
      this.partiesList = false;
      this.allList = false;
      this.workflowList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllMatterChangeLog();
    }
    if (value == "parties") {
      this.partiesList = true;
      this.changelogList = false;
      this.fileList = false;
      this.tasksList = false;
      this.notesList = false;
      this.emailList = false;
      this.allList = false;
      this.workflowList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllPartiesByMatterId();
    }
    if (value == "all") {
      this.allList = true;
      this.partiesList = true;
      this.changelogList = true;
      this.fileList = true;
      this.tasksList = true;
      this.notesList = true;
      this.emailList = true;
      this.workflowList = true;
      this.wikiList = true;
      this.stageList = true;
      this.getAllListFromMatter();
    }
    if (value == "workflow") {
      this.workflowList = true;
      this.allList = false;
      this.partiesList = false;
      this.changelogList = false;
      this.fileList = false;
      this.tasksList = false;
      this.notesList = false;
      this.emailList = false;
      this.wikiList = false;
      this.stageList = false;
      this.getAllCompleteWorkflowProcessList();
      //this.router.navigate(['/matters/matter-home/matter-workflow/', this.mId]);
    }
    if (value == "wiki") {
      this.wikiList = true;
      this.workflowList = false;
      this.allList = false;
      this.partiesList = false;
      this.changelogList = false;
      this.fileList = false;
      this.tasksList = false;
      this.notesList = false;
      this.emailList = false;
      this.stageList = false;
      this.matterDetailsData();
      if (this.matterDetails.type_of_case == 3) {
        var tagId = this.matterDetails.liti_arr.point_of_law;
        this.getPOLNamebyId(tagId);
        this.getArticleSearch();
        this.getSimilarPOLawSearch();
        this.getCaseDecisionByPOL();
      }
    }
    if (value == "stage") {
      this.stageList = true;
      this.wikiList = false;
      this.workflowList = false;
      this.allList = false;
      this.partiesList = false;
      this.changelogList = false;
      this.fileList = false;
      this.tasksList = false;
      this.notesList = false;
      this.emailList = false;
    }
    this.currentfilter = value; 
  }

  getPOLNamebyId(tagId) {
    this.data.getPOLNamebyId(tagId).subscribe((data) => {
      var dArr: any = [];
      dArr = data;
      this.POLName = dArr.point_of_law_name;
    });
  }

  getArticleSearch() {
    this.matterDetailsData();
    var tagId = this.matterDetails.liti_arr.point_of_law;
    this.data.getArticleSearch(tagId).subscribe((data) => {
      this.articleList = data;
    });
  }

  getSimilarPOLawSearch() {
    this.matterDetailsData();
    var tagId = this.matterDetails.liti_arr.point_of_law;

    this.data
      .getSimilarPOLawSearch(tagId, this.matterDetails.matter_id)
      .subscribe((data) => {
        this.sameTagMatterList = data;
      });
  }

  getCaseDecisionByPOL() {
    this.matterDetailsData();
    var tagId = this.matterDetails.liti_arr.point_of_law;
    this.data.getCaseDecisionByPOL(tagId).subscribe((data) => {
      this.polDataArr = data;
    });
  }

  // Matter Notes Listing
  getAllMatterNotesList() {
    this.data.getAllMatterNotesList(this.mId).subscribe((data) => {
      this.list = data;
    });
  }

  matterDetailsData() {
    this.data.getMatterDetByMatterId(this.mId).subscribe((response) => {
  
      if (response) {
        this.matterDetails = response;
        this.matterQuesAns = response["matterQuestionAnsData"];
      }
    });
  }

  openConfirmationDialog(noteId, matterId) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteMatterNotes(noteId, matterId, confirmed))
      .catch(() => {});
  }

  deleteMatterNotes(noteId, matterId, confirmed) {
    if (confirmed) {
      this.data.deleteMatterNotes(noteId, matterId).subscribe((response) => {
        this.getAllMatterNotesList();
        this.succ_msg = response["msg"];
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getAllListFromMatter();
        }, this.data.timeOut);
      });
    }
  }

  matterFormInit() {
    this.matterNotesForm = this.fb.group({
      matter_notes_id: new FormControl(""),
      matter_notes: new FormControl(""),
    });
  }

  // Matter Task

  taskEdit(t_id) {
    // if(id != 0) {
    // 	this.taskId = id;
    //   }else {
    // 	this.taskId = 0;
    // 	jQuery('#editTask').on('hide.bs.modal', ()=> {
    // 		this.listPlannedTaskFromMatter();
    // 		this.getAllMatterTaskList()
    // 	})
    // }
    this.router.navigate([
      "/matters/matter-home/matter-tasks/" + this.mId + "-" + t_id,
    ]);
    jQuery.reload();
    document.querySelector("body").classList.add("sidebar-toggle");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  getAllMatterTaskList() {
    this.data.getAllMatterTaskList(this.mId).subscribe((data) => {
      this.tasklist = data;
    });
  }

  taskConfirmationDialog(tId, matterId) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteMatterTask(tId, matterId, confirmed))
      .catch(() => {});
  }

  deleteMatterTask(tId, matterId, confirmed) {
    if (confirmed) {
      this.data.deleteMatterTask(tId, matterId).subscribe((response) => {
        this.getAllMatterTaskList();
        this.succ_msg = "Deleted Successfully.";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getAllListFromMatter();
        }, this.data.timeOut);
      });
    }
  }

  // Matter Files

  getAllMatterFileList() {
    this.data.getAllMatterFileList(this.mId).subscribe((data) => {
      this.filelists = data;
      //console.log(data);
      for (let i = 0; i < this.filelists.length; i++) {
        this.filelists[i].isPdf = this.filelists[i].doc_url.split('.').pop().toLowerCase() == 'pdf' ? 'y' : 'n';
      }
     // this.postdate = this.filelists[0].post_date;
    });
  }

  // fileConfirmationDialog(tId, matterId) {
  // 	this.confirmationDialogService.confirm('Please Confirm', 'Do you really want to Delete?')
  // 	.then((confirmed) => this.deleteMatterFile(tId, matterId, confirmed))
  // 	.catch(() => {});
  // }

  deleteMatterFile(tId, matterId, confirmed) {
    if (confirmed) {
      this.data.deleteMatterFile(tId, matterId).subscribe((response) => {
        this.getAllMatterFileList();
        this.succ_msg = "Deleted Successfully.";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getAllListFromMatter();
        }, this.data.timeOut);
      });
    }
  }

  fileConfirmationFiledeleteDialog(document_id, matter_file_id, matter_id) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) =>
        this.deleteMatterFilesFromNd(
          document_id,
          matter_file_id,
          matter_id,
          confirmed
        )
      )
      .catch(() => {});
  }
  deleteMatterFilesFromNd(document_id, matter_file_id, matter_id, confirmed) {
    if (confirmed) {
      this.data.deleteDocument(document_id).subscribe((response) => {
        this.deleteMatterFile(matter_file_id, matter_id, confirmed);
        this.getAllMatterFileList();
        this.succ_msg = "Deleted Successfully.";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getAllListFromMatter();
          this.getMatterDocuments();
        }, this.data.timeOut);
      });
    }
  }

  // Matter Emails

  getAllMatterEmailList() {
    this.data.getAllMatterEmailList(this.mId).subscribe((data) => {
      this.emailists = data;
    });
  }

  // Matter Change Log

  getAllMatterChangeLog() {
    this.data.getAllMatterChangeLog(this.mId).subscribe((data) => {
      this.logDetails = data;
    });
  }

  // Matter parties

  getAllPartiesByMatterId() {
    this.data.getAllPartiesByMatterId(this.mId).subscribe((data) => {
      this.partiesDetails = data;
      this.partiesDetails = Array.of(this.partiesDetails);
    });
  }

  // Matter Planned List

  listPlannedTaskFromMatter() {
    this.data.listPlannedTaskFromMatter(this.mId).subscribe((data) => {
      this.planDetails = data;
      this.defaultChoice = this.planDetails.done_status;
    });
  }

  // Matter All List

  getAllListFromMatter() {
    var i = 0;
    var arr: any = [];
    this.data.getAllListFromMatter(this.mId).subscribe((data) => {
      this.allDet = data;
      
      //this.allDetails = Array.of(this.allDetails);
      for (let index = 0; index < this.allDet.length; index++) {
        if (this.allDet[index].data_value != "deleted") {
          arr[i] = this.allDet[index];
          i++;
        }
      }
      this.allDetails = arr;
    });
  }

  // Matter Workflow

  getAllCompleteWorkflowProcessList() {
    this.data.getAllCompleteWorkflowProcessList(this.mId).subscribe((data) => {
      this.doneProcessLists = data;
    });
  }

  getMatterDocuments() {
    this.data.getMatterDetByMatterId(this.mId).subscribe((response) => {
      if (response) {
        this.matterDetails = response;
        let client_number = parseInt(this.matterDetails.client_number, 10);
        let matter_number = parseInt(this.matterDetails.matter_number, 10);
        this.data
          .getClientMatterDocuments(client_number, matter_number)
          .subscribe((response) => {
            if (response) {
              this.matterDocs = response;
            }
          });
      }
    });
  }

  downloadFile(document_id) {
    let url = this.netDocViewPath + '&dID=' + document_id + '&d_flg=1&view=1';
    this.data
      .getRawPageContent(url)
      .subscribe(
        data => this.rawPageContent=this.sanitizer.bypassSecurityTrustHtml(data),
        error => console.log(error)
      );
    jQuery("#downloadFile").modal("show");
  }
}