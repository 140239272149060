import { Component, OnInit, Output , EventEmitter } from '@angular/core';
import { ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  succ_msg: any;
  err_msg: any;
  personImportUrl : String;
  orgImportUrl : String;
  org_err_arr : any;
  person_err_arr: any;
  personList: any;
  orgList : any;
  allList:any;
 
  common_err_msg: any; contactParam: any = {}; importFile: any;

  org_exist: String = '';
  validation_arr: String = '';
  email_exist: String = '';
  country_code: String = '';
  country_name: String = '';
  issue_country: String = '';
  type: String = '';
  person_gender: String = '';
  marital_status: String = '';
  maritial_regime: String = '';
  org_country: String = '';
  entity_type_arr: String = '';
  org_reg_tag: String = '';

  common_err_msg1 : String = '';
  org_exist1: String  = '';
  validation_arr1: String  = '';
  email_exist1: String  = '';
  country_code1: String  = '';

  @ViewChild('peopleImportInput', { static: false }) peopleImportInput: any;
  @ViewChild('orgImportInput', { static: false }) orgImportInput: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  constructor(private fb: FormBuilder, private data: DataService, private router: Router) { }

  ngOnInit() {
    this.personImportUrl = `${environment.lpmsUrl}`+'documents/comp_cert/importCsv/add_persons.csv';
    this.orgImportUrl = `${environment.lpmsUrl}`+'documents/comp_cert/importCsv/add_organization.csv';
    
  }

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  tabClear(){   
      this.orgImportInput.nativeElement.value = null;
      let fileName2 = document.getElementById('orgInputName')
	  fileName2.innerHTML = ''
	  
      this.peopleImportInput.nativeElement.value = null;      
      let fileName = document.getElementById('peopleInputName')
	  fileName.innerHTML = ''
	  
	  this.err_msg = false
	  this.importFile = ''

     
      this.blankErroMsg();
  }

  fileChangeListener($event: any): void {

    let files = $event.srcElement.files;  

    let fileName = document.getElementById('peopleInputName')
    fileName.innerHTML = files[0].name;
    
    let fileName2 = document.getElementById('orgInputName')
    fileName2.innerHTML = files[0].name;

    if (this.isCSVFile(files[0])) {
      //const files = $event.target.files[0].name;
      const files = $event.target.files[0];
      this.importFile = files;
    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }
  fileReset() {
    this.peopleImportInput.nativeElement.value = "";
    this.orgImportInput.nativeElement.value = "";
  }

  getPersonList() {
    this.contactParam.contact_type = 'PERS';
    this.data.getAllListing(this.contactParam).subscribe(
      data => {
        this.personList = data;
      }
    );
  }
  getOrgListing() {
    this.contactParam.contact_type = 'ORG';
    this.data.getAllListing(this.contactParam).subscribe(
      data => {
        this.orgList = data;
      }
    );
  }
  getAllList(){
    this.contactParam.contact_type = '';
    this.data.getAllListing(this.contactParam).subscribe(
      data => {
        this.allList = data;
    
      }
    );
  }

  dataUpload(fileType) {
    this.common_err_msg =  '';
    this.common_err_msg1 = '';
    this.blankErroMsg();
    $('.custom-file-label').empty();
    var empt = document.forms["orgForm"]["org_file_upload"].value;
    let formdata = new FormData();
    formdata.append('from_csv', '1');
    if(this.importFile != undefined && this.importFile != null && this.importFile != ''){
      //alert("Please import valid .csv file.");
      if (fileType == 'org' && empt != "") {
        formdata.append('org_import_file', this.importFile);
        this.data.contactAddOrganisation(formdata).subscribe(response => {
          this.org_err_arr = response;
          var error_msg = '';		
          if (this.org_err_arr.err) {
          for (let index = 0; index < this.org_err_arr.err.length; index++) {
            var element = this.org_err_arr.err[index];
            var ind = index+1;
            error_msg = "In row, "+ind+" list of error(s) are, ";
          
            this.org_exist1 = element.org_exist;
            this.validation_arr1 = element.validation_err;
            this.email_exist1 = element.email_exist;
            this.country_code1 = element.country_code;
            this.org_country = element.org_country;
            this.entity_type_arr = element.entity_type_arr;
            this.org_reg_tag = element.org_reg_tag;	
            this.err_msg = false
            this.err_msg = ''
          
          }
            this.common_err_msg1 = error_msg;
          }else{
            this.succ_msg = response['msg'];
            this.succ_msg = 'Data Uploaded Successfully';
            setTimeout(() => {
              this.succ_msg = false;
              this.err_msg = false	
              this.err_msg = ''
              this.common_err_msg1 = ''
              this.closeModal.emit();
              this.blankErroMsg();
              this.getAllList()
              this.getPersonList()
              this.getOrgListing()
              this.orgImportInput.nativeElement.value = null;
              let fileName2 = document.getElementById('orgInputName')
              fileName2.innerHTML = ''
            }, this.data.timeOut);
          }
      
        });
      } else if (fileType == 'people') {
        formdata.append('person_import_file', this.importFile);
        this.data.contactAddPerson(formdata).subscribe(response => {
          this.person_err_arr = response;
          var error_msg = '';
          if (this.person_err_arr.err) {
            for (let index = 0; index < this.person_err_arr.err.length; index++) {
              var element = this.person_err_arr.err[index];
              var ind = index+1;
              this.org_exist = element.org_exist;
              this.validation_arr = element.validation_arr;
              this.email_exist = element.email_exist;
              this.country_code = element.country_code;
              this.country_name = element.country_name;
              this.issue_country = element.issue_country;
              this.type = element.type;
              this.person_gender = element.person_gender;
              this.marital_status = element.marital_status;
              this.maritial_regime = element.maritial_regime;
              this.err_msg = false
              this.err_msg = ''
            }
            error_msg += "In row, "+ind+" list of error(s) are, ";
            this.common_err_msg = error_msg;
          }else{
            this.succ_msg = response['msg'];
            this.succ_msg = 'Data Uploaded Successfully';
            setTimeout(() => {
              this.succ_msg = false;	
              this.err_msg = false
              this.err_msg = ''
              this.common_err_msg = ''
              this.closeModal.emit();
              this.blankErroMsg();
              this.getAllList()
              this.getPersonList()
              this.getOrgListing()
              this.peopleImportInput.nativeElement.value = null;      
              let fileName = document.getElementById('peopleInputName')
              fileName.innerHTML = ''
            }, this.data.timeOut);
          }
      
        });
      }
      else {
        this.err_msg = ''
      }
    }else{
      this.err_msg = "Please import valid .csv file."
    }

    

    // setTimeout(() => {
    //   this.succ_msg = '';
    //   this.succ_msg = false;
    //   this.closeModal.emit();
    // }, this.data.timeOut);

  }

  clickCancel(){
    this.closeModal.emit();	
    this.blankErroMsg();
    this.getAllList()
    this.getPersonList()
    this.getOrgListing()
  }

   blankErroMsg(){
      this.common_err_msg = '';
      this.org_exist= '';
      this.validation_arr = '';
      this.email_exist = '';
      this.country_code = '';
      this.country_name = '';
      this.issue_country = '';
      this.type = '';
      this.person_gender = '';
      this.marital_status = '';
      this.maritial_regime = '';

      this.common_err_msg1 = '';
      this.org_exist1 = '';
      this.validation_arr1 = '';
      this.email_exist1 = '';
      this.country_code1 = '';
      this.org_country = '';
      this.entity_type_arr  = '';
      this.org_reg_tag = '';
      
   }



}


