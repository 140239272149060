import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-view-process-st',
  templateUrl: './view-process-st.component.html',
  styleUrls: ['./view-process-st.component.scss']
})
export class ViewProcessStComponent implements OnInit {
  @Input() processId: number;
  constructor(private data: DataService) { }
  processDetails:any =[];
  ngOnInit() {
  }

  public ngOnChanges( changes: SimpleChanges ) : void {   
    let chg = changes['processId'];
    let cval = chg.currentValue;
    if (chg.currentValue > '0' && chg.currentValue == cval) {
      this.getProcessDetails();
     
    }else {      

        
	  }
	
  }

  getProcessDetails(){
    this.data.getProcessstDetails(this.processId).subscribe(
			data => {
				this.processDetails = data;
				
			}
    );
  }

}
