import { Component, OnInit, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
} from "@angular/forms";
import { AuthenticationService } from "../../services/auth.service";
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
declare var jQuery: any;

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  token: any;
  Username: string;
  userForm: FormGroup;
  msg: string;
  err_first_name: string;
  err_last_name: string;
  err_email: string;
  err_confirm_email: string;
  err_password: string;
  err_confirm_password: string;
  err_password_mismatch: string;
  err_user_type: string;
  err_user_group: string;
  err_inbox_email: string;
  err_inbox_password: string;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private googleAuthService: AuthService
  ) {
    auth.getLoggedInName.subscribe((name: string) => this.changeName(name));
  }

  ngOnInit() {
    this.Username = localStorage.getItem("Username");

    this.userForm = this.fb.group({
      first_name: new FormControl(""),
      last_name: new FormControl(""),
      email: new FormControl(""),
      confirm_email: new FormControl(""),
      password: new FormControl(""),
      confirm_password: new FormControl(""),
      user_type: new FormControl(""),
      user_group: new FormControl(""),
      inbox_email: new FormControl(""),
      inbox_password: new FormControl(""),
    });
  }

  createUser(data) {
    let formdata = new FormData();
    formdata.append("first_name", data.first_name);
    formdata.append("last_name", data.last_name);
    formdata.append("email", data.email);
    formdata.append("confirm_email", data.confirm_email);
    formdata.append("confirm_password", data.confirm_password);
    formdata.append("password", data.password);
    formdata.append("confirm_password", data.confirm_password);
    formdata.append("user_type", data.user_type);
    formdata.append("user_group", data.user_group);
    formdata.append("inbox_email", data.inbox_email);
    formdata.append("inbox_password", data.inbox_password);
    this.auth.createUser(formdata).subscribe(
      (response) => {
        if (response) {
          this.msg = response["msg"];
          this.userForm.reset();
          this.clickCancel();
        } else {
          this.msg = "";
        }
        window.setTimeout(() => {
          this.msg = "";
        }, 1500);
      },
      (error) => {
        if (error.error.first_name) {
          this.err_first_name = error.error.first_name;
        } else {
          this.err_first_name = "";
        }
        if (error.error.last_name) {
          this.err_last_name = error.error.last_name;
        } else {
          this.err_last_name = "";
        }
        if (error.error.email) {
          this.err_email = error.error.email;
        } else {
          this.err_email = "";
        }
        if (error.error.confirm_email) {
          this.err_confirm_email = error.error.confirm_email;
        } else {
          this.err_confirm_email = "";
        }

        if (error.error.password) {
          this.err_password = error.error.password;
        } else {
          this.err_password = "";
        }
        if (error.error.confirm_password) {
          this.err_confirm_password = error.error.confirm_password;
        } else {
          this.err_confirm_password = "";
        }
        if (error.error.inbox_email) {
          this.err_inbox_email = error.error.inbox_email;
        } else {
          this.err_inbox_email = "";
        }
        if (error.error.inbox_password) {
          this.err_inbox_password = error.error.inbox_password;
        } else {
          this.err_inbox_password = "";
        }
        if (error.error.user_group) {
          this.err_user_group = error.error.user_group;
        } else {
          this.err_user_group = "";
        }
        if (error.error.user_type) {
          this.err_user_type = error.error.user_type;
        } else {
          this.err_user_type = "";
        }
      }
    );
  }

  private changeName(name: string): void {
    this.Username = name;
  }

  clickCancel() {
    this.err_first_name =
      this.err_last_name =
      this.err_email =
      this.err_confirm_email =
      this.err_password =
      this.err_confirm_password =
      this.err_password_mismatch =
      this.err_user_type =
      this.err_user_group =
      this.err_inbox_email =
      this.err_inbox_password =
        "";
  }

  formInit() {
    this.userForm = new FormGroup({
      first_name: new FormControl(""),
      last_name: new FormControl(""),
      email: new FormControl(""),
      confirm_email: new FormControl(""),
      password: new FormControl(""),
      confirm_password: new FormControl(""),
      user_type: new FormControl(""),
      user_group: new FormControl(""),
      inbox_email: new FormControl(""),
      inbox_password: new FormControl(""),
    });
  }
}
