import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  Self,
} from "@angular/core";
import { isSameDay } from "date-fns";
import { Subject } from "rxjs";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarDateFormatter,
} from "angular-calendar";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ConfirmationDialogServiceService } from "../../confirmation-dialog/confirmation-dialog-service.service";
import { AbstractControl } from "@angular/forms";
import { CustomDateFormatter } from "./custom-date-formatter.provider";
import { setHours, setMinutes } from "date-fns";

const colors: any = {
  red: {
    primary: "#ad2121",
    // secondary: '#FAE3E3',
  },
  blue: {
    primary: "#1e90ff",
    //secondary: '#D1E8FF',
  },
  yellow: {
    primary: "#e3bc08",
    // secondary: '#FDF1BA',
  },
  gray: {
    primary: "#A9A9A9",
    secondary: "#808080",
  },
  orange: {
    primary: "#FFA500",
    // secondary: '#FF4500'
  },
};

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-calendar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./global-calendar.component.html",
  styleUrls: ["./global-calendar.component.scss"],
  providers: [
    /* { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, */ DatePipe,
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class GlobalCalendarComponent implements OnInit {
  @ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;
  @ViewChild("viewmodalContent", { static: true })
  viewmodalContent: TemplateRef<any>;
  isOpenCal: boolean = true;
  eventEditForm: FormGroup;
  taskEventForm: FormGroup;
  paticipantList: any;
  contactParam: any = {};
  clientList: any;
  matterList: any;
  geniusList: any;
  succ_msg: any = "";
  time_flag: boolean = false;
  listParam: any = {};
  taskList: any = [];
  location: any = [];
  selectedParticipants: any;
  tashFormDisplay: boolean = false;
  eventFormDisplay: boolean = true;
  starttime: any = [];
  endtime: any = [];
  err_title: string;
  err_location: string;
  err_notes: string;
  err_participants: string;
  err_alert: string;
  err_start_date: string;
  err_end_date: string;
  err_start_time: string;
  err_end_time: string;
  err_task_title: string;
  err_task_note: string;
  err_alrt_val: string;
  participants = (term) => ({ email: term });
  listEvent: any;
  events: CalendarEvent[] = [];
  submitted: boolean = false;
  modalRef: any = [];
  lastTaskId: any;
  colours;
  eventClass;
  modalOption: NgbModalOptions = {};
  assigneeList: any;
  assigneeType: String = "";
  minDate: Date;
  newendTimeArr: any;
  eventViewData: any;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  userTypeId: any;
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;
  customAlert: boolean = false;
  calendarList: any = [];
  selectedClient: any;
  calenderParam: any = {};
  theCheckbox: boolean;

  bnClass: string;
  ipClass: string;
  golbalClass: string;
  isGlobalDivShown: boolean = true;
  isIpDivShown: boolean = true;
  isbnDivShown: boolean = true;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private datePipe: DatePipe,
    @Self() private cdr: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    this.userTypeId = localStorage.getItem("UserType");
    this.data.calenderType = localStorage.getItem("calendar_type");

    if (this.data.calDashboardDate) {
      this.viewDate = this.data.calDashboardDate;
    }
    if (localStorage.getItem("cal_view") == "day") {
      this.view = this.CalendarView.Day;
    } else if (localStorage.getItem("cal_view") == "month") {
      this.view = this.CalendarView.Month;
    } else if (localStorage.getItem("cal_view") == "week") {
      this.view = this.CalendarView.Week;
    } else {
      this.view = this.CalendarView.Month;
    }

    this.minDate = new Date();
    this.getAllGoogleCalender();
    this.getAllEvents();
    this.listParticipent();
    this.listClientList();
    this.listMatterList("");
    //this.listGenius();
    this.eventEditFormInIt();
    this.taskEventFormInit();
    this.listTask();
    this.getLastTaskId();
    this.populateStartTime();
    this.populateEndTime();
    this.getLocation();

    if (this.data.calenderType === "ip") {
      this.ipClass = "active";
      this.bnClass = "";
      this.golbalClass = "";
    } else if (this.data.calenderType === "bn") {
      this.ipClass = "";
      this.bnClass = "active";
      this.golbalClass = "";
    } else {
      this.ipClass = "";
      this.bnClass = "";
      this.golbalClass = "active";
    }

    if (this.userTypeId == 4) {
      this.isGlobalDivShown = false;
      this.isIpDivShown = true;
      this.isbnDivShown = false;
      this.router.navigate(["/ipcalendar"]);
    } else if (this.userTypeId == 3) {
      this.isGlobalDivShown = false;
      this.isIpDivShown = false;
      this.isbnDivShown = true;
      this.router.navigate(["/bncalendar"]);
    } else {
      this.isGlobalDivShown = true;
      this.isIpDivShown = true;
      this.isbnDivShown = true;

      if (this.data.calenderType === "ip") {
        this.router.navigate(["/ipcalendar"]);
      } else if (this.data.calenderType === "bn") {
        this.router.navigate(["/bncalendar"]);
      } else {
        this.router.navigate(["/globalcalendar"]);
      }
    }
  }

  setClickGobalCalender() {
    var monthyear = new DatePipe("en-US").transform(new Date(), "yyyy-MM");
    this.data.calenderParam.event_date = monthyear;

    this.data.calenderType = localStorage.setItem("calendar_type", "global");

    this.bnClass = "";
    this.ipClass = "";
    this.golbalClass = "active";

    if ((this.userTypeId = 1)) {
      this.isGlobalDivShown = true;
      this.isIpDivShown = true;
      this.isbnDivShown = true;
    } else {
      this.isGlobalDivShown = false;
      this.isIpDivShown = false;
      this.isbnDivShown = false;
    }

    this.getAllGoogleCalender();
    this.getAllEvents();
  }

  setClickIpCalender() {
    var monthyear = new DatePipe("en-US").transform(new Date(), "yyyy-MM");
    this.data.calenderParam.event_date = monthyear;

    this.data.calenderType = localStorage.setItem("calendar_type", "ip");
    this.bnClass = "";
    this.ipClass = "active";
    this.golbalClass = "";

    if ((this.userTypeId = 1)) {
      this.isGlobalDivShown = true;
      this.isIpDivShown = true;
      this.isbnDivShown = true;
    } else {
      this.isGlobalDivShown = false;
      this.isIpDivShown = true;
      this.isbnDivShown = false;
    }

    this.getAllGoogleCalender();
    this.getAllEvents();
  }

  setClickBnCalender() {
    var monthyear = new DatePipe("en-US").transform(new Date(), "yyyy-MM");
    this.data.calenderParam.event_date = monthyear;

    this.data.calenderType = localStorage.setItem("calendar_type", "bn");
    this.bnClass = "active";
    this.ipClass = "";
    this.golbalClass = "";

    if ((this.userTypeId = 1)) {
      this.isGlobalDivShown = true;
      this.isIpDivShown = true;
      this.isbnDivShown = true;
    } else {
      this.isGlobalDivShown = false;
      this.isIpDivShown = false;
      this.isbnDivShown = true;
    }

    this.getAllGoogleCalender();
    this.getAllEvents();
  }

  showCalender() {
    this.isOpenCal = !this.isOpenCal;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    //  if (isSameMonth(date, this.viewDate)) {

    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
    this.viewDate = date;
    //}
  }

  dayClickedThumb({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent[];
  }): void {
    //  if (isSameMonth(date, this.viewDate)) {
    //this.view = this.CalendarView.Day;
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
    this.viewDate = date;
    //}
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: "sm" });
  }

  viewEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.eventViewData = event["data"];
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.modalOption.size = "sm";

    if (
      event["data"]["event_custom_alert_type"] == "week" &&
      event["data"]["event_custom_alert_val"] > 1
    ) {
      if (event["data"]["event_custom_alert_val"] > 1) {
        this.eventViewData["custom_alert"] =
          event["data"]["event_custom_alert_val"] + " weeks";
      } else {
        this.eventViewData["custom_alert"] =
          event["data"]["event_custom_alert_val"] + " week";
      }
    } else if (
      event["data"]["event_custom_alert_type"] == "day" &&
      event["data"]["event_custom_alert_val"] > 1
    ) {
      if (event["data"]["event_custom_alert_val"] > 1) {
        this.eventViewData["custom_alert"] =
          event["data"]["event_custom_alert_val"] + " days";
      } else {
        this.eventViewData["custom_alert"] =
          event["data"]["event_custom_alert_val"] + " week";
      }
    } else if (
      event["data"]["event_custom_alert_type"] == "hour" &&
      event["data"]["event_custom_alert_val"] > 1
    ) {
      if (event["data"]["event_custom_alert_val"] > 1) {
        this.eventViewData["custom_alert"] =
          event["data"]["event_custom_alert_val"] + " hours";
      } else {
        this.eventViewData["custom_alert"] =
          event["data"]["event_custom_alert_val"] + " hour";
      }
    }
    //  if (event['data']['calendar_type'] == 'task' || this.eventViewData['calendar_type'] == 'event') {
    if (event["data"]["matter"] > 0) {
      this.data
        .getMatterNameByMatterId(this.eventViewData["matter"])
        .subscribe((response) => {
          this.eventViewData["matter_name"] = response["matter_name"];
          this.eventViewData["matter_name"] = this.eventViewData[
            "matter_name"
          ].replace(/[^a-zA-Z ]/g, " ");
        });
    }
    if (event["data"]["client"] > 0) {
      this.data
        .getClientDetByClientId(this.eventViewData["client"])
        .subscribe((data) => {
          this.eventViewData["client_name"] =
            data["given_name"] +
            " " +
            data["middle_name"] +
            " " +
            data["family_name"];
        });
    }
    if (
      event["data"]["calendar_type"] == "event" &&
      this.eventViewData["genius"] > 0
    ) {
      this.data
        .getGinuesById(this.eventViewData["genius"], 3)
        .subscribe((data) => {
          this.eventViewData["genius_name"] =
            data["first_name"] +
            " " +
            data["middle_name"] +
            " " +
            data["last_name"];
        });
    }
    if (event["data"]["assignee_id"] > 0) {
      this.eventViewData["assign_id"] = event["data"]["assignee_id"];
      if (event["data"]["assignee_type"] == -999) {
        this.eventViewData["assign_type"] = "Client Task";
        this.data
          .getClientDetByClientId(event["data"]["assignee_id"])
          .subscribe((data) => {
            this.eventViewData["genius_name"] =
              data["given_name"] +
              " " +
              data["middle_name"] +
              " " +
              data["family_name"];
          });
      } else {
        if (event["data"]["assignee_type"] == 1) {
          this.eventViewData["assign_type"] = "Everyone’s Task";
        } else if (event["data"]["assignee_type"] == 3) {
          this.eventViewData["assign_type"] = "Genius Task";
        }
        this.data
          .getGinuesById(
            event["data"]["assignee_id"],
            event["data"]["assignee_type"]
          )
          .subscribe((data) => {
            this.eventViewData["genius_name"] =
              data["first_name"] +
              " " +
              data["middle_name"] +
              " " +
              data["last_name"];
          });
      }
    }
    this.modalRef.push(
      this.modal.open(this.viewmodalContent, this.modalOption)
    );
  }

  cancel() {
    this.blankErrorMsgs();
    this.eventEditFormInIt();
    this.time_flag = false;
    this.submitted = false;
    this.eventFormDisplay = true;
    this.getLastTaskId();
    this.theCheckbox = false;
    let popped = this.modalRef.pop();
    popped.close();
  }

  editEventPopup(event, iEvent) {
    this.tashFormDisplay = false;
    this.eventFormDisplay = true;
    this.editEventModal("Edited", event, iEvent);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.viewDate;
    var monthyear = new DatePipe("en-US").transform(this.viewDate, "yyyy-MM");
    this.data.calenderParam.event_date = monthyear;

    this.getAllEvents();

    //this.activeDayIsOpen = false;
  }

  blankErrorMsgs() {
    this.err_title =
      this.err_notes =
      this.err_start_time =
      this.err_end_date =
      this.err_task_title =
      this.err_task_note =
      this.err_alrt_val =
        "";
  }

  listClientList() {
    this.data.listClientList().subscribe((data) => {
      this.clientList = data;
      this.clientList = this.clientList.sort(
        (a, b) => b.client_id - a.client_id
      );
    });
  }

  listMatterList(event) {
    this.listParam.client_id = event.client_id;
    this.data.allMatters(this.listParam).subscribe((data) => {
      this.matterList = data;
      this.matterList = this.matterList.sort(
        (a, b) => b.matter_id - a.matter_id
      );
    });
  }

  listParticipent() {
    this.contactParam.contact_type = "";
    this.data.getAllListing(this.contactParam).subscribe((data) => {
      this.paticipantList = data;
    });
  }

  /*listGenius() {
    this.data.getTaskAssignee(3).subscribe((data) => {
      this.geniusList = data;
    });
  }*/

  getAssociateGenius(event) {
    //event.matter_id;
    return new Promise((resolve, reject) => {
      this.data.getAssociateGenius(event.matter_id).subscribe((data) => {
        this.geniusList = data;
      });
      resolve(null);
    });
  }

  addTime(event) {
    this.theCheckbox = false;
    this.time_flag = !this.time_flag;
    if (this.time_flag == false) {
      this.eventEditForm.patchValue({
        event_start_time: "00:00",
        event_end_time: "00:00",
      });
    }
  }

  allDay($event) {
    this.time_flag = false;
    this.theCheckbox = true;
    if (this.time_flag == false) {
      this.eventEditForm.patchValue({
        event_start_time: "",
        event_end_time: "",
      });
    }
  }

  getAllEvents() {
    this.data.calenderParam.userTypeId = localStorage.getItem("UserType");
    this.data.calenderParam.calendar_type =
      localStorage.getItem("calendar_type");

    if (this.data.calenderParam.event_date) {
      this.data.calenderParam.event_date = this.data.calenderParam.event_date;
    } else {
      this.viewDate;
      var monthyear = new DatePipe("en-US").transform(this.viewDate, "yyyy-MM");
      this.data.calenderParam.event_date = monthyear;
    }

    this.data.getAllEvent(this.data.calenderParam).subscribe((data) => {
      localStorage.removeItem("cal_view");
      this.listEvent = data;

      if (this.listEvent.length > 0) {
        this.events = this.listEvent.map((iEvent) => {
          if (iEvent.start_date != "" && iEvent.calendar_type == "event") {
            if (
              iEvent.start_time != iEvent.end_time &&
              iEvent.end_time != "00:00:00" &&
              iEvent.end_time != "23:59:00" &&
              iEvent.start_time != null &&
              iEvent.end_time != null
            ) {
              var startHour = iEvent.start_time.slice(0, 2);
              var startMin = iEvent.start_time.slice(3, 5);
              var endHour = iEvent.end_time.slice(0, 2);
              var endMin = iEvent.end_time.slice(3, 5);
              return {
                data: iEvent,
                start: setHours(
                  setMinutes(new Date(iEvent.start_date), startMin),
                  startHour
                ),
                end: setHours(
                  setMinutes(new Date(iEvent.end_date), endMin),
                  endHour
                ),
                title:
                  iEvent.event_title +
                  " <b>" +
                  iEvent.google_calendar_name +
                  "</b>",
                short_title:
                  iEvent.event_title + " - " + iEvent.google_calendar_name,
                //allDay: true,
                // cssClass: iEvent.user_type_id == 3 ? 'bnEvent' : 'ipEvent',
                // event_color: iEvent.user_type_id == 3 ? 'red' : 'yellow',
                cssClass: iEvent.color,
                event_color: iEvent.color,

                actions: [
                  {
                    label:
                      iEvent.added_by == localStorage.getItem("UserId")
                        ? '<i class="fa fa-pencil" aria-hidden="true"></i>'
                        : "",
                    a11yLabel: "Edit",
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      this.tashFormDisplay = false;
                      this.eventFormDisplay = true;

                      this.editEventModal("Edited", event, iEvent);
                    },
                  },
                  {
                    label:
                      iEvent.added_by == localStorage.getItem("UserId")
                        ? '<i class="fa fa-trash" aria-hidden="true"></i>'
                        : "",
                    a11yLabel: "Delete",
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      // this.events = this.events.filter((iEvent) => iEvent !== event);
                      this.openConfirmationDialog(iEvent.id, event);
                    },
                  },
                ],
              };
            } else if (
              iEvent.start_date != iEvent.end_date &&
              iEvent.start_time == iEvent.end_time &&
              iEvent.start_time != null &&
              iEvent.end_time != null
            ) {
              var startHour = iEvent.start_time.slice(0, 2);
              var startMin = iEvent.start_time.slice(3, 5);
              var endHour = iEvent.end_time.slice(0, 2);
              var endMin = iEvent.end_time.slice(3, 5);
              return {
                data: iEvent,
                start: setHours(
                  setMinutes(new Date(iEvent.start_date), startMin),
                  startHour
                ),
                end: setHours(
                  setMinutes(new Date(iEvent.end_date), endMin),
                  endHour
                ),
                title:
                  iEvent.event_title +
                  " <b>" +
                  iEvent.google_calendar_name +
                  "</b>",
                short_title:
                  iEvent.event_title + " - " + iEvent.google_calendar_name,
                allDay: true,
                // cssClass: iEvent.user_type_id == 3 ? 'bnEvent' : 'ipEvent',
                // event_color: iEvent.user_type_id == 3 ? 'red' : 'yellow',
                cssClass: iEvent.color,
                event_color: iEvent.color,

                actions: [
                  {
                    label:
                      iEvent.added_by == localStorage.getItem("UserId")
                        ? '<i class="fa fa-pencil" aria-hidden="true"></i>'
                        : "",
                    a11yLabel: "Edit",
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      this.tashFormDisplay = false;
                      this.eventFormDisplay = true;

                      this.editEventModal("Edited", event, iEvent);
                    },
                  },
                  {
                    label:
                      iEvent.added_by == localStorage.getItem("UserId")
                        ? '<i class="fa fa-trash" aria-hidden="true"></i>'
                        : "",
                    a11yLabel: "Delete",
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      // this.events = this.events.filter((iEvent) => iEvent !== event);
                      this.openConfirmationDialog(iEvent.id, event);
                    },
                  },
                ],
              };
            } else {
              return {
                data: iEvent,
                start: new Date(iEvent.start_date),
                end: new Date(iEvent.end_date),
                title:
                  iEvent.event_title +
                  " <b>" +
                  iEvent.google_calendar_name +
                  "</b>",
                short_title:
                  iEvent.event_title + " - " + iEvent.google_calendar_name,
                allDay: true,
                //cssClass: iEvent.user_type_id == 3 ? 'bnEvent' : 'ipEvent',
                //event_color: iEvent.user_type_id == 3 ? 'red' : 'yellow',
                cssClass: iEvent.color,
                event_color: iEvent.color,

                actions: [
                  {
                    label:
                      iEvent.added_by == localStorage.getItem("UserId")
                        ? '<i class="fa fa-pencil" aria-hidden="true"></i>'
                        : "",
                    a11yLabel: "Edit",
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      this.tashFormDisplay = false;
                      this.eventFormDisplay = true;

                      this.editEventModal("Edited", event, iEvent);
                    },
                  },
                  {
                    label:
                      iEvent.added_by == localStorage.getItem("UserId")
                        ? '<i class="fa fa-trash" aria-hidden="true"></i>'
                        : "",
                    a11yLabel: "Delete",
                    onClick: ({ event }: { event: CalendarEvent }): void => {
                      // this.events = this.events.filter((iEvent) => iEvent !== event);
                      this.openConfirmationDialog(iEvent.id, event);
                    },
                  },
                ],
              };
            }
          } else if (iEvent.calendar_type == "task") {
            return {
              data: iEvent,
              start: new Date(iEvent.due_date),
              end: new Date(iEvent.due_date),
              title: iEvent.task_name,

              // cssClass: iEvent.user_type_id == 3 ? 'bnTask' : 'ipTask',
              //event_color: iEvent.user_type_id == 3 ? 'red' : 'yellow',
              cssClass: iEvent.color,
              event_color: iEvent.color,
              allDay: true,
              actions: [
                {
                  label:
                    iEvent.added_by == localStorage.getItem("UserId")
                      ? '<i class="fa fa-pencil" aria-hidden="true"></i>'
                      : "",
                  a11yLabel: "Edit",
                  onClick: ({ event }: { event: CalendarEvent }): void => {
                    this.tashFormDisplay = true;
                    this.eventFormDisplay = false;
                    this.editEventModal("Edited", event, iEvent);
                  },
                },
                {
                  label:
                    iEvent.added_by == localStorage.getItem("UserId")
                      ? '<i class="fa fa-trash" aria-hidden="true"></i>'
                      : "",
                  a11yLabel: "Delete",
                  onClick: ({ event }: { event: CalendarEvent }): void => {
                    // this.events = this.events.filter((iEvent) => iEvent !== event);
                    this.openConfirmationDialog(iEvent.id, event);
                  },
                },
              ],
            };
          } else {
            return {
              data: iEvent,
              start: new Date(iEvent.start_date),
              end: new Date(iEvent.end_date),
              title:
                iEvent.event_title +
                " <b>" +
                iEvent.google_calendar_name +
                "</b>",
              short_title:
                iEvent.event_title + " - " + iEvent.google_calendar_name,
              allDay: true,
              // cssClass: 'eholiday',
              // event_color: 'grey',
              cssClass: iEvent.color,
              event_color: iEvent.color,
              meta: {
                type: "holiday",
                iEvent,
              },
            };
          }
        });

        this.cdr.markForCheck();
      } else {
        this.listEvent = [];
      }
    });
  }

  eventEditFormInIt() {
    this.eventEditForm = this.fb.group({
      event_id: new FormControl(""),
      google_event_id: new FormControl(""),
      event_title: ["", [Validators.required, removeSpaces]],
      //event_location: ['', [Validators.required, removeSpaces]],
      event_location: new FormControl(""),
      event_type: new FormControl(""),
      event_allday: new FormControl(""),
      event_notes: ["", [Validators.required, removeSpaces]],
      //participant: ['', Validators.required],
      participant: new FormControl(""),
      associate_cilent: new FormControl(""),
      associate_matter: new FormControl(""),
      associate_genius: new FormControl(""),
      event_start_date: new FormControl(this.viewDate),
      event_end_date: new FormControl(this.viewDate),
      //event_alert: ['', Validators.required],
      event_alert: new FormControl(""),
      event_start_time: new FormControl("00:00"),
      event_end_time: new FormControl("00:00"),
      event_custom_alrt_val: new FormControl(0),
      event_custom_alrt_type: new FormControl(""),
      calendar: new FormControl(""),
    });
  }
  get eventEditFormControl() {
    return this.eventEditForm.controls;
  }
  editEventModal(action: string, event: CalendarEvent, eventDetails) {
    this.modalData = { event, action };
    this.time_flag = false;
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.modalOption.size = "lg";
    this.modalOption.windowClass = "event-custom-class";
    this.modalRef.push(this.modal.open(this.modalContent, this.modalOption));

    if (eventDetails.all_day === "true") {
      this.theCheckbox = true;
    } else {
      this.theCheckbox = false;
    }

    if (eventDetails.task_id > 0) {
      let taskDetails;
      this.data.getlistDetails(eventDetails.task_id).subscribe(
        (taskDetails = (data) => {
          this.taskEventForm.patchValue({
            title: data.task_name,
            task_date: data.due_date,
            task_assignee_user: data.assignee_id,
            task_assignee: data.assignee_type,
            t_id: data.t_id,
            event_id: eventDetails.id,
            google_task_id: eventDetails.google_task_id,
            note: data.description,
          });

          this.onChange(eventDetails.assignee_type);
        })
      );
    } else {
      let startTime;
      let endTime;
      this.selectedClient = eventDetails.client;

      if (
        eventDetails.start_time != "00:00:00" &&
        eventDetails.end_time != "23:59:00"
      ) {
        this.time_flag = true;
        var stTime = this.rtrim(eventDetails.start_time, "00");
        var staTime = this.rtrim(stTime, ":");
        startTime = this.ltrim(staTime, "0");

        var eTime = this.rtrim(eventDetails.end_time, "00");
        var edTime = this.rtrim(eTime, ":");
        endTime = this.ltrim(edTime, "0");
      } else {
        this.time_flag = false;
        startTime = "00:00";
        endTime = "00:00";
      }
      if (
        eventDetails.event_alert == "CUSTOM" &&
        eventDetails.event_custom_alert_type != ""
      ) {
        this.customAlert = true;
      } else {
        this.customAlert = false;
      }
      this.eventEditForm.patchValue({
        event_id: eventDetails.id,
        google_event_id: eventDetails.google_event_id,
        event_title: eventDetails.event_title,
        event_location: eventDetails.event_location,
        event_type: eventDetails.event_type,
        event_notes: eventDetails.event_notes,
        participant: eventDetails.participants,
        associate_cilent: eventDetails.client,
        associate_matter: eventDetails.matter,
        associate_genius: eventDetails.genius,
        event_start_date: eventDetails.start_date_time,
        event_end_date: eventDetails.end_date_time,
        event_alert: eventDetails.event_alert,
        event_start_time: startTime,
        event_end_time: endTime,
        event_custom_alrt_val: eventDetails.event_custom_alert_val,
        event_custom_alrt_type: eventDetails.event_custom_alert_type,
        calendar: eventDetails.g_cal_id,
      });

      var parr = eventDetails.participants.split(",");
      const temparry = [];
      parr.map((item) => {
        temparry.push(item);
      });
      this.selectedParticipants = temparry;
    }
  }

  editEvent(post) {
    this.submitted = true;
    if (this.eventEditForm.valid) {
      let formdata = new FormData();
      formdata.append("event_id", post.event_id);
      formdata.append("calendar", post.calendar);
      formdata.append("google_event_id", post.google_event_id);
      formdata.append("event_title", post.event_title);
      formdata.append("event_location", post.event_location);
      formdata.append("all_day", post.event_allday);
      formdata.append("event_type", post.event_type);
      formdata.append("event_notes", post.event_notes);
      formdata.append("participants", post.participant);
      formdata.append("matter", post.associate_matter);
      formdata.append("client", post.associate_cilent);
      formdata.append("genius", post.associate_genius);
      formdata.append("event_alert", post.event_alert);
      formdata.append("start_date", post.event_start_date);
      formdata.append("end_date", post.event_end_date);

      formdata.append(
        "start_time",
        post.event_start_time != "" ? post.event_start_time : "00:00"
      );
      formdata.append(
        "end_time",
        post.event_end_time != "" ? post.event_end_time : "00:00"
      );

      //formdata.append("start_date_time",event_start_time != "" ? event_start_time : "00:00");
      //formdata.append("end_date_time",event_end_time != "" ? event_end_time : "00:00");

      formdata.append("event_custom_alert_type", post.event_custom_alrt_type);
      formdata.append("event_custom_alert_val", post.event_custom_alrt_val);

      /*if (
        (post.event_start_time != "" || post.event_start_time != "00:00") &&
        this.time_flag == true
      ) {
        let start_time = post.event_start_time
          .replace("AM", "")
          .replace("PM", "");
        let sdtime = new Date(
          start_date + " " + start_time + ":00"
        ).toISOString();
        formdata.append("start_date_time", sdtime);
      } else {
        let start_time = "00:00";
        let sdtime = new Date(start_date + " " + "00:00" + ":00").toISOString();
        formdata.append("start_date_time", sdtime);
      }
      if (
        (post.event_end_time != "" || post.event_end_time != "00:00") &&
        this.time_flag == true
      ) {
        let end_time = post.event_end_time.replace("AM", "").replace("PM", "");
        let edtime = new Date(end_date + " " + end_time + ":00").toISOString();
        formdata.append("end_date_time", edtime);
      } else {
        let end_time = "00:00";
        let edtime = new Date(end_date + " " + "00:00" + ":00").toISOString();
        formdata.append("end_date_time", edtime);
      }*/

      /*if (post.event_start_time != '') {
        let start_time = post.event_start_time.replace('AM', '').replace('PM', '');
        let sdtime = new Date(start_date + ' ' + start_time + ':00').toISOString();
        formdata.append('start_date_time', sdtime);

      } else {
        let start_time = '00:00';
        let sdtime = new Date(start_date + ' ' + start_time + ':00').toISOString();
        formdata.append('start_date_time', sdtime);

      }

      if (post.event_end_time != '') {
        let end_time = post.event_end_time.replace('AM', '').replace('PM', '');
        let edtime = new Date(end_date + ' ' + end_time + ':00').toISOString();
        formdata.append('end_date_time', edtime);
      } else {
        let end_time = '00:00';
        let edtime = new Date(end_date + ' ' + end_time + ':00').toISOString();
        formdata.append('end_date_time', edtime);

      }*/

      this.data.editCalendarEvent(formdata).subscribe(
        (response) => {
          if (response) {
            this.theCheckbox = false;
            this.succ_msg = response["msg"];
            setTimeout(() => {
              this.succ_msg = false;
              this.getAllEvents();
              // this.eventEditFormInIt();
              this.time_flag = false;
              this.cancel();
            }, this.data.timeOut);
          } else {
            this.succ_msg = "";
          }
        },
        (error) => {
          if (error.error.event_title) {
            this.err_title = error.error.event_title;
          } else {
            this.err_title = "";
          }

          if (error.error.paticipants) {
            this.err_participants = error.error.paticipants;
          } else {
            this.err_participants = "";
          }

          if (error.error.event_notes) {
            this.err_notes = error.error.event_notes;
          } else {
            this.err_notes = "";
          }

          if (error.error.start_date) {
            this.err_start_date = error.error.start_date;
          } else {
            this.err_start_date = "";
          }
          if (error.error.end_date) {
            this.err_end_date = error.error.end_date;
          } else {
            this.err_end_date = "";
          }
          if (error.error.start_time) {
            this.err_start_time = error.error.start_time;
          } else {
            this.err_start_time = "";
          }
          if (error.error.end_time) {
            this.err_end_time = error.error.end_time;
          } else {
            this.err_end_time = "";
          }
          if (error.error.event_custom_alert_val) {
            this.err_alrt_val = error.error.event_custom_alert_val;
          } else {
            this.err_alrt_val = "";
          }
        }
      );
    }
  }

  openConfirmationDialog(eventId, event) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to delete this event?")
      .then((confirmed) => this.deleteListItem(eventId, confirmed))
      .catch(() => {});
  }

  deleteListItem(eventId, confirmed) {
    if (confirmed) {
      this.data.deleteEvent(eventId).subscribe((response) => {
        this.getAllEvents();
        this.succ_msg = "Deleted Successfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
        }, this.data.timeOut);
      });
    }
  }

  listTask() {
    this.data.getTaskList(this.listParam).subscribe((data) => {
      this.taskList = data;
      this.taskList = this.taskList.items;
    });
  }

  taskEventFormInit() {
    this.taskEventForm = this.fb.group({
      title: ["", [Validators.required, removeSpaces]],
      task_assignee: ["", Validators.required],
      task_assignee_user: ["", Validators.required],
      task_date: new FormControl(this.viewDate),
      task_id: new FormControl(),
      t_id: new FormControl(),
      event_id: new FormControl(),
      google_task_id: new FormControl(),
      note: ["", [Validators.required, removeSpaces]],
    });
  }
  get taskEventFormControl() {
    return this.taskEventForm.controls;
  }

  updateTaskEvent(post) {
    this.submitted = true;
    if (this.taskEventForm.valid) {
      var start_date = new DatePipe("en-US").transform(
        post.task_date,
        "yyyy-MM-dd"
      );
      let formdata = new FormData();
      var date_time = new Date(start_date + " " + "00" + ":00").toISOString();
      formdata.append("title", post.title);
      formdata.append("task_due_date", start_date);
      formdata.append("start_date", start_date);
      formdata.append("task_date_time", date_time);
      formdata.append("end_date", start_date);
      formdata.append("assignee_id", post.task_assignee_user);
      formdata.append("assignee_type", post.task_assignee);
      formdata.append("task_id", post.task_id);
      formdata.append("note", post.note);
      formdata.append("eventId", post.event_id);
      formdata.append("t_id", post.t_id);
      formdata.append("google_task_id", post.google_task_id);

      this.data.updateCalendarTaskEvent(formdata).subscribe(
        (response) => {
          if (response) {
            this.succ_msg = response["msg"];

            setTimeout(() => {
              this.succ_msg = false;
              this.time_flag = false;
              this.cancel();
            }, 2000);
          } else {
            this.succ_msg = "";
          }
        },
        (error) => {
          if (error.error.title) {
            this.err_task_title = error.error.title;
          } else {
            this.err_task_title = "";
          }
          if (error.error.note) {
            this.err_task_note = error.error.note;
          } else {
            this.err_task_note = "";
          }
        }
      );
    }
  }

  displayTask() {
    this.tashFormDisplay = true;
    this.eventFormDisplay = false;
  }

  displayEvent() {
    this.tashFormDisplay = false;
    this.eventFormDisplay = true;
  }

  getLastTaskId() {
    return new Promise((resolve, reject) => {
      this.data.getLastTaskId().subscribe((data) => {
        this.lastTaskId = data;
        let numb = this.lastTaskId.toString().replace("T", "");
        let digit = Number(numb);
        if (digit > 0) {
          this.taskEventForm.controls["task_id"].setValue(this.lastTaskId);
        } else {
          this.taskEventForm.controls["task_id"].setValue("T1");
        }
      });
      resolve(null);
    });
  }

  populateStartTime() {
    var hours, minutes, ampm, hoursValue;
    for (var i = 420; i <= 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hoursValue = hours;
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;

      if (hours === 0) {
        hours = 12;
      }
      let newstartTimeArr = {
        value: hoursValue + ":" + minutes,
        text: hours + ":" + minutes + " " + ampm,
      };
      this.starttime.push(newstartTimeArr);
    }
  }

  populateEndTime() {
    var hours, minutes, ampm, hoursValue;
    for (var i = 420; i <= 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      hoursValue = hours;
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;

      if (hours === 0) {
        hours = 12;
      }
      let newendTimeArr = {
        value: hoursValue + ":" + minutes,
        text: hours + ":" + minutes + " " + ampm,
      };
      this.endtime.push(newendTimeArr);
    }
  }
  rtrim(str, chars) {
    chars = chars || "s";
    return str.replace(new RegExp("[" + chars + "]+$", "g"), "");
  }
  ltrim(str, chars) {
    chars = chars || "s";
    return str.replace(new RegExp("^[" + chars + "]+", "g"), "");
  }

  getLocation() {
    var i = 0;
    var arr: any = [];
    this.data.getEventLocation().subscribe((data) => {
      this.location = data;
      for (let index = 0; index < this.location.length; index++) {
        if (this.location[index].location != "None") {
          arr[i] = this.location[index];
          i++;
        }
      }
      this.location = arr;
    });
  }

  onChange(value) {
    if (value !== "") {
      if (value == "-999") {
        this.assigneeType = "Client";
        this.data.listClientList().subscribe((data) => {
          this.assigneeList = data;
        });
      } else {
        this.assigneeType = "Other";
        this.data.getTaskAssignee(value).subscribe((data) => {
          this.assigneeList = data;
        });
      }
    } else {
      this.assigneeList = [];
    }
  }

  getClientName(clientId) {
    if (clientId > 0) {
      let client_name =
        this.clientList.find((data) => data.client_id === clientId)
          .client_number +
        " " +
        this.clientList.find((data) => data.client_id === clientId).client_name;
      return client_name;
    }
  }

  getMatterName(matterId) {
    if (matterId > 0) {
      let matter_name =
        this.matterList.find((data) => data.matter_id === matterId)
          .matter_number +
        " " +
        this.matterList.find((data) => data.matter_id === matterId).matter_name;
      return matter_name;
    }
  }

  openCustomAlert(event) {
    this.customAlert = !this.customAlert;
    if (this.customAlert == false) {
      this.eventEditForm.patchValue({
        event_custom_alrt_type: "",
        event_custom_alrt_val: 0,
      });
      this.err_alrt_val = "";
    }
  }

  closeCustomAlert(event) {
    this.customAlert = false;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    if (
      item.givenName != null ||
      item.familyName != null ||
      item.middleName != null
    ) {
      return (
        item.givenName.toLocaleLowerCase().indexOf(term) > -1 ||
        item.location.toLocaleLowerCase().indexOf(term) > -1 ||
        item.familyName.toLocaleLowerCase().indexOf(term) > -1 ||
        item.middleName.toLocaleLowerCase().indexOf(term) > -1
      );
    }
  }

  /*getAllGoogleCalender(){
    
    this.data.calenderType = localStorage.getItem('calendar_type');
    this.calenderParam.calender_type = this.data.calenderType;
    this.data.getAllGoogleCalender(this.calenderParam).subscribe(
      data => {
        this.calendarList = data;
      }
    );
  }*/

  getAllGoogleCalender() {
    return new Promise((resolve) => {
      this.data.calenderType = localStorage.getItem("calendar_type");
      this.calenderParam.calender_type = this.data.calenderType;
      this.data.getAllGoogleCalender(this.calenderParam).subscribe((data) => {
        this.calendarList = data;
      });
      resolve(null);
    });
  }

  filterCalendar(event, trade) {
    const checked = event.target.checked;
    trade.forEach((item) => (item.selected = checked));
  }

  filterEvent(eventid) {
    this.data.calenderParam.eventId = eventid;
    this.getAllEvents();
  }
}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, "").length) {
    control.setValue("");
  }
  return null;
}
