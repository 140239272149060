import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-agreement-signed',
  templateUrl: './agreement-signed.component.html',
  styleUrls: ['./agreement-signed.component.scss']
})
export class AgreementSignedComponent implements OnInit {

  lId:any;
  confirm:boolean=false;
  agreementForm:FormGroup;
  succ_msg : any;
  lead_id : any;
  err_agreement_signed : any;
  list: any;
  isChecked: boolean = false;
  name:any;
  constructor(private fb: FormBuilder, private data: DataService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
   
    this.route.params.subscribe(params => {
      this.lId = +params['lId'];
      this.lead_id = this.lId;
    });
    this.aggreementInit();
    
    this.data.getLeadData( this.lId ).subscribe(res => {
      
      if(res){
       
        this.list = res;
        this.name = res['given_name']+' '+res['family_name']; 
        
        if(this.list.aggreement_signed == 1){
					this.list.aggreement_signed = true;
				}else{
					this.list.aggreement_signed = false;
        }
        this.agreementForm.patchValue({
          s_call_id:this.list.s_call_id,
          aggreement_signed: (this.list.aggreement_signed == 1)? true: false,
        });
        if(this.list.aggreement_signed == 1){
          this.isChecked = true;
        }else{
          this.isChecked = false;
        }
      }
    });
  }

  valChange(val){
	  if(this.confirm == true) {
		  
	  }else {
		  
	  }
  }

  agreementSigned(postData){
   
    let formdata = new FormData();	
    formdata.append('agreement_signed', postData.agreement_signed);
    formdata.append('lead_id', this.lead_id);
    this.data.agreementSigned(formdata).subscribe(response => {      
			if(response){
			  this.succ_msg = response['msg'];
			  this.blankErrorMsgs();
			  this.aggreementInit();
			  setTimeout(() => {
				 this.succ_msg = false;	
			  	 this.router.navigate(['/leads/leads-home/registered-client/', this.lead_id]);		   
			  }, this.data.timeOut);
			}else{
			  this.succ_msg = '';
			}
			
      },
      error => {
        if (error.error.agreement_signed) {
          this.err_agreement_signed = error.error.agreement_signed;
        } else {
          this.err_agreement_signed = '';
        }
      });
  }

  aggreementInit(){
    this.agreementForm = this.fb.group({
			agreement_signed: new FormControl(''),
		})
  }
  blankErrorMsgs(){
    this.err_agreement_signed = '';
  }

  clickBack(){
	  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate(['/leads/leads-home/generate-quote/', this.lead_id]);
  }

  /* clickNext(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate(['/leads/leads-home/registered-client/', this.lead_id]);
  } */

}

