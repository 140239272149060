import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

//   public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
//     return new DatePipe(locale).transform(date, 'EEE', locale);
//   }

//   public monthViewTitle({ date, locale }: DateFormatterParams): string {
//     return new DatePipe(locale).transform(date, 'MMM y', locale);
//   }

//   public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
//     return new DatePipe(locale).transform(date, 'EEE', locale);
//   }

    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale).transform(date, 'HH:mm', locale);
    }
    // public weekViewTitle({ date, locale }: DateFormatterParams): string {
    //     return new DatePipe(locale).transform(date, 'dd/MM/yyyy', locale);
    // }

    public dayViewTitle({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale).transform(date, 'EEEE dd/MM/yyyy', locale);
    }
}
