import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  url: string = '';
  urlSafe: SafeResourceUrl;
  loader: boolean = true;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    $(document).ready(function(){
      $(".toggle").click(function(){
        $(this).toggleClass('active');
        $(this).next('.toggleWrapper').slideToggle('fast');
        return false;
      });

      $(".hide").click(function(){
        $(this).toggleClass('active');
        $(this).parent().parent().parent().parent().toggleClass('active');
        return false;
      });

      var hgt = $(document).height();
	    $("#iFrame").height(hgt);
    });

    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });

    
    setTimeout(() => {
      this.loader = false;
      
      }, 4000);

    //this.url = `${environment.filemakerUrl}`+"table/?action=open_doc_list&client_id=2010&matter_id=5&from_lpms=1";
    this.url = `${environment.filemakerUrl}`+"table/?action=open_doc_list&from_lpms=1&from_other=1";
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
