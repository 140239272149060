import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  FormGroup,
  FormBuilder,
  FormControl
} from "@angular/forms";
import { environment } from "src/environments/environment";
declare var jQuery: any;
import { Router } from "@angular/router";

@Component({
  selector: "app-article-details",
  templateUrl: "./article-details.component.html",
  styleUrls: ["./article-details.component.scss"],
})
export class ArticleDetailsComponent implements OnInit {
  articleId: any;
  articleDetails: any = [];
  comments: string;
  count: number;
  searchBox: boolean = false;
  succ_msg: any;
  emails = (term) => ({ email: term });
  users: any = [];
  articleShareForm: FormGroup;
  param: any = {};
  err_comment: string;
  err_email: string = "";
  translateSrcLang: any;
  translateDestiLang: any;
  translatedDescription: boolean;
  translatedDescriptionContent: any;
  articleParam: any = {};
  relatedArticles: any = [];
  articleFilePath: any;
  favoriteBtn: boolean = false;
  files: any = [];
  htmlString: string;
  state$: Observable<object>;
  
  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private fb: FormBuilder,
    public router: Router
  ) {}

  ngOnInit() {
    jQuery(function () {
      jQuery(document).on("click", ".dropdown-menu", function (e) {
        e.stopPropagation(); // This replace if conditional.
      });
    });
    this.route.params.subscribe((params: Params) => {
      this.articleId = +params["aId"];
      if (this.articleId > 0) {
        this.getArticleDetails(this.articleId);
      }
    });

    this.articleShareFormInit();
    this.listContactEmail();
    this.articleViewCountadd();
    this.articleFilePath =
      `${environment.lpmsUrl}` + "documents/comp_cert/articleFiles/";
  }

  searchArticleList(value) {
    localStorage.setItem("headerSearchstring", value);
    var currentUrl = this.router.url;
    if (currentUrl == "/knowledge-base") {
      //location.reload();
    } else {
      this.router.navigateByUrl("/knowledge-base");
    }
  }

  recieveCount($event) {
    this.comments = $event;
    this.count = this.comments.length;
  }

  shareTo() {
    this.articleShareForm = this.fb.group({
      emails: new FormControl(""),
      comment: new FormControl(""),
    });
    jQuery("#shareModal").modal("show");
  }
  close() {
    jQuery("#shareModal").modal("hide");
  }

  articleShareFormInit() {
    this.articleShareForm = this.fb.group({
      emails: new FormControl(""),
      comment: new FormControl(""),
    });
  }

  shareArticle(post) {
    let formdata = new FormData();
    formdata.append("email", post.emails);
    formdata.append("comment", post.comment);
    formdata.append("base_url", location.origin);
    formdata.append("url", "knowledge-base/article/" + this.articleId);
    this.data.shareArticle(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrMsg();
          setTimeout(() => {
            this.succ_msg = false;
            this.close();
          }, 2000);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.comment) {
          this.err_comment = error.error.comment;
        } else {
          this.err_comment = "";
        }

        if (error.error.email) {
          this.err_email = error.error.email;
        } else {
          this.err_email = "";
        }
      }
    );
  }

  listContactEmail() {
    this.param.contact_type = "";
    this.data.getAllListing(this.param).subscribe((data) => {
      this.users = data;
    });
  }

  blankErrMsg() {
    this.err_comment = "";
    this.err_email = "";
  }

  translateContent(description, title) {
    jQuery(".dropdown-toggle").dropdown("hide");

    let formdata = new FormData();

    formdata.append("title", title);
    formdata.append("description", description);

    if (this.translateSrcLang != "") {
      this.translateSrcLang = this.translateSrcLang;
    } else {
      this.translateSrcLang = "en";
    }
    if (this.translateDestiLang != "") {
      this.translateDestiLang = this.translateDestiLang;
    } else {
      this.translateDestiLang = "ch";
    }

    formdata.append("src", this.translateSrcLang);
    formdata.append("desti", this.translateDestiLang);

    this.data.translateArticle(formdata).subscribe((data) => {
      if (data["status"] == 1) {
        this.translatedDescriptionContent = data["translated_description"];
        this.translatedDescription = true;
      }
    });
  }

  changeSrc(src) {
    if (src != "") {
      this.translateSrcLang = src;
    } else {
      this.translateSrcLang = "en";
    }
  }

  changeDesti(desti) {
    if (desti != "") {
      this.translateDestiLang = desti;
    } else {
      this.translateDestiLang = "ch";
    }
  }

  getRelatedArticles(article_id, category_id) {
    this.articleParam.category_id = category_id;
    this.articleParam.article_id = article_id;
    this.articleParam.selectedSearchKey = "related_article";

    this.data.getArticles(this.articleParam).subscribe((data) => {
      this.relatedArticles = data;
    });
  }

  favorite() {
    let formdata = new FormData();
    if (this.favoriteBtn == false) {
      formdata.append("fav_status", "yes");
    } else {
      formdata.append("fav_status", "no");
    }

    formdata.append("article_id", this.articleId);

    this.data.makeFavouriteArticle(formdata).subscribe((data) => {
      if (data) {
        this.getArticleDetails(this.articleId);
      }
    });
  }

  getArticleDetails(articleId) {
    this.data.getArticleDetails(articleId).subscribe((data) => {
      if (data["favourite"] == "yes") {
        this.favoriteBtn = true;
      } else {
        this.favoriteBtn = false;
      }
      if (data) {
        this.articleDetails = data;
        this.htmlString = this.decodeHtml(this.articleDetails.description);
        this.files = this.articleDetails.file;
        
        this.files = this.files.map((id, index) => {
          return {
            file_url: this.articleFilePath + encodeURIComponent(this.files[index].file_url),
            name: this.files[index].name
          };
        });
        
        this.getRelatedArticles(
          this.articleDetails.id,
          this.articleDetails.article_catagory_id
        );
      } else {
        this.articleDetails = "";
      }
    });
  }

  decodeHtml(str) {

    var map = {
      "&amp;": "&",
      "&quot;": '"',
      "&#039;": "'",
    };
    return str.replace(/&amp;|&quot;|&#039;/g, function (m) {
      return map[m];
    });
  }

  articleViewCountadd() {
    let formdata = new FormData();

    formdata.append("article_id", this.articleId);

    this.data.articleViewCount(formdata).subscribe((data) => {});
  }

  openEditArticle(article_id) {
    this.router.navigateByUrl("/knowledge-base", { state: { editArtcile: "popup", articleId: article_id} });
  }
}
