import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material';
import { LeadsEngagedComponent } from './leads-engaged/leads-engaged.component';
import { RouterModule } from '@angular/router';
import { AssignTaskComponent } from './assign-task/assign-task.component';
import { ScheduleCallComponent } from './schedule-call/schedule-call.component';
import { GenerateQuoteComponent } from './generate-quote/generate-quote.component';
import { AgreementSignedComponent } from './agreement-signed/agreement-signed.component';
import { VerifyCompanyComponent } from './verify-company/verify-company.component';
import { RegisteredClientComponent } from './registered-client/registered-client.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadsHomeComponent } from './leads-home.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';




@NgModule({
  declarations: [
    LeadsEngagedComponent, 
    AssignTaskComponent, 
    ScheduleCallComponent, 
    GenerateQuoteComponent, 
    AgreementSignedComponent, 
    VerifyCompanyComponent, 
    RegisteredClientComponent,
    LeadsHomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule
  ]
})
export class LeadsModule { }
