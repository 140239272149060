import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogServiceService } from 'src/app/confirmation-dialog/confirmation-dialog-service.service';

@Component({
  selector: 'app-process-st-list',
  templateUrl: './process-st-list.component.html',
  styleUrls: ['./process-st-list.component.scss']
})
export class ProcessStListComponent implements OnInit {
  processList: any = [];
  p: number = 0; itemsPerPage: number = 10;
  succ_msg: any;
  constructor(private fb: FormBuilder, private data: DataService, private router: Router, 
    private confirmationDialogService: ConfirmationDialogServiceService, 
    public activatedRoute: ActivatedRoute) { }

  listParam: any = {};
  processId:number = 0;
  sortDir = 1;//1= 'ASE' -1= DSC
  ngOnInit() {
    this.getProcessList();
  }

  getProcessList() {
    this.data.getprocessStlist(this.listParam).subscribe(
      data => {
        this.processList = data;
        
    });
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.listParam.pageNo = page - 1;
	this.getProcessList();
  }

  processEdit(id){
    if(id != 0) {
			this.processId = id;
		  }else {
			this.processId = 0;
		}
  }

  processstView(id){
    if(id != 0) {
			this.processId = id;
		  }else {
			this.processId = 0;
		}
  }

  openConfirmationDialog(processId) {
		this.confirmationDialogService.confirm('Please Confirm', 'Do you really want to Delete?')
			.then((confirmed) => this.deleteListItem(processId, confirmed))
			.catch(() => {});
	}

	

	deleteListItem(processId, confirmed) {
		if (confirmed) {
			this.data.deleteProcess(processId).subscribe(response => {
				this.getProcessList();
				this.succ_msg = "Deleted Successfully";
				setTimeout(() => {
					this.succ_msg = '';
					this.succ_msg = false;
				}, this.data.timeOut);
				}
			);
		}
  }
  
  searchList(value) {
    this.listParam.searchString = value;
      this.getProcessList();
  }

  filterMatterType(value){
    this.listParam.matterString = value;
      this.getProcessList();
  }

  sort(val: any,event) {
    let target = event.currentTarget,
    classList = target.classList;

    if (classList.contains('fa-caret-up')) {
      classList.remove('fa-caret-up');
      classList.add('fa-caret-down');
      this.sortDir= -1;
    } else {
      classList.add('fa-caret-up');
      classList.remove('fa-caret-down');
      this.sortDir= 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == 'desc') {
        this.listParam.sortOrder = 'asc';
      } else if (this.listParam.sortOrder == 'asc') {
        this.listParam.sortOrder = 'desc';
      } else {
        this.listParam.sortOrder = 'asc';
      }

    }
    this.getProcessList();
    
  }

  


}
