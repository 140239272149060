import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
} from "@angular/core";

import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { DataService } from "../../services/data.service";
import { environment } from "../../../environments/environment";
import { DashboardCalendarComponent } from "src/app/calendar/dashboard-calendar/dashboard-calendar.component";
import { DatePipe } from "@angular/common";
declare var jQuery: any;
@Component({
  selector: "app-bnip",
  templateUrl: "./bnip.component.html",
  styleUrls: ["./bnip.component.scss"],
})
export class BnipComponent implements OnInit {
  @ViewChild("container", { static: true, read: ViewContainerRef })
  entry: ViewContainerRef;
  client_number: number;
  filling_number: number;
  totalClient: any;
  totalTask: any;
  totalMatter: any;
  recentMatter: any;
  recentClient: any;
  recentTask: any;
  dashboardParam: any = {};
  deadlineParam: any = {};
  interval1: any = "today";
  interval2: any = "today";
  user_type: number = 4;
  matterList1: any = [];
  matterList2: any = [];
  taskList: any = [];
  articleParam: any = {};
  clientParam: any = {};
  articles: any = [];
  clientList: any = [];
  url: string = "";
  deadlines: any = [];
  filemakerUrl: string;
  applications: any = [];
  state$: Observable<object>;
  geniusList: any;
  month: any;
  date: any;
  deadlinesTotal: any = [];
  articleCategories: any = [];
  sortDir = 1; //1= 'ASE' -1= DSC
  bnlawyers: boolean = false;
  bnoperations: boolean = false;
  bnip: boolean = false;
  taskId: number = 0;
  personDetailsId: number = 0;
  personView: boolean = false;

  kbSubs: any;

  constructor(
    private data: DataService,
    public router: Router,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.data.calenderType = localStorage.setItem("calendar_type", "ip");
    this.data.userTypeId = localStorage.getItem("UserType");
    this.articleParam.intervalArticle = 0;
    this.articleParam.category = 0;

    if (this.data.userTypeId == 3) {
      this.bnlawyers = true;
      this.bnip = false;
      this.bnoperations = false;
    } else if (this.data.userTypeId == 4) {
      this.bnlawyers = false;
      this.bnip = true;
      this.bnoperations = false;
    } else {
      this.bnlawyers = true;
      this.bnip = true;
      this.bnoperations = true;
    }

    this.dashboardParam.intervalMatter = "today";
    this.dashboardParam.intervalClient = "today";
    this.deadlineParam.interval = "today";
    //this.articleParam.intervalArticle = 7;
    const myFactory = this.resolver.resolveComponentFactory(
      DashboardCalendarComponent
    );
    const myRef = this.entry.createComponent(myFactory);
    myRef.instance.calMonth.subscribe((response) => {
      let month = new DatePipe("en-US").transform(response, "MM");
      this.month = month;
      this.date = response;
    });
    this.getDashboardTotalActiveClient();
    this.getDashboardRecentClient();
    this.getDashboardTotalActiveTask();
    this.getDashboardRecentTask();
    this.getDashboardTotalActiveMatter();
    this.getDashboardRecentMatter();
    this.getDashBoardMatterList1();
    this.getDashBoardTaskList();
    this.getDashBoardMatterList2();
    this.getArticleList();
    this.getDeadlinesTotal();
    this.getDeadlines();
    this.filemakerUrl =
      `${environment.filemakerUrl}` + "table/?action=upcoming_deadlines";
    this.getClientList();
    this.getApplications();
    this.listGenius();
    this.getArticleCategories();

    this.state$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      map(() => {
        const currentNav = this.router.getCurrentNavigation();
        return currentNav.extras.state;
      })
    );
  }

  go(name) {
    if (name === "tasks") {
      $("document").ready(function () {
        jQuery("#taskModal").modal("show");
      });
      this.router.navigateByUrl("/tasks", { state: { addCreate: "popup" } });
    }
  }

  getArticleCategories() {
    this.data.getArticleCategories(this.articleParam).subscribe((data) => {
      this.articleCategories = data;
    });
  }

  getDashboardTotalActiveClient() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.intervalClient = "";
    this.data
      .getDashboardTotalActiveClient(this.dashboardParam)
      .subscribe((data) => {
        if (data) {
          this.totalClient = data["count"];
        } else {
          this.totalClient = 0;
        }
      });
  }

  getDashboardRecentClient() {
    //this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.intervalClient = this.interval2;
    this.data
      .getDashboardTotalActiveClient(this.dashboardParam)
      .subscribe((data) => {
        if (data) {
          this.recentClient = data["count"];
        } else {
          this.recentClient = 0;
        }
      });
  }

  setDayInterval2(value) {
    this.interval2 = value;
    this.getDashboardRecentClient();
    this.getDashboardRecentMatter();
  }

  setDayInterval1(value) {
    if (value == "") {
      this.deadlineParam.interval = "";
    } else if (value == "today") {
      this.deadlineParam.interval = "today";
    } else {
      this.deadlineParam.interval = value + "DAY";
    }
    this.interval1 = value;
    this.getDashboardRecentTask();
    this.getDeadlines();
  }

  getDeadlinesTotal() {
    this.deadlineParam.interval = "";
    this.data.getDeadlines(this.deadlineParam).subscribe((data) => {
      if (data) {
        this.deadlinesTotal = data;
      } else {
        this.deadlinesTotal = [];
      }
    });
  }

  getDashboardTotalActiveTask() {
    this.dashboardParam.user_id = localStorage.getItem("UserId");
    //this.dashboardParam.user_type_id = this.user_type;
    this.data
      .getDashboardTotalActiveTask(this.dashboardParam)
      .subscribe((data) => {
        if (data) {
          this.totalTask = data["count"];
        } else {
          this.totalTask = 0;
        }
      });
  }

  getDashboardRecentTask() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.intervaltask = this.interval1;
    this.dashboardParam.user_id = localStorage.getItem("UserId");
    this.data
      .getDashboardTotalActiveTask(this.dashboardParam)
      .subscribe((data) => {
        if (data) {
          this.recentTask = data["count"];
        } else {
          this.recentTask = 0;
        }
      });
  }

  getDashboardTotalActiveMatter() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.intervalMatter = "";
    this.data
      .getDashboardTotalActiveMatter(this.dashboardParam)
      .subscribe((data) => {
        if (data) {
          this.totalMatter = data["count"];
        } else {
          this.totalMatter = 0;
        }
      });
  }
  getDashboardRecentMatter() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.dashborad_type = "bnip";
    this.dashboardParam.matter_status = "case_closed";
    this.dashboardParam.intervalMatter = this.interval2;
    this.data
      .getDashboardTotalActiveMatter(this.dashboardParam)
      .subscribe((data) => {
        if (data) {
          this.recentMatter = data["count"];
        } else {
          this.recentMatter = 0;
        }
      });
  }

  getDashBoardMatterList1() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.type_of_case = 2;
    this.data.getDashboardMatterList(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.matterList1 = data;
      }
    });
  }

  getDashBoardMatterList2() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.type_of_case = 3;
    this.data.getDashboardMatterList(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.matterList2 = data;
      }
    });
  }

  getDashBoardTaskList() {
    this.dashboardParam.assignee = localStorage.getItem("UserId");
    this.dashboardParam.user_type_id = localStorage.getItem("UserType");
    this.data.getDashboardTaskList(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.taskList = data;
      }
    });
  }

  getDeadlines() {
    this.data.getDeadlines(this.deadlineParam).subscribe((data) => {
      if (data) {
        this.deadlines = data;
      } else {
        this.deadlines = [];
      }
    });
  }

  searchClient(client_number) {
    localStorage.setItem(
      "client_number",
      client_number != "null" ? client_number : ""
    );
    this.router.navigate(["/clients"]);
  }
  searchApplication(filling_number) {
    window.location.href =
      `${environment.filemakerUrl}` +
      "table/?action=list_table_view&block_id=38&s=&action=list_table_view&search_text=" +
      filling_number +
      "&tbl=";
    ///window.location.href = 'https://dev3.indusnettechnologies.com/lpms/filemaker/public_html/table/?action=list_table_view&block_id=38&s=&action=list_table_view&search_text=76492+&tbl=';
  }
  getClientList() {
    this.data.getClientList(this.clientParam).subscribe((response) => {
      if (response) {
        this.clientList = response;
      }
    });
  }

  getApplications() {
    this.data.getApplications().subscribe((data) => {
      this.applications = data;
    });
  }

  listGenius() {
    this.data.getTaskAssignee(3).subscribe((data) => {
      this.geniusList = data;
    });
  }
  filterCalendar(geniusId) {
    localStorage.setItem(
      "dashboard_month",
      this.month != "null" ? this.month : ""
    );
    localStorage.setItem(
      "dashboard_date",
      this.date != "null" ? this.date : ""
    );
    localStorage.setItem("genius", geniusId != "null" ? geniusId : "");
    this.router.navigateByUrl("/agenda");
  }

  filterTask(geniusid) {
    this.dashboardParam.geniusid = geniusid;
    this.getDashBoardTaskList();
  }

  setDayIntervalArticle(interval) {
    this.articleParam.intervalArticle = interval;
    this.articleParam.category = this.articleParam.category;
    this.getArticleList();
  }
  filterArticle(catId) {
    this.articleParam.category = catId;
    this.articleParam.intervalArticle = this.articleParam.intervalArticle;
    this.getArticleList();
  }

  getArticleList() {
    if (this.kbSubs) {
      this.kbSubs.unsubscribe();
    }
    this.articleParam.user_id = localStorage.getItem("UserId");
    this.articleParam.user_type_id = localStorage.getItem("UserType");
    this.kbSubs = this.data
      .getDashboardKowlegdeList(this.articleParam)
      .subscribe((data) => {
        this.articles = data;
      });
  }

  sortColumn(event, columnName) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    /*  if (classList.contains('fa fa-sort-desc')) {
      classList.add('fa fa-sort-asc');
      classList.remove('fa fa-sort-desc');
      this.sortDir=1;
      } else {
        
        classList.remove('fa fa-sort-asc');
        classList.add('fa fa-sort-desc');
        this.sortDir=-1;
      } */
    this.sortArr(columnName);
  }

  sortArr(colName: any) {
    this.deadlines.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  sort(val: any) {
    for (var i in val) {
      this.clientParam.sortBy = i;
      if (this.clientParam.sortOrder == "desc") {
        this.clientParam.sortOrder = "asc";
      } else if (this.clientParam.sortOrder == "asc") {
        this.clientParam.sortOrder = "desc";
      } else {
        this.clientParam.sortOrder = "asc";
      }
    }
    this.getClientList();
  }

  viewDetails(val, dId) {
    if (val == "personView") {
      this.personDetailsId = dId;
      this.personView = true;
    }
  }
  hideModal() {
    this.taskId = 0;
    jQuery("#taskModal,#personModal, #taskViewModal").modal("hide");
  }

  taskEdit(id) {
    if (id != 0) {
      this.taskId = id;
    } else {
      this.taskId = 0;
    }
  }
  taskView(id) {
    this.taskId = id;
  }
}
