import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }
  login(post) {
    return this.http.post(`${environment.lpmsUrl}` + 'auth/login', post);
  }

  changePassword(post) {
    return this.http.post(`${environment.lpmsUrl}` + 'auth/changePassword', post);
  }

  /* handle(token) {
    this.setToken(token);
  } */

  setToken(token) {
    localStorage.setItem('token', token);
  }
  getToken() {
    return localStorage.getItem('token');
  }

  remove() {
   
    localStorage.removeItem('token');
    localStorage.removeItem('Username');
    localStorage.removeItem('UserId');
    localStorage.removeItem('UserType');
    localStorage.clear();
  }

  getUser(){
    return this.http.get(`${environment.lpmsUrl}` + 'auth/getUser');
  }

  public isAuthenticated(): boolean {   
    const token = localStorage.getItem('token');
    if(token){
      return true;
    }else{
      false;
    }
    
  }
  forgotPassword(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/forgotPasswordMail', post);
  }

  resetPassword(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/resetPassword', post);
  }

  googleLogin(){
    return this.http.get('http://10.0.10.200/bnlaw/lumen/public/auth/login');
  }

  registerGoogleUser(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/registerGoogleUser', post);
  }

  updateLogoutTime(){
    return this.http.get(`${environment.lpmsUrl}` + 'auth/updateLoginHistory');
  }

  terroristListBackgroundChecking(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/backgroungChecking',post);
  }

  googleSearch(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/googleSearch',post);
  }

  searchPEP(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/pep',post);
  }
  ofacList(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/ofac',post);
  }

  filemakerLogin(post){
    return this.http.post(`${environment.filemakerUrl}` + 'login/?action=login', post);
  }

  filemakerLogout(id){
    return this.http.get(`${environment.filemakerUrl}` + 'users/?action=logout&id='+id ,{responseType: 'text'});
  }
  createUser(post){
    return this.http.post(`${environment.lpmsUrl}` + 'auth/createUser',post);
  }


  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  

}
