import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-reports-client',
  templateUrl: './reports-client.component.html',
  styleUrls: ['./reports-client.component.scss']
})
export class ReportsClientComponent implements OnInit {

  total_client: any = [];
  year_arr : any = ['2019','2020','2021'];
  selectedYear: any = '2019';
  case_type: any;
  case_id: any = 'IP';
  clientList: any;
  clientParam: any = {};
  clientId: any = 0;
  total_client_case: any = [];

  constructor(private data: DataService) { }

  ngOnInit() {
    this.getCaseTypeList();
    this.getClientList();
    this.getTotalClientByYear(this.selectedYear, this.case_id);
    this.getClientCaseDet(this.selectedYear, this.clientId);
  }

  getCaseTypeList(){
    this.data.getTypeOfCase().subscribe(
      data => {
        this.case_type = data;
      }
    );
  }

  getClientList() {
    this.data.getClientList(this.clientParam).subscribe(response => {
      if (response) {
        this.clientList = response;
      }
    });
  }

  yearChange(selectedYear){
    this.selectedYear = selectedYear;
    this.getTotalClientByYear(this.selectedYear, this.case_id);
    this.getClientCaseDet(this.selectedYear, this.clientId);
  }

  caseChange(case_id){
    this.case_id = case_id;
    this.getTotalClientByYear(this.selectedYear, this.case_id);
  }


  clientChange(client_id){
    this.clientId = client_id;
    this.getClientCaseDet(this.selectedYear, this.clientId);
  }
 

  getClientCaseDet(selectedYear, client_id) {
    this.data.getClientCaseDet(selectedYear, client_id).subscribe(data => {
      this.total_client_case = data;
      
    });
  }
  
  

  getTotalClientByYear(selectedYear, case_id) {
    this.data.getTotalClientByYear(selectedYear, case_id).subscribe(data => {
      this.total_client = data;
     
    });
  }

 
}
