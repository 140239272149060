import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { GlobalCalendarComponent } from './global-calendar/global-calendar.component';
import { CalendarHeaderComponent } from './common/calendar-header.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AgendaComponent } from './agenda/agenda.component';
import { DatePipe } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { DashboardCalendarComponent } from './dashboard-calendar/dashboard-calendar.component';
@NgModule({
  declarations: [CalendarHeaderComponent,
    GlobalCalendarComponent,
    CalendarHeaderComponent,
    AgendaComponent,
    DashboardCalendarComponent,
  ],
    
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ClickOutsideModule
  ],
  providers: [
    GlobalCalendarComponent,
    DatePipe,
    AgendaComponent
    
  ]
})
export class CalenderModule { }
