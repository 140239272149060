import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { environment } from "../../environments/environment";
import { DataService } from "../services/data.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  viewType: any;
  url: string = "";
  urlSafe: SafeResourceUrl;
  loginData: any;
  loader: boolean = true;
  generateDoc: boolean = false;
  mId: any;
  matterDetails: any = [];
  generateDocForm: FormGroup;
  succ_msg: any = "";
  err_matter_doc: any = "";

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private data: DataService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.generateDocForm = this.fb.group({
      generate_document: new FormControl(""),
    });

    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);
    this.data.getMatterDetByMatterId(this.mId).subscribe((response) => {
      if (response) {
        this.matterDetails = response;
        //if(this.matterDetails.type_of_case == 1){
        this.url =
          `${environment.filemakerUrl}` +
          "table/?action=matter_dashboard_application&form_id=26&rID=6064&pre_form_id=25&pre_row_id=1048&breadcum=M&client_number=" +
          this.matterDetails.client_number +
          "&mId=" +
          this.matterDetails.matter_number;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        //}
      }
    });

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      this.loader = false;
    }, 8000);

    /* this.route.params.subscribe(params => {
      if(params){
          if(params['viewType']){
            this.viewType = params['viewType'];
            if(this.viewType == "userType"){
              this.url = `${environment.filemakerUrl}`+"administrators/?action=manage_user_type";
              this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            }else if(this.viewType == "userGroup"){
              this.url = `${environment.filemakerUrl}`+"administrators/?action=manage_user_group";
              this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            }else if(this.viewType == "account"){
              this.url = `${environment.filemakerUrl}`+"profile/?action=edit_profile";
              this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            }else{
              this.url = `${environment.filemakerUrl}`+"administrators/?action=user_management";
              this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            }
      
          }
      }
    }); */
  }

  addDoc(postData) {
    this.generateDoc = true;
    let formdata = new FormData();

    formdata.append("matter_id", this.matterDetails.matter_id);
    formdata.append("matter_type_id", this.matterDetails.matter_type_id);
    formdata.append("matter_number", this.matterDetails.matter_number);
    formdata.append("client_number", this.matterDetails.client_number);
    formdata.append("given_name", this.matterDetails.given_name);
    formdata.append("generate_document", postData.generate_document);

    this.data.generateDoc(formdata).subscribe(
      (res) => {
        this.succ_msg = res["msg"];
      },
      (error) => {
        this.generateDoc = false;
        if (error.error.err_matter_doc) {
          this.err_matter_doc = error.error.err_matter_doc;
        } else {
          this.err_matter_doc = "";
        }
      }
    );
  }
}
