import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
declare var jQuery: any;
export interface TypeId {
  name: string;
  key: string;
}
export interface Single {
  name: string;
  key: string;
}

@Component({
  selector: "app-registered-client",
  templateUrl: "./registered-client.component.html",
  styleUrls: ["./registered-client.component.scss"],
})
export class RegisteredClientComponent implements OnInit {
  selectedFiles: any;
  clientRegister: FormGroup;
  countryList: any;
  maritialStatus: boolean = false;
  succ_msg: any = "";
  person_id: any;

  err_person_salutation: string;
  err_person_organization: string;
  err_person_given_name: string;
  err_person_family_name: string;
  err_person_email: string;
  err_person_phone_number: string;
  err_country_code: string;
  err_person_mobile: string;
  err_person_website: string;
  err_person_street: string;
  err_person_city: string;
  err_person_state: string;
  err_person_zip: string;
  err_person_country: string;
  err_person_type: string;
  err_person_id_type: string;
  err_person_id_number: string;
  err_person_id_issue_date: string;
  err_person_issue_country: string;
  err_person_maritial_status: string;
  err_person_id_scan: string;
  err_client_folder_name: string;
  err_person_client_number: String;
  err_person_communicate_in_chinese: String;
  err_person_billing_code: String;
  err_person_short_name: String;
  err_person_discount: String;
  err_org_exist: String;
  org_exist_msg: any = "";
  err_person_folder_name: String;
  err_team_code: String;
  lId: any;
  leadData: any;
  lead_id: any;
  listDetails: any = [];
  fileName: any = "";
  dynamicVar: any;

  //options: TypeId[] = [
  //{ name: 'Macau Non Permanent Identity Card/Bilhete de Identidade de Residente Não Permanente de Macau', key: 'Macau Non Permanent Identity Card' },
  //{ name: 'Macau Permanent Identity Card/Bilhete de Identidade de Residente Permanente de Macau', key: 'Macau Permanent Identity Card' },
  //{ name: 'Passport/Passporte', key: 'Passport' }
  //];

  options: TypeId[] = [
    {
      name: "Macau Permanent Identity Card/Bilhete de Identidade de Residente Permanente de Macau",
      key: "Macau Permanent Identity Card/",
    },
    {
      name: "Macau Non Permanent Identity Card/Bilhete de Identidade de Residente Não Permanente de Macau",
      key: "Macau Non Permanent Identity Card/Bilhete de Identidade de Residente Não Permanente de Macau",
    },
    { name: "Passport/Passporte", key: "Passport/Passporte" },
    {
      name: "China Identification Document",
      key: "China Identification Document",
    },
  ];

  married_status: Single[] = [
    {
      name: "Married in the matrimonial assets regime of joint assets/casado no regime da comunhão",
      key: "Married in the matrimonial assets regime of joint assets",
    },
    { name: "geral de bens", key: "geral de bens" },
    {
      name: "Married in the matrimonial assets regime of joint assets/casado no regime da comunhão",
      key: "Married in the matrimonial assets regime of joint assets",
    },
    { name: "geral de bens", key: "geral de bens" },
    {
      name: "Married in the matrimonial assets regime of acquired of assets/casado no regime da",
      key: "Married in the matrimonial assets regime of acquired of assets",
    },
    { name: "comunhão de bens adquiridos", key: "comunhão de bens adquiridos" },
    {
      name: "Married in the matrimonial assets regime of participation of acquired of assets/casado no",
      key: "Married in the matrimonial assets regime of participation of acquired of assets",
    },
    {
      name: "regime da participação de bens adquiridos",
      key: "regime da participação de bens adquiridos",
    },
  ];
  lead: any;
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.dynamicVar = `${environment.lpmsUrl}`;
    this.listNationality();
    this.contactFormInit();

    this.route.params.subscribe((params) => {
      if (params) {
        if (params["lId"]) {
          this.lId = +params["lId"];
          this.data.getLeadData(this.lId).subscribe((res) => {
            if (res) {
              this.leadData = res;
              this.lead_id = res["lead_id"];
            }
          });
        }
      }
    });

    this.editClient();
  }

  editClient() {
    this.data.editClientByLeadId(this.lId).subscribe((data) => {
      if (data["contact_id"] > 0) {
        this.listDetails = data;
        if (this.listDetails.id_scan_copy != null) {
          this.fileName =
            `${environment.lpmsUrl}` +
            "documents/comp_cert/personScanDoc/" +
            this.listDetails.id_scan_copy;
        } else {
          this.fileName = "";
        }
        this.clientRegister.patchValue({
          person_id: this.listDetails.contact_id,
          person_given_name: this.listDetails.given_name,
          person_family_name: this.listDetails.family_name,
          client_number: this.listDetails.client_number,
          client_short_name: this.listDetails.short_name,
          person_email: this.listDetails.email,
          person_mobile: this.listDetails.mobile_number,
          folder_name: this.listDetails.folder_name,
          person_street: this.listDetails.street,
          person_city: this.listDetails.city,
          person_state: this.listDetails.state,
          person_zip: this.listDetails.zip,
          team_code: this.listDetails.team_code,
          person_country: this.listDetails.country,
          client_billing_code: this.listDetails.billing_code,
          client_discount: this.listDetails.discount_in_percent,
          client_communicate_in_chinese:
            this.listDetails.communicate_in_chinese,
          person_id_type: this.listDetails.id_type,
          person_id_number: this.listDetails.reg_id_number,
          person_issue_date: this.listDetails.id_issue_date,
          person_issue_country: this.listDetails.id_issue_country,
          person_maritial_status: this.listDetails.marital_status,
          person_maritial_regime: this.listDetails.maritial_regime,
          person_spouse_name: this.listDetails.spouse_name,
        });
        /* this.onChange(this.listDetails.schedule_attend_by); */
      } else {
        this.contactFormInit();
        this.data.getLeadData(this.lId).subscribe((res) => {
          if (res) {
            this.lead = res;

            this.clientRegister.patchValue({
              person_id: res["person_id"] != "" ? res["person_id"] : "",
              client_number:
                res["client_number"] != "" ? res["client_number"] : "",
              person_given_name: this.lead.given_name,
              person_family_name: this.lead.family_name,
              person_email: res["lead_email"] != "" ? res["lead_email"] : "",
              person_mobile: res["mobile"] != "" ? res["mobile"] : "",
              folder_name: res["matter_type_name"],
              person_maritial_status:
                res["marital_status"] != "" ? res["marital_status"] : "",
              person_issue_date:
                res["id_issue_date"] != "" ? res["id_issue_date"] : "",
              person_id_number:
                res["id_doc_number"] != "" ? res["id_doc_number"] : "",
              person_id_type:
                res["id_doc_type"] != "" ? res["id_doc_type"] : "",
              person_issue_country:
                res["id_issue_country"] != "" ? res["id_issue_country"] : "",
            });
          }
        });
      }
    });
  }

  listNationality() {
    this.data.listNationality().subscribe((response) => {
      this.countryList = response;
    });
  }

  onChangeMStatus(value) {
    if (value == "Married") {
      this.maritialStatus = true;
    } else {
      this.maritialStatus = false;
    }
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    let fileName = document.getElementById("scan_file");
    fileName.innerHTML = this.selectedFiles[0].name;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
      };
    }
  }

  addContactPerson(postData) {
    if (postData.person_issue_date != "") {
      var dateSendingToServer = new DatePipe("en-US").transform(
        postData.person_issue_date,
        "yyyy-MM-dd"
      );
    } else {
      var dateSendingToServer = "";
    }

    let formdata = new FormData();
    formdata.append("salutation", postData.person_salutation);
    formdata.append("given_name", postData.person_given_name);
    formdata.append("family_name", postData.person_family_name);
    formdata.append("email", postData.person_email);
    formdata.append("mobile_number", postData.person_mobile);
    formdata.append("street", postData.person_street);
    formdata.append("city", postData.person_city);
    formdata.append("state", postData.person_state);
    formdata.append("zip", postData.person_zip);
    formdata.append("country", postData.person_country);
    formdata.append("type", "Client");
    formdata.append("client_number", postData.client_number);
    formdata.append("discount", postData.client_discount);
    formdata.append("short_name", postData.client_short_name);
    formdata.append("billing_code", postData.client_billing_code);
    formdata.append(
      "communicate_in_chinese",
      postData.client_communicate_in_chinese
    );
    formdata.append("id_type", postData.person_id_type);
    formdata.append("id_number", postData.person_id_number);
    formdata.append("id_issue_date", dateSendingToServer);
    formdata.append("id_issue_country", postData.person_issue_country);
    formdata.append("maritial_status", postData.person_maritial_status);
    formdata.append("maritial_regime", postData.person_maritial_regime);
    formdata.append("spouse_name", postData.person_spouse_name);
    formdata.append("from_form", "1");
    formdata.append("lead_id", this.lId);
    formdata.append("contact_type", "PERS");
    formdata.append(
      "team_code",
      postData.team_code != null ? postData.team_code : ""
    );
    formdata.append(
      "folder_name",
      postData.folder_name != null ? postData.folder_name : ""
    );

    if (postData.person_id) {
      formdata.append("person_id", postData.person_id);
    }

    if (this.selectedFiles == undefined) {
    } else {
      formdata.append("person_id_scan", this.selectedFiles[0]);
    }

    //formdata.append('person_id_scan', this.contactPersonForm.get('person_id_scan').value);

    this.data.contactAddPerson(formdata).subscribe(
      (response) => {
        if (response) {
          if (postData.person_id) {
          } else {
            /* this.data.insertClientToNDFromLPMS(formdata).subscribe(res => {

          }); */
          }
          this.succ_msg = response["msg"];
          this.onChangeMStatus("");
          setTimeout(() => {
            this.succ_msg = false;
            this.blankErrorMsgs();
            this.router.navigate(["/leads"]);
          }, this.data.timeOut);

          if (postData.person_id) {
          } else {
            this.contactFormInit();
          }
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.err_org_exist) {
          this.err_org_exist = error.error.err_org_exist_msg;
          this.org_exist_msg = "";
        } else {
          this.err_org_exist = "";
        }

        if (error.error.folder_name) {
          this.err_person_folder_name = error.error.folder_name;
        } else {
          this.err_person_folder_name = "";
        }

        if (error.error.team_code) {
          this.err_team_code = error.error.team_code;
        } else {
          this.err_team_code = "";
        }

        if (error.error.organization) {
          this.err_person_organization = error.error.organization;
        } else {
          this.err_person_organization = "";
        }

        if (error.error.given_name) {
          this.err_person_given_name = error.error.given_name;
        } else {
          this.err_person_given_name = "";
        }

        if (error.error.family_name) {
          this.err_person_family_name = error.error.family_name;
        } else {
          this.err_person_family_name = "";
        }

        if (error.error.phone_number) {
          this.err_person_phone_number = error.error.phone_number;
        } else {
          this.err_person_phone_number = "";
        }

        if (error.error.client_number) {
          this.err_person_client_number = error.error.client_number;
        } else {
          this.err_person_client_number = "";
        }

        if (error.error.country_code) {
          this.err_country_code = error.error.country_code;
        } else {
          this.err_country_code = "";
        }

        if (error.error.mobile_number) {
          this.err_person_mobile = error.error.mobile_number;
        } else {
          this.err_person_mobile = "";
        }

        if (error.error.website) {
          this.err_person_website = error.error.website;
        } else {
          this.err_person_website = "";
        }

        if (error.error.street) {
          this.err_person_street = error.error.street;
        } else {
          this.err_person_street = "";
        }
        if (error.error.city) {
          this.err_person_city = error.error.city;
        } else {
          this.err_person_city = "";
        }

        if (error.error.state) {
          this.err_person_state = error.error.state;
        } else {
          this.err_person_state = "";
        }

        if (error.error.zip) {
          this.err_person_zip = error.error.zip;
        } else {
          this.err_person_zip = "";
        }
        if (error.error.country) {
          this.err_person_country = error.error.country;
        } else {
          this.err_person_country = "";
        }

        if (error.error.type) {
          this.err_person_type = error.error.type;
        } else {
          this.err_person_type = "";
        }

        if (error.error.id_type) {
          this.err_person_id_type = error.error.id_type;
        } else {
          this.err_person_id_type = "";
        }

        if (error.error.id_number) {
          this.err_person_id_number = error.error.id_number;
        } else {
          this.err_person_id_number = "";
        }

        if (error.error.id_issue_date) {
          this.err_person_id_issue_date = error.error.id_issue_date;
        } else {
          this.err_person_id_issue_date = "";
        }

        if (error.error.id_issue_country) {
          this.err_person_issue_country = error.error.id_issue_country;
        } else {
          this.err_person_issue_country = "";
        }
        if (error.error.maritial_status) {
          this.err_person_maritial_status = error.error.maritial_status;
        } else {
          this.err_person_maritial_status = "";
        }

        if (error.error.billing_code) {
          this.err_person_billing_code = error.error.billing_code;
        } else {
          this.err_person_billing_code = "";
        }

        if (error.error.communicate_in_chinese) {
          this.err_person_communicate_in_chinese =
            error.error.communicate_in_chinese;
        } else {
          this.err_person_communicate_in_chinese = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_person_salutation =
      this.err_person_organization =
      this.err_person_given_name =
      this.err_person_family_name =
      this.err_person_email =
      this.err_person_phone_number =
      this.err_person_mobile =
      this.err_country_code =
      this.err_person_website =
      this.err_person_street =
      this.err_person_city =
      this.err_person_state =
      this.err_person_zip =
      this.err_person_country =
      this.err_person_type =
      this.err_person_id_type =
      this.err_person_id_number =
      this.err_person_id_issue_date =
      this.err_person_issue_country =
      this.err_person_maritial_status =
        "";
    this.err_person_client_number =
      this.err_person_communicate_in_chinese =
      this.err_person_billing_code =
      this.err_person_short_name =
      this.err_person_discount =
      this.err_org_exist =
        "";
    this.org_exist_msg = this.err_client_folder_name = "";
    this.err_person_folder_name = "";
    this.err_team_code = "";
  }

  clickCancel() {
    this.blankErrorMsgs();
    this.onChangeMStatus("");
    this.contactFormInit();
  }

  contactFormInit() {
    this.clientRegister = this.fb.group({
      person_salutation: new FormControl(""),
      person_organization: new FormControl(""),
      person_given_name: new FormControl(""),
      person_middle_name: new FormControl(""),
      person_family_name: new FormControl(""),
      person_phone_number: new FormControl(""),
      country_code: new FormControl(""),
      person_mobile: new FormControl(""),
      person_email: new FormControl(""),
      person_website: new FormControl(""),
      person_street: new FormControl(""),
      person_city: new FormControl(""),
      person_state: new FormControl(""),
      person_zip: new FormControl(""),
      person_country: new FormControl(""),
      person_type: new FormControl(""),
      client_number: new FormControl(""),
      client_discount: new FormControl(""),
      client_short_name: new FormControl(""),
      client_billing_code: new FormControl(""),
      client_communicate_in_chinese: new FormControl(""),
      person_id_type: new FormControl(""),
      person_id_number: new FormControl(""),
      person_issue_date: new FormControl(""),
      person_issue_country: new FormControl(""),
      person_id_scan: new FormControl(""),
      person_maritial_status: new FormControl(""),
      person_maritial_regime: new FormControl(""),
      person_spouse_name: new FormControl(""),
      person_id: new FormControl(""),
      person_new_org: new FormControl(""),
      team_code: new FormControl(""),
      folder_name: new FormControl(""),
    });
  }
}
