import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { environment } from "../../environments/environment";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-filemaker-application",
  templateUrl: "./filemaker-application.component.html",
  styleUrls: ["./filemaker-application.component.scss"],
})
export class FilemakerApplicationComponent implements OnInit {
  viewType: any;
  url: string = "";
  urlSafe: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private data: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params) {
        if (params["viewType"]) {
          this.viewType = params["viewType"];
          if (this.viewType == "application") {
            this.url =
              `${environment.filemakerUrl}` +
              "table/?action=list_table_view&block_id=38";
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url
            );
          } else if (this.viewType == "unpublishedtm") {
            this.url =
              `${environment.filemakerUrl}` +
              "table/?action=unpublished_trademarks";
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url
            );
          } else if (this.viewType == "unpublishedOther") {
            this.url =
              `${environment.filemakerUrl}` +
              "table/?action=unpublished_other_actions";
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url
            );
          } else {
            this.url =
              `${environment.filemakerUrl}` +
              "table/?action=ungranted_trademarks";
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url
            );
          }
        }
      }
    });
  }
}
