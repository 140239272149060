import { Component, OnInit, Inject } from "@angular/core";
import { environment } from "../../../environments/environment";
import { DataService } from "../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialogServiceService } from "../../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
export class ClientsComponent implements OnInit {
  p: number = 0;
  itemsPerPage: number = 100;
  clientList: any = [];
  clientParam: any = {};
  theCheckbox = false;
  succ_msg: any;
  exportClientUrl: String;

  personDetailsId: number = 0;
  orgDetailsId: number = 0;
  personView: boolean = false;
  orgView: boolean = false;
  personId: number = 0;
  orgId: number = 0;
  search: string = "";
  sortClass: string = "fa fa-sort-desc";
  sortDir = 1; //1= 'ASE' -1= DSC
  case_type: any;

  constructor(
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    var uId = localStorage.getItem("UserId");
    var client_number = localStorage.getItem("client_number");

    if (client_number != "" && client_number != null) {
      this.clientParam.searchString = client_number;
      this.search = client_number;
    }
    this.getClientList();
    this.getCaseTypeList();
    this.exportClientUrl =
      `${environment.lpmsUrl}` + "contact/exportClientList/" + uId;
    //this.unpublished();
    //this.unGrantedTm();
    //this.unpublishedOther();
    //this.getAppListFromFilemaker();

  //   this.route.queryParams.subscribe(queryParams => {

  //     this.personDetailsId = queryParams.personDetailsId;
      
  //     if(queryParams.personDetailsId){
  //       this.personDetailsId = queryParams.personDetailsId;
  //       this.personView = true;
  //       this.orgView = false;
  //     }else if (queryParams.orgDetailsId) {
  //       this.orgView = true;
  //       this.personView = false;
  //       this.orgDetailsId = queryParams.orgDetailsId;
  //     }
  // });


  }

  viewDetails(val, dId, type_of_case, clientID) {
    this.data.selectedTypeOfCase = type_of_case;
    this.data.selectedClient = clientID;
    let queryParams = {};

    if (val == "personView") {
      this.personDetailsId = queryParams['personDetailsId'] = dId;
      this.personView = true;
      this.orgView = false;
    } else if (val == "orgView") {
      this.orgView = true;
      this.personView = false;
      this.orgDetailsId = queryParams['orgDetailsId'] = dId;
    }
    
  }

  clientEdit(id) {
    if (id != 0) {
      this.personId = id;
      this.orgId = id;
    }
  }

  hideModal() {
    this.personId = 0;
    this.getClientList();
    jQuery("#personModal").modal("hide");
  }

  openConfirmationDialog(client_id) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(client_id, confirmed))
      .catch(() => {});
  }

  deleteListItem(client_id, confirmed) {
    if (confirmed) {
      this.data.deleteContact(client_id).subscribe((response) => {
        this.getClientList();
        this.succ_msg = "Deleted Succesfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
        }, this.data.timeOut);
      });
    }
  }

  getCaseTypeList() {
    this.data.getTypeOfCase().subscribe((data) => {
      this.case_type = data;
    });
  }

  toggleVisibility(event: any) {
    if (this.theCheckbox == true) {
      this.clientParam.coummnication = "Yes";
    } else {
      this.clientParam.coummnication = "";
    }

    if (this.clientParam.type_of_case) {
      this.clientParam.type_of_case = this.clientParam.type_of_case;
    } else {
      this.clientParam.type_of_case = 0;
    }

    if (this.clientParam.searchString) {
      this.clientParam.searchString = this.clientParam.searchString;
    }

    this.getClientList();
  }

  caseList(value) {
    this.clientParam.type_of_case = value;
    if (this.clientParam.coummnication) {
      this.clientParam.coummnication = this.clientParam.coummnication;
    }

    if (this.clientParam.searchString) {
      this.clientParam.searchString = this.clientParam.searchString;
    }

    this.getClientList();
  }

  searchData(value: any) {
    this.clientParam.searchString = value;

    if (this.clientParam.type_of_case) {
      this.clientParam.type_of_case = this.clientParam.type_of_case;
    } else {
      this.clientParam.type_of_case = 0;
    }

    if (this.clientParam.coummnication) {
      this.clientParam.coummnication = this.clientParam.coummnication;
    }

    this.getClientList();
  }

  getClientList() {
    this.data.getClientList(this.clientParam).subscribe((response) => {
      if (response) {
        this.clientList = response;
        localStorage.removeItem("client_number");
      }
    });
  }

  searchTaskList(value) {
    this.clientParam.searchString = value;
    this.getClientList();
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.clientParam.pageNo = page - 1;
    this.getClientList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }

    for (var i in val) {
      this.clientParam.sortBy = i;
      if (this.clientParam.sortOrder == "desc") {
        this.clientParam.sortOrder = "asc";
      } else if (this.clientParam.sortOrder == "asc") {
        this.clientParam.sortOrder = "desc";
      } else {
        this.clientParam.sortOrder = "asc";
      }
    }
    this.getClientList();
  }

  refresh() {
    window.location.reload();
  }
}
