import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DataService } from "../../services/data.service";
@Component({
  selector: "app-view-quotes",
  templateUrl: "./view-quotes.component.html",
  styleUrls: ["./view-quotes.component.scss"],
})
export class ViewQuotesComponent implements OnInit {
  quote_number: string;
  quote_email: string;
  quote_for: string;
  quote_status: string;
  quote_particular: string;
  quote_price: string;
  quote_date: string;
  valid_till: string;
  company: string;
  quote_from: string;

  modalRef: BsModalRef;
  @Input() quoteId: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private data: DataService) {}

  ngOnInit() {
    if (this.quoteId > 0) {
      this.quoteDetails();
    }
  }

  hideModal() {
    this.closeModal.emit();
  }

  async quoteDetails() {
    const result = await this.quoteId;
    if (result) {
      this.data.getQuoteDetails(this.quoteId).subscribe((response) => {
        if (response) {
          this.quote_number = response["quote_number"];
          this.quote_email = response["email"];
          this.quote_for = response["quote_for"];
          this.quote_number = response["quote_number"];
          this.quote_particular = response["quote_title"];
          this.quote_status = response["quote_status"];
          //if(response['currency'] == 'USD'){
          this.quote_price = "$" + response["cost"];
          //}else{
          //this.quote_price = '¥'+response['cost'];
          //}
          this.quote_date = response["quote_date"];
          this.valid_till = response["quote_valid_till_date"];
          this.company = response["company"];
          this.quote_from = response["quote_from"];
        }
      });
    } else {
    }
  }
}
