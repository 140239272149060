import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import Chart from 'chart.js';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss']
})
export class CorporateComponent implements OnInit {
  @Input() flag: any;
  @Input() year: string;
  type_chart_per: any = [];
  type_of_work_chart_per: any = [];

  constructor(private data: DataService) { }
  Param: any = {};
  type_of_work: any = [];
  type_of_work_chart: any = [];
  type_chart: any = [];
  countryWiseTypeOfwork: any = [];
  public countrywiseChartLabels: Label[] = [];
  countryWiseType_chart: any = [];
  countryWiseType_chart_num: any = [];
  countryWiseType_data: any = [];
  countryWiseType_data_num: any = [];
 

  current_year = '';

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };
  public barChartLabels: Label[] = ['Incorporation', 'Assignment of shares', 'Alteration', 'Dissolution', 'Overseas', 'Corporate Registrations', 'Junket'];

  ngOnInit() {
    this.Param.type_of_case = 2;
    this.Param.year = this.year;
    this.getTypeOfWork();
    this.getCountryWiseTypeOfWork();
   
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['year']) {
      let chg = changes['year'];
      let cval = chg.currentValue;
      this.Param.year = this.year;
      this.Param.type_of_case = 2;
      if (chg.firstChange != true) {
        this.Param.type_of_case = this.flag;
        this.Param.year = this.year;
        this.getTypeOfWork();
        this.getCountryWiseTypeOfWork();
        
      }
    }
  }

  getTypeOfWork() {

    this.data.getTypeOfWork(this.Param).subscribe(data => {
      this.current_year = this.Param.year;
      this.type_of_work = data;
      this.type_of_work_chart = data['type_of_work_chart'];
      this.type_of_work_chart_per = data['type_of_work_chart_per'];


      this.type_chart = null;
      let ctxl1 = 'canvas_corp_' + this.Param.year;
      this.type_chart = new Chart(ctxl1, {
        type: 'horizontalBar',
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.type_of_work_chart,
              backgroundColor: ['#ff00ff','#0000cc','#00ffff','#ffff00','#ff0000','#9900cc','#4da6ff'],

              label: 'Type of work'
            },

          ]
        },
        options: {
          legend: {
            display: false,
            labels : {
              fontColor : 'white'  
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                fontColor: 'white'
            },
              display: true,
              gridLines: {
                display: true,
                color: 'white'
              }
            }],
            yAxes: [{ ticks: {
              fontColor: 'white'
          },
              display: true
            }]
          }
        }
      })

      this.type_chart_per = null;
      let ctxl2 = 'canvas_corp_per_' + this.Param.year;
      this.type_chart_per = new Chart(ctxl2, {
        type: 'horizontalBar',
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.type_of_work_chart_per,
              backgroundColor: ['#ff00ff','#0000cc','#00ffff','#ffff00','#ff0000','#9900cc','#4da6ff'],

              label: 'Corporate Percentage'
            },

          ]
        },
        options: {
          legend: {
            display: false,
            labels : {
              fontColor : 'white'  
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                fontColor: 'white'
            },
              display: true,
              gridLines: {
                display: true,
                color: 'white'
              }
            }],
            yAxes: [{ ticks: {
              fontColor: 'white'
          },
              display: true
            }]
          }
        }
      })

    });
  }

  getCountryWiseTypeOfWork() {

    this.data.getCountryWiseTypeOfWork(this.Param).subscribe(data => {
      this.countryWiseTypeOfwork = data;
      Object.keys(data).forEach((key) => {
        this.countrywiseChartLabels.push(data[key]['country_name'])
        this.countryWiseType_data.push(data[key]['filling_pers'])
        this.countryWiseType_data_num.push(data[key]['filling'])
      })

      this.current_year = this.Param.year;
      this.countryWiseType_chart = null;
      let ctxl3 = 'canvas_country_corporate_' + this.Param.year;
      this.countryWiseType_chart = new Chart(ctxl3, {
        type: 'horizontalBar',
        data: {
          labels: this.countrywiseChartLabels,
          datasets: [
            {
              data: this.countryWiseType_data,
              backgroundColor: '#35c7ef',
              label: 'Origin %'
            },

          ]
        },
        options: {
          legend: {
            display: false,
            labels : {
              fontColor : 'white'  
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                fontColor: 'white'
            },
              display: true,
              gridLines: {
                display: true,
                color: 'white'
              }
            }],
            yAxes: [{ ticks: {
              fontColor: 'white'
          },
              display: true
            }]
          }
        }
      })

      this.countryWiseType_chart_num = null;
      let ctxl4 = 'canvas_country_corporate_num_' + this.Param.year;
      this.countryWiseType_chart_num = new Chart(ctxl4, {
        type: 'horizontalBar',
        data: {
          labels: this.countrywiseChartLabels,
          datasets: [
            {
              data: this.countryWiseType_data_num,
              backgroundColor: '#35c7ef',
              label: 'Origin'
            },

          ]
        },
        options: {
          legend: {
            display: false,
            labels : {
              fontColor : 'white'  
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                fontColor: 'white'
            },
              display: true,
              gridLines: {
                display: true,
                color: 'white'
              }
            }],
            yAxes: [{ ticks: {
              fontColor: 'white'
          },
              display: true
            }]
          }
        }
      })




    });
  }

  


}
