import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DataService } from "../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ThemePalette } from "@angular/material/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-matter-dashboard-left",
  templateUrl: "./matter-dashboard-left.component.html",
  styleUrls: ["./matter-dashboard-left.component.scss"],
})
export class MatterDashboardLeftComponent implements OnInit {
  matterDetails: any = [];
  mId: any;
  hideInfo: boolean = true;
  orgInfo: boolean = false;
  hideClientInfo: boolean = true;
  clientInfo: boolean = false;
  orgEditForm: FormGroup;
  clientEditForm: FormGroup;
  age: any;
  err_org_name: string = "";
  err_org_email: string = "";
  err_org_street: string = "";
  err_org_zip: string = "";
  err_given_name: string = "";
  err_middle_name: string = "";
  err_family_name: string = "";
  err_phone_number: string = "";
  err_email: string = "";
  succ_msg: any = "";

  modalRef: BsModalRef;
  appUrl: any;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.mId = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );

    this.matterDetailsData();
    this.orgFormInit();
    this.clientFormInit();
    //this.age = 40;
  }

  matterDetailsData() {
    this.data.getMatterDetByMatterId(this.mId).subscribe((response) => {
      if (response) {
        this.matterDetails = response;
        this.matterDetails.address =
          this.matterDetails.street +
          (this.matterDetails.city != ""
            ? ", " + this.matterDetails.city
            : "") +
          (this.matterDetails.state != ""
            ? ", " + this.matterDetails.state
            : "")  +
            (this.matterDetails.zip != ""
              ? ", " + this.matterDetails.zip
              : "");
      }
      this.data.dateDiff(this.mId).subscribe((response) => {
        this.age = response;
      });
    });
  }

  /*return new Promise((resolve, reject) => {
    this.data.dateDiff( this.mId ).subscribe(response => {
      this.age = response;
    });
    resolve(null);
  });*/

  toggle(e, dID) {
    e.classList.add("active");
    let div = document.getElementById(dID);
    if (div.style.display == "block") {
      e.classList.remove("active");
      div.style.display = "none";
      return;
    }
    div.style.display = "block";
  }

  orgEditToggle() {
    var edit = document.getElementById("orgEdit");
    edit.style.display = "none";
    this.hideInfo = !this.hideInfo;
    this.orgInfo = !this.orgInfo;

    this.data.getMatterDetByMatterId(this.mId).subscribe((response) => {
      if (response) {
        this.matterDetails = response;
        this.orgEditForm.patchValue({
          org_id: this.matterDetails.contact_id,
          org_name: this.matterDetails.org_name,
          org_email: this.matterDetails.email,
          org_phone: this.matterDetails.phone_number,
          org_website: this.matterDetails.website,
          org_street: this.matterDetails.street,
          org_city: this.matterDetails.city,
          org_state: this.matterDetails.state,
          org_zip: this.matterDetails.zip,
        });
      }
    });
  }

  clientEditToggle() {
    var edit = document.getElementById("clientEdit");
    edit.style.display = "none";
    this.hideClientInfo = !this.hideClientInfo;
    this.clientInfo = !this.clientInfo;

    this.data.getMatterDetByMatterId(this.mId).subscribe((response) => {
      if (response) {
        this.matterDetails = response;
        this.clientEditForm.patchValue({
          person_id: this.matterDetails.contact_id,
          given_name: this.matterDetails.given_name,
          middle_name: this.matterDetails.middle_name,
          family_name: this.matterDetails.family_name,
          phone_number: this.matterDetails.phone_number,
          email: this.matterDetails.email,
        });
      }
    });
  }

  cancel() {
    var edit = document.getElementById("orgEdit");
    edit.style.display = "block";
    this.hideInfo = !this.hideInfo;
    this.orgInfo = !this.orgInfo;
    this.blankErrorMsgs();
  }

  cancelClient() {
    var edit = document.getElementById("clientEdit");
    edit.style.display = "block";
    this.hideClientInfo = !this.hideClientInfo;
    this.clientInfo = !this.clientInfo;
    this.blankErrorMsgs();
  }

  orgFormInit() {
    this.orgEditForm = this.fb.group({
      org_id: new FormControl(""),
      org_name: new FormControl(""),
      org_phone: new FormControl(""),
      org_street: new FormControl(""),
      org_city: new FormControl(""),
      org_state: new FormControl(""),
      org_zip: new FormControl(""),
      org_email: new FormControl(""),
      org_website: new FormControl(""),
    });
  }

  clientFormInit() {
    this.clientEditForm = this.fb.group({
      person_id: new FormControl(""),
      given_name: new FormControl(""),
      middle_name: new FormControl(""),
      family_name: new FormControl(""),
      phone_number: new FormControl(""),
      email: new FormControl(""),
    });
  }

  addContactOrg(frmData) {
    let formdata = new FormData();
    formdata.append("org_name", frmData.org_name);
    formdata.append("org_email", frmData.org_email);
    formdata.append("org_phone", frmData.org_phone);
    formdata.append("org_website", frmData.org_website);
    formdata.append("org_street", frmData.org_street);
    formdata.append("org_city", frmData.org_city);
    formdata.append("org_state", frmData.org_state);
    formdata.append("org_zip", frmData.org_zip);
    formdata.append("from_form", "matter_dashboard");

    if (frmData.org_id) {
      formdata.append("org_id", frmData.org_id);
    }

    this.data.matterUpdateOrg(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrorMsgs();
          setTimeout(() => {
            this.succ_msg = false;
            this.matterDetailsData();
            this.cancel();
          }, this.data.timeOut);
          if (frmData.org_id) {
          } else {
            this.orgFormInit();
          }
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.name) {
          this.err_org_name = error.error.name;
        } else {
          this.err_org_name = "";
        }

        if (error.error.email) {
          this.err_org_email = error.error.email;
        } else {
          this.err_org_email = "";
        }

        if (error.error.street) {
          this.err_org_street = error.error.street;
        } else {
          this.err_org_street = "";
        }

        if (error.error.zip) {
          this.err_org_zip = error.error.zip;
        } else {
          this.err_org_zip = "";
        }
      }
    );
  }

  addContactClient(frmData) {
    let formdata = new FormData();
    formdata.append("given_name", frmData.given_name);
    formdata.append("middle_name", frmData.middle_name);
    formdata.append("family_name", frmData.family_name);
    formdata.append("phone_number", frmData.phone_number);
    formdata.append("email", frmData.email);
    formdata.append("from_form", "matter_dashboard");

    if (frmData.person_id) {
      formdata.append("person_id", frmData.person_id);
    }

    this.data.matterUpdatePerson(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.blankErrorMsgs();
          setTimeout(() => {
            this.succ_msg = false;
            this.matterDetailsData();
            this.cancelClient();
          }, this.data.timeOut);
          if (frmData.org_id) {
          } else {
            this.clientFormInit();
          }
        } else {
          this.succ_msg = "";
        }
      },

      (error) => {
        if (error.error.given_name) {
          this.err_given_name = error.error.given_name;
        } else {
          this.err_given_name = "";
        }

        if (error.error.family_name) {
          this.err_family_name = error.error.family_name;
        } else {
          this.err_family_name = "";
        }

        if (error.error.phone_number) {
          this.err_phone_number = error.error.phone_number;
        } else {
          this.err_phone_number = "";
        }

        if (error.error.email) {
          this.err_email = error.error.email;
        } else {
          this.err_email = "";
        }
      }
    );
  }

  blankErrorMsgs() {
    this.err_org_name =
      this.err_org_email =
      this.err_org_street =
      this.err_org_zip =
      this.err_given_name =
      this.err_family_name =
      this.err_phone_number =
      this.err_email =
        "";
  }
}
