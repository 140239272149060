import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatterWorkflowComponent } from './matter-workflow.component';
import { CompanyDissolutionComponent } from './company-dissolution/company-dissolution.component';
import { CompanyIncorporationComponent } from './company-incorporation/company-incorporation.component';
import { AssignmentQuotaComponent } from './assignment-quota/assignment-quota.component';
import { OverseasCompanyRegistrationComponent } from './overseas-company-registration/overseas-company-registration.component'





@NgModule({
  declarations: [
    MatterWorkflowComponent,
    CompanyDissolutionComponent,
    CompanyIncorporationComponent,
    AssignmentQuotaComponent,
    OverseasCompanyRegistrationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class MatterWorkflowModule { }
