import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  Directive,
  ViewContainerRef,
  ViewChildren,
  QueryList,
  ComponentFactoryResolver,
  AfterContentInit,
} from "@angular/core";
import { ChildboxComponent } from "../childbox/childbox.component";
import { DataService } from "src/app/services/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[datacontainer]",
})
export class DatacontainerDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
})
export class CommentsComponent implements OnInit, OnChanges {
  commentForm: FormGroup;
  commentInfo: Array<object> = [];
  submitted: Boolean = false;
  public id = 0;
  userId: any;
  @Input() articleId: any;
  @Output() usercomment = new EventEmitter();
  postComment: any = [];
  @Output() countComments = new EventEmitter();
  public loadComponent = false;
  public commentIndex = 0;
  public reply: Array<object> = [];
  commentParam: any = {};

  // @ViewChildren decorator to grab elements from the host view
  /* The return type of ViewChildren is QueryList.
  QueryList is just a fancy name for an object that stores
  a list of items. What is special about this object is
  when the state of the application changes Angular will
  automatically update the object items for you. */
  @ViewChildren(DatacontainerDirective)
  entry: QueryList<DatacontainerDirective>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private data: DataService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getArticleComments();
    this.createForm();
    this.userId = localStorage.getItem("UserId");
  }

  ngOnChanges() {
    this.getArticleComments();
    if (this.postComment !== undefined) {
    }
  }

  removeComment(no) {
    this.postComment.splice(no, 1);
    this.countComments.emit(this.postComment);
  }

  replyComment(comment_id, index) {
    this.loadComponent = true;
    const myFactory = this.resolver.resolveComponentFactory(ChildboxComponent);
    if (this.entry.toArray()[index].viewContainerRef.length <= 0) {
      const myRef = this.entry
        .toArray()
        [index].viewContainerRef.createComponent(myFactory);
      myRef.instance["commentNo"] = index;
      myRef.changeDetectorRef.detectChanges();
      myRef.instance.userReplycomment.subscribe((data) => {
        this.receiveReplyComment(data, comment_id);
      });

      myRef.instance.deletNo.subscribe((no) => {
        myRef.destroy();
      });
    }
  }

  receiveReplyComment(data, comment_id) {
    let formdata = new FormData();

    formdata.append("reply", data[0]["commentTxt"]);
    formdata.append("commentId", comment_id);
    formdata.append("user_id", this.userId);

    this.data.postReply(formdata).subscribe((response) => {
      if (response) {
        this.getArticleComments();
      }
    });
    this.loadComponent = false;
  }

  getArticleComments() {
    this.commentParam.articleId = this.articleId;
    if (this.articleId != undefined) {
      this.data.getArticleComments(this.commentParam).subscribe((data) => {
        this.postComment = data;
        
      });
    }
  }

  createForm() {
    this.commentForm = this.formBuilder.group({
      comment: [
        "",
        [
          Validators.required,
          Validators.minLength(6)
        ],
      ],
    });
  }

  onSubmit(post) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.commentForm.invalid) {
      return false;
    } else {
      this.commentInfo.push({
        commentId: this.id++,
        currentDate: new Date(),
        commentTxt: this.commentForm.controls["comment"].value,
        replyComment: [],
      });
      this.usercomment.emit(this.commentInfo);
      let formdata = new FormData();
      formdata.append("comment", post.comment);
      formdata.append("articleId", this.articleId);
      formdata.append("user_id", this.userId);

      this.data.postComment(formdata).subscribe((response) => {
        if (response) {
          this.getArticleComments();
        }
      });
    }

  }
}
