import { Component, OnInit } from '@angular/core';
import {FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../services/auth.service';
import { AuthService } from "angularx-social-login";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  token: any;
  Username: string;

  state$: Observable<object>;

  constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router, public activatedRoute: ActivatedRoute, private googleAuthService: AuthService) {
    auth.getLoggedInName.subscribe((name: string) => this.changeName(name));
  }

  ngOnInit() {
    this.Username = localStorage.getItem('Username');

    this.state$ = this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      map(() => {
        const currentNav = this.router.getCurrentNavigation();
        return currentNav.extras.state;
      })
    );
  }

  go(name) {
    if (name === 'leads') {
      $("document").ready(function () {
        jQuery('#leads').modal('show')
      });
      this.router.navigateByUrl('/leads', { state: { addCreate: 'popup' } });
    } else if (name === 'tasks') {
      $("document").ready(function () {
        jQuery('#taskModal').modal('show')
      });
      this.router.navigateByUrl('/tasks', { state: { addCreate: 'popup' } });
    } else if (name === 'matters') {
      $("document").ready(function () {
        jQuery('#createMatterModal').modal('show')
      });
      this.router.navigateByUrl('/matters', { state: { addCreate: 'popup' } });
    } else if (name === 'contacts_person') {
      $("document").ready(function () {
        jQuery('#personModal').modal('show')
      });
      this.router.navigateByUrl('/contacts', { state: { addCreate: 'popup' } });
    } else if (name === 'contacts_org') {
      $("document").ready(function () {
        jQuery('#organizationsModal').modal('show')
      });
      this.router.navigateByUrl('/contacts', { state: { addCreate: 'popup' } });
    }
  }

  private changeName(name: string): void {
    this.Username = name;
  }

  logout() {
   
    this.auth.updateLogoutTime().subscribe(response => {
      if (response) {
        this.auth.remove();
      }
    });
    var userId = localStorage.getItem('UserId');
    this.auth.filemakerLogout(userId).subscribe(response => {
      if (response) {

      }
    });
    localStorage.clear();
    // this.signOut();
    this.router.navigate(['/login']);
  }



}
