import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TooltipPosition } from "@angular/material/tooltip";
import { ConfirmationDialogServiceService } from "../../confirmation-dialog/confirmation-dialog-service.service";
declare var jQuery: any;

@Component({
  selector: "app-lead",
  templateUrl: "./lead.component.html",
  styleUrls: ["./lead.component.scss"],
})
export class LeadComponent implements OnInit {
  listParam: any = {};
  p: number = 0;
  itemsPerPage: number = 20;
  leadList: any = [];
  sort_order: any;
  parentExample: any;

  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new FormControl(this.positionOptions[5]);

  state$: Observable<any>;
  msgg: any;
  leadId: number = 0;
  succ_msg: any;
  leadFilePath: string;
  sortDir = 1; //1= 'ASE' -1= DSC

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {
    this.route.parent.params.subscribe((params) => console.log(params));
  }

  ngOnInit() {
    this.getLeadList();
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));

    this.state$.subscribe((res) => (this.msgg = res));

    if (this.msgg.addCreate === "popup") {
      $("document").ready(function () {
        jQuery("#leads").modal("show");
      });
    }
    this.leadFilePath = `${environment.lpmsUrl}` + "documents/comp_cert/pdf/";
  }

  openConfirmationDialog(tid) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteListItem(tid, confirmed))
      .catch(() => {});
  }

  deleteListItem(tid, confirmed) {
    if (confirmed) {
      this.data.deleteLeadList(tid).subscribe((response) => {
        this.succ_msg = "Deleted Successfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
          this.getLeadList();
        }, this.data.timeOut);
      });
    }
  }

  leadEdit(id) {
    if (id != 0) {
      this.leadId = id;
    } else {
      this.leadId = 0;
      jQuery("#leads").on("hide.bs.modal", () => {
        this.getLeadList();
      });
    }
  }

  getLeadList() {
    this.data.getLeadList(this.listParam).subscribe((data) => {
      this.leadList = data;
    });
  }

  valueChanged(val) {
    this.parentExample = val;
  }

  searchTaskList(value) {
    var s_key = jQuery("#searchKey").val();
    this.listParam.selectedSearchKey = s_key;
    this.listParam.searchString = value;
    this.getLeadList();
  }

  filterList(value) {
    this.listParam.formTypeSearch = value;
    this.getLeadList();
  }

  hideModal() {
    this.leadId = 0;
    this.getLeadList();
    jQuery("#leads").modal("hide");
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.listParam.pageNo = page - 1;
    this.getLeadList();
  }

  sort(val: any, event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains("fa-caret-up")) {
      classList.remove("fa-caret-up");
      classList.add("fa-caret-down");
      this.sortDir = -1;
    } else {
      classList.add("fa-caret-up");
      classList.remove("fa-caret-down");
      this.sortDir = 1;
    }
    for (var i in val) {
      this.listParam.sortBy = i;
      if (this.listParam.sortOrder == "desc") {
        this.listParam.sortOrder = "asc";
      } else if (this.listParam.sortOrder == "asc") {
        this.listParam.sortOrder = "desc";
      } else {
        this.listParam.sortOrder = "asc";
      }
    }
    this.getLeadList();
  }
}
