import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { AuthenticationService } from "../../../../services/auth.service";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-matter-email",
  templateUrl: "./matter-email.component.html",
  styleUrls: ["./matter-email.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MatterEmailComponent implements OnInit {
  mId: any;
  matterEmailForm: FormGroup;
  selectedFiles: any;
  selectedUploadFiles: any;
  url: any;
  succ_msg: any;
  err_email_to: any;
  err_email_cc: string = "";
  err_email_bcc: string = "";
  ifBcc: boolean = false;
  ifCc: boolean = false;
  templateList: any = [];
  templateData: any;
  attachmentList: any = "";
  attachfiles: any = [];
  files: string[] = [];

  editorApiKey: any;
  loginuserdtl: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "200px",
    maxHeight: "200px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getCurrentUserDtl();
    this.editorApiKey = this.data.getEditorApiKey();

    document.querySelector("body").classList.add("sidebar-toggle");
    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);
    this.matterFormInit();
    this.getAllTemplate();
  }

  ngOnDestroy() {
    document.querySelector("body").classList.remove("sidebar-toggle");
  }

  matterFormInit() {
    this.matterEmailForm = this.fb.group({
      email_to: new FormControl(""),
      email_cc: new FormControl(""),
      email_bcc: new FormControl(""),
      email_subject: new FormControl(""),
      email_body: new FormControl(""),
    });
  }

  getCurrentUserDtl() {
    return new Promise((resolve, reject) => {
      this.auth.getUser().subscribe((response) => {
        if (response) {
          this.loginuserdtl = response;

          this.composeEmail();

          if (this.templateData) {
            this.matterEmailForm.patchValue({
              email_body:
                "<br />" +
                this.htmlDecode(this.loginuserdtl.email_signature) +
                "<br />----<br/>" +
                this.templateData.mail_body,
            });
          } else {
            this.matterEmailForm.patchValue({
              email_body:
                "<br />" + this.htmlDecode(this.loginuserdtl.email_signature),
            });
          }
        }
      });
      resolve(null);
    });
  }

  composeEmail() {
    this.data.userTypeId = localStorage.getItem("UserType");

    this.err_email_to = "";
    this.err_email_cc = this.err_email_bcc = "";

    if (this.data.userTypeId == 3) {
      this.ifCc = !this.ifCc;
      this.matterEmailForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.opsCCEmail,
      });
    } else if (this.data.userTypeId == 4) {
      this.ifCc = !this.ifCc;
      this.matterEmailForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.ipCCEmail,
      });
    } else {
      this.ifCc = !this.ifCc;
      this.matterEmailForm.patchValue({
        email_cc: "bnunes@bnlawmacau.com",
      });
    }

    this.attachfiles = [];
    //this.checkFlag = 0;
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = reader.result;
      };
    }
  }

  togglecc() {
    this.ifCc = !this.ifCc;
  }

  togglebcc() {
    this.ifBcc = !this.ifBcc;
  }

  addMatterEmails(postData) {
    let formdata = new FormData();

    formdata.append("matter_id", this.mId);
    formdata.append("email_to", postData.email_to);

    if (this.ifCc == true) {
      formdata.append("cc", postData.email_cc);
    }
    if (this.ifBcc == true) {
      formdata.append("bcc", postData.email_bcc);
    }
    formdata.append("email_subject", postData.email_subject);
    formdata.append("email_body", postData.email_body);
    if (this.selectedFiles == undefined) {
    } else {
      formdata.append("email_attach_file", this.selectedFiles[0]);
    }

    this.data.addMatterEmails(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.err_email_to = "";
          this.router.navigate([
            "/matters/matter-home/matter-files/",
            this.mId,
          ]);
          setTimeout(() => {
            this.succ_msg = false;
            this.matterFormInit();
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.email_to != "") {
          this.err_email_to = error.error.email_to;
        } else {
          this.err_email_to = "";
        }
      }
    );
  }

  getAllTemplate() {
    this.data.getAllTemplate().subscribe((response) => {
      if (response) {
        this.templateList = response;
      }
    });
  }

  draft() {
    if (
      this.matterEmailForm.controls.email_to.value.length > 0 ||
      this.matterEmailForm.controls.email_subject.value.length > 0 ||
      this.matterEmailForm.controls.email_body.value.length > 0
    ) {
      this.addUserEmails(this.matterEmailForm.value, "draft");
    }
  }

  saveTemplate(templateData, template_flag) {
    this.addUserEmails(templateData, template_flag);
  }

  addUserEmails(postData, send_flag) {
    let formdata = new FormData();

    formdata.append("email_to", postData.email_to);
    if (
      this.ifCc == true &&
      postData.email_cc != null &&
      postData.email_cc != ""
    ) {
      formdata.append("cc", postData.email_cc);
    }
    if (
      this.ifBcc == true &&
      postData.email_bcc != null &&
      postData.email_bcc != null
    ) {
      formdata.append("bcc", postData.email_bcc);
    }
    formdata.append("email_subject", postData.email_subject);
    formdata.append("email_body", postData.email_body);
    formdata.append("from", "lpms_email");
    formdata.append("send_flag", send_flag);
    if (this.selectedFiles == undefined) {
    } else {
      for (var i = 0; i < this.files.length; i++) {
        formdata.append("email_attach_file[]", this.files[i]);
      }
    }
    if (this.attachmentList != "") {
      formdata.append("dbattachfiles[]", this.attachfiles);
    }

    if (postData.hid_mail_id != "") {
      formdata.append("hid_mail_id", postData.hid_mail_id);
    }

    if (postData.tracking_flag == true) {
      formdata.append("tracking_flag", "1");
    } else {
      formdata.append("tracking_flag", "0");
    }

    if (send_flag == "template") {
      formdata.append("template_name", postData.template_name);
      formdata.append("visible_for", postData.visible_for);
    }

    formdata.append("temporary_flag_email", "0");

    if (postData.email_trans_body != "") {
      formdata.append("email_trans_body", postData.email_trans_body);
    }

    if (postData.email_trans_subject != "") {
      formdata.append("email_trans_subject", postData.email_trans_subject);
    }

    this.data.addUserEmails(formdata).subscribe(
      (response) => {
        if (response) {
          if (send_flag == "draft") {
            this.succ_msg = "";
          } else {
            this.succ_msg = response["msg"];

            setTimeout(() => {
              this.succ_msg = false;
            }, 3000);
          }
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (send_flag == "sent") {
          if (error.error.email_to != "") {
            this.err_email_to = error.error.email_to;
          } else {
            this.err_email_to = "";
          }
        }

        if (send_flag == "template") {
        }
      }
    );
  }

  onChangeTemplate(template_id) {
    this.attachfiles = [];
    if (template_id != "") {
      this.data.editTemplateById(template_id).subscribe((response) => {
        if (response) {
          this.templateData = response;
          if (this.templateData.cc_list != null) {
            this.ifCc = true;
          }
          if (this.templateData.bcc_list != null) {
            this.ifBcc = true;
          }
          this.matterEmailForm.patchValue({
            email_to: this.templateData.mail_to_email,
            email_cc: this.templateData.cc_list,
            email_bcc: this.templateData.bcc_list,
            email_subject: this.templateData.subject,
          });
          this.attachmentList = this.templateData.attachment_name_list;
          if (this.attachmentList != null) {
            let flag = this.attachmentList.includes(",");
            if (flag == true) {
              this.attachfiles = this.attachmentList.split(",");
            } else {
              this.attachfiles.push(this.attachmentList);
            }
          }
        }
      });
    } else {
      this.matterFormInit();
      this.ifCc = false;
      this.ifBcc = false;
    }
  }

  htmlDecode(str) {
    let div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText;
  }
}
