import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { environment } from '../../../environments/environment';
import { FormGroup, FormBuilder, FormControl, Validators, Validator, FormArray, AbstractControl } from '@angular/forms';
import { DashboardCalendarComponent } from 'src/app/calendar/dashboard-calendar/dashboard-calendar.component';
import { DatePipe } from '@angular/common';
declare var jQuery: any;

@Component({
  selector: 'app-bnlaw',
  templateUrl: './bnlaw.component.html',
  styleUrls: ['./bnlaw.component.scss']
})
export class BnlawComponent implements OnInit {
  @ViewChild('container', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  client_number:number;
  filling_number:number;
  totalClient:any;
  totalTask:any;
  totalMatter:any;
  recentMatter:any;
  recentClient:any;
  recentTask:any;
  dashboardParam:any = {};
  deadlineParam:any = {};
  interval1:any = 'today';
  interval2:any =  'today';
  user_type:number = 3;
  matterList1:any = [];
  matterList2:any = [];
  taskList:any = [];
  articleParam:any= {};
  articles:any = [];
  url: string = '';
  deadlines:any = [];
  clientSearchForm: FormGroup;
  state$: Observable<object>;
  month: any;
  geniusList: any = [];
  date: any;
  deadlinesTotal: any = [];
  filemakerUrl: string;
  articleCategories: any =[];
  sortDir = 1;//1= 'ASE' -1= DSC
  bnlawyers: boolean = false;
  bnoperations: boolean = false;
  bnip: boolean = false;

  taskId:number=0;
  taskParam:any= {};
  
  kbSubs:any;

  constructor(private data: DataService,private fb: FormBuilder,  public router: Router,private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.data.calenderType = localStorage.setItem('calendar_type', 'bn');
    this.data.userTypeId = localStorage.getItem('UserType');
    this.articleParam.intervalArticle = 0;
    this.articleParam.category = 0;
    
    if(this.data.userTypeId==3){
      this.bnlawyers = true;
      this.bnip = false;
      this.bnoperations = false;
    }else if(this.data.userTypeId==4){
      this.bnlawyers = false;
      this.bnip = true;
      this.bnoperations = false;
    }else{
      this.bnlawyers = true;
      this.bnip = true;
      this.bnoperations = true;
    }
    const myFactory = this.resolver.resolveComponentFactory(DashboardCalendarComponent);
    const myRef = this.entry.createComponent(myFactory);
    myRef.instance.calMonth.subscribe(
      response => {
        let month = new DatePipe('en-US').transform(response, 'MM');
        this.month = month;
        this.date = response;
      }
    );
    this.dashboardParam.intervalMatter = 'today';
    this.dashboardParam.intervalClient = 'today';
    this.deadlineParam.interval ='today';
    this.getDashboardTotalActiveClient();
    this.getDashboardRecentClient();
    this.getDashboardTotalActiveTask();
    this.getDashboardRecentTask();
    this.getDashboardTotalActiveMatter();
    this.getDashboardRecentMatter();
    this.getDashBoardMatterList1();
    this.getDashBoardTaskList();
    this.getDashBoardMatterList2();
    this.getArticleList();
    this.listGenius();
    this.getDeadlinesTotal();
    this.getArticleCategories();
    this.articleParam.intervalArticle = 7;
    //this.deadlineParam.interval ='7DAY';
   
    this.getDeadlines();
    this.filemakerUrl = `${environment.filemakerUrl}`+'table/?action=upcoming_deadlines';

    this.state$ = this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      map(() => {
        const currentNav = this.router.getCurrentNavigation();
        return currentNav.extras.state;
      })
    );

  }

  go(name) {
    if (name === 'tasks') {
      $("document").ready(function () {
        jQuery('#taskModal').modal('show')
      });
      this.router.navigateByUrl('/tasks', { state: { addCreate: 'popup' } });
    }

  }

  getArticleCategories() {
    this.data.getArticleCategories(this.articleParam).subscribe(data => {
      this.articleCategories = data;
      
    });
  }

  getDashboardTotalActiveClient() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.intervalClient = '';
    this.data.getDashboardTotalActiveClient(this.dashboardParam).subscribe(data => {
      if(data){
        this.totalClient = data['count'];
      }else{
        this.totalClient = 0;
      }
     
     
    });
  }

 

  getDashboardRecentClient() {
    //this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.intervalClient = this.interval2;
    this.data.getDashboardTotalActiveClient(this.dashboardParam).subscribe(data => {
      if(data){
      this.recentClient = data['count'];
      }else{
        this.recentClient = 0 ; 
      }
    });
  }

  setDayInterval2(value){
 
    this.interval2 = value;
    this.getDashboardRecentClient();
    this.getDashboardRecentMatter();
  }

  setDayInterval1(value){

    this.interval1 = value;
    if(value == ''){
      this.deadlineParam.interval = '';
    }else if(value == 'today'){
      this.deadlineParam.interval = 'today';
    }else{
      this.deadlineParam.interval = value+'DAY';
    }
    
    this.getDashboardRecentTask();
    this.getDeadlines();
    
  }

  getDashboardTotalActiveTask() {
    this.dashboardParam.user_id = localStorage.getItem('UserId');
    this.data.getDashboardTotalActiveTask(this.dashboardParam).subscribe(data => {
      if(data){
        this.totalTask = data['count'];
      }else{
        this.totalTask = 0;
      }
     
     
     
    });
  }

  getDashboardRecentTask() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.user_id = localStorage.getItem('UserId');
    this.dashboardParam.intervaltask = this.interval1;
    
    this.data.getDashboardTotalActiveTask(this.dashboardParam).subscribe(data => {
      if(data){
      this.recentTask = data['count'];
      }else{
        this.recentTask = 0 ; 
      }
    });
  }

  getDashboardTotalActiveMatter() {
    //this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.matter_status = 'case_closed';
    this.dashboardParam.intervalMatter = '';
    this.data.getDashboardTotalActiveMatter(this.dashboardParam).subscribe(data => {
      if(data){
        this.totalMatter = data['count'];
      }else{
        this.totalMatter = 0;
      }
     
     
     
    });
  }
  getDashboardRecentMatter() {
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.dashborad_type = 'bnlaw';
    this.dashboardParam.intervalMatter = this.interval2;
    this.dashboardParam.matter_status = 'case_closed';
    this.data.getDashboardTotalActiveMatter(this.dashboardParam).subscribe(data => {
      if(data){
      this.recentMatter = data['count'];
      }else{
        this.recentMatter = 0 ; 
      }
    });
  }

  getDashBoardMatterList1(){
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.type_of_case = 2;
    this.data.getDashboardMatterList(this.dashboardParam).subscribe(data => {
      if(data){
        this.matterList1 = data;
       
      }
    });
  }

  getDashBoardMatterList2(){
    this.dashboardParam.user_type_id = this.user_type;
    this.dashboardParam.type_of_case = 3;
    this.data.getDashboardMatterList(this.dashboardParam).subscribe(data => {
      if(data){
        this.matterList2 = data;
      }
     
     
    });
  }


  getDeadlines(){
    this.data.getDeadlines(this.deadlineParam).subscribe(data => {
     
      if(data){
        this.deadlines = data;
      }else{
        this.deadlines  = [];
      }
      
    });
   
  }

  getDeadlinesTotal(){
    this.deadlineParam.interval = '';
    this.data.getDeadlines(this.deadlineParam).subscribe(data => {
      if(data){
        this.deadlinesTotal = data;
      }else{
        this.deadlinesTotal = [];
      }
      
    });
   
  }

  
  searchClient(client_number){
    localStorage.setItem('client_number', (client_number != 'null') ? client_number : '');
    this.router.navigate(['/clients']);

  }
  searchApplication(filling_number){
   
  window.location.href= `${environment.filemakerUrl}`+'table/?action=list_table_view&block_id=38&s=&action=list_table_view&search_text='+filling_number+'&tbl=';
   ///window.location.href = 'https://dev3.indusnettechnologies.com/lpms/filemaker/public_html/table/?action=list_table_view&block_id=38&s=&action=list_table_view&search_text=76492+&tbl=';
  }

  listGenius() {
    this.data.getTaskAssignee(3).subscribe(
      data => {
        this.geniusList = data;  
      }
    );
  }
  filterCalendar(geniusId){
    localStorage.setItem('dashboard_month', (this.month != 'null') ? this.month : '');
    localStorage.setItem('genius', (geniusId != 'null') ? geniusId : '');
    localStorage.setItem('dashboard_date', (this.date != 'null') ? this.date : '');
    this.router.navigateByUrl('/agenda');
  }


  getDashBoardTaskList(){

    this.dashboardParam.assignee=localStorage.getItem('UserId');
    this.dashboardParam.user_type_id = localStorage.getItem('UserType');
    this.data.getDashboardTaskList(this.dashboardParam).subscribe(data => {
      if(data){
        this.taskList = data;
      
      }
    });
  }
  
  filterTask(geniusid){
    this.dashboardParam.geniusid = geniusid;
    this.getDashBoardTaskList();
  }
 
  matterRedirect(caseType){
    this.router.navigate(['/matters/matterList/caseType/'+caseType]);
  }


  setDayIntervalArticle(interval){
    this.articleParam.intervalArticle = interval;
    this.articleParam.category = this.articleParam.category;
    this.getArticleList();
  }

  filterArticle(catId){
    this.articleParam.category = catId;
    this.articleParam.intervalArticle = this.articleParam.intervalArticle;
    this.getArticleList();
    
  }


  getArticleList() {
    if (this.kbSubs) {
      this.kbSubs.unsubscribe();
    }
    this.articleParam.user_id=localStorage.getItem('UserId');
    this.articleParam.user_type_id = localStorage.getItem('UserType');
    this.kbSubs = this.data.getDashboardKowlegdeList(this.articleParam).subscribe(data => {
      this.articles = data;
     
    });
   
  }

  sortColumn(event,columnName){
    let target = event.currentTarget,
    classList = target.classList;

    if (classList.contains('fa-caret-up')) {
      classList.remove('fa-caret-up');
      classList.add('fa-caret-down');
      this.sortDir= -1;
    } else {
      classList.add('fa-caret-up');
      classList.remove('fa-caret-down');
      this.sortDir= 1;
    }
    this.sortArr(columnName);
  }

  sortArr(colName:any){
   
    this.deadlines.sort((a,b)=>{
      a= a[colName].toLowerCase();
      b= b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }



  
	hideModal() {
		this.taskId = 0;
		jQuery("#taskModal, #taskViewModal").modal("hide");	
		
	}

	taskEdit(id) {
		if(id != 0) {
			this.taskId = id;
		  }else {
			this.taskId = 0;
		}
	}
	taskView(id) {
		this.taskId = id;
	}


}
