import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from "jquery";

@Component({
  selector: "app-matter-files",
  templateUrl: "./matter-files.component.html",
  styleUrls: ["./matter-files.component.scss"],
})
export class MatterFilesComponent implements OnInit {
  mId: any;
  teamList: any;
  get_doctype_Team: any;
  succ_msg: any;
  matterFileForm: FormGroup;
  err_matter_files: any;
  selectedFiles: any;
  selectedUploadFiles: any;
  matterDetails: any;
  url: any;
  type_of_case_name: any;
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    document.querySelector("body").classList.add("sidebar-toggle");
    $(document).ready(function () {
      /* $('input[type="file"]').change(function(e){
        let fileName = e.target[0].files[0].name;
        $(this).next('.custom-file-label').html(fileName);
      }); */
    });

    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);
    this.matterFormInit();
    this.matterDetailsById();
  }

  // ngOnDestroy() {
  //   document.querySelector('body').classList.remove('sidebar-toggle');
  // }

  matterFormInit() {
    this.matterFileForm = this.fb.group({
      doc_type: new FormControl(""),
      matter_file: new FormControl(""),
    });
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    if (event.target.files) {
      var reader = new FileReader();
      //reader.readAsDataURL(event.target.files); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = reader.result;
      };
    }
  }

  matterDetailsById() {
    this.data.getMatterDetails(this.mId).subscribe((data) => {
      this.matterDetails = data;
      this.getDocListTeamWise(this.matterDetails.type_of_case);
    });
  }

  getDocListTeamWise(type_of_case) {
    if (type_of_case == "1") {
      this.type_of_case_name = "IP";
    } else if (type_of_case == "2") {
      this.type_of_case_name = "CORPORATE";
    } else if (type_of_case == "3") {
      this.type_of_case_name = "LITIGATION";
    } else {
      this.type_of_case_name = "IP";
    }

    return new Promise((resolve, reject) => {
      this.data.getDocTypeByTeam(this.type_of_case_name).subscribe((data) => {
        this.get_doctype_Team = data;
      });
      resolve(null);
    });
  }

  addMatterFiles(postData) {
    let formdata = new FormData();
    if (this.selectedFiles == undefined) {
    } else {
      formdata.append("matter_file", this.selectedFiles[0]);
      formdata.append("doc_type", postData.doc_type);
    }
    formdata.append("matter_id", this.mId);
    this.data.addMatterFiles(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.err_matter_files = "";
          this.router.navigate([
            "/matters/matter-home/matter-notes/",
            this.mId,
          ]);
          setTimeout(() => {
            this.succ_msg = false;
            this.matterFormInit();
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.err_matter_file != "") {
          this.err_matter_files = error.error.err_matter_file;
        } else {
          this.err_matter_files = "";
        }
      }
    );
  }
}
