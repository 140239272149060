import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import Chart from "chart.js";

@Component({
  selector: "intellectual-property",
  templateUrl: "./intellectual-property.component.html",
  styleUrls: ["./intellectual-property.component.scss"],
})
export class IntellectualPropertyComponent implements OnInit {
  @Input() flag: any;
  @Input() year: string;
  type_chart: any = [];
  type_chart_num: any = [];
  ip_filling_chart: any = [];
  ip_filling_chart_num: any = [];
  type_of_work: any = [];
  bnIpfillingMacau: any = [];
  Param: any = {};
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };
  public barChartLabels: Label[] = [
    "New applications",
    "Renewals/Annuities",
    "Change of address/name/agent",
    "Assignments",
    "Appeals/Oppositions",
    "Official searches",
  ];
  public fillingbarChartLabels: Label[] = [
    "Trademarks ",
    "Invension Parent",
    "Extensions",
    "Utility Parents",
    "Designs",
  ];
  public countrywiseChartLabels: Label[] = [];
  public barChartType: ChartType = "horizontalBar";
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [77, 4, 15, 5, 2, 0], label: "Type of work" },
  ];

  type_of_work_chart: any = [];
  type_of_work_chart_num: any = [];
  filling_chart: any = [];
  filling_chart_num: any = [];
  trademark_type_of_work: any = [];
  trademark_type_of_work_num: any = [];
  trademark: any = [];
  design: any = [];
  trademark_type_chart: any = [];
  trademark_type_chart_num: any = [];
  design_type_of_work: any = [];
  design_type_of_work_num: any = [];
  design_type_chart: any = [];
  design_type_chart_num: any = [];
  parent: any = [];
  parent_type_of_work: any;
  parent_type_of_work_num: any;
  parent_type_chart: any = [];
  parent_type_chart_num: any = [];
  countryWiseTypeOfwork: any = [];

  countryWiseType_chart: any = [];
  countryWiseType_chart_num: any = [];
  countryWiseType_data: any = [];
  countryWiseType_data_num: any = [];

  current_year = "";
  trademarkObjective: any = [];
  parentObjective: any = [];
  propName = "country_name";

  constructor(private data: DataService) {}

  ngOnInit() {
    this.Param.type_of_case = 1;
    this.getTypeOfWork();
    this.getBnipFilling();
    this.getTragemarkReport();
    this.getDesignReport();
    this.getParentReport();
    this.getCountryWiseTypeOfWork();
    this.getObjectiveTrademark();
    this.getObjectiveParent();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["year"]) {
      let chg = changes["year"];
      let cval = chg.currentValue;

      this.Param.year = this.year;
      this.Param.type_of_case = 1;
      if (chg.firstChange != true) {
        this.Param.type_of_case = this.flag;
        this.getTypeOfWork();
        this.getBnipFilling();
        this.getTragemarkReport();
        this.getDesignReport();
        this.getParentReport();
        this.getCountryWiseTypeOfWork();
      }
    }
  }

  getTypeOfWork() {
    this.Param.matter_type_id = "";
    this.data.getTypeOfWork(this.Param).subscribe((data) => {
      this.current_year = this.Param.year;
      this.type_of_work = data;
      this.type_of_work_chart = data["type_of_work_chart"];
      this.type_of_work_chart_num = data["type_of_work_chart_num"];
      this.type_chart = null;
      let ctx1 = "canvas1_" + this.Param.year;
      this.type_chart = new Chart(ctx1, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.type_of_work_chart,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Work % [" + this.Param.year + "]",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
      this.type_chart_num = null;
      var ctx2 = "canvas1num_" + this.Param.year;
      this.type_chart_num = new Chart(ctx2, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.type_of_work_chart_num,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Type of work [" + this.Param.year + "]",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
  }

  getBnipFilling() {
    this.data.getBnIpfillingMacau(this.Param).subscribe((data) => {
      this.current_year = this.Param.year;
      this.bnIpfillingMacau = data;
      this.filling_chart = data["filling_chart"];
      this.filling_chart_num = data["filling_chart_num"];
      this.ip_filling_chart = null;
      var ctx3 = "canvas2_" + this.Param.year;
      this.ip_filling_chart = new Chart(ctx3, {
        type: "horizontalBar",
        data: {
          labels: this.fillingbarChartLabels,
          datasets: [
            {
              data: this.filling_chart,
              backgroundColor: [
                "#40ff00",
                "#ffff00",
                "#ff9900",
                "#ff0000",
                "#800000",
                "#660000",
              ],

              label: "Bn ip filling",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
      this.ip_filling_chart_num = null;
      var ctx4 = "canvas2num_" + this.Param.year;
      this.ip_filling_chart_num = new Chart(ctx4, {
        type: "horizontalBar",
        data: {
          labels: this.fillingbarChartLabels,
          datasets: [
            {
              data: this.filling_chart_num,
              backgroundColor: [
                "#40ff00",
                "#ffff00",
                "#ff9900",
                "#ff0000",
                "#800000",
                "#660000",
              ],

              label: "Bn ip filling",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
  }

  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public randomize(): void {
    this.barChartType = this.barChartType === "bar" ? "line" : "bar";
  }

  getTragemarkReport() {
    this.Param.matter_type_id = 5;
    this.data.getTypeOfWork(this.Param).subscribe((data) => {
      this.trademark = data;
      this.trademark_type_of_work = data["type_of_work_chart"];
      this.trademark_type_of_work_num = data["type_of_work_chart_num"];

      this.current_year = this.Param.year;
      this.trademark_type_chart = null;
      var ctx5 = "canvas3_" + this.Param.year;
      this.trademark_type_chart = new Chart(ctx5, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.trademark_type_of_work,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Work %",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });

      this.trademark_type_chart_num = null;
      var ctx6 = "canvas3num_" + this.Param.year;
      this.trademark_type_chart_num = new Chart(ctx6, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.trademark_type_of_work_num,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Type of work",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
    this.Param.matter_type_id = "";
  }

  getDesignReport() {
    this.Param.matter_type_id = 1;
    this.data.getTypeOfWork(this.Param).subscribe((data) => {
      this.design = data;
      this.design_type_of_work = data["type_of_work_chart"];
      this.design_type_of_work_num = data["type_of_work_chart_num"];

      this.current_year = this.Param.year;
      this.design_type_chart = null;
      var ctx7 = "canvas4_" + this.Param.year;
      this.design_type_chart = new Chart(ctx7, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.design_type_of_work,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Work %",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });

      this.design_type_chart_num = null;
      var ctx8 = "canvas4num_" + this.Param.year;
      this.design_type_chart_num = new Chart(ctx8, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.design_type_of_work_num,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Type of work",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
    this.Param.matter_type_id = "";
  }

  getParentReport() {
    this.Param.matter_type_id = 2;
    this.data.getTypeOfWork(this.Param).subscribe((data) => {
      this.parent = data;
      this.parent_type_of_work = data["type_of_work_chart"];
      this.parent_type_of_work_num = data["type_of_work_chart_num"];

      this.current_year = this.Param.year;
      this.parent_type_chart = null;
      var ctx9 = "canvas5_" + this.Param.year;
      this.parent_type_chart = new Chart(ctx9, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.parent_type_of_work,
              backgroundColor: [
                "#ff1aff",
                "#0000ff",
                "#00ffff",
                "#ffff00",
                "#e60000",
                "#b300b3",
              ],

              label: "Work %",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });

      this.parent_type_chart_num = null;
      var ctx10 = "canvas5num_" + this.Param.year;
      this.parent_type_chart_num = new Chart(ctx10, {
        type: "horizontalBar",
        data: {
          labels: this.barChartLabels,
          datasets: [
            {
              data: this.parent_type_of_work_num,
              backgroundColor: "#BA55D3",

              label: "Type of work",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
    this.Param.matter_type_id = "";
  }

  getCountryWiseTypeOfWork() {
    this.data.getCountryWiseTypeOfWork(this.Param).subscribe((data) => {
      this.countryWiseTypeOfwork = data;
      Object.keys(data).forEach((key) => {
        this.countrywiseChartLabels.push(data[key]["country_name"]);
        this.countryWiseType_data.push(data[key]["filling_pers"]);
        this.countryWiseType_data_num.push(data[key]["filling"]);
      });
      this.current_year = this.Param.year;
      this.countryWiseType_chart = null;
      var ctx11 = "canvas6_" + this.Param.year;
      this.countryWiseType_chart = new Chart(ctx11, {
        type: "horizontalBar",
        data: {
          labels: this.countrywiseChartLabels,
          datasets: [
            {
              data: this.countryWiseType_data,
              backgroundColor: "#FF7F50",
              label: "Origin %",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });

      this.countryWiseType_chart_num = null;
      var ctx12 = "canvas6num_" + this.Param.year;
      this.countryWiseType_chart_num = new Chart(ctx12, {
        type: "horizontalBar",
        data: {
          labels: this.countrywiseChartLabels,
          datasets: [
            {
              data: this.countryWiseType_data_num,
              backgroundColor: "#E9967A",
              label: "Origin",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
                gridLines: {
                  display: true,
                  color: "white",
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "white",
                },
                display: true,
              },
            ],
          },
        },
      });
    });
  }

  getObjectiveTrademark() {
    this.Param.matter_type_id = 5;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.trademarkObjective = data;
    });
  }

  getObjectiveParent() {
    this.Param.matter_type_id = 2;
    this.data.getReportObjective(this.Param).subscribe((data) => {
      this.parentObjective = data;
    });
  }

  customsort = (a, b) => {
    if (a.value[this.propName] === b.value[this.propName]) {
      return 0;
    } else if (a.value[this.propName] === null) {
      return -1;
    } else if (b.value[this.propName] === null) {
      return 1;
    }
    return a.value[this.propName]
      .toString()
      .localeCompare(b.value[this.propName].toString());
  };
}
