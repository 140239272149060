import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: "root",
})
export class DataService {
  forEach(arg0: (e: any) => void) {
    throw new Error("Method not implemented.");
  }

  timeOut = 2500;
  uId = sessionStorage.getItem("UserId");
  calDashboardDate: any;
  calenderParam: any = {};
  userTypeId: any;
  typeOfCase: any;
  calenderType: any;
  selectedClient: any;
  selectedTypeOfCase: any;
  newOrgName: any;

  constructor(private http: HttpClient) {}

  checkEmail() {
    return this.http.get(`${environment.lpmsUrl}` + "contact/checkEmail");
  }

  checkPhone() {
    return this.http.get(`${environment.lpmsUrl}` + "contact/checkPhone");
  }

  contactAddOrganisation(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "contact/addOrganisation",
      post
    );
  }

  listNationality() {
    return this.http.get(`${environment.lpmsUrl}` + "contact/countryList");
  }

  getOrgListing(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "contact/OrgList", {
      params: getParam,
    });
  }

  contactAddPerson(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "contact/addContactPerson",
      post
    );
  }

  getPersonList(getParam) {
    //return this.http.get(`${environment.lpmsUrl}` + 'contact/personList', { params: getParam });
  }

  listFilteredOrg() {
    return this.http.get(`${environment.lpmsUrl}` + "contact/listFilteredOrg");
  }

  getClientList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "contact/clientList", {
      params: getParam,
    });
  }

  getContactDetails(contactId, contactType) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "contact/getContactDetails/" +
        contactId +
        "/" +
        contactType
    );
  }

  getCountryName(counId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getCountryName/" + counId
    );
  }

  getMatterTypes() {
    return this.http.get(`${environment.lpmsUrl}` + "matters/matterTypeList/");
  }

  getMatterQsFields(matterTypeId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/matterQsFieldList/" + matterTypeId
    );
  }

  getMatterListValues(matterTypeId, matterFieldType, matterFieldControl) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getAllListValue/" +
        matterTypeId +
        "/" +
        matterFieldType +
        "/" +
        matterFieldControl
    );
  }

  getTemplateDtl(process_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/getTemplateDtl/" + process_id
    );
  }

  getTaskList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "task/listTask", {
      params: getParam,
    });
  }

  getLastClientNo(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "matters/getLastClientNo", {
      params: getParam,
    });
  }

  getLastMatterNo(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "matters/getLastMatterNo", {
      params: getParam,
    });
  }

  existingClientNoCheck(client_number) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/existingClientNoCheck/" +
        client_number
    );
  }

  addMatter(post) {
    return this.http.post(`${environment.lpmsUrl}` + "matters/addMatter", post);
  }

  addTaskpost(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "task/addUpdateTask",
      post
    );
  }

  getTaskListID() {
    return this.http.get(`${environment.lpmsUrl}` + "task/listTask");
  }

  listClientList() {
    return this.http.get(`${environment.lpmsUrl}` + "contact/clientList");
  }

  listMatterList() {
    return this.http.get(`${environment.lpmsUrl}` + "matters/matterList");
  }

  countMatter(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "matters/matterListCount", {
      params: getParam,
    });
  }

  allMatters(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllMattersList",
      { params: getParam }
    );
  }
  getLastTaskId() {
    return this.http.get(`${environment.lpmsUrl}` + "task/getLastTaskId");
  }
  deleteListItem(tid) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "task/deleteTask/" + tid
    );
  }

  deleteAllTaskItem(tid) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "task/deleteAllTask/" + tid
    );
  }

  getlistDetails(etId) {
    return this.http.get(`${environment.lpmsUrl}` + "task/editTask/" + etId);
  }
  exportTask() {
    return this.http.get(`${environment.lpmsUrl}` + "task/exportTask");
  }

  /*getTaskAssignee(uTypeId){
    return this.http.get(`${environment.lpmsUrl}` + 'task/listAssignee/'+uTypeId);
  }*/

  getTaskAssignee(caseTypeId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/listAssignee/" + caseTypeId
    );
  }

  getAssociateGenius(matterId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/getAssociateGenius/" + matterId
    );
  }

  getMatterList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "matters/matterList", {
      params: getParam,
    });
  }

  getmatterTypeList() {
    return this.http.get(`${environment.lpmsUrl}` + "matters/matterTypeList");
  }

  addLeadpost(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "leads/addUpdateLead",
      post
    );
  }

  getLeadList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "leads/listLeads", {
      params: getParam,
    });
  }
  getMatterDetByMatterId(matterId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/matterDet/" + matterId
    );
  }

  getMatterQsSetByMatterTypeId(leadId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/matterQsSet/" + leadId
    );
  }

  getLeadMatterAns(leadId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/getLeadMatterAns/" + leadId
    );
  }

  getLeadData(leadId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/getLeadDet/" + leadId
    );
  }

  addSchedule(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "leads/addUpdateSchedule",
      post
    );
  }

  addNewOrg(newOrgName) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/addNewOrg/" + newOrgName
    );
  }

  getAllTaskList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "task/getAlltaskList", {
      params: getParam,
    });
  }
  addTaskList(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "task/addUpdateTaskList",
      post
    );
  }

  getTaskListDetails(tListId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/editTaskList/" + tListId
    );
  }

  listFilteredContactPerson(personType) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getClientDetByType/" + personType
    );
  }

  addNewContact(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "matters/addNewContact",
      post
    );
  }
  clientRegister(post) {
    return this.http.post(`${environment.lpmsUrl}` + "contact/addClient", post);
  }

  agreementSigned(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "leads/agreementSigned",
      post
    );
  }

  getIpListFromFilemaker($flag) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "task/getIpListFromFilemaker/" +
        $flag +
        "/" +
        this.uId
    );
  }

  getAppListFromFilemaker(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/applistFromFilemaker/" + this.uId,
      { params: getParam }
    );
  }

  getAllListing(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "contact/AllList", {
      params: getParam,
    });
  }

  getAllTotalCount(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "contact/AllListCount", {
      params: getParam,
    });
  }

  getFieldList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "matters/getFieldList", {
      params: getParam,
    });
  }

  /* addCustomField(post){
    return this.http.post(`${environment.lpmsUrl}` + 'matters/addCustomField', post);
  } */

  /* getCustomFieldList(getParam){
    return this.http.get(`${environment.lpmsUrl}` + 'matters/getCustomFieldList', { params: getParam });
  } */

  deleteContact(client_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/deleteContact/" + client_id
    );
  }

  deleteAllContact(client_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/deleteAllContact/" + client_id
    );
  }

  deleteAllFromND(client_number, team) {
    return this.http.get(
      `${environment.filemakerUrl}` +
        "forms/?action=deleteClientMatterFromNDLPMS&client_number=" +
        client_number +
        "&flag=" +
        "Client&team=" +
        team
    );
  }

  deleteAllMatter(matter_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/deleteAllMatter/" + matter_id
    );
  }

  deleteAllMatterFromND(client_number, matter_number, team) {
    return this.http.get(
      `${environment.filemakerUrl}` +
        "forms/?action=deleteClientMatterFromNDLPMS&client_number=" +
        client_number +
        "&flag=" +
        "Matters&team=" +
        team +
        "&matter_number=" +
        matter_number
    );
  }

  deleteMatter($matter_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/deleteMatter/" + $matter_id
    );
  }
  getMatterDetails($matter_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/matterDet/" + $matter_id
    );
  }

  assigneeListByLeadId(lead_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/assigneeListByLeadId/" + lead_id
    );
  }

  deleteassigneeList($tId, $leadId) {
    return this.http.delete(
      `${environment.lpmsUrl}` +
        "leads/deleteTaskFromLeads/" +
        $tId +
        "/" +
        $leadId
    );
  }

  getLeadDetails(etId) {
    return this.http.get(`${environment.lpmsUrl}` + "leads/getLeadDet/" + etId);
  }

  deleteLeadList($tId) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "leads/deleteLead/" + $tId
    );
  }

  deleteTaskList($tId) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "task/deleteTaskList/" + $tId
    );
  }

  addMatterNotes(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "matters/addMatterNotes",
      post
    );
  }

  deleteMatterNotes($matterNoteId, $mId) {
    return this.http.delete(
      `${environment.lpmsUrl}` +
        "matters/deleteMatterNotes/" +
        $matterNoteId +
        "/" +
        $mId
    );
  }

  getAllMatterNotesList(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllMatterNotesList/" + mID
    );
  }

  editMatterNotesById(matter_notes_id) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/editMatterNotesById/" +
        matter_notes_id
    );
  }

  deleteListItemFromMatter(tid, mId) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "task/deleteTaskFromMatter/" + tid + mId
    );
  }

  /* Need to work on php */

  addMatterFiles(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "matters/addMatterFiles",
      post
    );
  }

  addMatterEmails(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "matters/addMatterEmails",
      post
    );
  }

  matterUpdateOrg(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "contact/updateOrgFromMatter",
      post
    );
  }
  matterUpdatePerson(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "contact/updatePersonFromMatter",
      post
    );
  }

  getAllMatterTaskList(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/listTaskFromMatter/" + mID
    );
  }

  deleteMatterTask($tId, $matterId) {
    return this.http.delete(
      `${environment.lpmsUrl}` +
        "task/deleteTaskFromMatter/" +
        $tId +
        "/" +
        $matterId
    );
  }

  getAllMatterFileList(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/listingMatterFile/" + mID
    );
  }

  deleteMatterFile($tId, $matterId) {
    return this.http.delete(
      `${environment.lpmsUrl}` +
        "matters/deleteMatterFile/" +
        $tId +
        "/" +
        $matterId
    );
  }

  getAllMatterEmailList(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/ListMatterEmails/" + mID
    );
  }

  getAllMatterChangeLog(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getChangeLog/" + mID
    );
  }

  getAllPartiesByMatterId(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllPartiesByMatterId/" + mID
    );
  }

  listPlannedTaskFromMatter(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/listPlannedTaskFromMatter/" + mID
    );
  }

  getAllListFromMatter(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllListFromMatter/" + mID
    );
  }
  getClientDetails(id, type) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getClientDetails/" + id + "/" + type
    );
  }

  getQuotePrice(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "/leads/getQuoteCost",
      post
    );
  }
  displayQuoteAmount(lId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/displayQuoteAmount/" + lId
    );
  }
  getAllQuotelist(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "quotes/getAllQuotesList", {
      params: getParam,
    });
  }

  getQuoteDetails(id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "quotes/getQuoteDetails/" + id
    );
  }

  getScheduleDetByLeadId(lId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/getScheduleDetByLeadId/" + lId
    );
  }

  editClientByLeadId(lId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/editClientByLeadId/" + lId
    );
  }

  getTypeOfCase() {
    return this.http.get(`${environment.lpmsUrl}` + "matters/getTypeOfCase");
  }

  getSourceOfWork() {
    return this.http.get(`${environment.lpmsUrl}` + "matters/getSourceOfWork");
  }

  getAllMatterTypes(caseId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllMatterTypes/" + caseId
    );
  }

  getMatterTypeDtl(matter_type_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getMatterTypeDtl/" + matter_type_id
    );
  }

  getLitigationMatterTypesValues(caseId, matterTypeId) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getLitigationMatterTypesValues/" +
        caseId +
        "/" +
        matterTypeId
    );
  }

  getLitigationMainIssueList(
    matter_type_id,
    litigation_matter_type_value,
    litigation_court_type
  ) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getLitigationMainIssueList/" +
        matter_type_id +
        "/" +
        litigation_matter_type_value +
        "/" +
        litigation_court_type
    );
  }
  getLitigationClientStatus(matter_type_id, litigation_matter_type_value) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getLitigationClientStatus/" +
        matter_type_id +
        "/" +
        litigation_matter_type_value
    );
  }

  getLitigationMatterCompetentCourt(caseId) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getLitigationMatterCompetentCourt/" +
        caseId
    );
  }

  getLitigationMatterOpposingParty(caseId) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getLitigationMatterOpposingParty/" +
        caseId
    );
  }

  getLitigationStage() {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getLitigationStage"
    );
  }

  dateDiff(matId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/dateDiff/" + matId
    );
  }

  getmatterTypeListForLeads() {
    return this.http.get(
      `${environment.lpmsUrl}` + "leads/matterTypeListForLeads"
    );
  }

  addCalendarEvent(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "calendar/addCalendarEvent",
      post
    );
  }

  listEmail(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "emails/emailList", {
      params: getParam,
    });
  }

  countEmails() {
    return this.http.get(`${environment.lpmsUrl}` + "emails/emailListCount");
  }

  emailDescById(mailId, audit_flag) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "emails/getEmailDescById/" +
        mailId +
        "/" +
        audit_flag
    );
  }

  associatEmail(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "emails/associateEmail",
      post
    );
  }

  /*getAllEvent(userTypeId){
    return this.http.get(`${environment.lpmsUrl}` + 'calender/getAllCalendarEvent/'+userTypeId);
  }*/
  getAllEvent(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "calender/getAllCalendarEvent",
      { params: getParam }
    );
  }

  getEventDetails(eventId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "calendar/getEventDetails/" + eventId
    );
  }

  editCalendarEvent(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "calendar/updateCalendarEvent",
      post
    );
  }

  addUserEmails(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "emails/addUserEmails",
      post
    );
  }

  translateContent(post) {
    return this.http.post(`${environment.lpmsUrl}` + "/emails/translate", post);
  }

  deleteEvent(eventId) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "calendar/deleteEvent/" + eventId
    );
  }

  addCalendarTaskEvent(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "calendar/addTaskEvent",
      post
    );
  }

  updateCalendarTaskEvent(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "calendar/editTaskEvent",
      post
    );
  }

  geteventByDate(currDate) {
    return this.http.get(
      `${environment.lpmsUrl}` + "emails/geteventByDate/" + currDate
    );
  }

  /* geteventByDate(post){
    return this.http.post(`${environment.lpmsUrl}` + 'emails/geteventByDate', post);
  } */
  agendaList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "calendar/agendaList", {
      params: getParam,
    });
  }

  /* filemakerLogin(){
    return this.http.get(`${environment.lpmsUrl}` + 'matters/filemakerLogin');
  } */

  getEventLocation() {
    return this.http.get(
      `${environment.lpmsUrl}` + "calendar/getEventLocation"
    );
  }

  getAllTemplate() {
    return this.http.get(`${environment.lpmsUrl}` + "emails/getAllTemplate");
  }

  editTemplateById(template_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "emails/getTemplateById/" + template_id
    );
  }

  matterWorkflowCompleteTask(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "matters/matterWorkflowCompleteTask",
      post
    );
  }

  checkDisabledButton(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/checkDisabledButton",
      { params: getParam }
    );
  }

  getAllCompleteWorkflowProcessList(mID) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/ListCompleteWorkflowProcess/" + mID
    );
  }

  checkBoxListByProcessCode(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/checkBoxListByProcessCode",
      { params: getParam }
    );
  }

  deleteEmail(mailId) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "emails/deleteEmail/" + mailId
    );
  }

  getClientDetByClientId(clientId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getClientDetBtClientId/" + clientId
    );
  }

  getGinuesById(geniusId, assigneeType) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "contact/getGeniusDetails/" +
        geniusId +
        "/" +
        assigneeType
    );
  }

  getEmailAudit(mailId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "emails/getEmailAuditDataById/" + mailId
    );
  }
  getMatterNameByMatterId(matterId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getMatterById/" + matterId
    );
  }

  getArticleCategories(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getArticleCategories",
      { params: getParam }
    );
  }

  insertClientToNDFromLPMS(post) {
    return this.http.post(
      `${environment.filemakerUrl}` + "forms/?action=insertClientToNDFromLPMS",
      post
    );
  }

  insertMatterToNDFromLPMS(post) {
    return this.http.post(
      `${environment.filemakerUrl}` + "forms/?action=insertMatterToNDFromLPMS",
      post
    );
  }

  postArticle(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/postArticle",
      post
    );
  }
  getArticlePointsOfLaw(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getArticlePointsOfLaw",
      { params: getParam }
    );
  }

  getArticles(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/listArticles",
      { params: getParam }
    );
  }

  getArticleDetails(articleId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getArticleDetails/" + articleId
    );
  }
  deleteArticle(articleId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/deleteArticle/" + articleId
    );
  }

  

  getFilterMatterList(viewType) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllFilterMatter/" + viewType
    );
  }

  getDashboardTotalActiveTask(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getTotalActiveTask",
      { params: getParam }
    );
  }

  getDashboardTotalActiveClient(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getTotalActiveClient",
      { params: getParam }
    );
  }

  getDashboardTotalActiveMatter(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getTotalActiveMatter",
      { params: getParam }
    );
  }

  getDashboardMatterList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "dashboard/getMatterList", {
      params: getParam,
    });
  }
  getDashboardTaskList(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "dashboard/getTaskList", {
      params: getParam,
    });
  }

  getDashboardKowlegdeList(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getKnowledgebaseList",
      { params: getParam }
    );
  }
  getDeadlines(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getUpcomingDeadlines",
      {
        params: getParam,
      }
    );
  }
  getApplications() {
    return this.http.get(
      `${environment.filemakerUrl}` +
        "table/?action=list_table_view&block_id=38&from_lpms=1"
    );
  }
  getArticleComments(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getAllComments",
      { params: getParam }
    );
  }
  getArticleCommentReplies(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getAllreplies",
      { params: getParam }
    );
  }
  postComment(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/postComment",
      post
    );
  }

  postReply(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/postReplies",
      post
    );
  }

  translateArticle(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "/knowledgeBase/translateContent",
      post
    );
  }
  markEmailAsRead(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "/emails/markEmailAsRead",
      post
    );
  }

  getContactsByContactType(type) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getContactsByContactType/" + type
    );
  }

  /*getAllJudgesByCompetentCourt(type_value_id){
    return this.http.get(`${environment.lpmsUrl}` + 'contact/getLitigationJudgesCompetentCourt/'+type_value_id);
  }*/

  getAllJudgesByCompetentCourt(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getLitigationJudgesCompetentCourt",
      { params: getParam }
    );
  }

  getPointsOfLaw(mainIssueId, lawFlag, litigation_matter_type_value) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getPointsOfLaw/" +
        mainIssueId +
        "/" +
        lawFlag +
        "/" +
        litigation_matter_type_value
    );
  }

  getSubPointsOfLaw(pointId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getSubPointsOfLaw/" + pointId
    );
  }

  getJudgeData(judgeId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getJudgeData/" + judgeId
    );
  }

  getArticleSearch(tagId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getArticleSearch/" + tagId
    );
  }

  getSimilarPOLawSearch(tagId, matter_id) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getSimilarPOLawSearch/" +
        tagId +
        "/" +
        matter_id
    );
  }

  getPopularArticles(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getPopularArticles",
      { params: getParam }
    );
  }
  getFavoriteAricles() {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getFavouriteArticles"
    );
  }

  shareArticle(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/shareArticle",
      post
    );
  }

  getLatestArticles(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/latestArticles",
      { params: getParam }
    );
  }

  getScheduleData() {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getScheduleData"
    );
  }

  getTotalLeads(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "dashboard/gettotalLeads", {
      params: getParam,
    });
  }
  getTotalYearlyMattersCount(getParam){
    return this.http.get(`${environment.lpmsUrl}` + "dashboard/getTotalYearlyMattersCount", {
      params: getParam,
    });
  }
  getTotalDocuments(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/gettotalDocuments",
      { params: getParam }
    );
  }

  getTotalEmails(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "dashboard/gettotalEmail", {
      params: getParam,
    });
  }
  getLeadToclient() {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getLeadsConvertedtoClient"
    );
  }

  getJudgeSpecificCases(caseType, matterTypeId, judgeId) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "matters/getJudgeSpecificCases/" +
        caseType +
        "/" +
        matterTypeId +
        "/" +
        judgeId
    );
  }
  getJudgeGrantDenied(judgeId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getJudgeGrantDenied/" + judgeId
    );
  }

  getCaseDecisionByPOL(polId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getCaseDecisionByPOL/" + polId
    );
  }

  getTotalUnreadEmail(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getTotalUnreadEmail",
      { params: getParam }
    );
  }

  publishArticle(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/publishArticle",
      post
    );
  }
  makeFavouriteArticle(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/makefavoriteArticle",
      post
    );
  }
  getResources(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getResources",
      { params: getParam }
    );
  }

  typeOfCourt(caseId) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "knowledgeBase/getLitigationMatterCompetentCourt/" +
        caseId
    );
  }

  getPOLNamebyId(tagId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getPOLNamebyId/" + tagId
    );
  }

  courtCaseDateDiff(judgeId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/courtCaseDateDiff/" + judgeId
    );
  }

  getCourtRuleDetails(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getCourtRuleDetails",
      { params: getParam }
    );
  }

  getTotalTags() {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getTotalTags"
    );
  }

  articleViewCount(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "knowledgeBase/articleViewCount",
      post
    );
  }

  getAllStaff() {
    return this.http.get(`${environment.lpmsUrl}` + "dashboard/getAllStaff");
  }

  getTotalAppointment(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "dashboard/getTotalAppointment",
      { params: getParam }
    );
  }

  /*getkbSubPointsOfLaw(){
    return this.http.get(`${environment.lpmsUrl}` + 'knowledgeBase/getSubPointsOfLaw');
  }*/

  getkbSubPointsOfLaw(point_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getSubPointsOfLaw/" + point_id
    );
  }

  getcourtRullingShortList() {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/courtRullingShortList"
    );
  }
  getTotalCourtRulling(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getTotalCourtRulling",
      { params: getParam }
    );
  }

  getTotalArticle() {
    return this.http.get(
      `${environment.lpmsUrl}` + "knowledgeBase/getTotalArticle"
    );
  }

  getAllContactLabel() {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/getContactGroupList"
    );
  }

  getTypeOfWork(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/dashboard/getTypeOfWork",
      { params: getParam }
    );
  }
  getBnIpfillingMacau(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/dashboard/getBnIpfillingMacau",
      { params: getParam }
    );
  }
  getCountryWiseTypeOfWork(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/dashboard/getCountryWiseTypeOfWork",
      { params: getParam }
    );
  }

  getTotalClientByYear(selectedYear, case_id) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "reports/clients/getTotalClientByYear/" +
        selectedYear +
        "/" +
        case_id
    );
  }

  getTotalMattersByYear(selectedYear) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "reports/matters/getTotalMattersByYear/" +
        selectedYear
    );
  }

  getDurationOfEachMatter(selectedYear) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "reports/matters/getDurationOfEachMatter/" +
        selectedYear
    );
  }

  getDurationOfEachMatterType(team, selectedYear) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "reports/matters/getDurationOfEachMatterType/" +
        team +
        "/" +
        selectedYear
    );
  }

  getAllMatterKind(caseId) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllMatterKind/" + caseId
    );
  }

  getAssignedTask(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/task/getTaskAssigned",
      { params: getParam }
    );
  }

  getAssignedTaskCompleted(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/task/getTaskAssignedCompleted",
      { params: getParam }
    );
  }

  avgDurationTaskCompleted(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/task/avgDurationTaskCompleted",
      { params: getParam }
    );
  }

  getprocessStlist(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "task/getAllProcesslist", {
      params: getParam,
    });
  }

  addEditProcessSt(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "task/addEditProcesslist",
      post
    );
  }

  getProcessstDetails(process_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/getProcessListDetails/" + process_id
    );
  }

  deleteProcess(process_id) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "task/deleteprocessSt/" + process_id
    );
  }

  getClientMatterDocuments(client_id, matter_id) {
    return this.http.get(
      `${environment.filemakerUrl}` +
        "table/?action=getMatterDocumentsByMatterId&client_id=" +
        client_id +
        "&matter_id=" +
        matter_id
    );
  }
  deleteDocument(documemt_id) {
    return this.http.get(
      `${environment.filemakerUrl}` +
        "table/?action=delete_document&document_id=" +
        documemt_id
    );
  }
  getAllChecklist() {
    return this.http.get(`${environment.lpmsUrl}` + "matters/getAllChecklist");
  }

  generateDoc(post) {
    return this.http.post(
      `${environment.lpmsUrl}` + "matters/generateDoc",
      post
    );
  }

  getClientCaseDet(selectedYear, client_id) {
    return this.http.get(
      `${environment.lpmsUrl}` +
        "reports/matters/getClientCaseDet/" +
        client_id +
        "/" +
        selectedYear
    );
  }

  getMatterAssignedCount(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/matters/getMatterAssignedCount",
      { params: getParam }
    );
  }

  getYearlyReport(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/dashboard/getYearlyReport",
      { params: getParam }
    );
  }

  getAllTaskByClientMatter(matter_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getAllTaskByClientMatter/" + matter_id
    );
  }

  getReportObjective(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/dashboard/getObjective",
      { params: getParam }
    );
  }

  getLabourTypeOfwork(getParam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "reports/dashboard/getLabourTypeOfwork",
      { params: getParam }
    );
  }
  getTotalTask(getParam) {
    return this.http.get(`${environment.lpmsUrl}` + "task/getTotalTask", {
      params: getParam,
    });
  }

  getAllProcesslistByMatterType(getparam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "task/getAllProcesslistByMatterType",
      { params: getparam }
    );
  }

  deleteArticleFile($fileid) {
    return this.http.delete(
      `${environment.lpmsUrl}` + "knowledgeBase/deleteArticleFile/" + $fileid
    );
  }

  /*getAllGoogleCalender(){
    return this.http.get(`${environment.lpmsUrl}` + 'calendar/getAllGoogleCalender');
  }*/

  getAllGoogleCalender(getparam) {
    return this.http.get(
      `${environment.lpmsUrl}` + "calendar/getAllGoogleCalender",
      { params: getparam }
    );
  }
  
  teamList() {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/teamList"
    );
  }


  getDocTypeByTeam(team_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "matters/getDocTypeByTeam/" + team_id
    );
  }


  getEditorApiKey() {
    return environment.tinymec;
  }

  
  removeRegisterImage(contact_id) {
    return this.http.get(
      `${environment.lpmsUrl}` + "contact/removeRegisterImage/" + contact_id
    );
  }

  getRawPageContent(url) {
    return this.http.get(url,{ responseType: 'text' });    
  }

}
