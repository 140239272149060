import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
declare var jQuery: any;
@Component({
  selector: 'app-matter-dashboard',
  templateUrl: './matter-dashboard.component.html',
  styleUrls: ['./matter-dashboard.component.scss']
})
export class MatterDashboardComponent implements OnInit {
  mId : any;
  matterDetails: any = [];
  fn:any = '';
  ln:any = '';
  constructor(private data: DataService) { }

  ngOnInit() {
    document.querySelector('body').classList.add('sidebar-toggle');

      if (window.innerWidth  < 960) { 
        this.matterSidebar();
      }

      let url = window.location.href;		
      this.mId = url.substr(url.lastIndexOf('/') + 1);

      this.matterDetailsData();

      jQuery(document).ready(function(){
        jQuery('.leftmenu li a').removeClass('open')
        jQuery('body.sidebar-toggle .leftmenu li ul').hide()
      })
      
  }

  // ngOnDestroy() {
  //   document.querySelector('body').classList.remove('sidebar-toggle');
  // }

  matterDetailsData(){
    
    this.data.getMatterDetByMatterId( this.mId ).subscribe(response => {
      if (response) {
        this.matterDetails = response;
        if(this.matterDetails.given_name != null){
          this.fn = this.matterDetails.given_name.substring(0,1);
        }else{
          this.fn = this.matterDetails.org_name.substring(0,1);
        }
        if(this.matterDetails.family_name != null){
          this.ln = this.matterDetails.family_name.substring(0,1);
        }
        
      }
    });
    
    }

  matterSidebar(){
	var x = document.getElementById('ipad-matter-sidebar');
	if (x.style.display === "none") {
		x.style.display = "block";
	  } else {
		x.style.display = "none";
	  }
	
  }

}
