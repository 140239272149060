import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Self,
} from "@angular/core";

import {
  CalendarEvent,
  CalendarMonthViewDay,
  CalendarView,
} from "angular-calendar";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-dashboard-calendar",
  templateUrl: "./dashboard-calendar.component.html",
  styleUrls: ["./dashboard-calendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DashboardCalendarComponent implements OnInit {
  viewDate: Date = new Date();

  view: CalendarView = CalendarView.Month;
  //view: CalendarView = CalendarView.Day;
  listEvent: any;
  userTypeId: any;
  searchId: any;

  @Output() calMonth = new EventEmitter();
  constructor(
    public data: DataService,
    private router: Router,
    @Self() private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.userTypeId = localStorage.getItem("UserType");

    /*if(this.router.url === '/bnip'){
      this.userTypeId = 4;
    }else if(this.router.url === '/bnlawyers'){
      this.userTypeId = 3;
    }else{
      this.userTypeId = 0;
    }*/
    this.getAllEvents();
    this.calMonth.emit(this.viewDate);
  }

  events: CalendarEvent<{ incrementsBadgeTotal: boolean }>[] = [];

  /*dayClickedThumb({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  
    //  if (isSameMonth(date, this.viewDate)) {
    //this.view = this.CalendarView.Day;
   
    this.viewDate = date;
    this.calMonth.emit(this.viewDate);
    this.data.calDashboardDate = this.viewDate;
    this.router.navigate(['/globalcalendar']);
    //}
  }*/

  dayClickedThumb({
    date,
    events,
    view,
  }: {
    date: Date;
    events: CalendarEvent[];
    view: CalendarView.Day;
  }): void {
    this.viewDate = date;
    this.calMonth.emit(this.viewDate);
    this.data.calDashboardDate = this.viewDate;
    localStorage.setItem("cal_view", "day");
    //this.view= CalendarView.Day;
    this.router.navigate(["/globalcalendar"]);
  }

  getAllEvents() {
    this.data.getAllEvent(this.data.calenderParam).subscribe((data) => {
      this.listEvent = data;
      this.events = this.listEvent.map((iEvent) => {
        if (iEvent.start_date != "" && iEvent.calendar_type == "event") {
          if (
            iEvent.start_time != "00:00:00" &&
            iEvent.end_time != "00:00:00" &&
            iEvent.start_time != null &&
            iEvent.end_time != null
          ) {
            var startHour = iEvent.start_time.slice(0, 2);
            var startMin = iEvent.start_time.slice(3, 5);
            var endHour = iEvent.end_time.slice(0, 2);
            var endMin = iEvent.end_time.slice(3, 5);
            return {
              data: iEvent,
              start: new Date(iEvent.start_date),
              end: new Date(iEvent.end_date),
              title: iEvent.event_title,
              cssClass: iEvent.user_type_id == 3 ? "bnEvent" : "ipEvent",
              event_color: iEvent.user_type_id == 3 ? "red" : "yellow",
            };
          } else {
            return {
              data: iEvent,
              start: new Date(iEvent.start_date),
              end: new Date(iEvent.end_date),
              title: iEvent.event_title,
              allDay: true,
              cssClass: iEvent.user_type_id == 3 ? "bnEvent" : "ipEvent",
              event_color: iEvent.user_type_id == 3 ? "red" : "yellow",
            };
          }
        } else if (iEvent.calendar_type == "task") {
          return {
            data: iEvent,
            start: new Date(iEvent.due_date),
            end: new Date(iEvent.due_date),
            title: iEvent.task_name,
            cssClass: iEvent.user_type_id == 3 ? "bnTask" : "ipTask",
            event_color: iEvent.user_type_id == 3 ? "red" : "yellow",
            allDay: true,
          };
        } else {
          return {
            data: iEvent,
            start: new Date(iEvent.start_date),
            end: new Date(iEvent.end_date),
            title: iEvent.event_title,
            allDay: true,
            cssClass: "eholiday",
            event_color: "grey",
            meta: {
              type: "holiday",
              iEvent,
            },
          };
        }
      });

      this.cdr.markForCheck();
    });
  }

  setMonth() {
    this.viewDate;
    var monthyear = new DatePipe("en-US").transform(this.viewDate, "yyyy-MM");
    this.data.calenderParam.event_date = monthyear;
    this.getAllEvents();
  }
}
