import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-matter-workflow',
  templateUrl: './matter-workflow.component.html',
  styleUrls: ['./matter-workflow.component.scss']
})
export class MatterWorkflowComponent implements OnInit {
  mId: any;
  wft : any;
  matterDetails: any;
  companyDissolution : boolean = false
  companyIncorporation : boolean = false
  assignmentofQuota : boolean = false
  overseasCompanyRegistration : boolean = false
  noDataAvailable: boolean = false;
  matter_type_id: any;
  constructor(private data: DataService) { }

	ngOnInit() {
		document.querySelector('body').classList.add('sidebar-toggle');
		let url = window.location.href;		
		this.mId = url.substr(url.lastIndexOf('/') + 1);
		//this.wft = "Company Dissolution"
		//this.companyDissolution = true

		this.data.getMatterDetByMatterId( this.mId ).subscribe(response => {
			if (response) {
				this.matterDetails = response;
				
				if(this.matterDetails.matter_type_id == '8' || this.matterDetails.matter_type_id == '10'){
					this.assignmentofQuota = true
					this.companyIncorporation = false
					this.companyDissolution = false
					this.overseasCompanyRegistration = false
					this.noDataAvailable = false
				}else if(this.matterDetails.matter_type_id == '9'){
					this.companyDissolution = true
					this.companyIncorporation = false
					this.assignmentofQuota = false
					this.overseasCompanyRegistration = false
					this.noDataAvailable = false
				}else if(this.matterDetails.matter_type_id == '6' || this.matterDetails.matter_type_id == '11'){
					this.companyIncorporation = true
					this.companyDissolution = false
					this.assignmentofQuota = false
					this.overseasCompanyRegistration = false
					this.noDataAvailable = false
				}else if(this.matterDetails.matter_type_id == '7'){
					this.overseasCompanyRegistration = true
					this.companyIncorporation = false
					this.companyDissolution = false
					this.assignmentofQuota = false
					this.noDataAvailable = false
				}else{
					this.noDataAvailable = true
					this.overseasCompanyRegistration = false
					this.companyIncorporation = false
					this.companyDissolution = false
					this.assignmentofQuota = false
				}
				this.wft = this.matterDetails.matter_type_name;
				this.matter_type_id = this.matterDetails.matter_type_id;
			}
		});
	}

  // ngOnDestroy() {
  //   document.querySelector('body').classList.remove('sidebar-toggle');
  // }

	/* onSelect(val){
		this.wft = val
		if(val == "Company Dissolution"){
			this.companyDissolution = true
			this.companyIncorporation = false
			this.assignmentofQuota = false
			this.overseasCompanyRegistration = false
		} else if(val == "Company Incorporation"){
			this.companyIncorporation = true
			this.companyDissolution = false
			this.assignmentofQuota = false
			this.overseasCompanyRegistration = false
		}else if(val == "Assignment of quota"){
			this.assignmentofQuota = true
			this.companyIncorporation = false
			this.companyDissolution = false
			this.overseasCompanyRegistration = false
		}else if(val == "Overseas Company Registration"){
			this.overseasCompanyRegistration = true
			this.companyIncorporation = false
			this.companyDissolution = false
			this.assignmentofQuota = false
		}
	} */

}
