import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
declare var jQuery: any;
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { DataService } from "../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogServiceService } from "../confirmation-dialog/confirmation-dialog-service.service";

declare var jQuery: any;

@Component({
  selector: "app-knowledge-base",
  templateUrl: "./knowledge-base.component.html",
  styleUrls: ["./knowledge-base.component.scss"],
})
export class KnowledgeBaseComponent implements OnInit {
  @ViewChild("uploadFile", { static: false })
  fileUploader: ElementRef;
  fileName: any;
  p: number = 0;
  itemsPerPage: number = 10;
  pn: number = 0;
  tagitemsPerPage: number = 20;
  articleParam: any = {};
  subCatParam: any = {};
  tagParam: any = {};
  courtParam: any = {};
  selectedFiles: any;
  url: any;
  articleForm: FormGroup;
  articleCategories: any = [];
  pointsOflaw: any = [];
  points_of_law = (term) => ({ point_id: term, point_of_law_name: term });
  addSubPointsOfLaw = (term) => ({
    subpoint_id: term,
    subpoint_of_law_name: term,
  });
  selectedSubpoints: any;
  articles: any = [];
  articleDetails: any = [];
  innerToggle: string;
  @Input() articleId: number = 0;
  err_title: string;
  err_category: string;
  err_file: string;
  succ_msg: any;
  articleFilePath: any;
  FormHeading: string;
  selectedPoints: any;
  displayFile: boolean = false;
  popularArticles: any = [];
  favouriteArticles: any = [];
  TypeOfCourt: any = [];

  Months: any = [];
  comments: string;
  count: number;
  err_translate: String = "";
  translateSrcLang: any = "";
  translateDestiLang: any = "";
  translatedDescription: boolean = false;

  searchBox: boolean = false;
  latestArticles: any = [];
  currentYear: any;
  currentMonth: any;
  userId: any;
  searchboxVal: any;
  resources: any = [];
  all_resources: any = [];
  courtRuleDetails: any = [];
  totalTag: any;
  pointsoflaw: any;
  idwisearray: any;
  tempPointlaw: Array<any> = [];
  subPointList: any = [];
  display_flag: boolean = false;
  ipdisplay_flag: boolean;
  nonipdisplay_flag: boolean;
  subPointArr: any = [];
  subPointlaw: Object;
  tempSubPointlaw: any;
  crpn: number = 0;
  courtRullingitemsPerPage: number = 20;
  totalCourtRuling: any;
  totalArticle: any;
  editorApiKey: any;
  articleSubCategories: any = [];
  files: string[] = [];

  subs: any = [];
  state$: Observable<any>;
  msgg: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogServiceService

  ) {}

  ngOnInit() {
    jQuery(function () {
      jQuery(document).on("click", ".dropdown-menu", function (e) {
        e.stopPropagation(); // This replace if conditional.
      });
      jQuery(document).on("click", ".category-list li a", function (e) {
        jQuery(this).next().toggle();
      });

      jQuery(document).on("click", ".toggleInner", function (e) {
        jQuery(this).toggleClass("collapsed");
        jQuery(this).next().toggle();
      });
    });
    if (localStorage.getItem("headerSearchstring") != undefined) {
      this.articleParam.headerSearchstring =
        localStorage.getItem("headerSearchstring");
    }
    this.currentYear = new Date().getFullYear();
    var date = new Date();
    this.currentMonth = date.getMonth() + 1;
    this.currentMonth =
      this.currentMonth < 10 ? "0" + this.currentMonth : this.currentMonth;
    jQuery("#month").val(this.currentYear + "-" + this.currentMonth);
    this.count = 0;
    this.articleParam.month = this.currentMonth;
    this.articleFormInit();
    this.getArticleCategories();
    // this.getArticlePointsOfLaw();
    this.getArticleList();
    this.getcourtRullingShortList();
    this.getAllResources();
    //this.getTagCount();
    //this.getWebPointsOfLaw();
    this.getTotalCourtRulling();
    this.editorApiKey = this.data.getEditorApiKey();
    //this.getTotalArticle();

    if (this.articleId > 0) {
      this.FormHeading = "Edit Article";
    } else {
      this.FormHeading = "Post Article";
    }

    this.articleFilePath =
      `${environment.lpmsUrl}` + "documents/comp_cert/articleFiles/";
    this.getPopularArticles();
    this.getFavoriteAricles();
    this.getLatestArticles();
    this.Months = [
      { name: "Jan", value: "01" },
      { name: "Feb", value: "02" },
      { name: "Mar", value: "03" },
      { name: "Apr", value: "04" },
      { name: "May", value: "05" },
      { name: "Jun", value: "06" },
      { name: "Jul", value: "07" },
      { name: "Aug", value: "08" },
      { name: "Sep", value: "09" },
      { name: "Oct", value: "10" },
      { name: "Nov", value: "11" },
      { name: "Dec", value: "12" },
    ];
    this.userId = localStorage.getItem("UserId");
    this.typeOfCourt();
    //this.getSubPointsOfLaw();

    this.articleParam.month = "";
    this.articleParam.searchString = "";
    this.articleParam.headerSearchstring = "";

    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));

    this.state$.subscribe((res) => (this.msgg = res));

    if (this.msgg.editArtcile === "popup") {
      this.addEditArticle(this.msgg.articleId);
    }

    // To enable the link/unlink pop-up in TinyMCE
    jQuery(document).on('focusin', function (e) {
      if (jQuery(e.target).closest(".tox-dialog").length) {
          e.stopImmediatePropagation();
      }
    });
  }

  onUploadFile(event) {
    // this.selectedFiles = event.target.files;
    // this.fileName = document.getElementById('scan_file')
    // this.fileName.innerHTML = this.selectedFiles[0].name;
    // if (event.target.files) {
    //   var reader = new FileReader();
    //   //reader.readAsDataURL(event.target.files); // read file as data url
    //   reader.onload = (event) => { // called once readAsDataURL is completed
    //     this.url = reader.result;

    //   }
    // }
    for (var i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
    }
  }

  articleFormInit() {
    this.articleForm = this.fb.group({
      article_id: new FormControl(""),
      title: new FormControl(""),
      points_of_law: new FormControl(""),
      sub_points_of_law: new FormControl(""),
      plaw_type: new FormControl(""),
      category: new FormControl(""),
      sub_category: new FormControl(""),
      file: new FormControl(""),
      description: new FormControl(""),
      translated_description: new FormControl(""),
      model_id: new FormControl(""),
    });
  }

  getArticleCategories() {
    if (this.subs["cats"]) {
      this.subs["cats"].unsubscribe();
    }
    this.subs["cats"] = this.data
      .getArticleCategories(this.articleParam)
      .subscribe((data) => {
        this.articleCategories = data;
      });
  }

  getArticleSubCategories(parentId) {
    if (this.subs["subCats"]) {
      this.subs["subCats"].unsubscribe();
    }
    this.subCatParam.parent_id = parentId;
    this.subs["subCats"] = this.data
      .getArticleCategories(this.subCatParam)
      .subscribe((data) => {
        this.articleSubCategories = data;
      });
  }

  saveArticle(post) {
    let formdata = new FormData();
    // if (this.selectedFiles == undefined) {

    // } else {
    //   formdata.append('file', this.selectedFiles[0]);

    // }
    if (this.files.length > 0) {
      for (var i = 0; i < this.files.length; i++) {
        formdata.append("file[]", this.files[i]);
      }
    }

    if (post.article_id > 0) {
      formdata.append("article_id", post.article_id);
    }
    // if(this.ipdisplay_flag == true){
    //   formdata.append('type', 'IP');
    // }else{
    //   formdata.append('type', 'NON-IP');
    // }
    formdata.append("type", post.plaw_type);
    formdata.append("category", post.category);
    formdata.append("subcategory", post.sub_category);
    formdata.append("title", post.title);
    formdata.append("points_of_law", post.points_of_law);
    formdata.append("sub_points_of_law", post.sub_points_of_law);

    formdata.append("description", post.description);
    formdata.append("translated_description", post.translated_description);
    formdata.append("model_id", post.model_id);
    this.data.postArticle(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.getArticleList();
          this.getArticleCategories();
          setTimeout(() => {
            this.succ_msg = false;
            this.close();
            if (this.msgg.editArtcile === "popup" && this.msgg.articleId > 0 ) {
              this.router.navigateByUrl("/knowledge-base/article/" + this.msgg.articleId);
            }
          }, 2000);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.title) {
          this.err_title = error.error.title;
        } else {
          this.err_title = "";
        }

        if (error.error.category) {
          this.err_category = error.error.category;
        } else {
          this.err_category = "";
        }

        if (error.error.file) {
          this.err_file = error.error.file;
        } else {
          this.err_file = "";
        }
      }
    );
  }

  getArticlePointsOfLaw() {
    if (this.subs["pointLaw"]) {
      this.subs["pointLaw"].unsubscribe();
    }
    this.subs["pointLaw"] = this.data
      .getArticlePointsOfLaw(this.tagParam)
      .subscribe((data) => {
        this.pointsOflaw = data;
      });
  }

  /*getWebPointsOfLaw() {
    this.data.getArticlePointsOfLaw(this.tagParam).subscribe(data => {
      this.pointsoflaw = data;
      this.pointsoflaw.forEach((currentValue, index) => {
        this.getSubPointsOfLaw(currentValue.point_id);
      });
    });
  }*/

  /*getSubPointsOfLaw(point_id){
    this.data.getkbSubPointsOfLaw(point_id).subscribe(
      data => {
        this.subPointList[point_id]=data;
        this.idwisearray=point_id;
      }
    );
  }*/

  getWebPointsOfLaw() {
    if (this.subs["wpointLaw"]) {
      this.subs["wpointLaw"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.subs["wpointLaw"] = this.data
        .getArticlePointsOfLaw(this.tagParam)
        .subscribe((data) => {
          this.pointsoflaw = data;
          this.pointsoflaw.forEach((currentValue, index) => {
            this.getSubPointsOfLaw(currentValue.point_id);
          });
        });
      resolve(null);
    });
  }

  getSubPointsOfLaw(point_id) {
    if (this.subs["spointLaw"]) {
      //this.subs["spointLaw"].unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this.data.getkbSubPointsOfLaw(point_id).subscribe((data) => {
        this.subPointList[point_id] = data;
        if (this.subPointList[point_id].length > 0) {
          this.idwisearray = this.subPointList[point_id].length;
        } else {
          this.idwisearray = 0;
        }
      });
      resolve(null);
    });
  }

  blankErrMsg() {
    this.err_category = this.err_title = this.err_file = "";
  }

  close() {
    this.blankErrMsg();
    this.articleFormInit();
    this.articleId = 0;
    jQuery("#postArticle").modal("hide");
    this.displayFile = false;
    this.selectedFiles = "";
    this.translatedDescription = false;
    //this.articleForm.reset();
    this.url = "";
    if (this.fileName != undefined) {
      this.fileName.innerHTML = "Upload File";
    }
    this.files = [];
  }

  getArticleList() {
    if (this.subs["aList"]) {
      this.subs["aList"].unsubscribe();
    }

    if (
      jQuery("#category_id").val() != "" &&
      Number(jQuery("#category_id").val()) > 0 &&
      jQuery("#category_id").val() != this.articleParam.category_id
    ) {
      this.getArticleSubCategories(jQuery("#category_id").val());
    }

    this.articleParam.interval = "";
    this.articleParam.limit = "";
    this.articleParam.from = "KB";
    this.articleParam.month =
      jQuery("#month").val() != null
        ? jQuery("#month").val()
        : this.currentYear + "-" + this.currentMonth;
    this.articleParam.category_id = jQuery("#category_id").val();
    this.articleParam.sub_category_id = jQuery("#sub_category_id").val();

    this.subs["aList"] = this.data
      .getArticles(this.articleParam)
      .subscribe((data) => {
        this.articles = data;
        this.totalArticle = this.articles.length;
      });
  }

  searchCat(category_id) {
    if (category_id > 0) {
      jQuery("#category_id").val(category_id);
      jQuery("#month").val("");
      jQuery("#sub_category_id").val("");

      this.getArticleList();
    }
  }

  articleModalOpen(articleId) {
    if (this.subs["aDtl"]) {
      this.subs["aDtl"].unsubscribe();
    }
    if (articleId > 0) {
      this.subs["aDtl"] = this.data
        .getArticleDetails(articleId)
        .subscribe((data) => {
          if (data) {
            this.articleDetails = data;
          } else {
            this.articleDetails = "";
          }
        });
    }

    jQuery("#postArticleDetails").modal("show");
  }

  paginationFeed(page) {
    this.p = page;
    let start = (page - 1) * this.itemsPerPage;
    this.articleParam.pageNo = page - 1;
    this.articleParam.start = start;
    let end = start + this.itemsPerPage;
    this.articleParam.end = end;
    this.getArticleList();
  }

  paginationCourtRulling(p) {
    this.crpn = p;
    let start = (p - 1) * this.courtRullingitemsPerPage;
    let end = start + this.courtRullingitemsPerPage;
    this.courtParam.p = p - 1;
    this.courtParam.start = start;
    this.courtParam.end = end;
    this.getAllResources();
  }

  paginationTag(p) {
    this.pn = p;
    let start = (p - 1) * this.tagitemsPerPage;
    let end = start + this.tagitemsPerPage;
    this.tagParam.p = p - 1;
    this.tagParam.start = start;
    this.tagParam.end = end;
    this.getArticlePointsOfLaw();
  }

  searchArticleList(value) {
    this.articleParam.headerSearchstring = value;
    jQuery("#category_id").val("");
    jQuery("#month").val("");
    jQuery("#sub_category_id").val("");
    this.getArticleList();
    this.getArticleCategories();
    //this.searchBox = !this.searchBox
  }

  search(value, serchKey) {
    this.articleParam.searchString = value;
    this.articleParam.selectedSearchKey = serchKey;

    this.getArticleList();
  }

  deleteArticle(artclId){

    if (!artclId) return false;

    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to delete this article?")
      .then((confirmed) => {
        if (confirmed) {
          console.log(artclId);
          this.data.deleteArticle(artclId).subscribe((data) => {
            this.getArticleList();
          })
        }
      
      })
      .catch(() => {});

  
  }
  addEditArticle(artclId) {
    if (this.subs["aDtl"]) {
      this.subs["aDtl"].unsubscribe();
    }
    if (artclId > 0) {
      this.articleId = artclId;
    }
    if (this.articleId > 0) {
      this.FormHeading = "Edit Article";
      this.subs["aDtl"] = this.data
        .getArticleDetails(this.articleId)
        .subscribe(async (data) => {
          if (data) {
            this.articleDetails = data;
            if (
              this.articleDetails.points_of_law != "" ||
              this.articleDetails.points_of_law != 0
            ) {
              this.display_flag = true;
            } else {
              this.display_flag = false;
            }
            await this.getArticleSubCategories(
              this.articleDetails.article_catagory_id
            );
            await this.getArticlePointsOfLaw();

            var plawcarr = this.articleDetails.points_of_law.split(",");
            var slawcarr = this.articleDetails.sub_points_of_law.split(",");

            const temparry1 = [];
            const temparry2 = [];
            plawcarr.map((item) => {
              this.data.getSubPointsOfLaw(item).subscribe((data) => {
                this.subPointlaw = data;
              });
              Number(item) > 0 ? temparry1.push(Number(item)) : "";
            });
            slawcarr.map((item) => {
              Number(item) > 0 ? temparry2.push(Number(item)) : "";
            });

            this.selectedPoints = temparry1;
            this.selectedSubpoints = temparry2;
            this.articleForm.patchValue({
              article_id: this.articleDetails.id,
              title: this.articleDetails.title,
              points_of_law: this.selectedPoints,
              sub_points_of_law: this.selectedSubpoints,
              plaw_type: this.articleDetails.plaw_type,
              category: this.articleDetails.article_catagory_id,
              description: this.articleDetails.description,
              sub_category: this.articleDetails.article_sub_cat_id,
            });
            if (this.articleDetails.file) {
              for (var i = 0; i < this.articleDetails.file.length; i++) {
                this.files.push(this.articleDetails.file[i]);
              }
            }
            if (this.articleDetails.plaw_type == "IP") {
              this.ipdisplay_flag = true;
              // this.display_flag = true;
            } else if (this.articleDetails.plaw_type == "NON-IP") {
              this.nonipdisplay_flag = true;
              //this.display_flag = true;
            }
          } else {
            this.articleDetails = "";
          }
        });
    } else {
      this.FormHeading = "Post Article";
    }
    jQuery("#postArticle").modal("show");
  }

  receiveComment($event) {
    this.comments = $event;
    this.count = this.comments.length;
  }

  recieveCount($event) {
    this.comments = $event;
    this.count = this.comments.length;
  }

  changeSrc(src) {
    this.err_translate = "";
    if (src != "") {
      this.translateSrcLang = src;
    } else {
      this.translateSrcLang = "en";
    }
  }

  changeDesti(desti) {
    this.err_translate = "";
    if (desti != "") {
      this.translateDestiLang = desti;
    } else {
      this.translateDestiLang = "ch";
    }
  }

  translateContent(postData) {
    if (this.subs["tranArt"]) {
      this.subs["tranArt"].unsubscribe();
    }
    jQuery(".dropdown-toggle").dropdown("hide");
    this.err_translate = "";
    let formdata = new FormData();
    if (postData != "") {
      formdata.append("title", postData.title);
      formdata.append("description", postData.description);
    }

    if (this.translateSrcLang != "") {
      this.translateSrcLang = this.translateSrcLang;
    } else {
      this.translateSrcLang = "en";
    }
    if (this.translateDestiLang != "") {
      this.translateDestiLang = this.translateDestiLang;
    } else {
      this.translateDestiLang = "ch";
    }

    formdata.append("src", this.translateSrcLang);
    formdata.append("desti", this.translateDestiLang);

    this.subs["tranArt"] = this.data
      .translateArticle(formdata)
      .subscribe((data) => {
        if (data["status"] == 1) {
          this.articleForm.patchValue({
            translated_description: data["translated_description"],
            model_id: data["model_id"],
          });
          this.translatedDescription = true;
        } else {
          this.err_translate = data["msg"];
        }
      });
  }

  getPopularArticles() {
    if (this.subs["pArt"]) {
      this.subs["pArt"].unsubscribe();
    }
    this.subs["pArt"] = this.data
      .getPopularArticles({ limit: 10 })
      .subscribe((data) => {
        this.popularArticles = data;
      });
  }

  getFavoriteAricles() {
    if (this.subs["fArt"]) {
      this.subs["fArt"].unsubscribe();
    }
    this.subs["fArt"] = this.data.getFavoriteAricles().subscribe((data) => {
      this.favouriteArticles = data;
    });
  }

  moreArticles() {
    jQuery("#moreArticles").modal("show");
  }

  clickCancel() {
    this.courtParam = [];
    this.tempPointlaw = [];
    this.courtParam.point_id = "";
    this.courtParam.type_of_court = "";
    this.courtParam.searchString = "";
    jQuery("#resources").modal("hide");
    jQuery("#pointoflaw").val("");
    jQuery("#searchBox").val("");
    jQuery("#type_of_court").val("");
    this.getWebPointsOfLaw();
    this.getAllResources();
    this.getTotalCourtRulling();
    this.tempPointlaw = [];
  }

  moreResources() {
    this.courtParam = [];
    this.tempPointlaw = [];
    this.courtParam.point_id = "";
    this.courtParam.type_of_court = "";
    this.courtParam.searchString = "";
    jQuery("#resources").modal("show");
    jQuery("#pointoflaw").val("");
    this.getWebPointsOfLaw();
    this.getAllResources();
    this.getTotalCourtRulling();
  }

  getLatestArticles() {
    if (this.subs["lArt"]) {
      this.subs["lArt"].unsubscribe();
    }
    this.articleParam.interval = 30;
    this.articleParam.limit = 5;
    this.subs["lArt"] = this.data
      .getLatestArticles(this.articleParam)
      .subscribe((data) => {
        this.latestArticles = data;
      });
  }

  morePopularArticles() {
    jQuery("#popularArticles").modal("show");
  }

  moreFavArticles() {
    jQuery("#favArticles").modal("show");
  }

  publishArticle(article_id) {
    if (this.subs["pArt"]) {
      this.subs["pArt"].unsubscribe();
    }
    let formdata = new FormData();
    formdata.append("article_id", article_id);
    this.subs["pArt"] = this.data.publishArticle(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.getArticleList();
          this.getArticleCategories();
          setTimeout(() => {
            this.succ_msg = false;
            this.close();
          }, 2000);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.title) {
          this.err_title = error.error.title;
        } else {
          this.err_title = "";
        }

        if (error.error.category) {
          this.err_category = error.error.category;
        } else {
          this.err_category = "";
        }

        if (error.error.file) {
          this.err_file = error.error.file;
        } else {
          this.err_file = "";
        }
      }
    );
  }

  getAllResources() {
    if (this.subs["res"]) {
      this.subs["res"].unsubscribe();
    }
    this.subs["res"] = this.data
      .getResources(this.courtParam)
      .subscribe((data) => {
        this.all_resources = data;
      });
  }

  /*filterByCourt(value){
    this.courtParam.type_of_court = value;
    //this.getAllResources();
  }*/

  viewCourtRule(id) {
    if (this.subs["cRule"]) {
      this.subs["recRules"].unsubscribe();
    }
    jQuery("#courtrule").modal("show");
    this.articleParam.id = id;
    this.subs["cRule"] = this.data
      .getCourtRuleDetails(this.articleParam)
      .subscribe((data) => {
        this.courtRuleDetails = data;
      });
  }

  getTagCount() {
    if (this.subs["tag"]) {
      this.subs["tag"].unsubscribe();
    }
    this.subs["tag"] = this.data.getTotalTags().subscribe((data) => {
      this.totalTag = data;
    });
  }

  onChangePointlaw(point: any, isChecked: boolean) {
    if (this.subs["res"]) {
      this.subs["res"].unsubscribe();
    }
    if (isChecked) {
      this.tempPointlaw.push(point);
      //this.articleParam.point_id =  this.tempPointlaw;
      let params = new HttpParams();
      this.courtParam = params.append("point_id", this.tempPointlaw.join(","));

      this.subs["res"] = this.data
        .getResources(this.courtParam)
        .subscribe((data) => {
          this.all_resources = data;
          this.getTotalCourtRulling();
        });
    } else {
      let index = this.tempPointlaw.indexOf(point);
      this.tempPointlaw.splice(index, 1);
      this.getAllResources();
      this.getTotalCourtRulling();
    }
  }
  onChangeSubPointlaw(subpoint: any, isChecked: boolean) {
    /*  if(isChecked) {
      this.tempSubPointlaw.push(subpoint);
      //this.articleParam.point_id =  this.tempPointlaw;
      let params = new HttpParams();
      params = params.append('sub_point_id', this.tempSubPointlaw.join(', '));
      this.data.getResources(params).subscribe(data => {
        this.all_resources = data;
      });
    }else{
      let index = this.tempSubPointlaw.indexOf(subpoint);
      this.tempSubPointlaw.splice(index,1);
      this.getAllResources();
    } */
  }

  filterPointsOflaw(value) {
    if (this.subs["pLaw"]) {
      this.subs["pLaw"].unsubscribe();
    }
    if (value != "") {
      this.tagParam.point_string = value;
      this.subs["pLaw"] = this.data
        .getArticlePointsOfLaw(this.tagParam)
        .subscribe((data) => {
          this.pointsoflaw = data;
          this.getWebPointsOfLaw();
        });
    }
  }

  searchcourtdata() {
    var searchBox = jQuery("#searchBox").val();
    this.courtParam.searchString = searchBox;
    var type_of_court = jQuery("#type_of_court").val();
    this.courtParam.type_of_court = type_of_court;
    //this.courtParam.searchString = value.replace(/[^a-zA-Z ]/g, '');
    //this.totalCourtRuling = 20;
    this.getAllResources();
    this.getTotalCourtRulling();
  }

  sort(val: any) {
    for (var i in val) {
      this.courtParam.sortBy = i;
      if (this.courtParam.sortOrder == "desc") {
        this.courtParam.sortOrder = "asc";
      } else if (this.courtParam.sortOrder == "asc") {
        this.courtParam.sortOrder = "desc";
      } else {
        this.courtParam.sortOrder = "asc";
      }
    }
    this.getAllResources();
  }

  typeOfCourt() {
    if (this.subs["tCourt"]) {
      this.subs["tCourt"].unsubscribe();
    }
    this.subs["tCourt"] = this.data.typeOfCourt(0).subscribe((response) => {
      if (response) {
        this.TypeOfCourt = response;
      }
    });
  }

  onChangePol(type_flag) {
    if (type_flag != "") {
      this.display_flag = true;
    } else {
      this.display_flag = false;
    }

    if (type_flag == "IP") {
      this.ipdisplay_flag = true;
      this.nonipdisplay_flag = false;
      this.tagParam.type = "IP";
    } else {
      this.ipdisplay_flag = false;
      this.nonipdisplay_flag = true;
      this.tagParam.type = "NON-IP";
    }
    //this.getArticlePointsOfLaw();
  }

  onChangePointOfLaw(event) {
    if (this.subs["spLaw"]) {
      this.subs["spLaw"].unsubscribe();
    }
    this.subPointArr.push(event.point_id);
    this.subs["spLaw"] = this.data
      .getSubPointsOfLaw(event.point_id)
      .subscribe((data) => {
        this.subPointlaw = data;
      });
  }

  searchPointsoflaw(term: string) {
    if (term.length > 1) {
      this.tagParam.point_string = term;
      this.getArticlePointsOfLaw();
    }
  }

  getcourtRullingShortList() {
    if (this.subs["rList"]) {
      this.subs["rList"].unsubscribe();
    }
    this.subs["rList"] = this.data
      .getcourtRullingShortList()
      .subscribe((data) => {
        this.resources = data;
      });
  }

  getTotalCourtRulling() {
    if (this.subs["tcRule"]) {
      this.subs["tcRule"].unsubscribe();
    }
    this.subs["tcRule"] = this.data
      .getTotalCourtRulling(this.courtParam)
      .subscribe((data) => {
        this.totalCourtRuling = data;
      });
  }

  removeSelectedFile(index, file_id) {
    if (this.subs["rFile"]) {
      this.subs["rFile"].unsubscribe();
    }
    // Delete the item from fileNames list
    this.files.splice(index, 1);
    if (file_id > 0) {
      this.subs["rFile"] = this.data
        .deleteArticleFile(file_id)
        .subscribe((data) => {});
    }
  }
}
