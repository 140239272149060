import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { DataService } from "../../services/data.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { AuthenticationService } from "../../services/auth.service";

declare var jQuery: any;

import { EmailsComponent } from "../emails.component";

@Component({
  selector: "app-composemail",
  templateUrl: "./composemail.component.html",
  styleUrls: ["./composemail.component.scss"],
})
export class ComposemailComponent implements OnInit {
  //@Input() EmailsComponent?: EmailsComponent;
  composeForm: FormGroup;
  err_email_to: String;
  err_email_cc: String = "";
  err_email_bcc: String = "";
  ifCc: boolean = false;
  loginuserdtl: any = [];
  attachfiles: any = [];
  hid_m_id: any = "";
  htmlContent = "";
  checkFlag: number = 0;
  templateId: any;
  testmaildtl: any;
  attachmentList: any = "";
  err_translate: String = "";
  templateData: any;
  ifBcc: boolean = false;
  templateList: any = [];
  translatedContent: any = "";
  succ_msg: any;
  selectedFiles: any;
  files: string[] = [];
  names: string[] = [];
  err_template_name: String;
  err_visible_for: String;
  getCheckTxt: any;

  editorApiKey: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "200px",
    maxHeight: "200px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "adobe-caslon-pro",
    defaultFontSize: "",
    fonts: [
      { class: "raleway", name: "Raleway" },
      { class: "calibri", name: "Calibri" },
      { class: "caslon", name: "adobe-caslon-pro" },
      { class: "arial", name: "arial-unicode-ms" },
    ],
    customClasses: [
      {
        name: "Small Text",
        class: "smallText",
        tag: "p",
      },
      {
        name: "Normal Text",
        class: "normalText",
        tag: "p",
      },
      {
        name: "Large Text",
        class: "largeText",
        tag: "p",
      },
      {
        name: "Huge Text",
        class: "hugeText",
        tag: "h2",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["fontSize", "heading"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  //Subscription
  subs: any = [];

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private fb: FormBuilder,
    private data: DataService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getCurrentUserDtl();
    this.composeFormInit();
    this.getAllTemplate();

    this.editorApiKey = this.data.getEditorApiKey();

    let url = window.location.href;
    this.templateId = url.substr(url.lastIndexOf("/") + 1);

    if (this.templateId != "" && !isNaN(this.templateId)) {
      //this.ifCompose = !this.ifCompose;
      this.testmaildtl = this.onChangeTemplate(this.templateId);
    }

    this.subs["tasks"] = this.activatedRoute.queryParams.subscribe((params) => {
      this.templateId = params["task"];
      if (this.templateId != "" && !isNaN(this.templateId)) {
        //this.ifCompose = !this.ifCompose;
        this.testmaildtl = this.processTemplate(this.templateId);
      }
    });
  }

  togglecc() {
    this.ifCc = !this.ifCc;
  }

  togglebcc() {
    this.ifBcc = !this.ifBcc;
  }

  getAllTemplate() {
    if (this.subs["template"]) {
      this.subs["template"].unsubscribe();
    }

    this.subs["template"] = this.data.getAllTemplate().subscribe((response) => {
      if (response) {
        this.templateList = response;
      }
    });
  }

  composeFormInit() {
    this.composeForm = this.fb.group({
      email_from: new FormControl(""),
      email_to: new FormControl(""),
      email_cc: new FormControl(""),
      email_bcc: new FormControl(""),
      email_subject: new FormControl(""),
      email_body: new FormControl(""),
      hid_mail_id: new FormControl(""),
      email_trans_body: new FormControl(""),
      email_trans_subject: new FormControl(""),
      tracking_flag: new FormControl(""),
      template_name: new FormControl(""),
      visible_for: new FormControl(""),
    });
  }

  composeEmail() {
    this.data.userTypeId = localStorage.getItem("UserType");

    this.err_email_to = "";
    this.err_email_cc = this.err_email_bcc = "";

    if (this.data.userTypeId == 3) {
      this.ifCc = !this.ifCc;
      this.composeForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.opsCCEmail,
      });
    } else if (this.data.userTypeId == 4) {
      this.ifCc = !this.ifCc;
      this.composeForm.patchValue({
        email_cc:
          (this.loginuserdtl.email != "" ? this.loginuserdtl.email + "," : "") +
          environment.ipCCEmail,
      });
    } else {
      this.ifCc = !this.ifCc;
      this.composeForm.patchValue({
        email_cc: "bnunes@bnlawmacau.com",
      });
    }

    this.attachfiles = [];
    this.hid_m_id = "";

    //this.checkFlag = 0;
  }

  getCurrentUserDtl() {
    return new Promise((resolve, reject) => {
      this.auth.getUser().subscribe((response) => {
        if (response) {
          this.loginuserdtl = response;
          console.log(this.loginuserdtl);
          this.composeEmail();

          if (this.templateData) {
            this.composeForm.patchValue({
              email_body:
                "<br />" +
                this.htmlDecode(this.loginuserdtl.email_signature) +
                "<br />----<br/>" +
                this.templateData.mail_body,
            });
          } else {
            this.composeForm.patchValue({
              email_body:
                "<br />" + this.htmlDecode(this.loginuserdtl.email_signature),
            });
          }
        }
      });
      resolve(null);
    });
  }

  htmlDecode(str) {
    let div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText;
  }

  cancelComposeEmail() {
    this.templateData = [];
    this.err_email_to = "";
    this.err_email_cc = this.err_email_bcc = "";
    this.router.navigate(["/emails"]);
  }

  saveDraft(draftData, draft_flag) {
    this.err_translate = "";
    this.addUserEmails(draftData, draft_flag);
  }

  saveTemplate(templateData, template_flag) {
    this.err_translate = "";
    this.addUserEmails(templateData, template_flag);
  }

  addUserEmails(postData, send_flag) {
    if (this.subs["emailUser"]) {
      this.subs["emailUser"].unsubscribe();
    }

    this.err_translate = "";
    this.err_email_to = "";
    this.err_email_cc = this.err_email_bcc = "";
    let formdata = new FormData();

    formdata.append("email_to", postData.email_to);
    if (
      this.ifCc == true &&
      postData.email_cc != null &&
      postData.email_cc != ""
    ) {
      formdata.append("cc", postData.email_cc);
    }
    if (
      this.ifBcc == true &&
      postData.email_bcc != null &&
      postData.email_bcc != ""
    ) {
      formdata.append("bcc", postData.email_bcc);
    }
    formdata.append("email_subject", postData.email_subject);
    formdata.append("email_body", postData.email_body);
    formdata.append("from", "lpms_email");
    formdata.append("send_flag", send_flag);
    if (this.selectedFiles == undefined) {
    } else {
      for (var i = 0; i < this.files.length; i++) {
        formdata.append("email_attach_file[]", this.files[i]);
      }
    }
    if (this.attachmentList != "") {
      formdata.append("dbattachfiles[]", this.attachfiles);
    }

    if (postData.hid_mail_id != "") {
      formdata.append("hid_mail_id", postData.hid_mail_id);
    }

    if (postData.tracking_flag == true) {
      formdata.append("tracking_flag", "1");
    } else {
      formdata.append("tracking_flag", "0");
    }

    if (send_flag == "template") {
      formdata.append("template_name", postData.template_name);
      formdata.append("visible_for", postData.visible_for);
    }

    /* if (this.ifCompose == false) {
      formdata.append("temporary_flag_email", "1");
    } else {
      formdata.append("temporary_flag_email", "0");
    }*/

    if (postData.email_trans_body != "") {
      formdata.append("email_trans_body", postData.email_trans_body);
    }

    if (postData.email_trans_subject != "") {
      formdata.append("email_trans_subject", postData.email_trans_subject);
    }

    this.subs["emailUser"] = this.data.addUserEmails(formdata).subscribe(
      (response) => {
        if (response) {
          jQuery(".template-modal").modal("hide");
          if (send_flag == "draft") {
            this.succ_msg = "";
          } else {
            this.succ_msg = response["msg"];
            //this.ifCompose = !this.ifCompose;
            this.err_email_to = "";
            this.err_email_cc = this.err_email_bcc = "";
            //this.ifDisable = true;
            this.templateData = [];
            setTimeout(() => {
              this.succ_msg = false;
            }, 3000);
            this.router.navigate(["/emails"]);
          }
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (send_flag == "sent") {
          if (error.error.email_to != "") {
            this.err_email_to = error.error.email_to;
          } else {
            this.err_email_to = "";
          }

          if (error.error.cc != "") {
            this.err_email_cc = error.error.cc;
          } else {
            this.err_email_cc = "";
          }

          if (error.error.bcc != "") {
            this.err_email_bcc = error.error.bcc;
          } else {
            this.err_email_bcc = "";
          }
        }

        if (send_flag == "template") {
          if (error.error.template_name != "") {
            this.err_template_name = error.error.template_name;
          } else {
            this.err_template_name = "";
          }

          if (error.error.visible_for != "") {
            this.err_visible_for = error.error.visible_for;
          } else {
            this.err_visible_for = "";
          }
        }
      }
    );
  }

  processTemplate(template_id) {
    this.err_translate = "";
    this.getCurrentUserDtl();
    if (this.subs["templateDtl"]) {
      this.subs["templateDtl"].unsubscribe();
    }
    if (template_id != "") {
      this.subs["templateDtl"] = this.data
        .getTemplateDtl(template_id)
        .subscribe((response) => {
          if (response) {
            this.templateData = response;
            if (this.templateData.cc != null) {
              this.ifCc = true;
            }
            if (this.templateData.bcc != null) {
              this.ifBcc = true;
            }

            this.composeForm.patchValue({
              email_cc: this.templateData.cc,
              email_bcc: this.templateData.bcc,
              email_subject: this.templateData.subject,
              email_body:
                "<br />" +
                this.htmlDecode(this.loginuserdtl.email_signature) +
                "<br />----<br/>" +
                this.templateData.mail_body,
            });
          }
        });
    } else {
      this.composeFormInit();
      this.ifCc = false;
      this.ifBcc = false;
    }
  }

  onChangeTemplate(template_id) {
    this.attachfiles = [];
    this.err_translate = "";
    if (template_id != "") {
      this.data.editTemplateById(template_id).subscribe((response) => {
        if (response) {
          this.templateData = response;

          if (this.templateData.bcc_list != null) {
            this.ifBcc = true;
          }

          this.data.userTypeId = localStorage.getItem("UserType");

          if (this.data.userTypeId == 3) {
            this.ifCc = true;
            this.composeForm.patchValue({
              email_cc:
                (this.loginuserdtl.email != ""
                  ? this.loginuserdtl.email + ","
                  : "") + environment.opsCCEmail,
            });
          } else if (this.data.userTypeId == 4) {
            this.ifCc = true;
            this.composeForm.patchValue({
              email_cc:
                (this.loginuserdtl.email != ""
                  ? this.loginuserdtl.email + ","
                  : "") + environment.ipCCEmail,
            });
          } else {
            this.ifCc = true;
            this.composeForm.patchValue({
              email_cc: "bnunes@bnlawmacau.com",
            });
          }

          this.composeForm.patchValue({
            email_to: this.templateData.mail_to_email,
            //email_cc: this.templateData.cc_list,
            email_bcc: this.templateData.bcc_list,
            email_subject: this.templateData.subject,
            email_body: this.templateData.body,
          });
          this.attachmentList = this.templateData.attachment_name_list;
          if (this.attachmentList != null) {
            var flag = this.attachmentList.includes(",");
            if (flag == true) {
              this.attachfiles = this.attachmentList.split(",");
            } else {
              this.attachfiles.push(this.attachmentList);
            }
          }
        }
      });
    } else {
      this.composeFormInit();
      this.ifCc = false;
      this.ifBcc = false;
    }
  }

  onUploadFile(event) {
    this.selectedFiles = event.target.files;
    for (var i = 0; i < event.target.files.length; i++) {
      this.names.push(event.target.files[i].name);
      this.files.push(event.target.files[i]);
    }
  }

  removeDbAttachment(index) {
    this.attachfiles.splice(index, 1);
  }

  removeAttachment(index) {
    this.names.splice(index, 1);
    this.files.splice(index, 1);
  }

  getCheckboxVal() {
    if (this.composeForm.controls.tracking_flag.value == true) {
      this.checkFlag = 1;
      this.getCheckTxt = "Do not Track Email Opening";
    } else {
      this.checkFlag = 0;
      this.getCheckTxt = "Track Email Opening";
    }
  }
}
