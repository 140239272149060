import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { DatacontainerDirective } from "src/app/knowledge-base/comments/comments.component";

import { DashboardCalendarComponent } from "src/app/calendar/dashboard-calendar/dashboard-calendar.component";
import { DataService } from "../../services/data.service";
import { DatePipe } from "@angular/common";
declare var jQuery: any;

@Component({
  selector: "app-bnoperations",
  templateUrl: "./bnoperations.component.html",
  styleUrls: ["./bnoperations.component.scss"],
})
export class BnoperationsComponent implements OnInit {
  @ViewChild("container", { static: true, read: ViewContainerRef })
  entry: ViewContainerRef;
  scheduleData: any = [];
  totalLeads: any;
  totalDocuments: any;
  totalYearlyMattersCount: any;
  totalEmails: any;
  taskList: any = [];
  dashboardParam: any = {};
  articles: any = [];
  totalUnreadEmail: any;
  state$: Observable<object>;
  geniusList: any;
  totalRecentDocuments: any;
  totalRecentLeads: any;
  staff: any;
  totalStaffCount: any;
  totalBnlawStaffCount: any;
  totalBnIpStaffCount: any;
  month: any;
  date: any;
  totalApointment: Object;
  type_of_work: any = [];
  type_of_work2: any = [];
  type_of_work3: any = [];
  Param: any = {};
  typeOfCase: any;
  //for bn operation account
  user_type: number = 0;
  articleParam: any = {};
  articleCategories: any = [];
  taskId: number = 0;
  clientList: any;
  kbSubs: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private data: DataService,
    public router: Router
  ) {}

  ngOnInit() {
    this.data.userTypeId = localStorage.getItem("UserType");
    this.articleParam.intervalArticle = 0;
    this.articleParam.category = 0;

    if (this.data.userTypeId == 3) {
      this.data.calenderType = localStorage.setItem("calendar_type", "bn");
      let urlfilter = this.router.url + "/bnlawyers";
      window.open(urlfilter);
    } else if (this.data.userTypeId == 4) {
      this.data.calenderType = localStorage.setItem("calendar_type", "ip");
      let urlfilter = this.router.url + "/bnip";
      window.open(urlfilter);
    } else {
      this.data.calenderType = localStorage.setItem("calendar_type", "global");
    }

    this.Param.type_of_case = 1;
    this.Param.year = 2020;
    this.typeOfCase = 1;
    const myFactory = this.resolver.resolveComponentFactory(
      DashboardCalendarComponent
    );
    const myRef = this.entry.createComponent(myFactory);
    myRef.instance.calMonth.subscribe((response) => {
      let month = new DatePipe("en-US").transform(response, "MM");
      this.month = month;
      this.date = response;
    });
    this.getSchedule();
    this.getTotalLeads("");
    //this.getTotalDocuments("");
    //this.getTotalEmails("");
    this.getDashBoardTaskList();
    this.getArticleList();
    //this.getTotalUnreadEmail("");
    this.listGenius();
    this.getTotalRecentDocuments("");
    this.getTotalRecentLeads("");
    this.getAllStaffs();
    this.getTotalAppointment("");
    this.getTypeOfWork();
    this.getArticleCategories();

    this.listClientList();

    this.getTotalYearlyMattersCount();
    
    this.state$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      map(() => {
        const currentNav = this.router.getCurrentNavigation();
        return currentNav.extras.state;
      })
    );
  }
  getTotalYearlyMattersCount(){
    this.data.getTotalYearlyMattersCount(this.dashboardParam).subscribe((data) => {
      this.totalYearlyMattersCount = data;
    });
  }
  listClientList() {
    this.data.listClientList().subscribe((data) => {
      this.clientList = data;
      this.clientList = this.clientList.sort(
        (a, b) => b.client_id - a.client_id
      );
      for (var i = 0; i < this.clientList.length; ++i) {
        this.clientList[i].client_name =
          this.clientList[i].client_number +
          " - " +
          this.clientList[i].client_name;
      }
    });
  }

  setFilter(type_of_case) {
    this.Param.type_of_case = type_of_case;
    this.Param.year = this.Param.year;
    this.getTypeOfWork();
  }

  setYear(year) {
    this.Param.type_of_case = this.Param.type_of_case;
    this.Param.year = year;
    this.getTypeOfWork();
  }

  getTypeOfWork() {
    this.data.getTypeOfWork(this.Param).subscribe((data) => {
      this.typeOfCase = this.Param.type_of_case;
      if (this.typeOfCase == 1) {
        this.type_of_work = data;
      } else if (this.typeOfCase == 2) {
        this.type_of_work3 = data;
      } else {
        this.type_of_work2 = data;
      }
    });
  }

  go(name) {
    if (name === "tasks") {
      $("document").ready(function () {
        jQuery("#taskModal").modal("show");
      });
      this.router.navigateByUrl("/tasks", { state: { addCreate: "popup" } });
    }
  }

  getSchedule() {
    this.data.getScheduleData().subscribe((data) => {
      this.scheduleData = data;
    });
  }

  getTotalLeads(staffId) {
    this.dashboardParam.staff = staffId;
    this.data.getTotalLeads(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalLeads = data["totalLead"];
      } else {
        this.totalLeads = 0;
      }
    });
  }

  getTotalRecentLeads(staffId) {
    this.dashboardParam.intervallead = 7;
    this.dashboardParam.staff = staffId;
    this.data.getTotalLeads(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalRecentLeads = data["totalLead"];
      } else {
        this.totalRecentLeads = 0;
      }
    });
  }

  getTotalDocuments(staffId) {
    this.dashboardParam.staff = staffId;
    this.data.getTotalDocuments(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalDocuments = data["total"];
      } else {
        this.totalDocuments = 0;
      }
    });
  }

  getTotalRecentDocuments(staffId) {
    this.dashboardParam.intervalDoc = 7;
    this.dashboardParam.staff = staffId;
    this.data.getTotalDocuments(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalRecentDocuments = data["total"];
      } else {
        this.totalRecentDocuments = 0;
      }
    });
  }

  getTotalEmails(staffId) {
    this.dashboardParam.staff = staffId;
    this.data.getTotalEmails(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalEmails = data["total"];
      } else {
        this.totalEmails = 0;
      }
    });
  }

  getDashBoardTaskList() {
    this.dashboardParam.assignee = localStorage.getItem("UserId");
    this.dashboardParam.user_type_id = localStorage.getItem("UserType");
    this.data.getDashboardTaskList(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.taskList = data;
      }
    });
  }

  getTotalUnreadEmail(staffId) {
    this.dashboardParam.staff = staffId;
    this.data.getTotalUnreadEmail(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalUnreadEmail = data["total"];
      } else {
        this.totalUnreadEmail = 0;
      }
    });
  }

  filterTask(geniusid) {
    this.dashboardParam.geniusid = geniusid;
    this.getDashBoardTaskList();
  }
  /*listGenius() {
    this.data.getTaskAssignee(1).subscribe(
      data => {
        this.geniusList = data; 
        
      }
    );
  }*/

  listGenius() {
    this.data.getTaskAssignee(3).subscribe((data) => {
      this.geniusList = data;
    });
  }
  setDayIntervalArticle(interval) {
    this.articleParam.intervalArticle = interval;
    this.articleParam.category = this.articleParam.category;
    this.getArticleList();
  }

  filterArticle(catId) {
    this.articleParam.category = catId;
    this.articleParam.intervalArticle = this.articleParam.intervalArticle;
    this.getArticleList();
  }

  getArticleList() {
    if (this.kbSubs) {
      this.kbSubs.unsubscribe();
    }
    this.articleParam.user_id = localStorage.getItem("UserId");
    this.articleParam.user_type_id = localStorage.getItem("UserType");
    this.kbSubs = this.data
      .getDashboardKowlegdeList(this.articleParam)
      .subscribe((data) => {
        this.articles = data;
      });
  }

  getAllStaffs() {
    this.data.getAllStaff().subscribe((data) => {
      if (data) {
        this.staff = data;
        this.totalStaffCount = Object.keys(this.staff).length;
        this.totalBnlawStaffCount = this.staff.filter((staff) =>staff.user_type_id == 4).length;
        this.totalBnIpStaffCount = this.staff.filter((staff) =>staff.user_type_id == 3).length;
          console.log(this.staff);
      }
    });
  }

  filterCalendar(staffId) {
    localStorage.setItem(
      "dashboard_month",
      this.month != "null" ? this.month : ""
    );
    localStorage.setItem("staff", staffId != "null" ? staffId : "");
    localStorage.setItem(
      "dashboard_date",
      this.date != "null" ? this.date : ""
    );
    this.router.navigateByUrl("/agenda");
  }

  setAddedByStaff(staffId) {
    this.getTotalLeads(staffId);
    //this.getTotalDocuments(staffId);
   // this.getTotalEmails(staffId);
    //this.getTotalRecentDocuments(staffId);
    //this.getTotalUnreadEmail(staffId);
    //this.getTotalRecentLeads(staffId);
    this.getTotalAppointment(staffId);
  }

  getTotalAppointment(staffId) {
    this.dashboardParam.staff = staffId;
    this.data.getTotalAppointment(this.dashboardParam).subscribe((data) => {
      if (data) {
        this.totalApointment = data["totalAppoint"];
      } else {
        this.totalUnreadEmail = 0;
      }
    });
  }
  getArticleCategories() {
    this.data.getArticleCategories(this.articleParam).subscribe((data) => {
      this.articleCategories = data;
    });
  }

  hideModal() {
    this.taskId = 0;
    jQuery("#taskModal, #taskViewModal").modal("hide");
  }

  taskEdit(id) {
    if (id != 0) {
      this.taskId = id;
    } else {
      this.taskId = 0;
    }
  }
  taskView(id) {
    this.taskId = id;
  }

  getListOfyears() {
    let year = 2021;
    const currentYear = new Date().getFullYear();
    const years = [];

    while (year <= currentYear) {
      years.push(year);
      year++;
    }
    return years;
  }

  
}
