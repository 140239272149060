import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  Validator,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import * as $ from "jquery";
import { ConfirmationDialogServiceService } from "../../../../confirmation-dialog/confirmation-dialog-service.service";

@Component({
  selector: "app-matter-notes",
  templateUrl: "./matter-notes.component.html",
  styleUrls: ["./matter-notes.component.scss"],
})
export class MatterNotesComponent implements OnInit {
  matterNotesForm: FormGroup;
  succ_msg: any = "";
  err_matter_notes: any = "";
  mId: any;
  listDetails: any;
  list: any;
  editorApiKey: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "200px",
    minHeight: "200px",
    maxHeight: "200px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "Open Sans",
    defaultFontSize: "",
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "bottom",
    toolbarHiddenButtons: [
      ["strikeThrough", "superscript", "subscript"],
      ["heading", "fontName"],
      [
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "indent",
        "outdent",
      ],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine"],
      ["unlink", "Insert Image", "video"],
    ],
  };

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit() {
    document.querySelector("body").classList.add("sidebar-toggle");
    this.editorApiKey = this.data.getEditorApiKey();
    this.matterFormInit();
    //this.getAllMatterNotesList();
    let url = window.location.href;
    this.mId = url.substr(url.lastIndexOf("/") + 1);
  }

  // ngOnDestroy() {
  //   document.querySelector('body').classList.remove('sidebar-toggle');
  // }

  matterFormInit() {
    this.matterNotesForm = this.fb.group({
      matter_notes: new FormControl(""),
      done_status: new FormControl(""),
      matter_note_id: new FormControl(""),
    });
  }

  addMatterNotes(postData) {
    let formdata = new FormData();
    formdata.append("matter_notes", postData.matter_notes);
    formdata.append("matter_id", this.mId);
    formdata.append("matter_note_id", postData.matter_note_id);

    this.data.addMatterNotes(formdata).subscribe(
      (response) => {
        if (response) {
          this.succ_msg = response["msg"];
          this.err_matter_notes = "";
          this.router.navigate([
            "/matters/matter-home/matter-workflow/",
            this.mId,
          ]);
          setTimeout(() => {
            this.succ_msg = false;
            this.matterFormInit();
            //this.getAllMatterNotesList();
          }, this.data.timeOut);
        } else {
          this.succ_msg = "";
        }
      },
      (error) => {
        if (error.error.matter_notes != "") {
          this.err_matter_notes = error.error.matter_notes;
        } else {
          this.err_matter_notes = "";
        }
      }
    );
  }

  editMatterNotesById() {
    this.data.editMatterNotesById(this.mId).subscribe((data) => {
      this.listDetails = data;
      this.matterNotesForm.patchValue({
        matter_notes: this.listDetails.matter_notes,
        done_status: this.listDetails.done_status,
        matter_note_id: this.listDetails.matter_notes_id,
      });
    });
  }

  getAllMatterNotesList() {
    this.data.getAllMatterNotesList(this.mId).subscribe((data) => {
      this.list = data;
    });
  }

  openConfirmationDialog(noteId, matterId) {
    this.confirmationDialogService
      .confirm("Please Confirm", "Do you really want to Delete?")
      .then((confirmed) => this.deleteMatterNotes(noteId, matterId, confirmed))
      .catch(() => {});
  }

  deleteMatterNotes(noteId, matterId, confirmed) {
    if (confirmed) {
      this.data.deleteMatterNotes(matterId, noteId).subscribe((response) => {
        this.getAllMatterNotesList();
        this.succ_msg = "Deleted Succesfully";
        setTimeout(() => {
          this.succ_msg = "";
          this.succ_msg = false;
        }, this.data.timeOut);
      });
    }
  }
}
